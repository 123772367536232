import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { NAV_ICON_LABELS } from "../bottom-nav-icon-list/constants";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import BottomNavIconList from "../bottom-nav-icon-list";
import { sendCustomGaEvents } from "../../../tracking";
import { bottomTabClicked } from "../../../constants/tracking-constants/super-home-events";
import { appUrl } from "../../../constants/url-constants";

const REDIRECT_URL = {
    HOME: "/",
    BUY_CAR: "/buy-used-car",
    CF: "/used-car-loan"
};

const BottomNavbar = ({
    history,
    cityInfo,
    isScrollingDown
}) => {

    const onClickSellTab = async () => {
        await yieldToMain();
        import(
            /* webpackChunkName: "sell-page" */ "../../../pages/mobile/home"
        ).finally(() => {
            history.push("/sell-used-cars");
        });
    };

    const redirectToUrl = (redirectTo) => {
        window.location.href = `${appUrl()}${redirectTo}`;
    };

    const onClickBuyCar = () => {
        const {seo_link: seoLink} = cityInfo || {};
        redirectToUrl(seoLink ? `/${seoLink}` : REDIRECT_URL.BUY_CAR);
    };

    const onTabClickHandler = useCallback(
        async (label, tab) => {
            try {
                switch (label) {
                case NAV_ICON_LABELS.HOME:
                    redirectToUrl(REDIRECT_URL.HOME);
                    break;
                case NAV_ICON_LABELS.BUY_CAR:
                    onClickBuyCar();
                    break;
                case NAV_ICON_LABELS.SELL_CAR:
                    onClickSellTab();
                    break;
                case NAV_ICON_LABELS.LOAN:
                    redirectToUrl(REDIRECT_URL.CF);
                    break;

                default:
                    return null;
                }

                await yieldToMain();
                sendCustomGaEvents({
                    ...bottomTabClicked,
                    eventLabel: (tab || "").split(" ").join("_")
                });

                return null;
            } catch (_) {
                //
            }
            return null;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [history]
    );

    return (
        <React.Fragment>
            <BottomNavIconList
                isScrollingDown={isScrollingDown}
                onTabClickHandler={onTabClickHandler}
            />
        </React.Fragment>
    );
};

BottomNavbar.propTypes = {
    history: PropTypes.object,
    cityInfo: PropTypes.object,
    isScrollingDown: PropTypes.bool
};

export default BottomNavbar;
