/* eslint-disable indent */
import React from "react";
import CarouselBottomArrow from "../../shared/carousel-bottom-arrow";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { Button } from "@cars24/turbo-web/lib/button";
import Map from "./images/map.png";
import { BodyText } from "@cars24/turbo-web/lib/body-text";
import { NUMBER } from "../../../constants/app-constants";
import LazyImage from "../../shared/lazy-image";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import HubFallback from "./images/hub-fallback.svg";
import { Heading } from "@cars24/turbo-web/lib/heading";

const BranchesHubs = ({
    heading = "CARS24 Hubs in your city",
    slidesToShow,
    hubsList = [],
    scrollWidgetGa
}) => {
    const { tokens } = useTokens();
    const sliderSetting = {
        slidesToShow,
        infinite: false,
        arrow: true,
        afterChange: scrollWidgetGa
    };
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    };
    const redirectToGoogleMap = (lat, lng) => {
        return window.location.replace(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`);
    };
    return (
        <React.Fragment>
            <div styleName="styles.branchWrapper">
            <Heading level={NUMBER.TWO} fontWeight="semibold" color={tokens.text_default_text_inverse}>{heading}</Heading>
            <div styleName={"styles.carouselWrap"}>
                <CarouselBottomArrow
                    settings={sliderSetting}>
                    {
                        hubsList.map((hub, index) => (
                            <div
                                styleName={"styles.cityCard"}
                                key={`hubs-slider ${index}`}
                            >
                                <div styleName={"styles.avtarImg"}>
                                    <LazyImage src={hub.parkingYardImage || HubFallback} alt={"hubs"} />
                                </div>
                                <div styleName={"styles.branchesHubsText"}>
                                    <BodyText level={NUMBER.TWO} isBold>{hub.branchName}</BodyText>
                                        <div styleName={"styles.address"}>
                                        <BodyText level={NUMBER.THREE} color={tokens.text_default_text_secondary}>{hub.address}</BodyText>
                                    </div>
                                    <div styleName={"styles.branchesHubsCta"}>
                                        <Button size="medium" label="Book inspection" onClick={goToTop} />
                                        <Button variant="secondary" size="medium" label="View location on map" prefixIcon={<img src={Map} alt="Map" />} onClick={() => redirectToGoogleMap(hub.lat, hub.lng)} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </CarouselBottomArrow>
            </div>
            </div>
        </React.Fragment>
    );
};

BranchesHubs.propTypes = {
    heading: PropTypes.string,
    slidesToShow: PropTypes.number,
    hubsList: PropTypes.array,
    scrollWidgetGa: PropTypes.func
};

export default BranchesHubs;
