/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { Fragment, useEffect, useState, createContext, useRef } from "react";
import loadable from "@loadable/component";
import Header from "../header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "../footer";
import "../../../styles/reset.mobile.css";
import "../../../styles/circle-progress.css";
import "../../../styles/slick.css";
import "../../../styles/slick-theme.css";
import "../../../styles/slick-reset-mobile.css";
import "@cars24/turbo-web/lib/index.css";
import { updateWebviewStatus, getProfileDetails, updateCurrentPageType } from "../../configuration/actions";
import { getItem, setItem } from "../../../utils/helpers/storage-crud";
import PropTypes from "prop-types";
import qs from "querystring";
import Error from "../../shared/error";
import ErrorHandler from "../../shared/with-error-handler";
// import ABExperiment from "../../shared/ab-experiment";
import "intersection-observer";
import smoothscroll from "smoothscroll-polyfill";
import "react-circular-progressbar/dist/styles.css";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
const RequestModal = loadable(() => import("../request-callback-modal"), { ssr: false });
import { showWishlistModal } from "../wishlisted-by-you/actions";
import { autoLogin, login } from "../login-with-user-details/actions";
import Smartlook from "smartlook-client";
import parseLoginCookie from "../../../utils/parsers/parse-login-cookie";
import { withRouter } from "react-router";
import AppNudgeHeader from "../app-nudge-header";
// import ChatIconComponent from "../chat-icon-comp";
const ChatIconComponent = loadable(() => import("../chat-icon-comp"), { ssr: false });
import AppNudgeHeaderSell from "../app-nudge-header-sell";
import BottomNavbar from "../bottom-navbar";
import useScrollStatus from "../../../hooks/use-scroll-status";

const ENABLE_POPUP_ROUTE = [
    "buy-used-cars",
    "car-details",
    "car-listing",
    "car-details-checkout"
];

const BOTTOM_NAV_ROUTES = [
    "used-car-loan",
    "buy-used-cars",
    "car-listing",
    "home",
    "sell-used-car",
    "used-car-evaluation",
    "seo"
];

export const LayoutContext = createContext(false);

// eslint-disable-next-line max-params
export default function withLayout(
    WrappedComponent,
    header = true,
    footer = true,
    options = {}
) {

    const {
        headerProps,
        enableRequestModal = true,
        nudgeProps,
        isSellAppDownloadBannerVisible = false,
        showBottomNavbar = false,
        bottomSticky = false
    } = options || {};
    const { showAppNudge, redirectionLink } = nudgeProps || {};

    // eslint-disable-next-line no-unused-vars
    const Layout = (props) => {
        const {
            showAppointmentLocationPicker,
            route = {},
            dlsMsiteListingExperiment = false,
            updateCurrentPageTypeConnect = () => {}
        } = props || {};
        const queryParams = parseLocationSearchParams(props.location.search);
        const { utm_source: utmSource = "" } = queryParams || {};
        const isUsedCarLoanPage = (route && (route.name || "").includes("used-car-loan"));
        const isUsedCarLoanExternalPartner = isUsedCarLoanPage && utmSource;
        const isEditBookingOnAppointmentConfirmation = props.location.pathname && props.location.pathname.includes("appointment/confirmation") && ("e" in queryParams) && showAppointmentLocationPicker;
        const forceHideFooter = queryParams.gsBookFlow || isEditBookingOnAppointmentConfirmation;
        const [toastMessageComp, setToastMessageComp] = useState(null);
        const [wishlistPopupComp, setWishlistPopupComp] = useState(null);
        const [wishlistPopupLoginComp, setWishlistPopupLoginComp] = useState(null);
        const [comingSoonComp, setComingSoonComp] = useState(null);
        const [cfTenurePopupComp, setCfTenurePopupComp] = useState(null);
        const [locationPickerComp, setLocationPickerComp] = useState(null);
        // const [bottomNavbarComp, setBottomNavbarComp] = useState(null);

        const showHeader = route.name === "car-listing" ? !dlsMsiteListingExperiment : true;
        const currentPageName = route && route.name || props.currentPageType;
        const showBottomNav = BOTTOM_NAV_ROUTES.includes(currentPageName) && !isUsedCarLoanExternalPartner;

        const footerRef = useRef(null);

        const { isScrollingDown } = useScrollStatus({ threshold: 100, ref: footerRef});

        useEffect(() => {
            if (props.toast) {
                import("../toast-message").then((comp) => {
                    setToastMessageComp(<comp.default route={props.route} />);
                });
            }
            if (props.isWishlistModalVisible) {
                import("../wishlist-popup").then((comp) => {
                    setWishlistPopupComp(<comp.default route={props.route} />);
                });
            }
            if (props.enableLoginPopup) {
                import("../wishlist-login-popup").then((comp) => {
                    setWishlistPopupLoginComp(<comp.default route={props.route} />);
                });
            }
            if (props.isComingSoon && currentPageName !== "buy-used-cars-home") {
                import("../coming-soon-city-modal").then((comp) => {
                    setComingSoonComp(<comp.default route={props.route} />);
                });
            }
            if (props.showLocationPicker || ENABLE_POPUP_ROUTE.includes(route.name)) {
                import("../location-picker-popup-v2").then((comp) => {
                    setLocationPickerComp(<comp.default route={props.route} />);
                });
            }

            if (props.isTenurePopupVisible) {
                import("../cf-tenure-popup").then((comp) => {
                    setCfTenurePopupComp(<comp.default route={props.route} />);
                });
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.toast, props.isWishlistModalVisible, props.enableLoginPopup, props.isComingSoon, props.showLocationPicker, props.isTenurePopupVisible, showBottomNavbar]);
        useEffect(() => {
            if (getItem("isWebView")) {
                props.updateWebviewStatusConnect(true);
            } else {
                const params = qs.parse(window.location.search.substring(1));
                const isWebView = [params.utm_source, params.device].includes("more_tab_app");
                if (isWebView || props.isWebview) {
                    props.updateWebviewStatusConnect(true);
                    setItem(true, "isWebView");
                }
            }
            if (props.isWebview) {
                const params = qs.parse(window.location.search.substring(1));
                if (params.authToken) {
                    const { mobile: userMobile = "" } = parseLoginCookie({ "Pvt_Authorization": params.authToken });
                    if (userMobile) {
                        props.autoLoginConnect({ phone_number: userMobile }).then((response) => {
                            const loginUrl = ((response.data || {}).login_url || "");
                            const accessToken = loginUrl.split("access-token=")[1] || "";
                            const payload = {
                                "access-token": accessToken,
                                redirect_url: encodeURIComponent(window.location.href)
                            };
                            props.loginConnect(payload).then(() => {
                                props.getProfileDetailsConnect();
                            }).catch(() => {
                                props.getProfileDetailsConnect();
                            });
                        });
                    }
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.isWebview]);

        useEffect(() => {
            smoothscroll.polyfill();
        }, []);

        useEffect(() => {
            return () => {
                props.showWishlistModalConnect(false);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.location.pathname]);

        useEffect(() => {
            if (window.smartlook) {
                const { name, mobile, email } = props || {};
                Smartlook.identify(mobile || email, {
                    name,
                    email,
                    mobile,
                    userId: mobile
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.isLoggedIn]);

        useEffect(() => {
            updateCurrentPageTypeConnect(route && route.name);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const appNudgeExperimentRoutes = ["buy-used-cars", "car-listing"];
        const isAppNudgeExperiment = appNudgeExperimentRoutes.includes(route.name) && props.appNudgeFloatingExperiment;

        const getLayout = () => {
            let component = (
                <Fragment>
                    <ErrorHandler>
                        {showAppNudge && !isAppNudgeExperiment ? <AppNudgeHeader route={route} redirectionLink={redirectionLink}/> : null}
                    </ErrorHandler>
                    <ErrorHandler>
                        {isSellAppDownloadBannerVisible ? <AppNudgeHeaderSell route={route} /> : null}
                    </ErrorHandler>

                    <ErrorHandler>
                        {header && !props.isWebview && showHeader && <Header isScrollingDown={isScrollingDown} route={route} showLocationPickerBar={isUsedCarLoanPage} showHamburgerMenu={!isUsedCarLoanExternalPartner} {...headerProps} showAppNudge={showAppNudge} />}
                        {/* {header && !props.isWebview && <CustomHeader {...props} />} */}
                    </ErrorHandler>
                    <ErrorHandler><LayoutContext.Provider value={isScrollingDown}> <WrappedComponent {...props} /> </LayoutContext.Provider></ErrorHandler>
                    <ErrorHandler>
                        {!forceHideFooter && footer && !props.isWebview && <Footer route={route} showBottomNavbar={showBottomNavbar} bottomSticky={bottomSticky} footerRef={footerRef}/>}
                    </ErrorHandler>
                    <ErrorHandler>
                        {wishlistPopupLoginComp &&
                            wishlistPopupLoginComp}
                    </ErrorHandler>
                    <ErrorHandler>
                        {wishlistPopupComp &&
                            wishlistPopupComp}
                    </ErrorHandler>
                    <ErrorHandler>
                        {(enableRequestModal && props.showCallBackPopup) && <RequestModal route={route} />}
                    </ErrorHandler>
                    {/* <ErrorHandler>
                        {enableRequestModal && <CEPRequestCallbackModal {...props} />}
                    </ErrorHandler> */}
                    <ErrorHandler>
                        {toastMessageComp &&
                            toastMessageComp
                        }
                    </ErrorHandler>
                    <ErrorHandler>
                        {/* <LocationPickerPopup {...props} /> */}
                        {/* { <LocationPickerPopupV2 {...props} /> } */}
                        {
                            locationPickerComp &&
                            locationPickerComp
                        }
                    </ErrorHandler>
                    <ErrorHandler>
                        <ChatIconComponent route={props.route} />
                        {/* <LiveChat/> */}
                    </ErrorHandler>
                    <ErrorHandler>
                        {comingSoonComp && comingSoonComp}
                    </ErrorHandler>
                    <ErrorHandler>
                        {
                            (cfTenurePopupComp && props.isTenurePopupVisible) &&
                            cfTenurePopupComp
                        }
                    </ErrorHandler>
                    <ErrorHandler>
                        {
                            showBottomNav && <BottomNavbar isScrollingDown={isScrollingDown} route={route} />
                        }
                    </ErrorHandler>
                    {/* <ErrorHandler>
                        <ChooseCountry />
                    </ErrorHandler> */}
                </Fragment>
            );
            if (props.isSSRError) {
                component = (
                    <Error />
                );
            }
            return component;
        };

        return getLayout();
    };

    Layout.propTypes = {
        isWebview: PropTypes.bool,
        updateWebviewStatusConnect: PropTypes.func,
        location: PropTypes.object,
        showWishlistModalConnect: PropTypes.func,
        showAppointmentLocationPicker: PropTypes.bool,
        loginConnect: PropTypes.func,
        autoLoginConnect: PropTypes.func,
        getProfileDetailsConnect: PropTypes.func,
        currentPageType: PropTypes.string
    };

    Layout.defaultProps = {
        showWishlistModalConnect: () => { }
    };

    const mapStateToProps = ({
        user: { isWebview, isSSRError, name, email, isLoggedIn, mobile, currentPageType },
        callBackDetails: {
            showCallBackPopup
        },
        wishlistLogin: { popup: enableLoginPopup },
        wishlist: { isWishlistModalVisible },
        consumerFinance: {
            isVisible: isCfVisible,
            isTenurePopupVisible
        },
        appointmentLocationPicker: {
            showAppointmentLocationPicker
        },
        locationPicker: {
            showLocationPicker,
            isComingSoon
        },
        toast: {
            toast
        },
        experiments: {
            dlsListingPage: {
                data: dlsMsiteListingExperiment
            },
            appNudgeFloatingExperiment: {
                data: appNudgeFloatingExperiment
            }
        }
    }) => {
        return {
            isWebview,
            isSSRError,
            showCallBackPopup,
            enableLoginPopup,
            isWishlistModalVisible,
            isCfVisible,
            showAppointmentLocationPicker,
            showLocationPicker,
            isComingSoon,
            toast,
            isTenurePopupVisible,
            name,
            email,
            isLoggedIn,
            mobile,
            dlsMsiteListingExperiment,
            currentPageType,
            appNudgeFloatingExperiment
        };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        updateWebviewStatusConnect: updateWebviewStatus,
        showWishlistModalConnect: showWishlistModal,
        loginConnect: login,
        autoLoginConnect: autoLogin,
        getProfileDetailsConnect: getProfileDetails,
        updateCurrentPageTypeConnect: updateCurrentPageType
    }, dispatch);

    return withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
}
