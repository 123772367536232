import React from "react";
import styles from "./styles.css";
import Search from "../search";
import PropTypes from "prop-types";
import CrossIcon from "../cross-image";

const SeoCityname = ({
    cities,
    onCitySelection = () => {},
    onClose = () => {}
}) => {
    return (
        <React.Fragment>
            <div styleName={"styles.cityName"}>
                <div styleName={"styles.searchBox"}>
                    <Search
                        autoFocus={true}
                        onChange={(data) => {
                            onCitySelection(data);
                            onClose();
                        }}
                        defaultMenuIsOpen={true}
                        placeholder={"Search city"}
                        data={cities}
                    />

                    <div styleName={"styles.closeIcon"} onClick={onClose}>
                        <CrossIcon/>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

SeoCityname.propTypes = {
    cities: PropTypes.array,
    onCitySelection: PropTypes.func,
    onClose: PropTypes.func
};

export default SeoCityname;
