import React from "react";
import styles from "./styles/styles.css";
import PropTypes from "prop-types";
import ArrowIcon from "../../shared/arrow";
import PilotFaq from "../../shared/pilot-faq/component";

const SeoCarOverviewFaq = ({
    carFullName,
    faqs,
    onPageRedirection
}) => {

    const getFaq = (faqSEO) => {
        const seoFaq = [];
        Object.keys(faqSEO).map((key) => (
            faqSEO[key].length > 0 && faqSEO[key].map((keyInside, indexInner) => (
                seoFaq.push({
                    id: indexInner + 1,
                    question: keyInside.seo_faq_question,
                    answer: keyInside.seo_faq_answer
                })
            ))
        ));
        return seoFaq;
    };

    return (
        <React.Fragment>
            {getFaq(faqs).length > 0 && <React.Fragment>
                <PilotFaq
                    heading={`FAQ’s ABOUT ${carFullName}`}
                    faqs={getFaq(faqs)}
                    columns={1}
                />
                <div styleName={"styles.readMore"} onClick={onPageRedirection}>VIEW MORE FAQs<ArrowIcon /></div>
            </React.Fragment>}
        </React.Fragment>
    );
};

SeoCarOverviewFaq.propTypes = {
    carFullName: PropTypes.string,
    faqs: PropTypes.string,
    onPageRedirection: PropTypes.func
};

export default SeoCarOverviewFaq;
