import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    data: {
        userDropList: [],
        allCardInfo: "",
        isUserDrop: false,
        isError: false,
        noDataFound: true,
        isUserToLead: false
    },
    isSSR: false,
    isU2lgaVisible: true
};

export const setUserDropData = (state = INITIAL_STATE, {data}) => {
    return { ...state, data};
};

export const setSsrStatus = (state = INITIAL_STATE, {isSSR}) => {
    return { ...state, isSSR};
};

export const resetUserDropData = () => {
    return INITIAL_STATE;
};

export const userDropUpdate = ({isU2lgaVisible}) => {
    return {...INITIAL_STATE, isU2lgaVisible};
};

export const setU2lGaFlag = (state = INITIAL_STATE, {isU2lgaVisible}) => {
    return { ...state, isU2lgaVisible};
};

export const HANDLERS = {
    [Types.SET_USER_DROP_DATA]: setUserDropData,
    [Types.SET_SSR_STATUS]: setSsrStatus,
    [Types.RESET_USER_DROP]: resetUserDropData,
    [Types.SET_U2L_GA_FLAG]: setU2lGaFlag,
    [Types.USER_DROP_UPDATE]: userDropUpdate
};

export default createReducer(INITIAL_STATE, HANDLERS);
