import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { setPageLoading } from "../../shared/car-animation-loader/actions";
import StageCard from "./component";
import { setStep, activateLastStep, resetLeadFormData, setFormData } from "../../mobile/lead-form/actions";
import { fetchData, fetchDataSuccess } from "../../mobile/banner-widget/actions";
import { fetchLeadDetails, fetchAppointmentDetails } from "../../mobile/appointment/actions";
import { saveUserConsent } from "../../configuration/actions";
import { updateStatsigConfig, updateStatsigTimeStamp } from "../ab-experiment/actions";
import { resetTruecallerDetails } from "../../mobile/truecaller-listener/actions";
import { setIsTrueCaller } from "../../mobile/lead-form/reducers";
import { updateRecommendationTransferStatus } from "../../mobile/best-matches-rail/actions";
import withOtp from "../with-otp";

const mapStateToProps = ({
    user: {
        name
    },
    userDropWidget,
    leadData,
    brands: {
        popular
    },
    widgetData,
    sellOnlineCities: {
        isSellOnline
    },
    experiments: {
        userToLeadDrop: {
            data: userToLeadDropExperiment
        },
        luxuryGoodPriceExperiment: {
            data: luxuryGoodPriceExperimentFlag
        },
        webQuoteRevampExperiment: {
            data: webQuoteRevampExperimentFlag
        }
    }
}) => ({
    userDropWidget,
    name,
    leadData,
    userToLeadDropExperiment,
    isSellOnline,
    widgetData,
    popular,
    luxuryGoodPriceExperimentFlag,
    webQuoteRevampExperimentFlag
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setFormDataConnect: setFormData,
    setStepConnect: setStep,
    activateLastStepConnect: activateLastStep,
    setPageLoadingConnect: setPageLoading,
    resetLeadFormDataConnect: resetLeadFormData,
    fetchDataConnect: fetchData,
    fetchDataSuccessConnect: fetchDataSuccess,
    fetchLeadDetailsConnect: fetchLeadDetails,
    fetchAppointmentDetailsConnect: fetchAppointmentDetails,
    saveUserConsentConnect: saveUserConsent,
    updateStatsigConfigConnect: updateStatsigConfig,
    updateStatsigTimeStampConnect: updateStatsigTimeStamp,
    resetTruecallerDetailsConnect: resetTruecallerDetails,
    setIsTrueCallerConnect: setIsTrueCaller,
    updateRecommendationTransferStatusConnect: updateRecommendationTransferStatus
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withOtp(StageCard)));
