import React from "react";
import Slider from "../slick-wrapper";
import { PropTypes } from "prop-types";
import styles from "./styles.css";

const CarouselBottomArrow = ({children, settings}) => {

    return (
        <div styleName={"styles.customCarousel"}>
            <Slider {...settings}>
                {children}
            </Slider>
        </div>
    );
};
CarouselBottomArrow.propTypes = {
    children: PropTypes.any,
    settings: PropTypes.object
};
export default CarouselBottomArrow;
