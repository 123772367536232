import { AuthService } from "../../../service/auth-service";
import { UsedCarService } from "../../../service/used-car-service";

const sendOTP = (data, params) => () => {
    return new Promise((resolve, reject) => {
        UsedCarService.gsBookSendOtp(data, params).then(resp => {
            const { data: { message } } = resp;
            resolve(message);
        }).catch(error => {
            reject(error);
        });
    });
};

const verifyOtp = (data, params) => () => {
    return new Promise((resolve, reject) => {
        UsedCarService.gsBookVerifyOtp(data, params)
            .then(response => {
                resolve(response.data.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const login = (params) => () => {
    return new Promise((resolve, reject) => {
        AuthService.login(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const autoLogin = (params) => () => {
    return new Promise((resolve, reject) => {
        AuthService.autoLogin(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export {
    sendOTP,
    verifyOtp,
    login,
    autoLogin
};
