import React, { useState } from "react";
import styles from "./styles/styles.css";
import PropTypes from "prop-types";
import ArrowIcon from "../../shared/arrow";
import H2 from "../../shared/h2/component";
import { NUMBER } from "../../../constants/app-constants";

const SeoCarOverviewSpecification = ({
    carFullName,
    model,
    specifications,
    onPageRedirection,
    hrefData
}) => {
    const {
        model_specifications: modelSpecifications,
        model_features: modelfeatures,
        content } = specifications;
    const [activeTab, setActiveTab] = useState("Features");
    const [activeData, setActiveData] = useState(modelSpecifications);

    const changeTabs = (active) => {
        if (active === "Features") {
            setActiveTab(active);
            setActiveData(modelSpecifications);
        } else {
            setActiveTab(active);
            setActiveData(modelfeatures);
        }
    };
    return (
        <React.Fragment>
            <div styleName={"styles.title"}>
                <H2 text={`${carFullName} key specifications`} />
            </div>
            <p styleName={"styles.txt"}>{content}</p>
            {modelfeatures ? <ul styleName={"styles.tabNav"}>
                <li styleName={activeTab === "Features" ? "styles.active" : ""}
                    onClick={() => changeTabs("Features")}>
                    Features
                </li>
                {modelfeatures && <li styleName={activeTab === "Specifications" ? "styles.active" : ""}
                    onClick={() => changeTabs("Specifications")}>
                    Specifications
                </li>}
            </ul> : <h3 styleName={"styles.headerText"}>Features</h3>}
            <div styleName={"styles.specification"}>
                <ul>
                    {
                        Object.keys(activeData || {}).slice(0, NUMBER.FIVE).map((key, index) => (
                            <li key={index}>
                                <p>{key}</p>
                                <span>{activeData[key]}</span>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div styleName={"styles.readMore"} onClick={onPageRedirection}><a href={hrefData("specifications")}>VIEW ALL {model} {activeTab === "Features" ? "FEATURES" : "SPECIFICATIONS"}</a> <ArrowIcon /></div>
        </React.Fragment>
    );
};

SeoCarOverviewSpecification.propTypes = {
    carFullName: PropTypes.string,
    specifications: PropTypes.string,
    onPageRedirection: PropTypes.func,
    model: PropTypes.string,
    hrefData: PropTypes.func
};

export default SeoCarOverviewSpecification;
