/* eslint-disable complexity */
/* eslint-disable max-statements */
import parseCookie from "../utils/helpers/parse-cookie";

const getDeviceType = () => {
    if (window.navigator.userAgent.match(/mobile/i)) {
        return "Mobile";
    } else if (window.navigator.userAgent.match(/iPad|Android|Touch/i)) {
        return "Tablet";
    } else {
        return "Desktop";
    }
};

const getAllCookies = () => {
    if (typeof document === "undefined" || typeof document === undefined) {
        return null;
    }
    return document.cookie.split(";");
};

const parseSingleCookie = (item) => {
    const items = item.trim().split("=");
    return items?.length > 0 ? items[1] : null;
};

export const getTrackingParameters = () => {
    if (typeof window === "undefined") {
        return {};
    }
    const returnObj = {
        "itm_source": "",
        "itm_medium": "",
        "utm_source": "",
        "utm_medium": "",
        "utm_keyword": "",
        "utm_matchtype": "",
        "utm_device": "",
        "adgroup_id": "",
        "campaign_id": "",
        "utm_campaign": "",
        "c2b_utm_content": "",
        "c2b_utm_term": "",
        "c2b_itm_source": "",
        "c2b_itm_medium": "",
        "c2b_utm_source": "",
        "c2b_utm_medium": "",
        "c2b_utm_keyword": "",
        "c2b_utm_matchtype": "",
        "c2b_utm_device": "",
        "c2b_adgroup_id": "",
        "c2b_campaign_id": "",
        "c2b_utm_campaign": ""
    };
    const cookies = getAllCookies();
    if (cookies && cookies.length) {
        cookies.forEach(item => {
            const items = item.trim().split("=");
            if (items?.[0] && returnObj.hasOwnProperty(items[0])) {
                returnObj[items[0]] = parseSingleCookie(item);
            }
        });
    }

    if (parseCookie("_ga")) {
        returnObj.gaId = parseCookie("_ga");
    }
    if (parseCookie("tvc_sm")) {
        returnObj.gtm_referral = parseCookie("tvc_sm");
    }
    if (parseCookie("c24-city")) {
        returnObj.userCity = parseCookie("c24-city");
    }

    const getLocalStorageItem = (key) => window.localStorage[key];

    if (getLocalStorageItem("c24_user_journey")) {
        const userJourney = JSON.parse(getLocalStorageItem("c24_user_journey"));
        returnObj.user_abjourney = userJourney.join("|");
    }
    returnObj.device_type = getDeviceType();

    if (parseCookie("leadSourceType")) {
        returnObj.leadSourceType = parseCookie("leadSourceType");
    }

    return returnObj;
};
