import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { BottomSheet } from "@cars24/turbo-web/lib/bottom-sheet";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import { BodyText } from "@cars24/turbo-web/lib/body-text";
import { TinyText } from "@cars24/turbo-web/lib/tiny-text";
import { Text } from "@cars24/turbo-web/lib/text";
import CarImg from "./images/car.png";
import { Button } from "@cars24/turbo-web/lib/button";
import { getPrice } from "../../../utils/helpers/price-formatter-min-max";

const GsTagNotSoldBottomSheet = ({content, isBottomsheet, setIsBottomsheet, isGSCarListed = false}) => {
    const { tokens } = useTokens();
    const {
        listingPrice,
        makeName,
        modelImageUrl,
        modelName,
        originalPrice,
        redirectionUrl,
        variantName,
        year
    } = content || {};
    const redirect = () => {
        window.location.href = redirectionUrl;
    };
    return (
        <BottomSheet
            overlayColor={tokens.background_surface_overlay_primary}
            height="fit-content"
            isOpen={isBottomsheet}
            onClose={setIsBottomsheet}
            onOverlayClick={setIsBottomsheet}
        >
            <div styleName={"styles.listedCarWrapper"}>
                <div styleName={"styles.titles"}>
                    <Text
                        align="center"
                        as="p"
                        color="#0F0F10"
                        fontWeight="semibold"
                        size="300"
                        transform="none"
                    >
                      Looks like this car is still listed<br/>on CARS24!
                    </Text>
                </div>
                <div styleName={"styles.card"}>
                    <img src={modelImageUrl || CarImg} alt="car" />
                    <div styleName={"styles.carDetailsWrapper"}>
                        <div styleName={"styles.carDetails"}>
                            <div styleName={"styles.carDetailsText"}>
                                <div>
                                    <BodyText
                                        level={2}
                                        isBold
                                        color={tokens.text_default_text_primary}
                                    >
                                        {`${year} ${makeName} ${modelName}`}
                                    </BodyText>
                                    <BodyText level={2} color={tokens.text_default_text_primary}>
                                        {variantName}
                                    </BodyText>
                                </div>
                                <div styleName={"styles.priceText"}>
                                    <BodyText
                                        level={2}
                                        isBold
                                        color={tokens.text_default_text_primary}
                                    >
                                     ₹{getPrice(listingPrice)}
                                    </BodyText>
                                    <TinyText
                                        strickThrough
                                    >
                                    ₹{getPrice(originalPrice)}
                                    </TinyText>
                                </div>
                            </div>
                            { isGSCarListed && <Button
                                label="View car details"
                                onClick={() => redirectionUrl && redirect()}
                                variant="primary"
                                size="small"
                            />}
                        </div>
                    </div>
                </div>
            </div>

        </BottomSheet>
    );
};
GsTagNotSoldBottomSheet.propTypes = {
    content: PropTypes.object,
    isBottomsheet: PropTypes.bool,
    setIsBottomsheet: PropTypes.func,
    isGSCarListed: PropTypes.bool
};
export default GsTagNotSoldBottomSheet;
