export const buyCarApplinkClick = {
    event: "c2c_download",
    eventCategory: "Buy_used_cars_C2C_HomePage",
    eventAction: "download"
};

export const footerClick = {
    event: "footer_click",
    eventAction: "footer"
};
