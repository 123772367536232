import React from "react";
import Statsig from "statsig-js";
import getMeaningfulExperimentNames from "../utils/statsig-ab-utils/get-meaningful-experiment-names";
import { useSelector, useDispatch } from "react-redux";
import { setExperiment as setExperimentConnect } from "../components/shared/ab-experiment/actions";

const useExperiments = ({ experimentKeys, callback = () => {} }) => {
    const { experiments: allExperiments } = useSelector(store => store);
    const { statsigConfig = false } = allExperiments || {};
    const {statsigInitialized = false, initializedTimeStamp = ""} = statsigConfig || {};

    const dispatch = useDispatch();

    const getExperiments = () => {

        experimentKeys.forEach((experimentName) => {
            const sanitizedExpName = getMeaningfulExperimentNames(experimentName);
            const experimentData = Statsig.getExperiment(experimentName);
            const { value } = experimentData || {};
            const { key = "", text = "" } = value || {};
            if (text) {
                callback(text);
            }
            dispatch(setExperimentConnect(sanitizedExpName, key));

        });

    };

    React.useEffect(() => {
        if (statsigInitialized && process.env.STATSIG_SECRET_CSR_KEY) {
            getExperiments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initializedTimeStamp]);

};

export default useExperiments;
