import { resetReducerKeys } from "../../../constants/reset-reducer-constant";
import Types from "./types";

const setData = () => ({
    type: Types.SET_DATA,
    noData: false
});

// eslint-disable-next-line
const selectCity = (id, name, image, url, viewAllCarsUrl, slug, isVirtualCity, carServiceCity=null, isB2CCity = false, isGroupCity = false,primaryGroupCity) => {
    return {
        type: Types.SELECT_CITY,
        data: {
            id,
            name,
            image,
            url,
            viewAllCarsUrl,
            slug,
            isVirtualCity: !!isVirtualCity,
            carServiceCity,
            isB2CCity,
            isGroupCity,
            primaryGroupCity: primaryGroupCity || null
        }
    };
};

const searchCity = data => ({ type: Types.SEARCH_CITY, data });

const updateC2CPromotionFlag = enableC2CPromotion => ({
    type: Types.UPDATE_C2C_PROMOTION_FLAG,
    enableC2CPromotion
});

const resetCities = () => ({ type: resetReducerKeys.RESET_CITIES });

const setCityAvailability = (isAvailable) => ({
    type: Types.SET_CITY_AVAILABILITY,
    isAvailable
});

export {
    searchCity,
    selectCity,
    setData,
    updateC2CPromotionFlag,
    resetCities,
    setCityAvailability
};
