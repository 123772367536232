import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserJourneyDrop from "./component";
import { withRouter } from "react-router";
import { setFormData, updateLead } from "../lead-form/actions";
import { setStep, activateLastStep, resetLeadFormData } from "../lead-form/actions";
import { setPageLoading } from "../../shared/car-animation-loader/actions";
import { resetLeadFormData as resetPopupData } from "../car-registration-landing-banner-experiment/actions";
import { fetchOffersDetails } from "../seller-offer/actions";

const mapStateToProps = ({
    user: {
        name
    },
    userDropWidget: {
        data: {userDropList, isUserToLead}
    },
    leadData,
    meta: { mainHeading, cmsContentDetails }
}) => ({
    userDropList,
    name,
    leadData,
    isUserToLead,
    mainHeading,
    cmsContentDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setFormDataConnect: setFormData,
    setStepConnect: setStep,
    activateLastStepConnect: activateLastStep,
    setPageLoadingConnect: setPageLoading,
    resetLeadFormDataConnect: resetLeadFormData,
    resetPopupDataConnect: resetPopupData,
    updateLeadConnect: updateLead,
    fetchOffersDetailsConnect: fetchOffersDetails

}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserJourneyDrop));
