import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        FETCH_DATA
        FETCH_LEAD_MODEL_SUCCESS
        FETCH_LEAD_MODEL_FAILURE
        SET_IS_LOADING
    `,
    {
        prefix: "leadmodels/"
    }
);
