/* eslint-disable consistent-return */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LEAD_FORM, LEAD_STATUSES, NUMBER, STEP_NAMES, USER_LOGIN_MEDIUM, USER_LOGIN_STATUS, stringConstants } from "../../../constants/app-constants";
import { APPOINTMENT_WIDGET_TYPE, SCRAP_CAR_TYPES } from "../../../constants/seller-constant";
import { getMonth, getParticularDay, getWeekDay } from "../../../utils/helpers/get-day-from-date";
import oridinalSuffix from "../../../utils/helpers/oridinal-suffix";
import {getCurrentStep, setLeadDetail } from "./selector";
import hasInvalidPrice from "../../../utils/helpers/has-invalid-price";
import checkScrapCarEligibility from "../../../utils/helpers/check-scrap-car-eligibility";
import { FUNNEL_CONFIG } from "../../../utils/funnel/steps.mobile";
import { sendCustomGaEvents } from "../../../tracking";
import { appointmentWidget } from "./tracking";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { ga4Signin } from "../../../constants/tracking-constants/ga4-ecommerce-event";
import { statsigInitialization } from "../../../utils/statsig-ab-utils/initialize-user-csr";
import { variants } from "../../../constants/variant-constants";

const useAppointmentWidget = ({
    leadData,
    leadResponse,
    appointmentAlreadyExist,
    fetchAppointmentDetailsConnect,
    sourceType,
    onOtpVerify,
    setShowOtpField,
    setIsTrueCallerConnect,
    updateStatsigConfigConnect,
    saveUserConsentConnect,
    phoneNumber,
    updateStatsigTimeStampConnect,
    otp,
    userCityId,
    gaId
}) => {
    const history = useHistory();

    const [isFetching, setIsFetching] = useState(false);
    const [widgetType, setWidgetType] = useState(null);
    const [appointmentDetail, setAppointmentDetail] = useState(null);
    const [showLoginScreen, setShowLoginScreen] = useState(false);
    const [LoginAuthenticatorWithTruecallerComp, setLoginAuthenticatorWithTruecallerComp] = useState(null);
    const [allowWhatsapp, setAllowWhatsapp] = useState(true);

    const scrapEligibility = checkScrapCarEligibility(leadResponse || {});
    const isScrapCar = scrapEligibility === SCRAP_CAR_TYPES.ELIGIBLE;

    const parseDateString = (date) => {
        const _date = new Date(date);
        const day = getWeekDay(_date, false);
        const appoinntmentDate = oridinalSuffix(getParticularDay(_date));
        const month = `${getMonth(_date, true)}`;
        return {
            year: _date.getFullYear(),
            day,
            appoinntmentDate: `${appoinntmentDate} ${month},`
        };
    };

    useEffect(() => {
        let payload = null;
        if (leadData) {
            if (leadData.year.id && !appointmentAlreadyExist) {
                payload = {
                    brand: leadData.brand,
                    model: leadData.model,
                    year: leadData.year,
                    fuelType: leadData.fuelType,
                    transmission: leadData.transmission,
                    variant: leadData.variant,
                    state: leadData.registrationState,
                    rto: leadData.rto

                };
            }

            if (leadResponse) {
                payload = setLeadDetail(leadResponse);
                setIsFetching(true);
                if (leadResponse.lead_type === "original" || leadResponse.lead_type === "mkt_page") {
                    if (hasInvalidPrice(leadData.priceRange)) {
                        setWidgetType(APPOINTMENT_WIDGET_TYPE.WEB_QUOTE_NOT_FOUND);
                    } else {
                        payload.vgmin = leadResponse.very_good_min_quote_price;
                        payload.vgmax = leadResponse.very_good_max_quote_price;
                        setWidgetType(APPOINTMENT_WIDGET_TYPE.WEB_QUOTE_GENERATED);
                    }
                    if (leadResponse.status === LEAD_STATUSES.CAPTURED) {
                        fetchAppointmentDetailsConnect(leadResponse.token)
                            .then(appointmentResponse => {
                                if (appointmentResponse.appointment_geo_data) {
                                    if (appointmentResponse.verified === stringConstants.YES) {
                                        setWidgetType(APPOINTMENT_WIDGET_TYPE.APPOINTMENT_CREATED);
                                        payload.appointment = {
                                            date: parseDateString(appointmentResponse.date).appoinntmentDate,
                                            day: parseDateString(appointmentResponse.date).day,
                                            isOpenSlot: appointmentResponse.is_open_slot,
                                            time: appointmentResponse.time,
                                            openSlotTime: appointmentResponse.open_slot_time,
                                            timeRange: appointmentResponse.time_range
                                        };
                                    }
                                }

                            });
                    }
                } else if (isScrapCar || (checkScrapCarEligibility(leadResponse || {}) === SCRAP_CAR_TYPES.NON_SERVICIBLE)) {
                    setWidgetType(APPOINTMENT_WIDGET_TYPE.WEB_QUOTE_GENERATED);
                }

            } else {
                setWidgetType(APPOINTMENT_WIDGET_TYPE.LEAD_NOT_CREATED);
            }
            setIsFetching(false);
            setAppointmentDetail(payload);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadData]);

    const goToSteps = () => {

        if (widgetType === APPOINTMENT_WIDGET_TYPE.LEAD_NOT_CREATED) {
            if (!sourceType) {
                const { stepper = {} } = FUNNEL_CONFIG[LEAD_FORM.DEFAULT] || {};
                const currentStep = getCurrentStep({ leadData, currentStep: leadData.currentStep, stepper });
                if (leadData && leadData.variantActiveScreen === "fueltype" && leadData.fuelType.displayText === variants.Electric) {
                    history.push(`/sell-online-evaluation/${STEP_NAMES.Variant}/${STEP_NAMES.FuelType}`);
                } else {history.push(`/sell-online-evaluation/${currentStep}`);}
            }
            sendCustomGaEvents({
                ...appointmentWidget,
                eventLabel: "lead_not_created"
            });
        } else if (widgetType === APPOINTMENT_WIDGET_TYPE.WEB_QUOTE_GENERATED && isScrapCar) {
            if (leadResponse && leadResponse.scrapLeadDetail && (leadResponse.scrapLeadDetail.customerConsent || leadResponse.scrapLeadDetail.netAmount)) {
                history.push(`/scrap-cars/final-price/${leadResponse.token}`);
            } else {
                history.push(`/scrap-cars/info/${leadResponse.token}`);
            }
        } else if (widgetType === APPOINTMENT_WIDGET_TYPE.WEB_QUOTE_GENERATED || widgetType === APPOINTMENT_WIDGET_TYPE.WEB_QUOTE_NOT_FOUND) {
            sendCustomGaEvents({
                ...appointmentWidget,
                eventLabel: "lead_created"
            });
            history.push(`/appointment/index?token=${leadData.token}`);
        }
    };

    const onWhatsappChange = (e) => {
        const { target: { checked } } = e;
        setAllowWhatsapp(checked);
    };

    const saveConsent = () => {
        const params = {
            chat: allowWhatsapp
        };
        saveUserConsentConnect(phoneNumber, params);
    };

    const onBack = () => {
        setShowOtpField(false);
        setShowLoginScreen(false);
    };

    const onTruecallerSuccess = () => {
        setIsTrueCallerConnect(true);
        onBack();
    };

    const otpVerificationHandler = async () => {
        if (otp && otp.length === NUMBER.FOUR) {
            onOtpVerify().then(async () => {
                saveConsent();
                updateStatsigConfigConnect({ statsigInitialized: true });
                await statsigInitialization({ city: userCityId, clientId: gaId, updateUser: true });
                updateStatsigTimeStampConnect();
                onBack();
                await yieldToMain();
                sendCustomGaEvents({
                    ...ga4Signin,
                    // user_id: phoneNumber,
                    user_status: USER_LOGIN_STATUS.LOGGED_IN,
                    login_type: USER_LOGIN_MEDIUM.PHONE
                });
            });
        }
    };

    const importLoginAuthenticatorWithTrueCaller = () => {
        if (LoginAuthenticatorWithTruecallerComp === null) {
            import(/* webpackChunkName: "login-authenticator-with-truecaller" */ "../../mobile/login-authenticator-with-truecaller").then((comp) => {
                setLoginAuthenticatorWithTruecallerComp(() => comp.default);
                setIsFetching(false);
                setShowLoginScreen(true);
            });
        } else {
            setIsFetching(false);
            setShowLoginScreen(true);
        }
    };

    const loginCallback = async () => {
        await yieldToMain();
        setIsFetching(true);
        importLoginAuthenticatorWithTrueCaller();
    };

    return {
        isFetching,
        widgetType,
        appointmentDetail,
        isScrapCar,
        parseDateString,
        goToSteps,
        loginCallback,
        otpVerificationHandler,
        onTruecallerSuccess,
        onWhatsappChange,
        showLoginScreen,
        LoginAuthenticatorWithTruecallerComp,
        allowWhatsapp,
        onBack
    };
};

export default useAppointmentWidget;
