export const brandIconClicked = {
    event: "brand_icon_clicked",
    eventCategory: "homepage",
    eventAction: "brand_selected"
};

export const moreBrandsClicked = {
    event: "more_brands_clicked",
    eventCategory: "homepage",
    eventAction: "more_brands"
};

export const moreButton = {
    event: "more_button",
    eventCategory: "homepage",
    eventLabel: "moreButton",
    eventAction: "topbanner"
};

export const brandSelectionHome = {
    event: "more_button",
    eventCategory: "homepage",
    eventLabel: "brandSelection",
    eventAction: "modelSelectionScreenOpen"
};
