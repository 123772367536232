import jwtDecoder from "jwt-decode";
import { NUMBER } from "../../constants/app-constants";

export default (cookies = {}) => {
    try {
        const { Pvt_Authorization: secureToken = "" } = cookies || {};
        const decoded = jwtDecoder(secureToken);
        const { email, phoneNumber, userId, exp } = decoded || {};
        const currentTime = Math.floor(Date.now() / NUMBER.THOUSAND);
        if (exp >= currentTime) {
            return {
                email,
                userId,
                secureToken,
                isUserSessionValid: true,
                mobile: phoneNumber.toString(),
                profileMobile: phoneNumber.toString(),
                isLoggedIn: true,
                unAuthorized: false,
                isFetched: true
            };
        }
        return {
            isFetched: true
        };
    } catch (e) {
        return {
            isFetched: true
        };
    }
};
