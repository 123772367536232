import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import SemiModal from "../../desktop/semi-modal";
import warningIllustration from "./images/warning.svg";
import CloseButton from "../../shared/icon-cross";

const BookingLimitReached = ({ onClose }) => {
    return (
        <SemiModal>
            <div styleName={"styles.modalWrap"}>
                <span styleName={"styles.close"} onClick={onClose}><CloseButton type="orange" /> </span>
                <img src={warningIllustration} alt="Booking Limit Reached " />
                <h4 styleName={"styles.heading"}>Booking Limit Reached !</h4>
                <p styleName={"styles.content styles.message"}>Sorry, you have reached the maximum <br/> attempts for booking a car.</p>
                <p styleName={"styles.content"}>For more details, please contact <br/> CARS24 customer support</p>
            </div>
        </SemiModal>
    );
};

BookingLimitReached.propTypes = {
    onClose: PropTypes.func
};

export default BookingLimitReached;
