export const USER_JOURNEY_STEPS = {
    LEAD: "LEAD",
    LEAD_QUESTION: "LEAD_QUESTION",
    LEAD_NARROW: "LEAD_NARROW",
    LEAD_NO_PRICE: "LEAD_NO_PRICE",
    LEAD_SCRAP: "LEAD_SCRAP",
    LEAD_SCRAP_PRICE: "LEAD_SCRAP_PRICE",
    LEAD_SCRAP_RANGE: "LEAD_SCRAP_RANGE",
    BOOKED: "BOOKED",
    BOOKED_SO: "BOOKED_SO",
    BOOKED_NARROW: "BOOKED_NARROW",
    CONFIRMED: "CONFIRMED",
    CONFIRMED_SO: "CONFIRMED_SO",
    CONFIRMED_NARROW: "CONFIRMED_NARROW",
    MISSED: "MISSED",
    MISSED_SO: "MISSED_SO",
    MISSED_NARROW: "MISSED_NARROW",
    CANCELLED: "CANCELLED",
    INSPECTED: "INSPECTED",
    INSPECTED_PRICE_ACTIVE: "INSPECTED_PRICE_ACTIVE",
    INSPECTED_IN_PROGRESS: "INSPECTED_IN_PROGRESS",
    INSPECTED_PRICE_EXPIRED: "INSPECTED_PRICE_EXPIRED",
    INSPECTED_NO_BID: "INSPECTED_NO_BID",
    INSPECTED_USER_INTENT: "INSPECTED_USER_INTENT",
    ACTIVE_PRICE_EXPIRED: "ACTIVE_PRICE_EXPIRED",
    SO_RESCHEDULE: "SO_RESCHEDULE",
    USER_TO_LEAD: "USER_TO_LEAD",
    BOUGHT_TO_STOCKIN: "BOUGHT_TO_STOCKIN",
    STALE_LEAD: "STALE_LEAD",
    STALE_LEAD_NARROW: "STALE_LEAD_NARROW",
    STALE_LEAD_QUESTION: "STALE_LEAD_QUESTION"
};

export const WIDGET_RESPONSE_CONSTANTS = {
    YES: "Yes",
    ECONNABORTED: "ECONNABORTED"
};
