/* eslint-disable max-params */
/* eslint-disable new-cap */
import Types from "./types";
import ConsumerFinanceService from "../../../service/consumer-finance-service";
import { UsedCarService } from "../../../service/used-car-service";
import { CHECKOUT_STEP_KEY } from "../../../constants/checkout-constants";
import UploadService from "../../../service/upload-service";
import b2cCrmService from "../../../service/b2c-crm-service";
import { formatAddressSlotsResponse } from "../faster-checkout/util";
import { CheckoutService } from "../../../service/checkout-service";
import { OmsAggregatorService } from "../../../service/oms-aggregator-service";
import { resetReducerKeys } from "../../../constants/reset-reducer-constant";
import { B2cGatewayService } from "../../../service/b2c-gateway-service";

const updateErrorReason = (data) => ({
    type: Types.UPDATE_ERROR_REASON,
    data
});

const setStep = ({ currentStep, tabs }) => {
    return {
        type: Types.SET_CURRENT_STEP,
        currentStep,
        tabs
    };
};

const setFinanceData = (data) => {
    return {
        type: Types.SET_FINANCE_DATA,
        data
    };
};

const setLoanTenureValue = ({ loanTenure }) => {
    return {
        type: Types.SET_LOAN_TENURE_VALUE,
        loanTenure
    };
};

const setCreditScoreValue = ({ creditScoreValue }) => {
    return {
        type: Types.SET_CREDIT_SCORE_VALUE,
        creditScoreValue
    };
};

const setDownPaymentValue = ({ downPaymentValue }) => {
    return {
        type: Types.SET_DOWNPAYMENT_VALUE,
        downPaymentValue
    };
};

const setMonthlyEmiValue = ({ monthlyEmiValue }) => {
    return {
        type: Types.SET_MONTHLY_EMI_VALUE,
        monthlyEmiValue
    };
};

const setDownpaymentChanged = (isChanged) => {
    return {
        type: Types.SET_DOWNPAYMENT_CHANGED,
        isChanged
    };
};

const setOrder = ({
    bookingAmount,
    bookingConfirmDate,
    chargeDetails,
    country,
    createdDate,
    currency,
    deliveryAddress,
    deliveryMode,
    financeOpted,
    financeTerms,
    finalFinanceTerms,
    id,
    orderId,
    pickupStoreId,
    pickupTime,
    status,
    storeId,
    totalAmount,
    userId,
    vehiclePrice,
    vehicleType,
    userDetailsSubmitted,
    userVariant,
    pincodeServiceable,
    deliveryTime,
    deliveredDetails,
    orderReturnDetails,
    deliveryPickupVariant,
    fasterCheckout,
    paymentMethodType,
    orderReward,
    experimentVariant,
    bankStatementSubmitted
}) => {
    return {
        type: Types.SET_ORDER,
        bookingAmount,
        bookingConfirmDate,
        chargeDetails,
        country,
        createdDate,
        currency,
        deliveryAddress,
        deliveryMode,
        financeOpted,
        financeTerms,
        finalFinanceTerms,
        id,
        orderId,
        pickupStoreId,
        pickupTime,
        status,
        storeId,
        totalAmount,
        userId,
        vehiclePrice,
        vehicleType,
        userDetailsSubmitted,
        userVariant,
        pincodeServiceable,
        deliveryTime,
        orderReturnDetails,
        deliveredDetails,
        deliveryPickupVariant,
        fasterCheckout,
        paymentMethodType,
        orderReward,
        experimentVariant,
        bankStatementSubmitted
    };
};

const setVehiclePrice = (price) => ({
    type: Types.SET_VEHICLE_PRICE,
    price
});

const setFinanceSelectedValue = ({ financeSelected }) => {
    return {
        type: Types.SET_FINANCE_SELECTED,
        financeSelected
    };
};

const setMinMaxDownPaymentValue = ({ minDownpaymentValue, maxDownpaymentValue }) => {
    return {
        type: Types.SET_MIN_MAX_DOWNPAYMENT_VALUE,
        minDownpaymentValue,
        maxDownpaymentValue
    };
};

const setMinMaxEmiValue = ({ minEmiValue, maxEmiValue }) => {
    return {
        type: Types.SET_MIN_MAX_EMI_VALUE,
        minEmiValue,
        maxEmiValue
    };
};

const getOrderDetailInit = () => ({
    type: Types.GET_ORDER_DETAIL_INIT
});

const getOrderDetailSuccess = (data) => ({
    type: Types.GET_ORDER_DETAIL_SUCCESS,
    data
});

const getOrderDetailFailure = (error) => ({
    type: Types.GET_ORDER_DETAIL_FAILURE,
    error
});

const getOrderDetail = (appointmentId, token, listingPrice) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(getOrderDetailInit());
        CheckoutService.getOrderSummary(appointmentId, token)
            .then(response => {
                dispatch(setVehiclePrice(listingPrice));
                dispatch(getOrderDetailSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(getOrderDetailFailure(error));
                // dispatch(updateErrorReason(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const getOrderDetailByOrderId = (orderId, token, listingPrice) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(getOrderDetailInit());
        OmsAggregatorService.getOrderDetails(token, orderId)
            .then(response => {
                dispatch(setVehiclePrice(listingPrice));
                dispatch(getOrderDetailSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(getOrderDetailFailure(error));
                // dispatch(updateErrorReason(error));
                reject(error);
            });
    });
};

const checkOrderDetailInit = () => ({
    type: Types.CHECK_ORDER_DETAIL_INIT
});

const checkOrderDetailSuccess = (data) => ({
    type: Types.CHECK_ORDER_DETAIL_SUCCESS,
    data
});

const checkOrderDetailFailure = (error) => ({
    type: Types.CHECK_ORDER_DETAIL_FAILURE,
    error
});

const checkOrderDetail = (query, appointmentId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(checkOrderDetailInit());
        OmsAggregatorService.checkOrderDetail(query, appointmentId, token)
            .then(response => {
                dispatch(checkOrderDetailSuccess(response.data));
                dispatch(setVehiclePrice(query.vehiclePrice));
                resolve(response.data);
            }).catch(error => {
                dispatch(checkOrderDetailFailure(error));
                dispatch(updateErrorReason(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const createOrderSuccess = (data) => ({
    type: Types.CREATE_ORDER_SUCCESS,
    data
});

const createOrderFailure = (error) => ({
    type: Types.CREATE_ORDER_FAILURE,
    error
});

const createOrder = (params, token, source, listingPrice) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.createOrder(params, token, source)
            .then(response => {
                dispatch(setVehiclePrice(listingPrice));
                dispatch(createOrderSuccess(response.data));
                resolve(response.data);
                // resolve(ORDER_MOCK_1);
                // dispatch(createOrderSuccess(ORDER_MOCK_1));
            })
            .catch(error => {
                dispatch(createOrderFailure(error));
                dispatch(updateErrorReason(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const createOrderWoL = (params, token, source, listingPrice) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.createOrderWoL(params, token, source)
            .then(response => {
                dispatch(setVehiclePrice(listingPrice));
                dispatch(createOrderSuccess(response.data));
                resolve(response.data);
                // resolve(ORDER_MOCK_1);
                // dispatch(createOrderSuccess(ORDER_MOCK_1));
            })
            .catch(error => {
                dispatch(createOrderFailure(error));
                dispatch(updateErrorReason(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const getfetchFinanceInitiated = () => ({
    type: Types.GET_FINANCE_INITIATED
});

const getfetchFinanceSuccess = () => ({
    type: Types.GET_FINANCE_SUCCESS
});

const getfetchFinanceFailure = () => ({
    type: Types.GET_FINANCE_FAILURE
});

const fetchFinance = (vehiclePrice) => (dispatch) => {
    dispatch(getfetchFinanceInitiated());
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().fetchFinanceDetail(vehiclePrice)
            .then(response => {
                dispatch(getfetchFinanceSuccess());
                resolve(response.data);
            })
            .catch(err => {
                dispatch(getfetchFinanceFailure());
                dispatch(updateErrorReason(err));
                reject(err);
            });
        // CheckoutService.fetchFinanceDetail(params)
        //     .then(response => resolve(response.data))
        //     .catch(error => reject(error));
        // setTimeout(() => {
        //     resolve(CHECKOUT_MOCK_DATA);
        // });
    });

};

const updateFinancePersonalDetail = (params, orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinancePersonalDetail(params, orderId, token)
            .then(response => resolve(response.data))
            .catch(error => {
                dispatch(updateErrorReason(error));
                reject(error);
            });
    });
};

// eslint-disable-next-line max-params
const updateFinanceDetail = (order, orderId, token, source) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinanceDetail(order, orderId, token, source)
            .then(response => resolve(response.data))
            .catch(error => {
                dispatch(updateErrorReason(error));
                reject(error);
            });
    });
};

const getDeliveryInfoSuccess = (data, pincode, deliveryMode) => ({
    type: Types.GET_DELIVERY_INFO_SUCCESS,
    data,
    pincode,
    deliveryMode
});

const getDeliveryInfoFailure = (error, deliveryMode) => ({
    type: Types.GET_DELIVERY_INFO_FAILURE,
    error,
    deliveryMode
});

const getDeliveryInfo = ({ appointmentId, pincode, token, deliveryMode, clientId }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getPickupLocations({ appointmentId, pincode, token, clientId, source: "mSite" }).then((response) => {
            dispatch(getDeliveryInfoSuccess(response.data.data, pincode, deliveryMode));
            resolve(response.data.data);
        }).catch((error) => {
            dispatch(getDeliveryInfoFailure(error, deliveryMode));
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });
};

const updateDeliveryModeSuccess = (data) => ({
    type: Types.UPDATE_DELIVERY_MODE_SUCCESS,
    data
});

const updateDeliveryModeFailure = (error) => ({
    type: Types.UPDATE_DELIVERY_MODE_FAILURE,
    error
});

const updateDeliveryMode = (payload, orderId, token, source) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateTestDriveDetail(payload, orderId, token, source).then((response) => {
            dispatch(updateDeliveryModeSuccess(response.data));
            resolve();
        }).catch(error => {
            dispatch(updateDeliveryModeFailure(error));
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });
};

const initiateOrderPayment = ({ payload, paymentMethod, orderId, token, source, bi2bcExperiment }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.initiateOrderPayment({ payload, paymentMethod, orderId, token, source, bi2bcExperiment }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });
};

const updateOrderBooking = (orderId, token) => (dispatch) => {
    return new Promise((resolve) => {
        CheckoutService.updateOrderBooking(orderId, token).then((data) => {
            resolve(data);
        }).catch((error) => dispatch(updateErrorReason(error)));
    });
};

const uploadFile = (url, formData) => (dispatch) => {
    return new Promise((resolve) => {
        UploadService().uploadFile(url, formData).then(data => {
            resolve(data);
        }).catch((error) => dispatch(updateErrorReason(error)));
    });
};

const setSelectedFinancingData = ({ loanTenure }) => ({
    type: Types.SET_SELECTED_FINANCING_DATA,
    loanTenure
});

const setOrderId = ({ orderId }) => ({
    type: Types.SET_ORDER_ID,
    orderId
});

const setOrderCreatedDateTime = ({ orderCreatedDatetime }) => ({
    type: Types.SET_ORDER_CREATED_DATE_TIME,
    orderCreatedDatetime
});

const setDeliveryMode = ({ deliveryMode }) => ({
    type: Types.SET_DELIVERY_MODE,
    deliveryMode
});

const setFinanceChargeList = ({ financeChargeList }) => ({
    type: Types.SET_FINANCE_CHARGE_LIST,
    financeChargeList
});

const setChargeDetailsList = (chargeDetailsList) => ({
    type: Types.SET_CHARGE_LIST,
    chargeDetailsList
});

const setFinancingTab = ({ financingTab }) => ({
    type: Types.SET_FINANCING_TAB,
    financingTab
});

const setFinancingScreen = ({ activeScreen }) => ({
    type: Types.SET_FINANCE_SCREEN,
    activeScreen
});

const setFinancePersonalDetails = (data) => ({
    type: Types.SET_FINANCE_PERSONAL_DETAILS,
    data
});

const setFinanceAddressDetails = (data) => ({
    type: Types.SET_FINANCE_ADDRESS_DETAILS,
    data
});

const setFinanceIncomeDetails = (data) => ({
    type: Types.SET_FINANCE_INCOME_DETAILS,
    data
});

const setData = ({ type, data }, { stepper }) => dispatch => {
    switch (type) {
    case CHECKOUT_STEP_KEY.Financing:
        dispatch(setSelectedFinancingData({
            loanTenure: data.loanTenure,
            stepper
        }));
        break;
    }
};

const setFinanceUpdatedData = ({
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList,
    loanAmount
}) => ({
    type: Types.SET_FINANCE_UPDATED_DATA,
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList,
    loanAmount
});

const setCheckoutInitialStep = (step) => ({
    type: Types.SET_INITIAL_STEP,
    step
});

const setDeliveryData = (data) => ({
    type: Types.SET_DELIVERY_DATA,
    data
});

const setConfirmBookingData = (data) => ({
    type: Types.SET_CONFIRM_BOOKING_DATA,
    data
});

const cancelOrder = (orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.cancelOrder(orderId, token)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch(createOrderFailure(error));
                dispatch(updateErrorReason(error));
                reject(error);
            });
    });
};

const getPaymentSummary = (token, orderId) => async (dispatch) => {
    try {
        const response = await CheckoutService.getPaymentSummary(token, orderId);
        return response.data;
    } catch (error) {
        dispatch(updateErrorReason(error));
        throw error;
    }
};

const getNeftInfo = (token, orderId) => async (dispatch) => {
    try {
        const response = await CheckoutService.getNeftInfo(token, orderId);
        return response.data;
    } catch (error) {
        dispatch(updateErrorReason(error));
        throw error;
    }
};

const initPostBookingPayment = (token, orderId, amount) => async (dispatch) => {
    try {
        const response = await CheckoutService.initPostBookingPayment(token, orderId, amount);
        return response.data;
    } catch (error) {
        dispatch(updateErrorReason(error));
        throw error;
    }
};

const getPostBookingPaymentStatus = (token, orderId, paymentId) => async (dispatch) => {
    try {
        const response = await CheckoutService.getPostBookingPaymentStatus(token, orderId, paymentId);
        return response.data;
    } catch (error) {
        dispatch(updateErrorReason(error));
        throw error;
    }
};

const getPaymentHistory = (token, orderId) => async (dispatch) => {
    try {
        const response = await CheckoutService.getPaymentHistory(token, orderId);
        return response.data;
    } catch (error) {
        dispatch(updateErrorReason(error));
        throw error;
    }
};

const resetCheckoutData = () => dispatch => {
    dispatch({ type: resetReducerKeys.RESET_CHECKOUT });
};

const updateActiveFinanceForm = (formStep) => {
    return {
        type: Types.UPDATE_ACTIVE_FINANCE_FORM,
        formStep
    };
};

const updateFinanceFormState = (formStep, isCompleted) => {
    return {
        type: Types.UPDATE_FINANCE_FORM_STATE,
        formStep,
        isCompleted
    };
};

const getPerfiosUrl = (orderId, params, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.getPerfiosUrl(orderId, params, token).then((data) => {
            resolve(data.data);
        }).catch((error) => {
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });
};

const uploadBankDocument = (params) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().uploadDocumentV2(params).then((data) => {
            resolve(data.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const fetchPincodeCity = (pincode, updateAddress = true) => dispatch => {
    return new Promise((resolve, reject) => {
        UsedCarService.fetchPincodeConfig(pincode)
            .then(response => {
                const { stateName, cityName } = response.data.data;
                if (updateAddress) {
                    dispatch(setFinanceAddressDetails({
                        pincode,
                        state: stateName,
                        city: cityName
                    }));
                }

                resolve(response.data.data);
            }).catch((err) => {
                reject(err);
            });

    });
};

const validatePan = (params) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().validatePan(params).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

// const updateVisitedSteps = (step) => ({
//     type: Types.UPDATE_VISITED_STEPS,
//     step
// });

const getUserLatestOrder = (latestOrder) => ({
    type: Types.FETCH_LATEST_ORDER,
    latestOrder
});

const fetchUserLatestOrder = (token) => dispatch => {
    return new Promise((resolve, reject) => {
        CheckoutService.getUserLatestOrder(token).then((data) => {
            dispatch(getUserLatestOrder(data.data));
            resolve(data);
        }).catch((error) => {
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });
};

const getDeliverySlotsSuccess = (data) => ({
    type: Types.GET_DELIVERY_SLOTS_SUCCESS,
    data
});

const resetDeliverySlots = () => ({
    type: Types.RESET_DELIVERY_SLOTS
});

const getDeliverySlotsFailure = (error) => ({
    type: Types.GET_DELIVERY_SLOTS_FAILURE,
    error
});

const getDeliverySlots = (appointmentId, token, params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.getDeliverySlotsV2(appointmentId, token, params)
            .then(response => {
                dispatch(getDeliverySlotsSuccess(response.data?.dateSlots));
                resolve(response.data?.dateSlots);
            }).catch(error => {
                dispatch(getDeliverySlotsFailure(error));
                dispatch(updateErrorReason(error));
                reject(error);
            });
    });
};

const getBankList = () => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getBankList().then((data) => {
            resolve(data.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getBankStatementStatus = (userId) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getBankStatementStatus(userId).then((data) => {
            resolve(data.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getDeeplinkingPerfiosUrl = (params, token, source) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.getDeeplinkingPerfiosUrl(params, token, source).then((data) => {
            resolve(data.data);
        }).catch((error) => {
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });
};
const getAllOfferssInitiated = () => ({
    type: Types.GET_ALL_OFFER_INITIATED
});

const getAllOffersSuccess = (data) => ({
    type: Types.GET_ALL_OFFER_SUCCESS,
    data
});

const getAllOffersFailure = (error) => ({
    type: Types.GET_ALL_OFFER_FAILURE,
    error
});

const getAllOffers = (params) => (dispatch) => {
    dispatch(getAllOfferssInitiated());
    // dispatch(getAllOffersSuccess(SELECT_OFFER_MOCK));
    // dispatch(getAllOffersFailure({error: "Fail"}));
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().generateOffer(params).then((data) => {
            dispatch(getAllOffersSuccess(data.data));
            resolve(data.data);
        }).catch((error) => {
            dispatch(getAllOffersFailure(error));
            reject(error);
        });
    });

};

const generateCfOffer = (params) => (dispatch) => {
    dispatch(getAllOfferssInitiated());
    // dispatch(getAllOffersSuccess(SELECT_OFFER_MOCK));
    // dispatch(getAllOffersFailure({error: "Fail"}));
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().generateCfOffer(params).then((response) => {
            const { data: offerData } = response || {};
            const { data } = offerData || {};
            dispatch(getAllOffersSuccess(data));
            resolve(data);
        }).catch((error) => {
            dispatch(getAllOffersFailure(error));
            reject(error);
        });
    });

};

const changeDownpaymentValue = (value) => ({
    type: Types.CHANGE_OFFER_DOWNPAYMENT,
    value
});

const setIsCarModal = (isCarModel) => ({
    type: Types.SHOW_CAR_MODAL_MODAL,
    isCarModel
});

const setFinanceUserSelectedOffersData = ({
    creditScore,
    downPayment,
    emi,
    tenure,
    loanInsurancePercentage,
    chargeList
}) => ({
    type: Types.FINANCE_USER_SELECTED_OFFER_DATA,
    creditScore,
    downPayment,
    emi,
    tenure,
    loanInsurancePercentage,
    chargeList
});

const resetCheckout = () => ({
    type: Types.RESET_CHECKOUT
});

const cfStatusVerify = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.cfStatusVerify(params).then((data) => {
            resolve(data.data);
        }).catch((error) => {
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });

};

const updateFasterCheckoutToggle = (fasterCheckout) => ({
    type: Types.UPDATE_FAST_CHECKOUT_TOGGLE,
    fasterCheckout
});
const onApplyCouponCode = (orderId, secureToken, couponCode) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.applyCouponCode(orderId, secureToken, couponCode)
            .then(({ data }) => {
                dispatch(getOrderDetailSuccess(data));
                resolve(data);
            })
            .catch(error => {
                dispatch(updateErrorReason(error));
                reject(error);
            });
    });
};

const onRemoveCouponCode = (orderId, secureToken, couponCode) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.removeCouponCode(orderId, secureToken, couponCode)
            .then(({ data }) => {
                dispatch(getOrderDetailSuccess(data));
                resolve(data);
            })
            .catch(error => {
                dispatch(updateErrorReason(error));
                reject(error);
            });
    });
};

const fetchJuspayPayload = (token, orderId, payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.fetchJuspayPayload(token, orderId, payload).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });
};

const validateDocumentUpload = (userId, uid, params) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().validateDocumentUpload(userId, uid, params).then((data) => {
            resolve(data.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setInspectionStateSuccess = (data) => {
    return {
        type: Types.SET_INSPECTION_STATE,
        data
    };
};

const getInspectionStates = (token, orderId) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await CheckoutService.getInspectionStates(orderId, token);
            dispatch(setInspectionStateSuccess(response.data));
            resolve(response.data);
        } catch (error) {
            dispatch(updateErrorReason(error));
            reject(error);
        }
    });
};

const getInspectionMaster = (token, orderId) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await CheckoutService.getInspectionMaster(orderId, token);
            resolve(response.data);
        } catch (error) {
            dispatch(updateErrorReason(error));
            reject(error);
        }
    });
};

const validateInspectionCode = (token, orderId, code) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await CheckoutService.validateInspectionCode(orderId, token, code);
            resolve(response.data);
        } catch (error) {
            dispatch(updateErrorReason(error));
            reject(error);
        }
    });
};

const inspectionResult = (token, orderId, data) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await CheckoutService.inspectionResult(orderId, token, data);
            resolve(response.data);
        } catch (error) {
            dispatch(updateErrorReason(error));
            reject(error);
        }
    });
};

const uploadDefects = (token, orderId, data) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await CheckoutService.uploadDefects(orderId, token, data);
            resolve(response.data);
        } catch (error) {
            dispatch(updateErrorReason(error));
            reject(error);
        }
    });
};
const getInspectionResult = (token, orderId) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await CheckoutService.getInspectionResult(orderId, token);
            resolve(response.data);
        } catch (error) {
            dispatch(updateErrorReason(error));
            reject(error);
        }
    });
};

const applyInspectionOffer = (token, orderId, data) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await CheckoutService.applyInspectionOffer(orderId, token, data);
            resolve(response.data);
        } catch (error) {
            dispatch(updateErrorReason(error));
            reject(error);
        }
    });
};

const isEligibleForInspection = (token, orderId, params) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await CheckoutService.isEligibleForInspection(orderId, token, params);
            resolve(response.data);
        } catch (error) {
            dispatch(updateErrorReason(error));
            reject(error);
        }
    });
};

const getLoanStagesSuccess = (loanStages, currentLoanStageId, isLoanDiscarded) => ({
    type: Types.GET_LOAN_STAGES,
    loanStages,
    currentLoanStageId,
    isLoanDiscarded
});

const getLoanStages = (userId) => async (dispatch) => {
    try {
        const response = await ConsumerFinanceService().getLoanStages(userId);
        dispatch(getLoanStagesSuccess(
            response.data.data.loanStages,
            response.data.data.currentLoanStageId,
            response.data.data.isDiscarded,
            response.data.data.showFinalizeLoanGratification
        ));
        return response.data;
    } catch (error) {
        return null;
    }
};

const mockNextStage = () => {
    return {
        type: Types.MOCK_NEXT_STAGE
    };
};

const finalizeLoanGratificationViewed = () => {
    return {
        type: Types.FINALIZE_LOAN_GRATIFICATION_VIEWED
    };
};

const getCreditVerificationFormStaticData = () => async () => {
    try {
        const response = await ConsumerFinanceService().getCreditVerificationFormStaticData();
        return response.data;
    } catch (error) {
        return null;
    }
};

const getCreditVerificationForm = (userId) => async () => {
    try {
        const response = await ConsumerFinanceService().getCreditVerificationForm(userId);
        return response.data;
    } catch (error) {
        return null;
    }
};

const postCreditVerificationForm = (userId, data) => async () => {
    try {
        const response = await ConsumerFinanceService().postCreditVerificationForm(userId, data);
        return response.data;
    } catch (error) {
        return null;
    }
};

const postCreditVerificationFormSelfEmployed = (userId, data) => async () => {
    try {
        const response = await ConsumerFinanceService().postCreditVerificationFormSelfEmployed(userId, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getLoanTnCSuccess = (data) => ({
    type: Types.GET_LOAN_TNC,
    data
});

const getLoanTnC = (userId, secureToken) => async (dispatch) => {
    try {
        const tncResponse = await ConsumerFinanceService().getLoanTnC(userId, secureToken);
        dispatch(getLoanTnCSuccess(tncResponse.data.data));
        return tncResponse.data.data;
    } catch (error) {
        return null;
    }
};

const getLoanTnCCarDetailsSuccess = (data) => ({
    type: Types.GET_LOAN_TNC_CAR_DETAILS,
    data: { ...data.inventoryV2, baseUrl: data.baseUrl }
});

const getLoanTnCCarDetails = (carId) => async (dispatch) => {
    try {
        const response = await UsedCarService.fetchCarDetail(carId, null, {});
        dispatch(getLoanTnCCarDetailsSuccess(response.data.data));
    } catch (error) {
        //
    }
};

const markTermsFinalized = () => ({
    type: Types.MARK_TERMS_FINALIZED
});

const disableTncFeedback = () => ({
    type: Types.DISABLE_TNC_FEEDBACK
});

const updateUserData = (params) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().updateUserData(params).then((data) => {
            resolve(data.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getCaVirtualNumber = (orderId, token) => async () => {
    try {
        const response = await CheckoutService.getCaVirtualNumber(orderId, token);
        return response.data;
    } catch (error) {
        return null;
    }
};

const fetchLoanDataForDocumentVerification = (userId, secureToken) => async () => {
    try {
        const response = await ConsumerFinanceService().fetchLoanDataForDocumentVerification(userId, secureToken);
        return response.data;
    } catch (error) {
        return null;
    }
};

const setCheckoutInitiatedStatus = (status) => {
    return {
        type: Types.SET_CHECKOUT_INITIATED_STATUS,
        status
    };
};

const getFeedbackFormData = (type) => async () => {
    try {
        const response = await ConsumerFinanceService().getFeedbackFormData(type);
        return response.data;
    } catch (error) {
        return null;
    }
};

const postFeedbackFormData = (userId, payload) => async () => {
    try {
        const response = await ConsumerFinanceService().postFeedbackFormData(userId, payload);
        return response.data;
    } catch (error) {
        return null;
    }
};

const getCancellationFeedbackQuestions = (bookingId, token, feedbackType) => async () => {
    try {
        const response = await b2cCrmService().getCancellationFeedbackQuestions(bookingId, token, feedbackType);
        return response.data;
    } catch (error) {
        return null;
    }
};

const postCancellationFeedback = (data, bookingId, token, feedbackType) => async () => {
    try {
        const response = await b2cCrmService().postCancellationFeedback(data, bookingId, token, feedbackType);
        return response.data;
    } catch (error) {
        return null;
    }
};

const getOrderSummaryInit = (data) => ({
    type: Types.GET_ORDER_SUMMARY_INIT,
    data
});

const getOrderSummarySuccess = (data) => ({
    type: Types.GET_ORDER_SUMMARY_SUCCESS,
    data
});

const getOrderSummaryFailure = (error) => ({
    type: Types.GET_ORDER_SUMMARY_FAILURE,
    error
});

const getOrderSummary = (orderId, token) => (dispatch) => {
    dispatch(getOrderSummaryInit());
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getOrderSummary(orderId, token).then((response) => {
            dispatch(getOrderSummarySuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getOrderSummaryFailure(error));
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });
};

const setCheckoutLoader = (isLoading) => ({
    type: Types.SET_CHECKOUT_LOADER,
    isLoading
});

const confirmFreeBookingInit = () => ({
    type: Types.CONFIRM_FREE_BOOKING_INIT
});

const confirmFreeBookingSuccess = (data) => ({
    type: Types.CONFIRM_FREE_BOOKING_SUCCESS,
    data
});

const confirmFreeBookingFailure = (error) => ({
    type: Types.CONFIRM_FREE_BOOKING_FAILURE,
    error
});

const confirmFreeBooking = (payload, orderId, token, source, bi2bcExperiment) => (dispatch) => {
    dispatch(confirmFreeBookingInit());
    return new Promise((resolve, reject) => {
        CheckoutService.confirmFreeBooking(payload, orderId, token, source, bi2bcExperiment).then((response) => {
            dispatch(confirmFreeBookingSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(confirmFreeBookingFailure(error));
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });
};

const getFinvuData = (params = {}) => async () => {
    try {
        const response = await ConsumerFinanceService().getFinvuData(params);
        return response.data;
    } catch (error) {
        return null;
    }
};

const getBankAnalysis = (userId, mobileNumber, token) => async () => {
    try {
        const response = await ConsumerFinanceService().getBankAnalysis(userId, mobileNumber, token);
        return response.data;
    } catch (error) {
        return null;
    }
};

const startAnalyserProcess = (userId, token, source) => async () => {
    try {
        const response = await ConsumerFinanceService().startAnalyserProcess(userId, token, source);
        return response.data;
    } catch (error) {
        return null;
    }
};

const getProcessStatus = (trackerId, token) => async () => {
    try {
        const response = await ConsumerFinanceService().getProcessStatus(trackerId, token);
        return response.data;
    } catch (error) {
        return null;
    }
};

const setFinvuPhoneNumber = (finvuPhoneNumber) => {
    return {
        type: Types.SET_FINVU_PHONE_NUMBER,
        finvuPhoneNumber
    };
};

const updatePaymentOption = (payload, orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updatePaymentOption(payload, orderId, token, "mSite").then((response) => {
            setOrder({
                ...response.data
            });
            resolve(response.data);
        }).catch((error) => {
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });

};

const getBookingAmountSuccess = (data) => ({
    type: Types.GET_BOOKING_AMOUNT_SUCCESS,
    data
});

const getBookingAmount = (orderId, token, params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.getBookingAmount(orderId, token, params).then((response) => {
            dispatch(getBookingAmountSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(updateErrorReason(error));
            reject(error);
        });
    });
};

const setLocationAddress = (data) => ({
    type: Types.SET_LOCATION_ADDRESS,
    data
});

const setCalulatorEmiOffer = (data) => ({
    type: Types.SET_CALCULATOR_EMI_OFFER,
    data
});

const setCheckoutInfo = ({ ...info }) => ({
    type: Types.SET_CHECKOUT_INFO,
    info
});

const getBookingDetails = (token, orderId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getBookingDetails(token, orderId).then(response => {
            dispatch(setCheckoutInfo(response.data));
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
};

const fetchAddressSlots = (orderId, token, params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.fetchAddressSlots(orderId, token, params).then((response) => {
            dispatch(getDeliverySlotsSuccess(response.data.slots));
            resolve(formatAddressSlotsResponse(response.data, params));
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateFinanceSource = (data) => ({
    type: Types.UPDATE_FINANCE_SOURCE,
    data
});

const saveDeliveryScheduleSlots = (payload, orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.saveDeliveryScheduleSlots(orderId, token, payload).then((response) => {
            dispatch(updateDeliveryModeSuccess(response.data));
            resolve();
        }).catch(error => {
            dispatch(updateDeliveryModeFailure(error));
            reject(error);
        });
    });
};

const getDeliveryScheduleSlots = (orderId, token, params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.getDeliveryScheduleSlots(orderId, token, params).then((response) => {
            dispatch(getDeliverySlotsSuccess(response.data));
            resolve(formatAddressSlotsResponse(response.data, params));
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateSelectedPickupLocation = (selectedPickupLocationIndex) => ({
    type: Types.UPDATED_SELECTED_PICKUP_LOCATION,
    selectedPickupLocationIndex
});

const updateSelectedDateSlot = (selectedDateSlot) => ({
    type: Types.UPDATE_SELECTED_DATE_SLOT,
    selectedDateSlot
});

const showSimilarCarPopup = (data) => ({
    type: Types.SIMILAR_CAR_POPUP,
    data
});

const getVisitRatingStatus = (orderId, secureToken) => () => {
    return new Promise((resolve, reject) => {
        b2cCrmService().getVisitRatingStatus(orderId, secureToken)
            .then(response => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
    });
};

const getOrderInfo = (orderId, secureToken) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getOrderSummary(orderId, secureToken)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const deliveryChecklistSuccess = (data) => ({
    type: Types.UPDATE_DELIVERY_CHECKLIST_DATA,
    data
});

const getDeliveryChecklistDetails = (token, orderId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getDeliveryChecklistDetails(token, orderId).then((response) => {
            dispatch(deliveryChecklistSuccess(response.data.data));
            resolve(response.data.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deliveryChecklistOTP = (data) => ({
    type: Types.UPDATE_DELIVERY_CHECKLIST_OTP,
    data
});

const getDeliveryChecklistOTP = (token, orderId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getDeliveryChecklistOTP(token, orderId).then((response) => {
            dispatch(deliveryChecklistOTP(response.data.data));
            resolve(response.data.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setDeliveryChecklistItems = (token, orderId, payload) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.setDeliveryChecklistItems(token, orderId, payload).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getPostCheckoutOffers = (params) => (dispatch) => {
    dispatch(getAllOfferssInitiated());

    return new Promise((resolve, reject) => {
        OmsAggregatorService.getPostCheckoutOffers(params).then((response) => {
            dispatch(getAllOffersSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getAllOffersFailure(error));
            reject(error);
        });
    });
};

const fetchCalculatedPostCheckoutOffer = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.fetchCalculatedPostCheckoutOffer(params).then((response) => {
            dispatch(getAllOffersSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getAllOffersFailure(error));
            reject(error);
        });
    });
};

const fetchCalculatedDIYOffer = (params) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.fetchCalculatedDIYOffer(params).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const clearUserSelectedPostCfOffer = () => ({
    type: Types.CLEAR_USER_SELECTED_OFFER
});

const setUserSelectedPostCfOffer = (data) => ({
    type: Types.SET_USER_SELECTED_POST_CF_OFFER,
    data
});

const getUserSelectedPostCfOffer = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getUserSelectedOfferV2(params).then(response => {
            const { data } = response || {};
            dispatch(setUserSelectedPostCfOffer(data));
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
};

const saveUserSelectedPostCfOffer = (payload) => (_, getState) => {
    return new Promise((resolve, reject) => {
        const { checkout } = getState();
        const { postCheckoutData } = checkout || {};
        const { cf } = postCheckoutData || {};
        const { diyCohortVariant } = cf || {};
        const apiRequest = diyCohortVariant !== "B"
            ? ConsumerFinanceService().saveUserSelectedOffer(payload)
            : ConsumerFinanceService().saveUserSelectedOfferV2(payload);
        apiRequest.then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
};

const updateHDMapPopup = (isHDMapPopup) => ({
    type: Types.UPDATE_HD_MAP_POPUP,
    isHDMapPopup
});

const setCfBankList = (data) => ({
    type: Types.SET_CF_BANK_LIST,
    data
});

const getCfBankList = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getCfBankList().then((response) => {
            const { data: responseData } = response || {};
            const { data } = responseData || [];
            dispatch(
                setCfBankList(data.map(bank => ({
                    ...bank,
                    value: bank.id,
                    label: bank.name
                })))
            );
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setPostCfFinanceData = (data) => ({
    type: Types.SET_POST_CF_FINANCE_DATA,
    data
});

const setCfFailureScreenType = (data) => ({
    type: Types.SET_CF_FAILURE_SCREEN_TYPE,
    data
});

const couponApply = (secureToken, orderId, couponCode, payload) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.applyCoupon(secureToken, orderId, couponCode, payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const couponRemove = (secureToken, orderId, couponCode) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.removeCoupon(secureToken, orderId, couponCode)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
const setToggleAddedBenefitsPopup = () => ({
    type: Types.TOGGLE_ADDED_BENEFIT_POPUP
});

const getOfferRangesSuccess = (data) => ({
    type: Types.GET_OFFER_RANGES_SUCCESS,
    data
});

const getOfferRanges = (orderId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getOfferRanges(orderId).then((response) => {
            const { data: responseData } = response || {};
            dispatch(getOfferRangesSuccess(responseData));
            resolve(responseData);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setBookedOrdersList = (data) => ({
    type: Types.SET_BOOKED_ORDERS_LIST,
    data
});

const getBookedOrdersList = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getBookedOrdersList(payload).then((response) => {
            const { BOOKED_ORDERS = {} } = response.data || {};
            dispatch(setBookedOrdersList(BOOKED_ORDERS));
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setRefreshStateState = (refreshStableState) => ({
    type: Types.SET_REFRESH_STABLE_STATE,
    refreshStableState
});

const getDeliveryChecklistDetailsV2 = (token, orderId) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getDeliveryChecklistDetailsV2(token, orderId)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

const postDeliveryChecklistData = (token, orderId, payload) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.postDeliveryDetails(token, orderId, payload)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

const setCurrentLocation = (data) => ({
    type: Types.SET_CURRENT_LOCATION,
    data
});

const setCheckoutData = ({ ...info }) => ({
    type: Types.SET_CHECKOUT_DATA,
    info
});

const getBookingData = (token, orderId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getBookingData(token, orderId).then(response => {
            const { cf = {} } = response.data || {};
            dispatch(setCheckoutData(response.data));
            dispatch(setUserSelectedPostCfOffer(cf));
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
};

const setLoanStatusLabel = (label) => (dispatch) => {
    dispatch({ type: Types.SET_LOAN_STATUS_LABEL, label });
};

const setOrderSummaryData = (data) => ({
    type: Types.SET_ORDER_SUMMARY_DATA,
    data
});

const getOrderSummaryData = (token, orderId, params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getOrderSummaryData(token, orderId, params).then(response => {
            dispatch(setOrderSummaryData(response.data));
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
};

const updatePaymentMode = (token, orderId, financeMode) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updatePaymentMode(token, orderId, financeMode).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
};

const getRatingDataSucess = (data) => ({
    type: Types.SET_RATING_DATA,
    data
});

const getRatingData = (token, orderId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        b2cCrmService().getRatingData(token, orderId).then(response => {
            resolve(response.data);
            dispatch(getRatingDataSucess(response.data));
        }).catch(error => {
            reject(error);
        });
    });
};

const postRatingData = (token, orderId, payload) => () => {
    return new Promise((resolve, reject) => {
        b2cCrmService().postRatingData(token, orderId, payload).then(res => {
            resolve(res.data);
        }).catch(error => {
            reject(error);
        });
    });
};

const setVasListSucess = (data) => ({
    type: Types.SET_VAS_LIST,
    data
});

const getVasList = (token, orderId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getVasList(token, orderId).then(res => {
            resolve(res.data);
            dispatch(setVasListSucess(res.data));
        }).catch(err => {
            reject(err);
        });
    });
};

const addVas = (token, orderId, id) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.addVas(token, orderId, id).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
};

const deleteVas = (token, orderId, id) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.deleteVas(token, orderId, id).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
};

const getRefundData = (token, orderId) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getRefundData(token, orderId).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
};
const setVasListV2Sucess = (data) => ({
    type: Types.SET_VAS_LIST_V2,
    data
});

const getVasListV2 = (token, orderId, params = null) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getVasListV2(token, orderId, params).then(res => {
            resolve(res.data);
            dispatch(setVasListV2Sucess(res.data));
        }).catch(err => {
            reject(err);
        });
    });
};

const addVasV2 = (token, orderId, id) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.addVasV2(token, orderId, id).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
};

const deleteVasV2 = (token, orderId, id) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.deleteVasV2(token, orderId, id).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
};

const getVasFaq = (token, type, params) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getVasFaqs(token, type, params).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
};

const getVasNomineeDetails = (token, orderId) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getVasNomineeDetails(token, orderId).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
};

const postVasNomineeDetails = (token, payload) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.postVasNomineeDetails(token, payload).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
};

const setCheckoutSource = (source) => ({
    type: Types.SET_CHECKOUT_SOURCE,
    source
});

const getInsuranceUpgradeDetails = (payload) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getInsuranceDetail(payload).then(response => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setPostBookingSource = (data) => ({
    type: Types.SET_POST_BOOKING_SOURCE,
    data
});

const getVasTestimonials = (token) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getVasTestimonials(token).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
};
const addMoreCarSubmit = (token, payload) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.addMoreCarSubmit(token, payload)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const setAddMoreCarData = (data) => ({
    type: Types.SET_ADD_MORE_CAR,
    data
});

const getAddMoreCarData = (token, orderId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.getAddMoreCarData(token, orderId)
            .then((res) => {
                resolve(res.data);
                dispatch(setAddMoreCarData(res.data));
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getCarStatus = (token, orderId, appId) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.getCarStatus(token, orderId, appId)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const rescheduleAddMoreCar = (token, payload) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.rescheduleAddMoreCar(token, payload)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const bulkCancellation = (token, payload) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.bulkCancellation(token, payload)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const bulkFeedBack = (token, payload) => () =>  {
    return new Promise((resolve, reject) => {
        b2cCrmService().bulkFeedback(token, payload)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getVasFaqV2 = (token, type, params) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getVasFaqV2(token, type, params).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
};
const getInsuranceIdvs = (token, orderId, params) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getIdvs(token, orderId, params)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getWarrantyData = (token, orderId) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getWarrantyData(token, orderId)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
const fetchBuybackInit = () => ({
    type: Types.FETCH_BUYBACK_INIT
});

const fetchBuybackSuccess = (data) => ({
    type: Types.FETCH_BUYBACK_SUCCESS,
    data
});

const fetchBuybackFailure = (error) => ({
    type: Types.FETCH_BUYBACK_FAILURE,
    error
});

const fetchBuyback = (data) => dispatch => {
    dispatch(fetchBuybackInit());
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getBuyback(data)
            .then((res) => {
                dispatch(fetchBuybackSuccess(res.data));
                resolve(res.data);
            }).catch((err) => {
                dispatch(fetchBuybackFailure(err));
                reject(err);
            });
    });
};

const toggleBuyback = () => ({
    type: Types.TOGGLE_BUYBACK
});

const setTestDriveLocationDetails = (data) => ({
    type: Types.SET_TEST_DRIVE_LOCATION_DATA,
    data
});

const resetTestDriveLocationDetails = () => ({
    type: Types.RESET_TEST_DRIVE_DATA
});

const setTestDriveDeliveryDetails = (data) => ({
    type: Types.SET_TEST_DRIVE_DELIVERY_DATA,
    data
});

const setTestDriveDetailsFetched = (data) => ({
    type: Types.SET_TEST_DRIVE_DETAILS_FETCHED,
    data
});

const getTestDriveDeliveryDetails = ({allowUpdate = true, ...payload}) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getTestDriveDeliveryDetails(payload).then((res) => {
            if (allowUpdate) {
                dispatch(setTestDriveDeliveryDetails(res.data));
            }
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const setTestDriveConfig = (data) => ({
    type: Types.SET_TEST_DRIVE_CONFIG,
    data
});

const getTestDriveConfig = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.getTestDriveConfig(payload).then((res) => {
            dispatch(setTestDriveConfig(res.data));
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const setTestDriveAddressFormData = (data) => ({
    type: Types.SET_TEST_DRIVE_ADDRESS_FORM_DATA,
    data
});

const setSelectedDeliveryAddress = (data) => ({
    type: Types.SET_SELECTED_DELIVERY_ADDRESS,
    data
});

const updateTestDriveAddress = (payload) => () => {
    return new Promise((resolve, reject) => {
        B2cGatewayService.updateUserTestDriveAddress(payload).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const setTestDriveAddressList = (data) => ({
    type: Types.SET_TEST_DRIVE_ADDRESS_LIST,
    data
});

const getTestDriveAddressList = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        B2cGatewayService.getTestDriveAddressList(payload).then((res) => {
            dispatch(setTestDriveAddressList(res.data));
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const setTestDriveSuccessLoader = (data) => ({
    type: Types.SET_TEST_DRIVE_SUCCESS_LOADER,
    data
});

const getTestDrivePaymentStatus = (payload) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.getTestDrivePaymentStatus(payload).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const clearAddressFormData = () => ({
    type: Types.CLEAR_ADDRESS_FORM_DATA
});

const clearSelectedDeliveryAddress = () => ({
    type: Types.CLEAR_SELECTED_DELIVERY_ADDRESS
});

const setUserHomeLocation = (data) => ({
    type: Types.SET_USER_HOME_LOCATION,
    data
});

const clearUserHomeLocation = () => ({
    type: Types.CLEAR_USER_HOME_LOCATION
});

export {
    setStep,
    setData,
    setOrderCreatedDateTime,
    fetchFinance,
    setFinanceData,
    setLoanTenureValue,
    setCreditScoreValue,
    getDeliveryInfo,
    setDownPaymentValue,
    setMonthlyEmiValue,
    getOrderDetail,
    updateFinancePersonalDetail,
    setDeliveryMode,
    setFinancingTab,
    setMinMaxDownPaymentValue,
    setMinMaxEmiValue,
    setFinanceSelectedValue,
    setFinancePersonalDetails,
    createOrder,
    setOrderId,
    setOrder,
    updateFinanceDetail,
    updateDeliveryMode,
    updateOrderBooking,
    initiateOrderPayment,
    uploadFile,
    setFinanceUpdatedData,
    setCheckoutInitialStep,
    setDeliveryData,
    setFinancingScreen,
    setConfirmBookingData,
    cancelOrder,
    getPaymentSummary,
    getNeftInfo,
    initPostBookingPayment,
    getPostBookingPaymentStatus,
    getPaymentHistory,
    setFinanceChargeList,
    setChargeDetailsList,
    resetCheckoutData,
    fetchUserLatestOrder,
    updateActiveFinanceForm,
    updateFinanceFormState,
    setFinanceAddressDetails,
    setFinanceIncomeDetails,
    getPerfiosUrl,
    uploadBankDocument,
    fetchPincodeCity,
    validatePan,
    getDeliverySlots,
    getBankList,
    getAllOffers,
    changeDownpaymentValue,
    setIsCarModal,
    setFinanceUserSelectedOffersData,
    resetCheckout,
    cfStatusVerify,
    updateFasterCheckoutToggle,
    onApplyCouponCode,
    onRemoveCouponCode,
    fetchJuspayPayload,
    // updateVisitedSteps
    getBankStatementStatus,
    getDeeplinkingPerfiosUrl,
    validateDocumentUpload,
    getInspectionStates,
    getInspectionMaster,
    validateInspectionCode,
    inspectionResult,
    uploadDefects,
    getInspectionResult,
    applyInspectionOffer,
    isEligibleForInspection,
    updateUserData,
    fetchLoanDataForDocumentVerification,
    getLoanStages,
    mockNextStage,
    finalizeLoanGratificationViewed,
    getCreditVerificationFormStaticData,
    getCreditVerificationForm,
    postCreditVerificationForm,
    postCreditVerificationFormSelfEmployed,
    getLoanTnC,
    getLoanTnCCarDetails,
    markTermsFinalized,
    disableTncFeedback,
    getCaVirtualNumber,
    setCheckoutInitiatedStatus,
    getFeedbackFormData,
    postFeedbackFormData,
    getCancellationFeedbackQuestions,
    postCancellationFeedback,
    getOrderSummary,
    setCheckoutLoader,
    confirmFreeBooking,
    checkOrderDetail,
    setVehiclePrice,
    setDownpaymentChanged,
    setCheckoutInfo,
    getBookingDetails,
    fetchAddressSlots,
    updateFinanceSource,
    updateSelectedPickupLocation,
    updateSelectedDateSlot,
    saveDeliveryScheduleSlots,
    getDeliveryScheduleSlots,
    getDeliveryChecklistDetails,
    getDeliveryChecklistOTP,
    setDeliveryChecklistItems,
    getFinvuData,
    getBankAnalysis,
    startAnalyserProcess,
    getProcessStatus,
    setFinvuPhoneNumber,
    updatePaymentOption,
    getBookingAmount,
    setLocationAddress,
    resetDeliverySlots,
    setCalulatorEmiOffer,
    updateErrorReason,
    generateCfOffer,
    getPostCheckoutOffers,
    getUserSelectedPostCfOffer,
    saveUserSelectedPostCfOffer,
    showSimilarCarPopup,
    getVisitRatingStatus,
    getOrderInfo,
    setUserSelectedPostCfOffer,
    updateHDMapPopup,
    getCfBankList,
    setPostCfFinanceData,
    setCfFailureScreenType,
    setToggleAddedBenefitsPopup,
    getOfferRanges,
    getBookedOrdersList,
    couponApply,
    couponRemove,
    setRefreshStateState,
    createOrderWoL,
    getDeliveryChecklistDetailsV2,
    postDeliveryChecklistData,
    setLoanStatusLabel,
    setCurrentLocation,
    getBookingData,
    getOrderSummaryData,
    updatePaymentMode,
    getRatingData,
    postRatingData,
    getVasList,
    addVas,
    deleteVas,
    getRefundData,
    getVasListV2,
    addVasV2,
    deleteVasV2,
    getVasFaq,
    getVasNomineeDetails,
    postVasNomineeDetails,
    setCheckoutSource,
    getInsuranceUpgradeDetails,
    clearUserSelectedPostCfOffer,
    getOrderDetailByOrderId,
    setPostBookingSource,
    getVasTestimonials,
    addMoreCarSubmit,
    getAddMoreCarData,
    getCarStatus,
    rescheduleAddMoreCar,
    bulkCancellation,
    bulkFeedBack,
    fetchCalculatedPostCheckoutOffer,
    fetchCalculatedDIYOffer,
    getVasFaqV2,
    getInsuranceIdvs,
    getWarrantyData,
    fetchBuyback,
    toggleBuyback,
    getTestDriveDeliveryDetails,
    setTestDriveLocationDetails,
    setTestDriveDetailsFetched,
    setTestDriveAddressFormData,
    updateTestDriveAddress,
    getTestDriveAddressList,
    setTestDriveSuccessLoader,
    getTestDrivePaymentStatus,
    setSelectedDeliveryAddress,
    clearAddressFormData,
    getTestDriveConfig,
    clearSelectedDeliveryAddress,
    resetTestDriveLocationDetails,
    setUserHomeLocation,
    clearUserHomeLocation
};
