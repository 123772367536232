import Types from "./types";

const updateTruecallerDetails = data => ({
    type: Types.UPDATE_TRUECALLER_DETAILS,
    data
});

const resetTruecallerDetails = () => ({
    type: Types.RESET_TRUECALLER_DETAILS
});

export {
    updateTruecallerDetails,
    resetTruecallerDetails
};
