/* eslint-disable max-params */
import React from "react";
import PropTypes from "prop-types";
import FaqSchema from "./schema";
import { Heading } from "@cars24/turbo-web/lib/heading";
import { AccordionList } from "@cars24/turbo-web/lib/accordion-list";
import { Accordion } from "@cars24/turbo-web/lib/accordion";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import { NUMBER } from "../../../constants/app-constants";
import styles from "./styles.css";

const initialOpenIndexes = [NUMBER.ZERO];

const PilotFaqV2 = ({
    heading = "Frequently asked questions",
    attachSchema = true,
    list = [],
    clickHandler = () => {}
}) => {
    const { tokens } = useTokens();
    const { spacing_2: spacing } = tokens || {};

    return (
        !!(list || []).length && <div styleName={"styles.pilotFaqWrapper"}>
            <Heading level={NUMBER.TWO}>{heading}</Heading>
            <AccordionList
                initialOpenIndexes={initialOpenIndexes}
                items={list}
                gap={spacing}
            >
                {(item, index, isOpen, onAccordionClick) => (
                    <Accordion
                        key={item.id}
                        title={item.question}
                        isOpen={isOpen}
                        onChange={() => {
                            onAccordionClick({ index });
                            clickHandler(item.id);
                        }}
                        shadow={false}
                        size="regular"
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: item.answer
                            }}
                        />
                    </Accordion>
                )}
            </AccordionList>
            {attachSchema && <FaqSchema faqs={list} />}
        </div>
    );
};

PilotFaqV2.propTypes = {
    heading: PropTypes.string,
    attachSchema: PropTypes.bool,
    list: PropTypes.array,
    clickHandler: PropTypes.func
};

export default PilotFaqV2;
