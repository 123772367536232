/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect, useState } from "react";
import styles from "./styles.css";
import ArrowIcon from "../../../components/shared/arrow";
import PropTypes from "prop-types";
import SellingCar from "./images/sellingCar.svg";
import H2 from "../../shared/h2/component";
import Button from "../../../components/shared/button";
import useToggle from "../../../hooks/use-toggle";
import SeoCarOverviewTabs from "../seo-car-overview-tabs";
import SeoCarOverviewInfo from "../seo-car-overview-info";
import SeoCarOverviewPrice from "../seo-car-overview-price";
import SeoCarOverviewServiceCost from "../seo-car-overview-service-cost";
import InventoryCarCard from "../inventory-car-card";
import SeoCityname from "../../shared/seo-city-name";
import { NUMBER, FALLBACK_C2B_CITY_ID } from "../../../constants/app-constants";
import Slider from "../../shared/slick-wrapper";
import { SOURCE } from "../../../components/mobile/inventory-car-card/constant";
import priceFormatter from "../../../utils/helpers/price-formatter";
import SeoCarOverviewSpecification from "../../../components/mobile/seo-car-overview-service-specification";
import SeoCarOverviewFaq from "../seo-car-overview-faq";
// import Filters from "../../../utils/filters";
import SeoOverview from "../seo-overview";
import { getCombinedValue, getSeoCarName, getFullName, getBuyUsedCarUrl } from "../../../utils/helpers/seo-car-config";
import { Helmet } from "react-helmet-async";
import { appUrl } from "../../../constants/url-constants";
import SeoCarProsCons from "../seo-car-overview-pros-cons";
import OverviewDescription from "../../shared/overview-description";

const SeoCarOverview = ({
    gaId,
    carDetail,
    location,
    updateCurrentSEOCityConnect,
    // fetchSEODetailsConnect,
    updateSSRStatusConnect,
    fetchCarListConnect,
    history
}) => {
    const [isOpen, setIsOpen] = useToggle();
    const [combinedPrice, setCombinedPrice] = useState([]);
    // const [combinedVariants, setCombinedVariants] = useState([]);
    const [combinedMileage, setCombinedMileage] = useState([]);
    const [usedCarLink, setUsedCarLink] = useState("");
    const { tabs, pageData, currentSEOSelectedCity, usedCars } = carDetail;
    const pageSlug = tabs.length > 0 ? tabs.find(e => e.page_type === "overview").slug : "";
    const {
        make,
        model,
        overview: {
            overview: {
                content,
                model_review: modelReview,
                latest_updates: latestUpdates,
                pros,
                cons
            },
            price,
            variants,
            mileage,
            service_cost: serviceCost,
            specifications,
            images,
            colors: {
                colors,
                content: colorContent
            },
            faqs
        },
        mediaConfigUrl,
        modelImageMobile,
        relatedModelList,
        topCity
    } = pageData || {};
    const { label: cityName, value: cityId } = currentSEOSelectedCity;
    const { content: imageContent } = images;
    const {
        // Petrol: variantPertol = [],
        // Diesel: variantsDiesel = [],
        // CNG: variantsCNG = [],
        content: variantsContent
    } = variants;
    const {
        Petrol: mileagePetrol = [],
        Diesel: mileageDiesel = [],
        CNG: mileageCNG = [],
        content: mileageContent
    } = mileage;
    const carFullName = getFullName(make, model);
    const seoCarName = getSeoCarName(location.pathname);
    const getCitiesObject = () => Object.keys(pageData.city).map((item) => ({
        label: pageData.city[item],
        value: item
    }));

    const sliderSettings = {
        slidesToShow: 1.1,
        slidesToScroll: 1,
        infinite: false,
        arrow: false,
        dots: true
    };

    useEffect(() => {
        setCombinedPrice(getCombinedValue(price.Petrol, price.Diesel, price.CNG, false));
        // setCombinedVariants(getCombinedValue(variantPertol, variantsDiesel, variantsCNG));
        setCombinedMileage(getCombinedValue(mileagePetrol, mileageDiesel, mileageCNG));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityName, price, variants, mileage]);

    // useEffect(() => {
    //     if (!isSSR) {
    //         fetchSEODetailsConnect({ slug: seoCarName, city: cityId });
    //         window.scrollTo(0, 0);
    //     }
    //     // if (isSSR) updateSSRStatusConnect(false);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isSSR, seoCarName, cityId]);

    useEffect(() => {
        const payload = cityId || FALLBACK_C2B_CITY_ID;
        const uri = getBuyUsedCarUrl(payload, seoCarName);
        fetchCarListConnect(uri, "", gaId);
    }, [cityId, fetchCarListConnect, gaId, seoCarName]);

    const onSelection = ({ label, value }) => {
        updateCurrentSEOCityConnect(label, value);
        // fetchSEODetailsConnect({ slug: seoCarName, city: value });
        updateSSRStatusConnect(false);
    };
    const citySlectionCallback = (e) => {
        e.preventDefault();
        setIsOpen();
    };

    const onPageRedirection = (pageType, isHistory = false, event = null) => {
        if (event) event.preventDefault();
        const url = tabs.find(e => pageType === e.page_type);
        const redirectionUrl = url ? `${url.slug}/` : "";
        if (isHistory) {
            history.push(`/${redirectionUrl}`);
        } else {
            return `${appUrl()}/${redirectionUrl}`;
        }
        return null;
    };

    useEffect(() => {
        setUsedCarLink(`${appUrl()}/buy-used-${carFullName.replace(" ", "-").toLowerCase()}-cars-${cityName.replace(" ", "-").toLowerCase()}`);
        // const payload = cityId || FALLBACK_C2B_CITY_ID;
        // const carName = removeSlash(seoCarName);
        // const appliedFilters = new Filters({ cityID: payload });
        // setUsedCarLink(appliedFilters.getListingPayload({
        //     options: {
        //         search: carName
        //     }
        // }).relativeURL);
    }, [carFullName, cityId, cityName, seoCarName]);

    const redirectToListing = (event) => {
        event.preventDefault();
        window.location.href = usedCarLink;
    };

    const getCityUrl = (topCities, isHistory, event) => {
        if (event) event.preventDefault();
        const redirection = `${seoCarName.replace("/", "-")}-car-prices-in-${topCities.slug}`;
        if (isHistory) {
            history.push(`/${redirection}`);
        } else {
            return `${appUrl()}/${redirection}`;
        }
        return null;
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>{carFullName} Price in India, Specs, Colors, Images, Variants, Mileage, Service Costs</title>
                <meta name="description" content={`${carFullName} Price in India - Get on road price of ${carFullName} in your city. Also view, ${model} Colours, Specs, Images, Mileage, Colours and Variants at Cars24.`} />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${appUrl()}/${pageSlug}/`} rel="canonical" />
            </Helmet>
            <div styleName={"styles.seoPageWrapper"}>
                <div styleName={"styles.fixedSeoCarHeader"}>
                    {/* <ArrowIcon rotateBy={180}/> */}
                    {/* <Breadcrumb/> */}
                    <SeoCarOverviewTabs pageTabs={tabs} />
                </div>
                <div styleName={"styles.carDetailsUnderWrapper"}>
                    <SeoCarOverviewInfo
                        carFullName={carFullName}
                        price={combinedPrice}
                        cityName={cityName}
                        citySlectionCallback={citySlectionCallback}
                        media={images}
                        baseUrl={mediaConfigUrl}
                        colorsNumber={colors ? colors.length : 0}
                    />
                    {content && <div styleName={"styles.contentArea"}>
                        <H2 text={`${carFullName} summary`} />
                        {/* <p>{content}</p> */}
                        <OverviewDescription data={content} />
                    </div>}
                    <a href={usedCarLink} styleName={"styles.buyButton"}><Button text={`BUY USED ${carFullName}`} onClick={redirectToListing} /></a>

                    {/* Selling a car */}
                    <div styleName={"styles.carSellingSection"}>
                        <div styleName={"styles.carDetailsImg"}>
                            <img src={SellingCar} alt="sellingCar" />
                        </div>
                        <div styleName={"styles.carSellingText"}>
                            <p>Book an appointment for free car</p>
                            <p>inspection and sell it instantly, same day!</p>
                            <h5>It only takes 30 seconds</h5>
                            <div styleName={"styles.SellingCta"}>
                                <Button text={"SELL MY CAR"} onClick={() => history.push("/sell-online-evaluation")} />
                            </div>
                        </div>
                    </div>
                    {/* Car Mileage */}
                    {combinedMileage && combinedMileage.length > 0 && <React.Fragment>
                        <div styleName={"styles.title"}>
                            <H2 text={`${carFullName} MILEAGE`} />
                        </div>
                        <p styleName={"styles.txt"}>{mileageContent}</p>

                        <div styleName={"styles.cityShowroom"}>
                            <ul>
                                <li>
                                    <p>Variants</p>
                                    <span>Mileage</span>
                                </li>
                                {combinedMileage.map((mileages, index) => (
                                    <li key={index}>
                                        <p>{mileages.transmission_type}, {mileages.fuel_type}</p>
                                        <span>{mileages.arai_mileage} kmpl</span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div styleName={"styles.readMore"} onClick={(event) => onPageRedirection("mileage", true, event)}><a href={onPageRedirection("mileage")}>VIEW {model} Details</a><ArrowIcon /></div>

                    </React.Fragment>}

                    {/* Latest Upadate */}
                    {latestUpdates && <React.Fragment>
                        <div styleName={"styles.title"}>
                            <H2 text={`${carFullName} LATEST UPDATES`} />
                        </div>
                        {/* <p styleName={"styles.txt"}>{latestUpdates}</p> */}
                        <OverviewDescription data={latestUpdates} />
                    </React.Fragment>}

                    {/* Price in top cities */}
                    <div styleName={"styles.title"}>
                        <H2 text={`${carFullName} Price in Top Cities`} />
                    </div>
                    <div styleName={"styles.cityShowroom"}>
                        <ul>
                            <li>
                                <p>City</p>
                                <span>Ex-Showroom Price</span>
                            </li>
                            {topCity.map((topCities, index) => (
                                <li key={index}>
                                    <a styleName="styles.topCityLink" href={getCityUrl(topCities, false)} onClick={(event) => getCityUrl(topCities, true, event)}>{topCities.city_name}</a>
                                    <span>₹ {priceFormatter(topCities.ex_showroom_price)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* Car Color Options */}
                    {colors && colors.length > 0 &&
                        <React.Fragment>
                            <div styleName={"styles.title"}>
                                <H2 text={`${carFullName} COLOR OPTIONS`} />
                            </div>
                            <div styleName={"styles.carDetailsImg"}>
                                <img src={`${mediaConfigUrl}${colors[0].images[0]}`} alt={`${carFullName} - ${colors[0].colorName}`} />
                            </div>
                            <p styleName={"styles.txt"}>{colorContent}</p>

                            <div styleName={"styles.colorSelection"}>
                                <ul>
                                    {colors.map((color, inedex) => (
                                        <li key={inedex}>
                                            <a href={onPageRedirection("colors")}>
                                                <span styleName={"styles.red"} style={{ backgroundColor: `${color.colorCode}` }} />
                                                <p>{color.colorName}</p>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div styleName={"styles.readMore"} onClick={(event) => onPageRedirection("colors", true, event)}><a href={onPageRedirection("colors")}>VIEW ALL {carFullName} COLORS</a> <ArrowIcon /></div>
                        </React.Fragment>}

                    {/* PRO'S & CONS */}
                    <div styleName={"styles.leftRightSpace"} className="mb-5">
                        <SeoCarProsCons carFullName={carFullName} price={price} pros={pros} cons={cons} />
                    </div>

                    {/* Car Images */}
                    <div>
                        <div styleName={"styles.title"}>
                            <H2 text={`${carFullName} IMAGES`} />
                        </div>
                        <div styleName={"styles.carDetailsImg"}>
                            <img src={`${mediaConfigUrl}${modelImageMobile}`} alt={`${carFullName} - Front Side`} />
                        </div>
                        <p styleName={"styles.txt"}>{imageContent}</p>
                        <div styleName={"styles.readMore"} onClick={(event) => onPageRedirection("images", true, event)}><a href={onPageRedirection("images")}>VIEW ALL {model} IMAGES</a> <ArrowIcon /></div>
                    </div>
                    {/* Review */}
                    {modelReview && <React.Fragment>
                        <div styleName={"styles.title"}>
                            <H2 text={`${carFullName} EXPERT REVIEWS`} />
                        </div>
                        {/* <p styleName={"styles.txt"}>{modelReview}</p> */}
                        <OverviewDescription data={modelReview} />
                        {/* <div styleName={"styles.readMore"}>READ MORE <ArrowIcon /></div>  */}
                    </React.Fragment>}
                    {/* Car Key Specfications */}
                    <SeoCarOverviewSpecification specifications={specifications} carFullName={carFullName} model={model} onPageRedirection={(event) => onPageRedirection("specifications", true, event)} hrefData={onPageRedirection} />
                    <div styleName={"styles.leftRightSpace"} className="mb-5">
                        <SeoCarOverviewPrice carFullName={carFullName} price={price} variantsContent={variantsContent} model={model} onPageRedirection={(event) => onPageRedirection("variants", true, event)} hrefData={onPageRedirection} />
                    </div>
                    {/* Car Variant List */}
                    {/* {combinedVariants && combinedVariants.length > 0 &&
                        <React.Fragment>
                            <div styleName={"styles.title"}>
                                <H2 text={`${carFullName} VARIANTS`} />
                            </div>
                            <p styleName={"styles.txt"}>{variantsContent}</p>
                            <div styleName={"styles.cityShowroom"}>
                                <ul>
                                    <li>
                                        <p>Variants</p>
                                        <span>Price</span>
                                    </li>
                                    {combinedVariants.map((variant, index) => (
                                        <li key={index}>
                                            <p>{variant.variantName}</p>
                                            <span>₹ {priceFormatter(variant.exShowroomPrice)}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div styleName={"styles.readMore"} onClick={onPageRedirection.bind(null, "variants")}>VIEW ALL {carFullName} VARIANTS <ArrowIcon /></div>
                        </React.Fragment>} */}
                    {/* Car Service & Maintenance Cost */}
                    <div styleName={"styles.leftRightSpace"}>
                        <SeoCarOverviewServiceCost carFullName={carFullName} serviceCost={serviceCost} onPageRedirection={(event) => onPageRedirection("service_cost", true, event)} hrefData={onPageRedirection} />
                    </div>
                    {/* Similar Cars */}
                    {relatedModelList.length > 0 && <React.Fragment>
                        <div styleName={"styles.title"}>
                            <H2 text={`SIMILAR CARS TO ${carFullName}`} />
                        </div>
                        <SeoOverview relatedModelList={relatedModelList} currentCity={cityName} mediaConfigUrl={mediaConfigUrl} />
                        {/* <div styleName={"styles.readMore"}>VIEW All SIMILAR CARS <ArrowIcon /></div> */}
                    </React.Fragment>}
                    {/* FAQs */}
                    <div styleName={"styles.faq"}>
                        <SeoCarOverviewFaq carFullName={carFullName} faqs={faqs} onPageRedirection={() => onPageRedirection("faqs")} />
                    </div>
                    {/* Used Cars */}
                    {usedCars && usedCars.length > 0 && <React.Fragment>
                        <div styleName={"styles.title"}>
                            <H2 text={`USED ${carFullName}`} />
                        </div>
                        <div styleName={"styles.carouselWrap"}>
                            <Slider {...sliderSettings}>
                                {usedCars
                                    .slice(0, NUMBER.FIVE)
                                    .map((item, index) => (
                                        <InventoryCarCard
                                            key={index}
                                            {...item}
                                            source={SOURCE.SIMILAR_CARS}
                                        />

                                    )
                                    )}
                            </Slider>
                        </div>
                        <div styleName={"styles.readMore"} className="mb-5" onClick={redirectToListing}><a href={usedCarLink}>VIEW All {carFullName} <ArrowIcon /></a></div>
                    </React.Fragment>}
                    {/* car overview price */}

                    {/* <div styleName={"styles.title"}>
                    <H4 text={"MORE MARUTI CARS"} />
                </div>
                <div styleName={"styles.moreCars"}>
                    <ul>
                        <li>
                            <p>SWIFT DEZIRE</p>
                            <span>VIEW 20 CARS</span>
                        </li>
                        <li>
                            <p>SWIFT DEZIRE</p>
                            <span>VIEW 20 CARS</span>
                        </li>
                        <li>
                            <p>SWIFT DEZIRE</p>
                            <span>VIEW 20 CARS</span>
                        </li>
                        <li>
                            <p>SWIFT DEZIRE</p>
                            <span>VIEW 20 CARS</span>
                        </li>
                    </ul>
                </div>
                <div styleName={"styles.readMore"}>VIEW All CARS <ArrowIcon /></div> */}

                    {/* <div styleName={"styles.title"}>
                    <H4 text={"3000+ PREOWNED CARS"} />
                </div> */}

                    {/* <div styleName={"styles.readMore"}>VIEW All PRE OWNED CARS<ArrowIcon /></div> */}
                    {/* <div styleName={"styles.title"}>
                    <H4 text={"LATEST NEWS"} />
                </div> */}

                </div>
                {/* <div styleName={"styles.cta"}>
          <Button text={"BUY USED MARUTI BALENO"}/>
      </div> */}
                {
                    isOpen &&
                    <SeoCityname
                        onCitySelection={onSelection}
                        cities={getCitiesObject()}
                        onClose={() => {
                            setIsOpen();
                        }} />
                }
            </div >
        </React.Fragment>
    );
};

SeoCarOverview.propTypes = {
    gaId: PropTypes.string,
    carDetail: PropTypes.object,
    location: PropTypes.object,
    updateCurrentSEOCityConnect: PropTypes.func,
    fetchSEODetailsConnect: PropTypes.func,
    updateSSRStatusConnect: PropTypes.func,
    fetchCarListConnect: PropTypes.func,
    history: PropTypes.object
};

export default SeoCarOverview;
