import { useState, useEffect } from "react";
import { NUMBER } from "../../constants/app-constants";

const useTypingAnimation = (originalPlaceholder, typingSpeed, isAnimationAllowed = false) => {
    const [placeholder, setPlaceholder] = useState("");
    const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!isAnimationCompleted && isAnimationAllowed) {
            let currentIndex = NUMBER.ZERO;

            const interval = window.setInterval(() => {
                if (currentIndex < originalPlaceholder.length) {
                    const nextChar = originalPlaceholder[currentIndex];
                    setPlaceholder((prevPlaceholder) => prevPlaceholder + nextChar);
                    currentIndex++;
                } else {
                    setIsAnimationCompleted(true);
                    window.clearInterval(interval);
                }
            }, typingSpeed);

            return () => {
                window.clearInterval(interval);
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnimationCompleted, originalPlaceholder, isAnimationAllowed]);

    if (!isAnimationAllowed) return originalPlaceholder;

    return placeholder;
};

export default useTypingAnimation;
