import { UsedCarService } from "../../../service/used-car-service";
import { CatalogGatewayService } from "../../../service/catalog-gateway";
import Types from "./types";

const fetchWishlistedCarsInit = () => ({
    type: Types.FETCH_WISHLISTED_CARS
});

const fetchWishlistedCarsSuccess = (data) => ({
    type: Types.FETCH_WISHLISTED_CARS_SUCCESS,
    data
});

const fetchWishlistedCarsFailure = (error) => ({ type: Types.FETCH_WISHLISTED_CARS_FAILURE, error });

const addToWishlistInit = (carId) => ({
    type: Types.ADD_TO_WISHLIST,
    carId
});

const addToWishlistSuccess = (carId) => ({
    type: Types.ADD_TO_WISHLIST_SUCCESS,
    carId
});

const addToWishlistFailure = (error) => ({ type: Types.ADD_TO_WISHLIST_FAILURE, error });

const addToWishlist = (data = {}, params, token) => dispatch => {
    dispatch(addToWishlistInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.addToWishlist(data, params, token)
            .then(response => {
                dispatch(addToWishlistSuccess(data.appointmentId));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(addToWishlistFailure(error));
                reject(error);
            });
    });

};

const removeFromWishListInit = (carId) => ({
    type: Types.REMOVE_FROM_WISHLIST,
    carId
});

const removeFromWishListSuccess = (carId) => ({
    type: Types.REMOVE_FROM_WISHLIST_SUCCESS,
    carId
});

const removeFromWishListFailure = (error) => ({ type: Types.REMOVE_FROM_WISHLIST_FAILURE, error });

const removeFromWishList = (params = {}, token) => dispatch => {
    dispatch(removeFromWishListInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.addToWishlist(params, {}, token)
            .then(response => {
                dispatch(removeFromWishListSuccess(params.appointmentId));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(removeFromWishListFailure(error));
                reject(error);
            });
    });
};

const fetchSimilarWishlistCars = (activeCarId) => ({
    type: Types.FETCH_SIMILAR_TO_WISHLIST_CARS,
    activeCarId
});

const fetchSimilarToWishlistCarsSuccess = (data) => ({
    type: Types.FETCH_SIMILAR_TO_WISHLIST_CARS_SUCCESS,
    data
});

const fetchSimilarToWishlistCarsFailure = (error) => ({ type: Types.FETCH_SIMILAR_TO_WISHLIST_CARS_FAILURE, error });

const fetchSimilarToWishlistCars = (carId, params) => dispatch => {
    dispatch(fetchSimilarWishlistCars(carId));
    return new Promise((resolve, reject) => {
        UsedCarService.fetchSimilarCarsById(carId, params)
            .then(response => {
                const data = response.data.data || {};
                const { similarCars } = data;
                dispatch(fetchSimilarToWishlistCarsSuccess(similarCars || []));
                resolve(response.data.data);
            }).catch(error => {
                dispatch(fetchSimilarToWishlistCarsFailure(error));
                reject(error);
            });
    });
};

const showWishlistModal = (isWishlistModalVisible) => ({
    type: Types.SHOW_WISHLIST_MODAL,
    isWishlistModalVisible
});

const setWishlistAppIds = (data) => ({
    type: Types.SET_WISHLIST_APP_IDS,
    data
});

const fetchWishlistCarsId = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.fetchWishlistedCarsIds(payload).then((response) => {
            const { data: responseData } = response || {};
            const { appIds } = responseData || {};
            dispatch(setWishlistAppIds(appIds));
            resolve(appIds);
        }).catch((error) => {
            reject(error);
        });
    });
};

const fetchWishlistedCars = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(fetchWishlistedCarsInit());
        CatalogGatewayService.fetchWishlistedCarsData(payload).then(response => {
            const { data } = response || {};
            dispatch(fetchWishlistedCarsSuccess(data));
            resolve(data);
        }).catch((error) => {
            dispatch(fetchWishlistedCarsFailure(error));
            reject(error);
        });
    });
};

const clearRemovedWishlistCarId = () => ({
    type: Types.CLEAR_REMOVED_WISHLIST_CAR_ID
});

export {
    fetchWishlistedCars,
    addToWishlist,
    removeFromWishList,
    fetchSimilarToWishlistCars,
    showWishlistModal,
    fetchWishlistCarsId,
    clearRemovedWishlistCarId
};
