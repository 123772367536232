import { AB_TEST_VARIATIONS, HTTP_CODES, NUMBER, stringConstants } from "../../constants/app-constants";
import { USER_JOURNEY_STEPS, WIDGET_RESPONSE_CONSTANTS } from "../../constants/home-widget-constants";
import { PRICE_DISCOVERY } from "../../constants/seller-diy-constants";
import { offerStatuses } from "../../constants/seller-constant";

const redirectionPaths = {
    [USER_JOURNEY_STEPS.INSPECTED]: `/inspection-complete/:leadToken?token_type=lead_token`,
    [USER_JOURNEY_STEPS.CONFIRMED]: `/appointment/confirmation/?l=:leadToken`,
    [USER_JOURNEY_STEPS.CONFIRMED_NARROW]: `/appointment/confirmation/?l=:leadToken`,
    [USER_JOURNEY_STEPS.CONFIRMED_SO]: `/sell-online-offer/booked?token=:leadToken`,
    [USER_JOURNEY_STEPS.MISSED_NARROW]: `/appointment/confirmation/?l=:leadToken&e=1`,
    [USER_JOURNEY_STEPS.MISSED]: `/appointment/confirmation/?l=:leadToken&e=1`,
    [USER_JOURNEY_STEPS.MISSED_SO]: `/sell-online-offer/booked?token=:leadToken`,
    [USER_JOURNEY_STEPS.BOOKED]: `/appointment/index?token=:leadToken`,
    [USER_JOURNEY_STEPS.BOOKED_NARROW]: `/appointment/index?token=:leadToken`,
    [USER_JOURNEY_STEPS.BOOKED_SO]: `/sell-online-offer?token=:leadToken`,
    [USER_JOURNEY_STEPS.LEAD_QUESTION]: `/sell-online-questions?token=:leadToken`,
    [USER_JOURNEY_STEPS.LEAD_SCRAP_PRICE]: `/scrap-cars/final-price/:leadToken`,
    [USER_JOURNEY_STEPS.LEAD_SCRAP_RANGE]: `/scrap-cars/info/:leadToken`,
    [USER_JOURNEY_STEPS.LEAD_NO_PRICE]: `/location-search/:leadToken?source=nonso`,
    [USER_JOURNEY_STEPS.LEAD_NARROW]: `/location-search/:leadToken?source=nonso`,
    [USER_JOURNEY_STEPS.LEAD]: `/location-search/:leadToken?source=nonso`,
    [USER_JOURNEY_STEPS.CANCELLED]: "/reg-number-details/",
    [USER_JOURNEY_STEPS.SO_RESCHEDULE]: `/reschedule-appointment/slot?token=:leadToken&e=1`,
    [USER_JOURNEY_STEPS.STALE_LEAD]: `/appointment/index?token=:leadToken`,
    [USER_JOURNEY_STEPS.STALE_LEAD_NARROW]: `/appointment/index?token=:leadToken`,
    [USER_JOURNEY_STEPS.STALE_LEAD_QUESTION]: `/sell-online-questions?token=:leadToken`
};

const getRedirectionPath = (data = {}, isDesktop) => {
    const { stage = "", screenType = "", leadToken = "" } = data;
    let screenTypePath = redirectionPaths[screenType] ? redirectionPaths[screenType] : redirectionPaths[USER_JOURNEY_STEPS.LEAD];
    if (screenType === USER_JOURNEY_STEPS.MISSED_SO) {
        if (isDesktop) {
            screenTypePath = screenTypePath.replace("booked", "address");
        } else {
            screenTypePath = redirectionPaths[USER_JOURNEY_STEPS.SO_RESCHEDULE];
        }
    }
    const currentPath = stage === USER_JOURNEY_STEPS.INSPECTED ? redirectionPaths[USER_JOURNEY_STEPS.INSPECTED] : screenTypePath;
    return currentPath.replace(":leadToken", leadToken);
};

const isNoPriceScreen = (data = {}) => {
    const {
        veryGoodMinQuotePrice = "",
        veryGoodMaxQuotePrice = "",
        excellentMinQuotePrice = "",
        fairMaxQuotePrice = "",
        goodMaxQuotePrice = "",
        goodMinQuotePrice = "",
        maxQuotedPrice = "",
        minQuotedPrice = "",
        stage = ""
    } = data || {};
    return [USER_JOURNEY_STEPS.LEAD, USER_JOURNEY_STEPS.BOOKED, USER_JOURNEY_STEPS.STALE_LEAD].includes(stage) &&
        !(veryGoodMinQuotePrice || veryGoodMaxQuotePrice || excellentMinQuotePrice || fairMaxQuotePrice ||
            goodMaxQuotePrice || goodMinQuotePrice || maxQuotedPrice || minQuotedPrice);
};

export const isAuctionInProgress = (priceDiscovery) => {
    return [
        PRICE_DISCOVERY.START,
        PRICE_DISCOVERY.NOT_STARTED,
        PRICE_DISCOVERY.NEGOTIATE_PRICE_REQUESTED
    ].includes(priceDiscovery);
};

// eslint-disable-next-line complexity
export const getRepeatUserScreenDetails = (details = {}) => {
    const {
        isExpired,
        priceDiscovery,
        inspectionOfferedPrice,
        allowPllIntent,
        captureIntentAgain,
        pllIntent,
        appointmentData
    } = details || {};
    const { is_purchased: isPurchased } = appointmentData || {};
    const isPriceExpired = isExpired || !inspectionOfferedPrice;
    if (
        isAuctionInProgress(priceDiscovery) ||
        (priceDiscovery === PRICE_DISCOVERY.OVER && !inspectionOfferedPrice && !isExpired && !allowPllIntent)
    ) return USER_JOURNEY_STEPS.INSPECTED_IN_PROGRESS;
    if (isPriceExpired) {
        if (allowPllIntent && pllIntent && !captureIntentAgain) return USER_JOURNEY_STEPS.INSPECTED_IN_PROGRESS;
        if (captureIntentAgain || (allowPllIntent && !pllIntent)) return USER_JOURNEY_STEPS.INSPECTED_USER_INTENT;
        if (inspectionOfferedPrice) return USER_JOURNEY_STEPS.ACTIVE_PRICE_EXPIRED;
        return USER_JOURNEY_STEPS.INSPECTED_PRICE_EXPIRED;
    } else if ([NUMBER.ZERO, null].includes(inspectionOfferedPrice)) {
        return USER_JOURNEY_STEPS.INSPECTED_NO_BID;
    }
    if (isPurchased === stringConstants.YES && !isExpired) {
        return USER_JOURNEY_STEPS.BOUGHT_TO_STOCKIN;
    }
    return USER_JOURNEY_STEPS.INSPECTED_PRICE_ACTIVE;
};

const getScreenType = (data) => {
    const { stage = "" } = data || {};
    if (!stage || !USER_JOURNEY_STEPS[stage]) return USER_JOURNEY_STEPS.LEAD;

    if (stage === USER_JOURNEY_STEPS.INSPECTED) {
        return getRepeatUserScreenDetails(data);
    } else if (stage === USER_JOURNEY_STEPS.LEAD_SCRAP) {
        if (data && data.netAmount) {
            return USER_JOURNEY_STEPS.LEAD_SCRAP_PRICE;
        } else {
            return USER_JOURNEY_STEPS.LEAD_SCRAP_RANGE;
        }
    } else if (isNoPriceScreen(data)) {
        return USER_JOURNEY_STEPS.LEAD_NO_PRICE;
    } else {
        return stage;
    }
};

// eslint-disable-next-line complexity
const getParsedData = (data = {}, isDesktop) => {
    const {
        carImage = "",
        vehicleRegNo: registrationNumber = "",
        inspectionDate = "",
        inspectionDayAlias = "",
        kilometreDriven = NUMBER.ZERO,
        time = "",
        date = "",
        dayAlias = "",
        inspectionOfferedPrice = "",
        isExpired = "",
        priceDiscovery = "",
        allowPllIntent = "",
        captureIntentAgain = "",
        pll_intent: pllIntent = "",
        userJourneyAbExperiment = null,
        appointmentData = {},
        jsonContent = {},
        template = {},
        screen: diyScreenType = ""
    } = data;

    const {
        make_display: brand = "",
        make_id: brandId,
        model_display: model = "",
        model_id: modelId,
        variant_display_name: variant,
        variant_id: variantId,
        year = "",
        variant_year_id: yearId,
        stage = "",
        fuel_type: fuelType = "",
        customerName = "",
        token: leadToken = "",
        rto_code: rtoCode = "",
        rto_id: rtoId = NUMBER.ZERO,
        netAmount = NUMBER.ZERO,
        maxPrice = NUMBER.ZERO,
        minPrice = NUMBER.ZERO,
        very_good_min_quote_price: veryGoodMinQuotePrice = "",
        very_good_max_quote_price: veryGoodMaxQuotePrice = "",
        narrowPriceMin = "",
        narrowPriceMax = "",
        appointment_id: appointmentId = "",
        locality_address: address = "",
        address1 = "",
        address2 = "",
        offered_price: offeredPrice = "",
        lead_id: leadId = "",
        user_id: userId = "",
        abExperiment2: resetAuctionExperiment = "",
        is_open_slot: isOpenSlot = "",
        transmission_type: transmissionType = "",
        state_id: stateId = NUMBER.ZERO,
        state_code: stateCode = "",
        updatedAtFormatted: priceUpdateDate = "",
        updatedAtDayAlias: priceUpdateDayAlias = "",
        excellent_min_quote_price: excellentMinQuotePrice = "",
        fair_max_quote_price: fairMaxQuotePrice = "",
        good_max_quote_price: goodMaxQuotePrice = "",
        good_min_quote_price: goodMinQuotePrice = "",
        max_quoted_price: maxQuotedPrice = "",
        min_quoted_price: minQuotedPrice = "",
        lead_type: leadType = "",
        lead_offer: leadOffer = {},
        inspectedCar = {}
    } = appointmentData;
    const { offer_status: offerStatus = ""  } = leadOffer || {};

    const details = {
        stage,
        netAmount,
        narrowPriceMin,
        narrowPriceMax,
        appointmentData,
        veryGoodMinQuotePrice,
        veryGoodMaxQuotePrice,
        offeredPrice,
        leadToken,
        isExpired,
        priceDiscovery,
        inspectionOfferedPrice,
        allowPllIntent,
        captureIntentAgain,
        pllIntent,
        excellentMinQuotePrice,
        fairMaxQuotePrice,
        goodMaxQuotePrice,
        goodMinQuotePrice,
        maxQuotedPrice,
        minQuotedPrice,
        jsonContent,
        template
    };

    const screenType = getScreenType(details);
    const redirection = getRedirectionPath({ screenType, ...details, isDesktop });

    return {
        ...(stage === USER_JOURNEY_STEPS.LEAD_SCRAP && {
            maxPrice,
            minPrice,
            price: netAmount
        }),
        ...(([USER_JOURNEY_STEPS.BOOKED,
            USER_JOURNEY_STEPS.CONFIRMED,
            USER_JOURNEY_STEPS.LEAD,
            USER_JOURNEY_STEPS.STALE_LEAD,
            USER_JOURNEY_STEPS.MISSED].includes(stage)) && {
            minPrice: veryGoodMinQuotePrice,
            maxPrice: veryGoodMaxQuotePrice
        }),
        ...(([USER_JOURNEY_STEPS.BOOKED_NARROW,
            USER_JOURNEY_STEPS.CONFIRMED_NARROW,
            USER_JOURNEY_STEPS.MISSED_NARROW,
            USER_JOURNEY_STEPS.LEAD_NARROW,
            USER_JOURNEY_STEPS.STALE_LEAD_NARROW].includes(stage)) && {
            minPrice: narrowPriceMin,
            maxPrice: narrowPriceMax
        }),
        ...(([USER_JOURNEY_STEPS.BOOKED_SO,
            USER_JOURNEY_STEPS.CONFIRMED_SO,
            USER_JOURNEY_STEPS.MISSED_SO].includes(stage)) && {
            price: offeredPrice
        }),
        ...((stage === USER_JOURNEY_STEPS.INSPECTED) && {
            price: inspectionOfferedPrice
        }),
        screenType,
        brand,
        brandId,
        model,
        modelId,
        variant,
        variantId,
        year,
        yearId,
        stage,
        fuelType,
        customerName,
        leadToken,
        kilometreDriven,
        inspectionDate,
        registrationNumber,
        rtoCode,
        rtoId,
        carImage,
        redirection,
        date,
        time,
        allowPllIntent,
        captureIntentAgain,
        pllIntent,
        appointmentId,
        address,
        leadId,
        userId,
        userJourneyAbExperiment,
        resetAuctionExperiment,
        isOpenSlot: isOpenSlot === WIDGET_RESPONSE_CONSTANTS.YES,
        branchAddress: `${address1} ${address2}`,
        transmissionType,
        stateId,
        stateCode,
        priceUpdateDate,
        dayAlias,
        inspectionDayAlias,
        priceUpdateDayAlias,
        leadType,
        diyScreenType,
        responseLeadData: {
            make_id: appointmentData.make_id,
            model_id: appointmentData.model_id,
            year_id: appointmentData.variant_year_id,
            variant_id: appointmentData.variantId,
            kms: appointmentData.kms,
            state_id: appointmentData.state_id,
            rto_id: appointmentData.rto_id,
            user_city_id: appointmentData.cityId
        },
        leadOffer,
        isOfferActive: offerStatus === offerStatuses.OFFER_ACTIVE,
        jsonContent,
        template,
        veryGoodMinQuotePrice,
        veryGoodMaxQuotePrice,
        fairMaxQuotePrice,
        goodMaxQuotePrice,
        goodMinQuotePrice,
        maxQuotedPrice,
        minQuotedPrice,
        inspectedCar
    };
};

const getExperimentType = (experiment) => {
    if (experiment === null) {
        return null;
    } else if (experiment) {
        return experiment;
    } else {
        return AB_TEST_VARIATIONS.CONTROL;
    }
};

const isHomePageRevampApplicable = (homePageRevamp, details) => {
    const { missedLeadExperimentType, missedAppointmentExperimentType } = details || {};
    if (missedLeadExperimentType === AB_TEST_VARIATIONS.CONTROL || missedAppointmentExperimentType === AB_TEST_VARIATIONS.CONTROL) return false;
    return missedLeadExperimentType === AB_TEST_VARIATIONS.VARIANT || missedAppointmentExperimentType === AB_TEST_VARIATIONS.VARIANT || homePageRevamp;
};

// eslint-disable-next-line complexity
export default ({ widgetDetails = {}, responseStatus, isClient = false, isDesktop = true, isUserToLead = false }) => {
    if (responseStatus !== HTTP_CODES.SUCCESS && !isUserToLead) {
        return {
            userDropList: [],
            allCardInfo: "",
            isUserDrop: false,
            isError: isClient ? false : responseStatus === WIDGET_RESPONSE_CONSTANTS.ECONNABORTED,
            experimentType: AB_TEST_VARIATIONS.CONTROL
        };
    }
    const { list = [] } = widgetDetails;
    const userDropList = [];
    const allCardInfoList = [];
    const firstCardData = (list && list.length && list[NUMBER.ZERO] && list[NUMBER.ZERO].data) || {};
    const showWidget = (firstCardData && (isHomePageRevampApplicable(firstCardData.homePageRevamp, firstCardData.appointmentData))) || false;
    const experimentType = getExperimentType(firstCardData && firstCardData.userJourneyAbExperiment);

    list.forEach(({ data = {} }) => {
        const parsedData = getParsedData(data, isDesktop);
        if (showWidget || isUserToLead) {
            userDropList.push(parsedData);
        }
        allCardInfoList.push(`${parsedData.leadId},${parsedData.screenType.toLowerCase()}`);
    });

    return {
        userDropList,
        allCardInfo: allCardInfoList.toString(),
        isUserDrop: userDropList.length !== NUMBER.ZERO,
        isError: false,
        experimentType,
        noDataFound: userDropList.length === NUMBER.ZERO,
        isUserToLead,
        ...(firstCardData && firstCardData.appointmentData && { leadOrApptExp: `lead_${(firstCardData.appointmentData.missedLeadExperimentType || "null").toLowerCase()}, appt_${(firstCardData.appointmentData.missedAppointmentExperimentType || "null").toLowerCase()}` })
    };
};

export const getUserDropData = (isUserDropAfterLogin, name) => {

    return  isUserDropAfterLogin ? {
        badge: {
            title: "car details pending",
            variant: "warning"
        },
        title: "Know the best selling price for your car ₹",
        subTitle: "Continue to add your car details.",
        carName: `${name}`,
        showActionBtns: true,
        actionButtons: {
            primaryButtonCtaText: "Complete car details",
            primaryButtonCtaUrl: "/"
        }
    } : {
        badge: {
            title: "action needed",
            variant: "warning"
        },
        title: "Know the best selling price for your car ₹",
        carName: `${name}`,
        showActionBtns: true,
        actionButtons: {
            primaryButtonCtaText: "Log in to view price",
            primaryButtonCtaUrl: "/"
        }
    };
};
