import Types from "./types";
import { STEP_NAMES } from "../../../constants/app-constants";
import { LeadServiceJava } from "../../../service/lead-service-java-api";

import queryString from "querystring";
import { CarService } from "../../../service/car-service";
import { VehicleService } from "../../../service/vehicle-service";
import { setExperiment } from "../../shared/ab-experiment/actions";
import { generateLeadTokenPayload, getUpdatedLeadFormData } from "./selector";
import { parseLeadFormData } from "../../../utils/parsers/parse-lead-form-data";
import { INITIAL_STATE as leadFormInitialState } from "./reducers";

const setStep = ({ currentStep, tabs }) => {
    return {
        type: Types.SET_CURRENT_STEP,
        currentStep,
        tabs
    };
};

// const setBrand = ({ brand, stepper, tabs, tabData, registrationNo }) => ({
//     type: Types.SET_BRAND,
//     brand,
//     stepper,
//     tabs,
//     tabData,
//     registrationNo
// });

// const setModel = ({ model, stepper, tabs, tabData, registrationNo }) => ({
//     type: Types.SET_MODEL,
//     model,
//     stepper,
//     tabs,
//     tabData,
//     registrationNo
// });

// const setYear = ({ year, registrationNo }) => ({
//     type: Types.SET_YEAR,
//     year,
//     registrationNo
// });

// const setVariant = ({ variant, registrationNo }) => ({
//     type: Types.SET_VARIANT,
//     variant,
//     registrationNo
// });

// const setRegistrationState = ({ registrationState, registrationNo }) => ({
//     type: Types.SET_REGISTRATION_STATE,
//     registrationState,
//     registrationNo
// });

const setKilometerDriven = ({ kilometersDriven }) => ({
    type: Types.SET_KILOMETER_DRIVEN,
    kilometersDriven
});

const makeLeadInit = () => ({
    type: Types.MAKE_LEAD
});

const makeLeadSuccess = (data) => ({
    type: Types.MAKE_LEAD_SUCCESS,
    data
});

const makeLeadFailure = (data) => ({
    type: Types.MAKE_LEAD_FAILURE,
    data
});

const showProceedButton = () => ({
    type: Types.SHOW_PROCEED_BUTTON
});

const activateLastStep = (data) => ({
    type: Types.ACTIVATE_LAST_STEP,
    data
});

const setIsTrueCaller = (isTruecaller) => ({
    type: Types.SET_IS_TRUE_CALLER,
    isTruecaller
});

const setIsTrueCallerCancelled = (isTruecallerCancelled) => ({
    type: Types.SET_IS_TRUE_CALLER_CANCELLED,
    isTruecallerCancelled
});

// const setBranch = ({ branch, stepper, tabs, tabData }) => ({
//     type: Types.SET_BRANCH,
//     branch,
//     stepper,
//     tabs,
//     tabData
// });

const updateLeadData = (data) => ({
    type: Types.UPDATE_LEAD_DATA,
    data
});

const saveLeadFormData = (payload) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.saveLeadFormData(payload).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
};

// eslint-disable-next-line max-params
const makeLead = (query, payload, userToken, stableID, leadData, gaId) => dispatch => {
    const stringifiedQuery = queryString.stringify(query);
    dispatch(makeLeadInit());
    return new Promise((resolve, reject) => {
        LeadServiceJava.createLead(stringifiedQuery, payload, userToken, true)
            .then(response => {
                if (stableID || gaId) dispatch(saveLeadFormData(generateLeadTokenPayload({leadToken: response.data.detail.token, stableID, leadData, gaId})));
                dispatch(makeLeadSuccess(response.data.detail));
                resolve(response.data.detail);
            })
            .catch(error => {
                reject(error);
                dispatch(makeLeadFailure(error));
            });
    });
};

const makeLeadwithoutPhoneNumber = (query, payload) => dispatch => {
    const stringifiedQuery = queryString.stringify(query);
    dispatch(makeLeadInit());
    return new Promise((resolve, reject) => {
        LeadServiceJava.createLeadWithoutPhone(stringifiedQuery, payload)
            .then(response => {
                resolve(response.data.detail);
                dispatch(makeLeadSuccess(response.data.detail));
            })
            .catch(error => {
                reject(error);
                dispatch(makeLeadFailure(error));
            });
    });
};

// eslint-disable-next-line max-params
const updateLead = (query, params, updateReducer = true, stableID, leadData, gaId) => dispatch => {
    const stringifiedQuery = queryString.stringify(query);
    return new Promise((resolve, reject) => {
        LeadServiceJava.updateLead(stringifiedQuery, params).then(response => {
            resolve(response.data.detail);
            if (updateReducer) {
                dispatch(makeLeadSuccess(response.data.detail));
                if (stableID || gaId) dispatch(saveLeadFormData(generateLeadTokenPayload({leadToken: response.data.detail.token, stableID, leadData, gaId})));
            }
        }).catch(error => {
            reject(error);
            dispatch(makeLeadFailure(error));
        });
    });
};

const updateLeadWithoutPhoneNumber = (query, params) => dispatch => {
    const stringifiedQuery = queryString.stringify(query);
    return new Promise((resolve, reject) => {
        LeadServiceJava.updateLeadWithoutPhone(stringifiedQuery, params).then(response => {
            resolve(response.data.detail);
            dispatch(makeLeadSuccess(response.data.detail));
        }).catch(error => {
            reject(error);
            dispatch(makeLeadFailure(error));
        });
    });
};

const mergeLeadData = (response) => ({
    type: Types.MERGE_LEAD_DATA,
    data: response
});

const setRTO = (rto) => ({
    type: Types.SET_RTO,
    rto
});

const setFuelType = (carFuelType) => ({
    type: Types.SET_FUEL_TYPE,
    carFuelType
});

const setTransmissionType = (transmission) => ({
    type: Types.SET_TRANSMISSION_TYPE,
    transmission
});

// eslint-disable-next-line complexity
// const sendLeadFormData = ({ data, stableId, type, leadData = {}, stepper, tabs, tabData, registrationNo, gaId}) => (dispatch) => {
//     const updatedLeadData = getUpdatedLeadFormData({ data, type, leadData, stepper, tabs, tabData, registrationNo });
//     const payload = {
//         ...(stableId && { client_id: stableId || gaId }),
//         data: updatedLeadData
//     };

//     dispatch(updateLeadData(payload.data));
//     dispatch(saveLeadFormData(payload));
// };

// eslint-disable-next-line complexity
const setData = ({ type, data }, { stepper, tabs, tabData, stableId = "", leadData = {}, gaId }) => (dispatch, getState) => {
    const registrationNo = getState().registrationNo;
    const updatedLeadData = getUpdatedLeadFormData({
        data,
        type: type === STEP_NAMES.Kms ? STEP_NAMES.KilometerDriven : type,
        leadData,
        stepper,
        tabs,
        tabData,
        registrationNo
    });
    const payload = {
        client_id: stableId || gaId,
        data: updatedLeadData
    };

    dispatch(updateLeadData(payload.data));
    dispatch(saveLeadFormData(payload));
};

// const setData = ({ type, data }, { stepper, tabs, tabData, stableId = "", leadData = {}, gaId }) => (dispatch, getState) => {
//     const registrationNo = getState().registrationNo;
//     dispatch(sendLeadFormData({
//         stableId,
//         data,
//         type: type === STEP_NAMES.Kms ? STEP_NAMES.KilometerDriven : type,
//         leadData,
//         stepper,
//         tabs,
//         tabData,
//         registrationNo,
//         gaId
//     }));
// switch (type) {
// case STEP_NAMES.Brand:
//     dispatch(setBrand({
//         [STEP_NAMES.Brand]: data,
//         stepper,
//         tabs,
//         tabData,
//         registrationNo
//     }));
//     break;
// case STEP_NAMES.Model:
//     dispatch(setModel({
//         [STEP_NAMES.Model]: data,
//         stepper,
//         tabs,
//         tabData,
//         registrationNo
//     }));
//     break;
// case STEP_NAMES.Year:
//     dispatch(setYear({ [STEP_NAMES.Year]: data, registrationNo }));
//     break;
// case STEP_NAMES.Variant:
//     dispatch(setVariant({ [STEP_NAMES.Variant]: data, registrationNo }));
//     break;
// case STEP_NAMES.Transmission:
//     dispatch(setTransmissionType(data.displayText));
//     break;
// case STEP_NAMES.FuelType:
//     dispatch(setFuelType(data.displayText));
//     break;
// case STEP_NAMES.RTO:
//     dispatch(setRTO(data));
//     break;
// case STEP_NAMES.State:
//     dispatch(setRegistrationState({ [STEP_NAMES.State]: data, registrationNo }));
//     break;
// case STEP_NAMES.Kms:
//     dispatch(setKilometerDriven({ [STEP_NAMES.KilometerDriven]: data, registrationNo }));
//     break;
// case STEP_NAMES.Branch:
//     dispatch(setBranch({
//         [STEP_NAMES.Branch]: data,
//         stepper,
//         tabs,
//         tabData
//     }));
// }
// };

const setFormData = (details) => ({
    type: Types.SET_LEAD_FORM_DATA,
    details
});

const resetLeadForm = (clearToken = false) => ({
    type: Types.RESET_LEAD_FORM_DATA,
    clearToken
});

const setFlowName = (flowName) => ({ type: Types.SET_FLOW_NAME, flowName });

const updateCTALocation = (ctaLocation) => ({
    type: Types.UPDATE_CTA_LOCATION,
    ctaLocation
});

const updateProceedCTAStatus = (proceedCTA) => ({
    type: Types.UPDATE_PROCEED_CTA_STATUS,
    proceedCTA
});

const updateExperimentalT0CTAClickStatus = (experimentalT0CTAClicked) => ({
    type: Types.UPDATE_EXPERIMENTAL_CTA_CLICK_STATUS,
    experimentalT0CTAClicked
});

const setTutorialStatus = (isTutorialEnabled) => ({
    type: Types.SET_TUTORIAL_STATUS,
    isTutorialEnabled
});

const setAckoExtrasForAppointment = (ackoAppointmentExtras) => ({
    type: Types.SET_ACKO_EXTRAS_FOR_APPOINTMENT,
    ackoAppointmentExtras
});

const setCarCondition = (condition) => ({
    type: Types.CAR_CONDITION_TYPE,
    condition
});

const setIsWebQuoteAccepted = (isWebQuoteAccepted) => ({
    type: Types.SET_IS_WEB_QUOTE_ACCEPTED,
    isWebQuoteAccepted
});

const setActiveScreen = (activeScreen) => ({
    type: Types.SET_ACTIVE_SCREEN,
    activeScreen
});

const setFgvgeScreen = (fgvgeScreen) => ({
    type: Types.SET_FGVGE_SCREEN,
    fgvgeScreen
});

const resetAllScreen = () => ({
    type: Types.RESET_ALL_SCREEN
});

const setUserExpectedPrice = (expectedPrice) => ({
    type: Types.USER_EXPECTED_PRICE,
    expectedPrice
});

const setUserFairCarReasons = (reasons) => ({
    type: Types.USER_FAIR_CAR_PRICE_REASON,
    reasons
});

const setUserAdditionalQuestion = (additionalQuestions) => ({
    type: Types.SET_USER_ADDITIONAL_QUESTIONS,
    additionalQuestions
});

const setUserCarRgistrationNo = (carRegistrationNo) => ({
    type: Types.SET_USER_CAR_REGISTRATION_NUMBER,
    carRegistrationNo
});

const setShowConfirmDetailModal = (flag) => ({
    type: Types.SET_SHOW_CONFIRM_DETAIL_MODAL,
    flag
});

// eslint-disable-next-line no-unused-vars
const getVehicleDetail = (registrationNo, extraParams) => dispatch => {
    return new Promise((resolve, reject) => {
        CarService.vehicleNoRegistration(registrationNo, extraParams)
            .then(response => {
                resolve(response.data.detail);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const allStepFilled = (isAllStepFill) => ({
    type: Types.SET_MODAL_VALUE,
    isAllStepFill
});

const setOwnerName = (ownerName) => ({
    type: Types.SET_OWNER_NAME,
    ownerName
});

const setVariantActiveScreen = (variantActiveScreen) => ({
    type: Types.SET_VARIANT_ACTIVE_SCREEN,
    variantActiveScreen
});

const setIsSellOnline = (flag) => ({
    type: Types.SET_IS_SELL_ONLINE,
    flag
});

const setLeadScore = (leadScore) => ({
    type: Types.SET_LEAD_SCORE,
    leadScore
});

const setSellMktUserDrop = (sellMktUserDrop) => ({
    type: Types.SET_SELL_MKT_USER_DROP,
    sellMktUserDrop
});

// eslint-disable-next-line no-unused-vars
const updateLeadWithGsDeny = (token, params) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.updateLeadWithGsDeny(token, params).then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const askUserIntent = (flag) => ({
    type: Types.ASK_USER_INTENT,
    flag
});

const setUserIntent = (intent) => ({
    type: Types.SET_USER_INTENT,
    intent
});

const setSimilarCars = (similarCars) => ({
    type: Types.SET_SIMILAR_CARS,
    similarCars
});

const getQuestionForUserIntent = (type = "") => () => {
    return new Promise((resolve, reject) => {
        VehicleService.getQuestionForUserIntent(type).then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const checkAppointmentExistence = (data) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.checkAppointmentExistence(data).then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const verifyAuthUserLead = (data) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.verifyAuthUserLead(data).then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const setNarrowPriceExperiment = (experiment) => ({
    type: Types.SET_NARROW_PRICE_EXPERIMENT,
    experiment
});

// eslint-disable-next-line no-unused-vars
const reVerifyAppointment = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.reVerifyAppointment(params).then(() => {
            resolve();
        }).catch(error => {
            reject(error);
        });
    });
};

const getSimilarCarsSold = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        VehicleService.getSimilarCarsSold(params).then(response => {
            dispatch(setSimilarCars(response.data.detail));
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const updateLeadDetails = (payload) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.updateLeadDetails(payload).then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
            dispatch(makeLeadFailure(error));
        });
    });
};

// eslint-disable-next-line no-unused-vars
const fetchLeadDetails = (token, pvtAuth) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.fetchLeadDetails(token, {}, true, pvtAuth).then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

// eslint-disable-next-line no-unused-vars
const saveCcDropLead = (payload) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.saveCcDropLead(payload).then(() => {
            resolve();
        }).catch(error => {
            reject(error);
        });
    });
};

const checkC2bGaExperiments = (expKey, payload) => dispatch => {
    return new Promise((resolve, reject) => {
        VehicleService.checkC2bGaExperiments(payload).then((response) => {
            const expValue = response.data.detail.experimentValue;
            dispatch(setExperiment(expKey, expValue));
            resolve(expValue);
        }).catch(error => {
            reject(error);
        });
    });
};

const setStateActiveScreen = (stateActiveScreen) => ({
    type: Types.SET_STATE_ACTIVE_SCREEN,
    stateActiveScreen
});

const fetchChannelPartnerLeadDetails = (partnerToken) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.getChannelPartnerLeadDetails(partnerToken)
            .then(response => resolve(response.data.detail))
            .catch(error => reject(error));
    });
};

const setShowCarLoader = (flag) => ({
    type: Types.SHOW_CAR_LOADER,
    flag
});

const setSelectedCityId = (id) => ({
    type: Types.SET_SELECTED_CITY_ID,
    id
});

const setStatsigText = (text) => ({
    type: Types.SET_STATSIG_TEXT,
    text
});

const saveDataToLeadAttributes = (token, payload) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.saveDataToLeadAttributes(token, payload).then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const setSellerFinance = (option) => ({
    type: Types.SET_USER_FINANCE,
    option
});

const sendClevertapEvents = ({ token, secureToken, payload }) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.getAppClickEvent({ token, payload, secureToken })
            .then((res) => {
                resolve(res);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const getLeadFormData = (clientId, statsigStableId) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.getLeadFormData(clientId || statsigStableId)
            .then((res) => {
                resolve(res);
                const leadFormDetails = parseLeadFormData(res || {});
                dispatch(updateLeadData(leadFormDetails));
            })
            .catch(error => {
                reject(error);
            });
    });
};

const resetLeadFormData = (clearToken = false, stableID) => dispatch => {
    if (!clearToken) {
        dispatch(saveLeadFormData({
            client_id: stableID,
            data: {...leadFormInitialState}
        }));
    }
    dispatch(resetLeadForm(clearToken));
};

const updateMergedLeadForm = (details, stableID, gaId) => dispatch => {
    dispatch(saveLeadFormData({
        client_id: stableID || gaId,
        data: {...leadFormInitialState, ...details}
    }));
};
const getSimilarCarsData = (token) => {
    return new Promise((resolve, reject) => {
        VehicleService.similarCarsData(token)
            .then((res) => {
                resolve(res);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export {
    setData,
    setStep,
    setUserIntent,
    setSelectedCityId,
    getQuestionForUserIntent,
    setKilometerDriven,
    makeLead,
    showProceedButton,
    activateLastStep,
    updateLead,
    mergeLeadData,
    resetLeadFormData,
    setIsTrueCaller,
    setIsTrueCallerCancelled,
    setFlowName,
    updateCTALocation,
    setRTO,
    allStepFilled,
    updateProceedCTAStatus,
    updateExperimentalT0CTAClickStatus,
    setTutorialStatus,
    setAckoExtrasForAppointment,
    setCarCondition,
    setIsWebQuoteAccepted,
    setActiveScreen,
    setFgvgeScreen,
    resetAllScreen,
    setUserExpectedPrice,
    setUserFairCarReasons,
    setUserAdditionalQuestion,
    setUserCarRgistrationNo,
    getVehicleDetail,
    setShowConfirmDetailModal,
    setFuelType,
    setOwnerName,
    setTransmissionType,
    setVariantActiveScreen,
    setIsSellOnline,
    makeLeadwithoutPhoneNumber,
    updateLeadWithoutPhoneNumber,
    setLeadScore,
    askUserIntent,
    updateLeadWithGsDeny,
    setNarrowPriceExperiment,
    checkAppointmentExistence,
    verifyAuthUserLead,
    reVerifyAppointment,
    getSimilarCarsSold,
    updateLeadDetails,
    fetchLeadDetails,
    saveCcDropLead,
    setSellMktUserDrop,
    checkC2bGaExperiments,
    setStateActiveScreen,
    setFormData,
    setShowCarLoader,
    fetchChannelPartnerLeadDetails,
    setStatsigText,
    saveDataToLeadAttributes,
    setSellerFinance,
    saveLeadFormData,
    sendClevertapEvents,
    updateLeadData,
    getLeadFormData,
    updateMergedLeadForm,
    getSimilarCarsData
};
