export const checkEquifax = (equifax, dealerCode) => equifax && equifax === "B" && !dealerCode;

export const checkRtdLoader = ({equifaxLoader, equifax, dealerCode = false}) => {
    const isEquiFaxFlowEnabled = checkEquifax(equifax, dealerCode);
    const showEquifaxLoader = isEquiFaxFlowEnabled && equifaxLoader && (equifaxLoader === "A" || equifaxLoader === "B");
    return showEquifaxLoader;
};

export const checkLoaderWithCta = (equifaxLoader) => equifaxLoader && equifaxLoader === "B";

export const checkDiyOfferExperiment = (diyCohort) => diyCohort && diyCohort === "B";

export const checkDiyVariant = (diyVariant) => diyVariant && diyVariant === "B";

export const checkCfFeedbackFlow = (feedbackConfig) => feedbackConfig && feedbackConfig === "A";

export const checkPanValidationEnabled = (panValidationVariant) => panValidationVariant && panValidationVariant === "A";

export const checkAaThemeRevampVariant = (aaThemeRevampVariant) => aaThemeRevampVariant && aaThemeRevampVariant === "A";

