import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const BottomNavIcon = ({
    pageName,
    label,
    onTabClickHandler = () => {},
    ActiveIcon,
    DefaultIcon,
    tab,
    currentPageType
}) => {
    const IconComp = (pageName || []).includes(currentPageType) ? ActiveIcon : DefaultIcon;

    const onClickHandler = (tabLabel, tabValue) => {
        if ((pageName || []).includes(currentPageType)) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        } else {
            onTabClickHandler(tabLabel, tabValue);
        }
    };

    return (
        <li key={label} styleName={`styles.icon ${(pageName || []).includes(currentPageType) ? "styles.active" : ""}`} className="shrinkOnTouch" onClick={() => onClickHandler(label, tab)}>
            <IconComp />
            <span>{tab}</span>
        </li>

    );
};

BottomNavIcon.propTypes = {
    pageName: PropTypes.string,
    label: PropTypes.string,
    onTabClickHandler: PropTypes.func,
    ActiveIcon: PropTypes.node,
    DefaultIcon: PropTypes.node,
    tab: PropTypes.string,
    currentPageType: PropTypes.string
};

export default BottomNavIcon;
