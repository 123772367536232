import React, { useEffect, useState, useCallback } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { NUMBER } from "../../../constants/app-constants";

const ContainerSticky = ({
    children,
    offset,
    bottomOffset,
    elementRef,
    thresholdRef,
    isInitiallyMounted = false,
    isRevamped = false,
    onSticky = () => {},
    onStatic = () => {},
    onFirstFold = () => {},
    isScrollingDown
}) => {
    const [shadowClass, setshadowClass] = useState(isInitiallyMounted ? "styles.sticky" : "");
    const [isMounted, setIsMounted] = useState(isInitiallyMounted);

    const getScrollClass = () => {
        if (!isRevamped) return "";
        return isScrollingDown ? "styles.stickyPositionScrollDown" : "styles.stickyPositionScrollUp";
    };

    const handleScroll = useCallback(() => {
        if (thresholdRef &&
            thresholdRef.current && (
            (window.innerHeight - thresholdRef.current.getBoundingClientRect().y)
                > bottomOffset)
        ) {
            setshadowClass("");
            setIsMounted(false);
            onStatic();
        } else if (elementRef &&
            elementRef.current &&
            elementRef.current.getBoundingClientRect().y < offset - NUMBER.SEVENTY) {
            setshadowClass(`styles.sticky ${getScrollClass()}`);
            setIsMounted(true);
            onSticky();
        } else {
            setshadowClass("");
            setIsMounted(false);
            onStatic();
            onFirstFold();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bottomOffset, elementRef, offset, thresholdRef, isScrollingDown, shadowClass]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, [elementRef, handleScroll, isScrollingDown]);

    return (
        <div styleName={`${shadowClass}`}>
            {isMounted && children}
        </div>
    );
};

ContainerSticky.propTypes = {
    elementRef: PropTypes.object,
    children: PropTypes.object,
    offset: PropTypes.number,
    bottomOffset: PropTypes.number,
    thresholdRef: PropTypes.object,
    styleType: PropTypes.string,
    isInitiallyMounted: PropTypes.bool,
    onSticky: PropTypes.func,
    onStatic: PropTypes.func,
    onFirstFold: PropTypes.func,
    isRevamped: PropTypes.bool,
    isScrollingDown: PropTypes.bool
};

export default ContainerSticky;
