import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_CAR_PRICE_DETAIL
    FETCH_LEAD_DETAIL_FAILURE
    SET_IS_SUBMITTED
    SET_ACTIVE_SCREEN
    IS_SUBMITTING_DATA
    IS_PINCODE_GS_ELIGBLE
    POST_SELL_ONLINE_QUESTIONS
    SET_MMYVS_ELIGIBLITY
    STORE_ANSWERED_QUESTION
    SET_IS_GS_ELIGIBLE
    FETCH_APPOINTMENT_DETAIL_SUCCESS
    FETCH_APPOINTMENT_DETAIL_FAILURE
    FETCH_APPOINTMENT_DETAIL_INIT
    POST_OFFER_PRICE_INPUT_SUCCESS
    SET_OFFER_PRICE
    UPDATE_APPOINTMENT_BOOKING_FAILURE
    UPDATE_APPOINTMENT_BOOKING_INIT
    SET_CURRENT_QUESTION_INDEX
    FETCH_DATE_SLOTS_SUCCESS
    FETCH_DATE_SLOTS_FAILURE
    FETCH_DATE_SLOTS_INIT
    SET_DATA_FROM_SSR
    SET_SSR
    RESET_QUESTIONS
    SET_REGISTRATION_NUMBER
    SET_LEAD_CREATED_TYPE
    SET_DEALER_CODE
    GET_DEALER_CAR_PRICE
    GET_VEHICLE_BOUGHT_COUNT
    GET_CURRENT_VERSION_QUESTION
    GET_CURRENT_VERSION_DETAILS
    FETCH_SLOTS_OFFER_BY_LOCATIONS_SUCCESS
    FETCH_SLOTS_OFFER_BY_LOCATIONS_FAILURE
    SET_IS_SELL_ONLINE_CITY
    SET_USER_REPORT
    SET_IS_APPLICABLE_FOR_PRICE_REPORT
    SET_SELLER_REVIEW
    SET_USER_LOCATION_ACCESSIBILITY
    SET_USER_RESPONSE
    SET_HYPOTHECATION
    SET_SKIP_QUESTIONS
    SET_SELECTED_CAR_PARTS
    RESET_SELECTED_CAR_PARTS
    SET_SO_LEAD_DATA
    SET_CAR_EXPERT_DATA
    SET_CONSULTATION_DATA
    SET_SO_LOCATION
    SET_OFFERED_PRICE
    SET_APPOINTMENT_DETAILS_LOADING
    SET_IS_CHANGING_LOCATION
    SET_VIDEO_GALLERY
    SET_FLOW_TYPE
`,
    {
        prefix: "sellonlineofferprice/"
    }
);
