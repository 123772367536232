import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isSSR: false,
    isFetched: false,
    isLoading: true,
    content: null,
    pageType: null,
    seoData: null,
    tabs: [],
    pageData: null,
    isError: false,
    noContent: false,
    currentSEOSelectedCity: null,
    usedCars: []
};

export const fetchSEODetails = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true, isFetched: false };
};

export const fetchSEODetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isLoading: false,
        isFetched: true,
        ...data,
        ...(!state.currentSEOSelectedCity && {currentSEOSelectedCity: {label: data.pageData.currentCity }})
    };
};

export const fetchSEODetailsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: false,
        isFetched: true,
        isError: true
    };
};

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return {
        ...state,
        isSSR
    };
};

export const updateCurrentSEOCity = (state = INITIAL_STATE, { currentSEOSelectedCity }) => {
    return {
        ...state,
        currentSEOSelectedCity
    };
};

export const fetchCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: false
    };
};

export const fetchCarListSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        usedCars: data.content
    };
};

export const fetchCarListFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isLoading: false };
};

export const HANDLERS = {
    [Types.FETCH_SEO_DETAILS]: fetchSEODetails,
    [Types.FETCH_SEO_DETAILS_SUCCESS]: fetchSEODetailsSuccess,
    [Types.FETCH_SEO_DETAILS_FAILURE]: fetchSEODetailsFailure,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.UPDATE_CURRENT_SEO_CITY]: updateCurrentSEOCity,
    [Types.FETCH_CAR_LIST]: fetchCarList,
    [Types.FETCH_CAR_LIST_SUCCESS]: fetchCarListSuccess,
    [Types.FETCH_CAR_LIST_FAILURE]: fetchCarListFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
