/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo } from "react";
import styles from "./styles.css";
import LogoCard from "../../shared/logo-card";
import PropTypes from "prop-types";
import { LEAD_FORM, STEP_NAMES, NUMBER, AB_TEST_VARIATIONS } from "../../../constants/app-constants";
import { FUNNEL_CONFIG } from "../../../utils/funnel/steps.mobile";
import TransparentButton from "../../shared/button/transparent-button";
import { sendCustomGaEvents } from "../../../tracking";
import { brandIconClicked, brandSelectionHome, moreBrandsClicked, moreButton } from "./tracking";
import reorderBrandList from "../../../utils/helpers/reorder-brand-list";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const CarBrandList = ({
    useParentCallback = null,
    leadData,
    history,
    popular,
    isSSR,
    setDataConnect,
    setStepConnect,
    btnCallback = false,
    activateLastStepConnect,
    fetchDataConnect,
    isParentBuyer = false,
    getCarPrice = () => { },
    imgSize = 0,
    fetchPriorityCount = NUMBER.ZERO,
    visiblePopularBrands = NUMBER.TWELVE,
    isRevamped = false,
    sellMarketingExperiment,
    // Will be used for UI
    isCustomClassRequired = false,
    isDlsCompliant,
    isLuxuryPage,
    luxury,
    statsigId,
    gaId,
    selectedBrandId
}) => {
    const { brand: { id: brandId } = {} } = leadData || {};
    const brandList = useMemo(
        () => reorderBrandList({popular: isLuxuryPage ? luxury : popular, visiblePopularBrands}),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [luxury, visiblePopularBrands, popular]
    );

    const selectedUserBrand = brandId;
    const [formConfig, setFormConfig] = useState(null);

    useEffect(() => {
        if (!isSSR) {
            fetchDataConnect();
        }
    }, [fetchDataConnect, isSSR]);

    useEffect(() => {
        const type = LEAD_FORM.DEFAULT;
        setFormConfig(FUNNEL_CONFIG[type]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setData = (data) => {
        setDataConnect(data, {...formConfig, stableId: statsigId, leadData, gaId});
    };

    const nextStep = async (stepName) => {
        activateLastStepConnect(false);
        setStepConnect({ currentStep: stepName, tabs: formConfig.tabs });
        if (isParentBuyer) {
            getCarPrice();
        } else {
            await yieldToMain();
            history.push("/sell-online-evaluation");
        }
    };

    const onClickHandler = async (selectedBrand) => {
        if (typeof useParentCallback === "function") {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useParentCallback(selectedBrand);
        } else {
            setData({ type: STEP_NAMES.Brand, data: selectedBrand });
            nextStep(STEP_NAMES.Year);
            await yieldToMain();
            sendCustomGaEvents(brandSelectionHome);
        }
        await yieldToMain();
        sendCustomGaEvents({
            ...brandIconClicked,
            eventLabel: selectedBrand.displayText
        });

    };

    const onMoreClickHandler = async () => {
        if (typeof btnCallback === "function") {
            btnCallback();
        } else {
            nextStep(STEP_NAMES.Brand);
            await yieldToMain();
            sendCustomGaEvents(moreButton);
        }
        await yieldToMain();
        sendCustomGaEvents(moreBrandsClicked);
    };

    const getText = () => {
        if (sellMarketingExperiment === AB_TEST_VARIATIONS.VARIANT) return "Select Brand";
        return "Or, start with your car brand";
    };
    return (
        <React.Fragment>
            {
                brandList.length ? <React.Fragment>
                    {
                        !isDlsCompliant && sellMarketingExperiment === AB_TEST_VARIATIONS.VARIANT &&
                        <p styleName={"styles.orSection"}>
                            <span>OR</span>
                        </p>
                    }

                    {
                        (!isParentBuyer && !isDlsCompliant) &&
                        <h4 styleName={"styles.smallHeading"}>{getText()}</h4>
                    }

                    <ul styleName={`styles.brandLogoWrap ${isRevamped ? "styles.marketingBrandsWrapper" : ""} ${isCustomClassRequired ? "styles.logoRevamped" : ""} ${isDlsCompliant ? "styles.sellMarketingRevampWrapper" : ""}`}>
                        <li styleName={`styles.brandLogo`}>
                            {
                                brandList.map(({ logo, make_name, make_display, make_id }, index) => {
                                    return (
                                        <LogoCard
                                            key={index}
                                            image={imgSize && imgSize > 0 ? `${logo}?width=${imgSize}` : logo}
                                            selected={[selectedUserBrand, selectedBrandId].includes(make_id)}
                                            onClick={() => onClickHandler({
                                                displayText: make_display,
                                                id: make_id
                                            })}
                                            isLazyLoad={index > fetchPriorityCount}
                                            altText={make_name}
                                        />
                                    );
                                })
                            }
                        </li>
                        <li styleName={"styles.buttonWrap"}>
                            <div styleName={"styles.moreCta"}>
                                <TransparentButton
                                    text={"more"}
                                    onClick={onMoreClickHandler}
                                />
                            </div>
                        </li>
                    </ul>
                </React.Fragment> : null
            }
        </React.Fragment>
    );
};

CarBrandList.propTypes = {
    useParentCallback: PropTypes.func,
    leadData: PropTypes.object,
    history: PropTypes.object,
    popular: PropTypes.array,
    isSSR: PropTypes.bool,
    btnCallback: PropTypes.func,
    setDataConnect: PropTypes.func,
    setStepConnect: PropTypes.func,
    activateLastStepConnect: PropTypes.func,
    fetchDataConnect: PropTypes.func,
    setMarketingDataConnect: PropTypes.func,
    isParentBuyer: PropTypes.bool,
    getCarPrice: PropTypes.func,
    visiblePopularBrands: PropTypes.number,
    isRegNumberExperiment: PropTypes.bool,
    imgSize: PropTypes.number,
    fetchPriorityCount: PropTypes.number,
    isRevamped: PropTypes.bool,
    sellMarketingExperiment: PropTypes.string,
    isCustomClassRequired: PropTypes.bool,
    isDlsCompliant: PropTypes.bool,
    isLuxuryPage: PropTypes.bool,
    luxury: PropTypes.array,
    statsigId: PropTypes.string,
    gaId: PropTypes.string,
    selectedBrandId: PropTypes.number
};

export default CarBrandList;

