/* eslint-disable max-statements */
import React, { useState, useEffect, useMemo, useRef, useContext, useCallback, startTransition } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import styles from "./styles.css";
import NotFound from "../../shared/page-not-found";
import { HTTP_CODES, seoTemplateType, STEP_NAMES, LEAD_FORM, NUMBER, redirect } from "../../../constants/app-constants";
import { findByKey } from "../../../utils/helpers/find-by-key";
import ErrorHandler from "../../shared/with-error-handler";
import { FUNNEL_CONFIG } from "../../../utils/funnel/steps.mobile";
import { LazyComponent } from "../../shared/lazy-image";
import Button from "../../shared/button";
import { C2B_LEAD_TYPES } from "../../../constants/seller-lead-types";
import { sendCustomGaEvents } from "../../../tracking";
import { scrollStoresAndParkingYards, popularCityTextClick, regNumberSkip, sellNowCTA, sellNowStickyCTA, cityHomePageBanner } from "./tracking";
import { appUrl } from "../../../constants/url-constants";
import { InView } from "react-intersection-observer";
import { APP_NUDGE_LINKS } from "../../../constants/app-nudge-links";
import SellUsedCarCityList from "../../shared/sell-used-car-city-list";
import TestimonialCarousel from "../testimonial-carousel";
import SeoPageContent from "../seo-page-content";
import MetaTags from "../../shared/meta-tags";
import BranchesHubs from "../branches-hubs";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { Helmet } from "react-helmet-async";
import ContainerSticky from "../../shared/container-sticky";
import useExperiments from "../../../hooks/use-statsig-experiment";
import { EXPERIMENT_NAME } from "../../../utils/statsig-ab-utils/constant";
const SellerExperienceProcess = loadable(() => import("../seller-experience-process"));
const WhyCars24Section = loadable(() => import("../sell-marketing-revamp-why-cars-section"));
import HomeBannerWrapper from "../home-banner-wrapper";
const RatingSection = loadable(() => import("../sell-marketing-revamp-rating"));
const TestimonialVideos = loadable(() => import("../sell-marketing-revamp-testimonials"));
const DiscoverCars = loadable(() => import("../discover-cars"));
import PilotFaqV2 from "../pilot-faq-v2";
import { LinkButton } from "@cars24/turbo-web/lib/link-button";
import { ArrowIcon } from "@cars24/turbo-web/lib/icons/arrow-icon";
const GetAppInlineBanner = loadable(() => import("../get-app-inline-banner"));
import ExchangeBonus from "../exchange-bonus";
import { Breadcrumbs } from "@cars24/turbo-web/lib/breadcrumbs";
import { LayoutContext } from "../layout/component";
import GenericBrandBanner from "../../shared/generic-brand-banner";
import useC2bCleverTap from "../../../hooks/use-c2b-clevertap";

// eslint-disable-next-line complexity
const SellUsedCarSeo = ({
    list: reviewList,
    match,
    history,
    status,
    resetLeadFormDataConnect,
    isFirstLoad,
    setFirstLoadConnect,
    updateLeadSourceConnect,
    templateType,
    setDataConnect,
    identifiers,
    allBrands,
    allModels,
    setStepConnect,
    cityList,
    topCityKey,
    nearestCities,
    setIsSellOnlineConnect,
    hubsAndParkingYards,
    isSSR,
    fetchDataConnect,
    topCitiesList,
    faqList,
    setStatsigTextConnect,
    genericContent,
    cmsContentDetails,
    leadData,
    statsigId,
    gaId,
    statsigDynamicConfigData,
    websiteAdvertisementBannerData
}) => {
    useC2bCleverTap("Sell_car");
    useExperiments({
        experimentKeys: [EXPERIMENT_NAME.app_download_banner], callback: (text) => {
            setStatsigTextConnect(text);
        }
    });
    const { websiteAds = {}} = statsigDynamicConfigData || {};
    const defaultType = LEAD_FORM.DEFAULT;
    const [hasReset, setHasReset] = useState(false);
    const safetyBannerTop = useRef();
    const [askForUserConfirmation, setAskForUserConfirmation] = useState(false);
    const [isSkipDisabled, setIsSkipDisabled] = useState(false);
    const [isScrollApplicable, setIsScrollApplicable] = useState(false);
    const [carRegPopup, setCarRegPopup] = useState(null);
    const isScrollingDown = useContext(LayoutContext);

    const cityId = identifiers && identifiers.city && identifiers.city.toString();
    const { city_name: cityName } = findByKey(cityList, "city_id", cityId);
    const { jsonContent = {}} = genericContent || {};
    const {headingFour = "People we made happy", headingOne = "Steps to sell your car", headingThree = "Trusted by sellers across india", headingTwo = "Why CARS24"} = jsonContent || {};
    const { homePageLottie = [] } = cmsContentDetails || {};
    const slugCityName = cityName || "";
    const citySlugName = (slugCityName || "").toLowerCase();
    const SELL_USED_CAR_CITY_ROUTE_NAME = `sell-used-car${citySlugName}`;
    const memoizedTopCitiesList = useMemo(() => {
        if (topCitiesList && topCitiesList.length) return topCitiesList.slice(NUMBER.ZERO, NUMBER.TWENTY);
        return null;
    }, [topCitiesList]);

    useEffect(() => {
        updateLeadSourceConnect(C2B_LEAD_TYPES.SEO_ORIGINAL);
    }, [updateLeadSourceConnect]);

    useEffect(() => {
        setIsSellOnlineConnect(false);
    }, [setIsSellOnlineConnect]);
    useEffect(() => {

        if (isFirstLoad && ![seoTemplateType.CITY, seoTemplateType.CITY_LOCATION, "",
            seoTemplateType.GENERIC, undefined]
            .includes(templateType)) {
            setFirstLoadConnect();
            resetLeadFormDataConnect();
            setHasReset(true);
        }
    }, [isFirstLoad, resetLeadFormDataConnect, setFirstLoadConnect, templateType]);

    useEffect(() => {

        if (hasReset && identifiers) {
            if (identifiers.make) {
                const carBrand = findByKey(allBrands, "make_id", identifiers.make);
                setDataConnect({
                    type: STEP_NAMES.Brand, data: {
                        displayText: carBrand.make_display, id: identifiers.make
                    }
                }, {...FUNNEL_CONFIG[LEAD_FORM.DEFAULT], stableId: statsigId, leadData, gaId});
                setStepConnect({
                    currentStep: STEP_NAMES.Year,
                    tabs: FUNNEL_CONFIG[LEAD_FORM.DEFAULT].tabs
                });
            }
            if (identifiers.model) {
                const carModel = findByKey(allModels, "model_id", identifiers.model);
                setDataConnect({
                    type: STEP_NAMES.Model, data: {
                        displayText: carModel.model_display, id: identifiers.model
                    }
                }, { ...FUNNEL_CONFIG[LEAD_FORM.DEFAULT], stableId: statsigId, leadData, gaId});
                setStepConnect({
                    currentStep: STEP_NAMES.Year,
                    tabs: FUNNEL_CONFIG[LEAD_FORM.DEFAULT].tabs
                });
            }
        }
    }, [allBrands, allModels, gaId, hasReset, identifiers, leadData, setDataConnect, setStepConnect, statsigId]);

    useEffect(() => {
        if (!isSSR) {
            const city = match && match.params && match.params.city;
            const cmsPayload = {
                cmsuri: `/${city}`,
                expand: "make_list,seo_content,city_list,customer_review_list,tweets"
            };
            fetchDataConnect(cmsPayload, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSSR]);
    const handleClickCount = useCallback((count) => {
        if (count > NUMBER.ZERO) {
            setIsSkipDisabled(true);
        }
    }, []);

    const nextStep = useCallback(async (stepName) => {
        setStepConnect({ currentStep: stepName, tabs: FUNNEL_CONFIG[defaultType].tabs });
        await yieldToMain();
        history.push(`/sell-online-evaluation/${stepName}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, defaultType]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSkipHandler = async (isBrandSelected) => {
        if (isBrandSelected || leadData.brand.id) {
            nextStep(STEP_NAMES.Year);
        } else {
            nextStep(STEP_NAMES.Brand);
        }
        startTransition(() => {
            sendCustomGaEvents(regNumberSkip);
        });
    };
    const setData = useCallback((data) => {
        setDataConnect(data, FUNNEL_CONFIG[defaultType]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultType]);

    const onSelection = useCallback((selectedBrand) => {
        setData({ type: STEP_NAMES.Brand, data: selectedBrand });
        if (!isSkipDisabled) {
            setAskForUserConfirmation(true);
        } else {
            onSkipHandler(true);
        }
    }, [setData, isSkipDisabled, setAskForUserConfirmation, onSkipHandler]);

    const handleAutoScroll = useCallback((inView) => {
        setIsScrollApplicable(inView);
    }, []);

    const onClick = useCallback(async (isStickyCta) => {
        setAskForUserConfirmation(true);

        /* tracking GA events */
        startTransition(() => {
            if (isStickyCta) {
                sendCustomGaEvents(sellNowStickyCTA);
            } else {
                sendCustomGaEvents(sellNowCTA);
            }
        });
    }, []);

    const scrollHubsGaCallback = () => {
        sendCustomGaEvents(scrollStoresAndParkingYards);
    };

    const handleCityListGa = async (popularCityName) => {
        startTransition(() => {
            sendCustomGaEvents({
                ...popularCityTextClick,
                eventLabel: popularCityName
            });
        });
    };

    useEffect(() => {
        if (askForUserConfirmation && carRegPopup === null) {
            import(/* webpackChunkName: "car-registration-popup" */ "../car-registration-popup").then((comp) => {
                setCarRegPopup(
                    <comp.default
                        close={() => setAskForUserConfirmation(false)}
                        onSkip={onSkipHandler}
                    />
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [askForUserConfirmation]);

    const onRedirect = async () => {
        startTransition(() => {
            sendCustomGaEvents(cityHomePageBanner);
        });
        window.location.href = `/buy-used-cars-${citySlugName}`;
    };
    const computeBreadcrumbList = () => {
        const list = [
            {
                name: "Home",
                url: "/"
            },
            {
                name: "Sell car",
                url: "/sell-used-cars/"
            }];

        if (slugCityName) {
            list.push({
                name: `Sell car in ${slugCityName}`
            });
        }
        return list;
    };

    if (status === HTTP_CODES.NOT_FOUND) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <Helmet>
                <link href={`${appUrl()}${history.location.pathname.replace(/\/$/, "")}/`} rel="canonical" />
            </Helmet>
            {askForUserConfirmation && carRegPopup}
            <HomeBannerWrapper
                safetyBannerTop={safetyBannerTop}
                onSelection={onSelection}
                handleOnClick={onClick}
                handleClickCount={handleClickCount}
                isSellMarketingRevamp
                isDlsCompliant
            />
            <MetaTags match={match} attacheWebPageSchema/>
            { websiteAdvertisementBannerData &&  <div styleName={"styles.adsBanner"}><GenericBrandBanner config={websiteAds} type={"320x100"} fetchpriority="high" pageType={`sell-used-car-${cityName}`}  position = "top" source = "mSite"/></div>}
            <div styleName={"styles.highlights"}>
                <InView as="div" threshold={0.5} onChange={
                    (inView) => handleAutoScroll(inView)}
                >
                    <SellerExperienceProcess
                        heading={headingOne}
                        isScrollApplicable={isScrollApplicable}
                    />
                </InView>
            </div>
            {
                homePageLottie && homePageLottie.length > 0 && <div styleName={"styles.whyCars"}>
                    <LazyComponent>
                        <WhyCars24Section heading={headingTwo} lottieData={homePageLottie} />
                    </LazyComponent>
                </div>
            }
            <div styleName={"styles.ratings"}>
                <LazyComponent>
                    <RatingSection heading={headingThree}/>
                </LazyComponent>
            </div>
            <div styleName={"styles.testimonialVideos"}>
                <LazyComponent>
                    <TestimonialVideos heading={headingFour} />
                </LazyComponent>
            </div>
            {
                !!(reviewList.length >= NUMBER.TWO) &&
                <ErrorHandler>
                    <div styleName={"styles.testimonial"} className="cvh">
                        <TestimonialCarousel
                            testimonialRevamp={true}
                            customersHeaderReview={false}
                            currentPage={"seo"}
                            cityName={slugCityName}
                            isLazyLoaded={true}
                            heading={"10 Lakh+ happy customers"}
                            showDate={false}
                            isDlsCompliant
                        />
                    </div>
                </ErrorHandler>
            }
            <ContainerSticky
                offset={NUMBER.TEN}
                bottomOffset={80}
                elementRef={safetyBannerTop}
                isRevamped={true}
                isScrollingDown={isScrollingDown}
            >
                <div styleName={"styles.buttonSticky"}>
                    <Button
                        onClick={() => onClick(true)}
                        text={"GET CAR PRICE"} />
                </div>
            </ContainerSticky>
            <div styleName={"styles.discoverCars"}><LazyComponent><DiscoverCars handleClick={onClick} /></LazyComponent></div>
            <LazyComponent>
                <ExchangeBonus onClick={onRedirect} isDlsCompliant />
            </LazyComponent>
            {
                hubsAndParkingYards && !!hubsAndParkingYards.length &&
                <div styleName={"styles.branchesHubsCarousel"} className="cvh">
                    <BranchesHubs
                        slidesToShow={1.1}
                        hubsList={hubsAndParkingYards}
                        scrollWidgetGa={scrollHubsGaCallback}
                    />
                </div>
            }
            {
                !!(faqList && faqList.length) &&
                <div styleName={"styles.faqWrap"} className="cvh">
                    <PilotFaqV2 list={faqList} attachSchema />
                    <LinkButton
                        label={"Read More"}
                        suffixIcon={<ArrowIcon size="14px" />}
                        size="medium"
                        variant="brand"
                        underLine={false}
                        href={`${redirect.CONSUMER_WEB_IN}/faq/`}
                    />
                </div>
            }
            <LazyComponent>
                <div styleName={"styles.getAppInlineBanner"}>
                    <GetAppInlineBanner route={{ name: SELL_USED_CAR_CITY_ROUTE_NAME }} redirectionLink={APP_NUDGE_LINKS.OTHER_PAGES.INLINE_BANNER} isSellPage/>
                </div>
            </LazyComponent>
            <SeoPageContent
                isDlsCompliant
            />

            {
                !!(nearestCities && nearestCities.length) &&
                <div styleName={"styles.cityWrap"} className="cvh">
                    <ErrorHandler>
                        <SellUsedCarCityList
                            cityList={nearestCities}
                            topCityKey={topCityKey}
                            callBack={handleCityListGa}
                            heading={"Nearby Cities to Sell your Car"}
                            showArrow
                            showMoreControl
                            isDlsCompliant
                        />
                    </ErrorHandler>
                </div>
            }
            {
                !!(memoizedTopCitiesList && memoizedTopCitiesList.length) &&
                <div styleName={"styles.cityWrap"} className="cvh">
                    <ErrorHandler>
                        <SellUsedCarCityList
                            cityList={memoizedTopCitiesList}
                            callBack={handleCityListGa}
                            heading={"Popular Cities to Sell Your Car"}
                            showArrow
                            showMoreControl
                            isDlsCompliant
                        />
                    </ErrorHandler>
                </div>
            }

            <div styleName={"styles.breadcrumbs"} >
                <Breadcrumbs
                    attachSchema
                    baseUrl={appUrl()}
                    list={computeBreadcrumbList()}
                />
            </div>

        </React.Fragment>
    );
};

SellUsedCarSeo.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    identifiers: PropTypes.object.isRequired,
    templateType: PropTypes.string.isRequired,
    status: PropTypes.number,
    resetLeadFormDataConnect: PropTypes.func,
    isFirstLoad: PropTypes.bool,
    setFirstLoadConnect: PropTypes.func,
    updateLeadSourceConnect: PropTypes.func,
    allBrands: PropTypes.array,
    allModels: PropTypes.array,
    setDataConnect: PropTypes.func,
    setStepConnect: PropTypes.func,
    cityList: PropTypes.array.isRequired,
    topCityKey: PropTypes.string,
    setIsSellOnlineConnect: PropTypes.func,
    completeSellOnlineCities: PropTypes.array,
    list: PropTypes.array,
    nearestCities: PropTypes.array,
    hubsAndParkingYards: PropTypes.array,
    isSSR: PropTypes.bool,
    fetchDataConnect: PropTypes.func,
    topCitiesList: PropTypes.array,
    faqList: PropTypes.array,
    setStatsigTextConnect: PropTypes.func,
    genericContent: PropTypes.object,
    cmsContentDetails: PropTypes.object,
    isUserDrop: PropTypes.bool,
    route: PropTypes.any,
    leadData: PropTypes.object,
    statsigId: PropTypes.string,
    gaId: PropTypes.string,
    statsigDynamicConfigData: PropTypes.object,
    websiteAdvertisementBannerData: PropTypes.bool
};

export default SellUsedCarSeo;
