import Types from "./types";
import { UsedCarService } from "../../../service/used-car-service";
import { CarService } from "../../../service/car-service";

const fetchSEODetailsInit = () => ({ type: Types.FETCH_SEO_DETAILS });

const fetchSEODetailsSuccess = (data) => ({
    type: Types.FETCH_SEO_DETAILS_SUCCESS,
    data
});

const fetchSEODetailsFailure = () => ({ type: Types.FETCH_SEO_DETAILS_FAILURE });

const fetchSEODetails = (payload) => (dispatch) => {
    dispatch(fetchSEODetailsInit());
    return new Promise((resolve, reject) => {
        CarService.fetchSEODetails(payload)
            .then(response => {
                dispatch(fetchSEODetailsSuccess(response.data.detail));
                resolve(response.data.detail);
            })
            .catch((error) => {
                dispatch(fetchSEODetailsFailure());
                reject(error);
            });
    });
};

const updateSSRStatus = (isSSR) => ({ type: Types.UPDATE_SSR_STATUS, isSSR });

const updateCurrentSEOCity = (label, value) => ({
    type: Types.UPDATE_CURRENT_SEO_CITY,
    currentSEOSelectedCity: {
        label, value
    }
});

const fetchCarListInit = () => ({
    type: Types.FETCH_CAR_LIST
});

const fetchCarListSuccess = (data) => ({
    type: Types.FETCH_CAR_LIST_SUCCESS,
    data
});

const fetchCarListFailure = (error) => ({ type: Types.FETCH_CAR_LIST_FAILURE, error });

const fetchCarList = (payload, pincode, clientId) => dispatch => {
    dispatch(fetchCarListInit());
    return new Promise((resolve, reject) => {
        UsedCarService.fetchCarsFromPathName({ payload, pincode, clientId })
            .then(response => {
                dispatch(fetchCarListSuccess(response.data.data));
                resolve(response.data.data);
            }).catch(error => {
                dispatch(fetchCarListFailure(error));
                reject(error);
            });
    });
};

export {
    updateCurrentSEOCity,
    fetchSEODetails,
    updateSSRStatus,
    fetchCarList
};
