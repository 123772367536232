/* eslint-disable max-params */
import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

/* eslint-disable max-statements */
const b2cGatewayServiceApi = (api) => {

    const updateUserTestDriveAddress = ({payload, token}) => {
        return api.post(`/address`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            version: "v1"
        });
    };

    const getTestDriveAddressList = ({token}) => {
        return api.get(`/address`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            version: "v1"
        });
    };

    const initVasPayment = (token, body, params, userId) => {
        return api.post(`/vas/service-history/init-payment`, body, {
            params,
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                ...(userId && { userId }),
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN"
            }
        });
    };

    const getVasDetails = (token, orderId) => {
        return api.get(`vas/service-history/${orderId}/details`, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN"
            }
        });
    };

    const getFulfilmentData = (token, regNo) => {
        return api.get(`warranty/${regNo}/fulfillment`, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN"
            }
        });
    };

    return {
        updateUserTestDriveAddress,
        getTestDriveAddressList,
        initVasPayment,
        getVasDetails,
        getFulfilmentData
    };
};

export const B2cGatewayService = b2cGatewayServiceApi(middlewareConfig(MIDDLEWARE_ENUMS.B2C_GATEWAY_SERVICE));
