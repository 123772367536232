import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_COMPARING_WIDGET
    SET_COMPARING_ACTIVE

    ADD_CAR_TO_COMPARE
    REMOVE_CAR_COMPARE

    SET_COMPARE_CAR_LIMIT_ERROR

    SET_SIMILAR_CARS_POPUP

    FETCH_COMPARING_CAR_DETAIL_DATA
    FETCH_COMPARING_CAR_DETAIL_DATA_FAILURE
    FETCH_COMPARING_CAR_DETAIL_DATA_SUCCESS

    SET_CAR_IMPERFECTION_POPUP

    SET_RELOAD_COMPARE_CAR
`,
    {
        prefix: "comparewidget/"
    }
);
