import Types from "./types";

const updateAppNudgeFlags = (flag) => {
    return {
        type: Types.SET_APP_NUDGE_HEADER_VALUE,
        flag
    };
};

const  updateNudgePopUpValue = (flag) => ({
    type: Types.SET_NUDGE_POPUP_VALUE,
    flag
});

const  updateSellNudgeValue = (flag) => ({
    type: Types.SET_SELL_APP_NUDGE_VALUE,
    flag
});

export {
    updateAppNudgeFlags,
    updateNudgePopUpValue,
    updateSellNudgeValue
};
