/* eslint-disable complexity */
/* eslint-disable max-params */
/* eslint-disable max-statements */
import { STEP_NAMES, ACTIVE_VARIANT_SCREEN, CURRENT_REG_SCREEN } from "../../../constants/app-constants";

export const TAB_SECTIONS = {
    [STEP_NAMES.Variant]: [STEP_NAMES.FuelType, ACTIVE_VARIANT_SCREEN.TRANSMISSIONTYPE, ACTIVE_VARIANT_SCREEN.VARIANT_TYPE ],
    [STEP_NAMES.State]: [CURRENT_REG_SCREEN.STATE, CURRENT_REG_SCREEN.RTO]
};

export const TABS_WITH_SECTIONS = [STEP_NAMES.State, STEP_NAMES.Variant];

export const LEAD_FORM_SECTION_MAP = {
    [STEP_NAMES.FuelType]: STEP_NAMES.FuelType,
    [ACTIVE_VARIANT_SCREEN.TRANSMISSIONTYPE]: STEP_NAMES.Transmission,
    [ACTIVE_VARIANT_SCREEN.VARIANT_TYPE]: STEP_NAMES.Variant,
    [CURRENT_REG_SCREEN.RTO]: CURRENT_REG_SCREEN.RTO,
    [CURRENT_REG_SCREEN.STATE]: STEP_NAMES.State
};

export const isValidLeadFormTab = (tabs, requestedTab) => {

    for (let i = 0; i < tabs.length; i++) {
        const tabName = tabs[i].name.toLowerCase();
        if (tabName === requestedTab || (tabName === CURRENT_REG_SCREEN.STATE && STEP_NAMES.State === requestedTab)) {
            return true;
        }
    }
    return false;
};

const getName = (stateName) => {
    if (!stateName) {
        return null;
    }
    return stateName === STEP_NAMES.KilometerDriven ? STEP_NAMES.Kms : stateName;
};

// eslint-disable-next-line complexity
export const findEmptyLeadFormTab = (tabs, leadData, requestedTab) => {
    for (const tab of tabs) {
        const { stateName } = tab;
        /* if requested tab is already filled */
        if (requestedTab === getName(stateName) && leadData[stateName] && leadData[stateName].displayText) {
            return requestedTab;
        } else if (stateName === STEP_NAMES.State) {
            if ((leadData[stateName] && !leadData[stateName].displayText) || (leadData[stateName] && (!leadData[STEP_NAMES.RTO] || !leadData[STEP_NAMES.RTO].displayText))) {
                return  getName(stateName);
            }
        } else if (leadData[stateName] && !leadData[stateName].displayText) {
            return  getName(stateName);
        }
    }

    return null;
};

export const getActiveTab = (tabs, leadData, requestedTab) => {

    const currentStep = findEmptyLeadFormTab(tabs, leadData, requestedTab);
    if (currentStep) return currentStep;
    return leadData.currentStep;
};

export const getActiveScreen = (section) => {

    switch (section) {
    case ACTIVE_VARIANT_SCREEN.VARIANT_TYPE :
        return ACTIVE_VARIANT_SCREEN.VARIANT;

    case ACTIVE_VARIANT_SCREEN.VARIANT :
        return ACTIVE_VARIANT_SCREEN.VARIANT;

    case STEP_NAMES.FuelType:
        return ACTIVE_VARIANT_SCREEN.FUELTYPE;

    case ACTIVE_VARIANT_SCREEN.TRANSMISSIONTYPE:
        return ACTIVE_VARIANT_SCREEN.TRANSMISSIONTYPE;

    case CURRENT_REG_SCREEN.STATE:
        return CURRENT_REG_SCREEN.STATE;
    default:
        return CURRENT_REG_SCREEN.RTO;
    }
};

export const getPreviousFilledSection = (requestedSection, leadData, tab) => {

    let previousFilledSection = requestedSection;

    /* if the current tab doesn't include the requested section, then assign the first section of the tab */
    if (!TAB_SECTIONS[tab].includes(requestedSection)) {
        previousFilledSection = tab === STEP_NAMES.Variant ? "variant" : CURRENT_REG_SCREEN.STATE;
    }

    const sectionsForTab = TAB_SECTIONS[tab];

    for (let i = 0; i < sectionsForTab.length; i++) {
        const section = sectionsForTab[i];
        const keyValue = LEAD_FORM_SECTION_MAP[section];
        if (section === previousFilledSection && leadData[keyValue].displayText) {
            previousFilledSection = section;
            break;
        }
        if (!leadData[keyValue].displayText) {
            previousFilledSection = section;
            break;
        }
    }
    return previousFilledSection;
};

