import Types from "./types";
import { CatalogGatewayService } from "../../../service/catalog-gateway";

const fetchDataSuccess = data => ({
    type: Types.FETCH_FOOTER_DATA_SUCCESS,
    data
});

const fetchDataFailure = error => ({
    type: Types.FETCH_FOOTER_DATA_FAILURE,
    error
});

const fetchData = params => dispatch => {
    CatalogGatewayService.fetchMobileFooterLinks(params)
        .then(response => {
            dispatch(fetchDataSuccess(response.data));
        })
        .catch(error => {
            dispatch(fetchDataFailure(error));
        });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

export {
    fetchData,
    fetchDataSuccess,
    fetchDataFailure,
    updateSSRStatus
};
