import React from "react";

const BuyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clipPath="url(#clip0_5155_122274)">
                <path d="M17.392 10.0366L12.9544 4.74404C12.6322 4.36048 12.158 4.1394 11.6573 4.1394H5.42184C4.48693 4.1394 3.73047 4.89586 3.73047 5.83078V10.0392" fill="white" />
                <path d="M17.392 10.0366L12.9544 4.74404C12.6322 4.36048 12.158 4.1394 11.6573 4.1394H5.42184C4.48693 4.1394 3.73047 4.89586 3.73047 5.83078V10.0392" stroke="#717272" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M18.9095 10.0364H3.0905C2.28436 10.0364 1.63086 10.6899 1.63086 11.496V14.8734C1.63086 15.6796 2.28436 16.3331 3.0905 16.3331H18.9095C19.7156 16.3331 20.3691 15.6796 20.3691 14.8734V11.496C20.3691 10.6899 19.7156 10.0364 18.9095 10.0364Z" fill="white" stroke="#717272" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M6.89675 18.9488C8.64437 18.9488 10.0611 17.5321 10.0611 15.7844C10.0611 14.0368 8.64437 12.6201 6.89675 12.6201C5.14914 12.6201 3.73242 14.0368 3.73242 15.7844C3.73242 17.5321 5.14914 18.9488 6.89675 18.9488Z" fill="white" stroke="#717272" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M15.2835 18.9488C17.0311 18.9488 18.4478 17.5321 18.4478 15.7844C18.4478 14.0368 17.0311 12.6201 15.2835 12.6201C13.5359 12.6201 12.1191 14.0368 12.1191 15.7844C12.1191 17.5321 13.5359 18.9488 15.2835 18.9488Z" fill="white" stroke="#717272" strokeWidth="1.3" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_5155_122274">
                    <rect width="21" height="21" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default BuyIcon;
