import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import SimilarCarCardSeo from "./component";
import { updateSSRStatus } from "../seo-car-overview/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSSRStatusConnect: updateSSRStatus
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SimilarCarCardSeo));
