import React from "react";
import PropTypes from "prop-types";
import ExchangeBanner from "./images/exchangeBanner.jpg";
import styles from "./styles.css";
import Button from "../../shared/button";
import { EXCHANGE_POINTS } from "./selector";
import { Heading } from "@cars24/turbo-web/lib/heading";
import { NUMBER } from "../../../constants/app-constants";

const ExchangeBonus = ({
    cityInfo,
    onClick,
    isDlsCompliant = false
}) => {
    const { is_buy_used_car: isBuyUsedCar = false} = cityInfo || {};

    return (
        <React.Fragment>
            {
                isBuyUsedCar &&
                <div styleName={"styles.exclusive"}>
                    {isDlsCompliant ? <Heading level={NUMBER.TWO}>Offers</Heading> :  <h2>Exclusive offer</h2>}
                    <div styleName={"styles.exclusiveWrapper"}>
                        <div styleName={"styles.exclusiveBannerWrapper"}>
                            <picture width="360" height="450">
                                <source srcSet={ExchangeBanner} type="image/jpg" />
                                <img width="360" fetchpriority="high" height="450" styleName={"styles.banners"} src={ExchangeBanner} alt="ExchangeBanner" />
                            </picture>
                            <div styleName={"styles.bannerContent"}>
                                <div styleName="styles.content">
                                    <h3>Exchange Bonus<br />up to <span>₹20,000</span></h3>
                                    <ul>
                                        {
                                            EXCHANGE_POINTS.map((item, index) => (
                                                <li key={index}>
                                                    <img src={item.img} alt={item.title} />
                                                    {item.title}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div styleName={"styles.cityCta"}>
                                    <Button text={"buy cars in your city "} onClick={onClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

ExchangeBonus.propTypes = {
    cityInfo: PropTypes.object,
    onClick: PropTypes.func,
    isDlsCompliant: PropTypes.bool
};

export default ExchangeBonus;
