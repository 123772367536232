import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

const BASE_URL = "/api/v1/bff-schema";
const superAppCmsService = (api) => {
    const getCmsData = (bannerType) => {
        return api.get(`${BASE_URL}/page/page-template/${bannerType}`);
    };
    const getCarouselData = ({slugId, slugValue}) => {
        return api.get(`${BASE_URL}/consumer-api/carousel?categorySlug=${slugId}_${slugValue}`);
    };
    const getPageTemplate = (pageTemplate) => {
        return api.get(`${BASE_URL}/page/page-template/${pageTemplate}`);
    };

    return {
        getCmsData,
        getPageTemplate,
        getCarouselData
    };
};

export const SuperAppCmsService = superAppCmsService(middlewareConfig(MIDDLEWARE_ENUMS.SUPER_APP_CMS_SERVICE));
