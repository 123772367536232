/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styles from "./styles.css";
import IconHeart from "../../shared/icon-heart/component";
import priceFormatter from "../../../utils/helpers/price-formatter";
import Exclamation from "./images/exclamation-icon.svg";
import exclamationBlue from "./images/exclamation-icon-blue.svg";
import ArrowIcon from "../../shared/arrow";
import { NUMBER } from "../../../constants/app-constants";
import { SOURCE } from "./constant";
import makePriceLabel from "../../../utils/helpers/make-price-label";
import { sendCustomGaEvents } from "../../../tracking";
import mobileLastDigit from "../../../utils/helpers/mobile-last-digit";
import Button from "../../shared/button";
import Cancelled from "./images/cancel.svg";
import Expired from "./images/red-info-icon.svg";
// import likeIcon from "./images/thumb-up.svg";
import Modal from "../../shared/modal";
import loadable from "@loadable/component";
import { RESERVATION_OPTION } from "../../../constants/checkout-constants";
import { CF_STATUS } from "../../../constants/cf-constants";

const PreApprovedLoanPopup = loadable(() => import("../pre-approved-loan-popup"));
const ListingDiscountModal = loadable(() => import("../listing-discount-modal"));
const ROIOfferPopup = loadable(() => import("../roi-offer-popup"));
import mapPin from "./images/location-icon.svg";
import { FORM_SOURCE } from "../interest-form-revamp/constant";
import { getFuelType } from "../../../utils/helpers/get-fuel-type";
import saveCookie from "../../../utils/helpers/save-cookie";
import { trackBookingClick, trackRefundStatusClick } from "./tracking-constants";

const ExperimentCarCard = ({
    carName,
    emi,
    price,
    originalPrice,
    kilometer,
    fuelType,
    ownerNumber,
    ownerLabel,
    downPayment,
    showWishlist,
    onClickWishlist,
    isWishlistActive,
    registrationNumber,
    isDiscountPriceExperiment,
    toggelTooltip,
    tooltipVisible,
    source,
    showPreApproved,
    variant,
    transmission,
    bookingId,
    appointmentId,
    userId,
    mobile,
    getPaymentHistoryConnect,
    setOpenNoRefundPopup,
    setShowPaymentHistory,
    secureToken,
    orderId,
    isDelivered,
    isBookingValid,
    isCarSold,
    setCfModalTypeConnect,
    history,
    emiOriginalStartingValue,
    isEMIOfferExperiment,
    roiDiscounted,
    openCfFormPopup = () => { },
    checkoutUrls = {},
    relativeURL = "/",
    parkingYardAddress,
    isROIOfferApplicable,
    isEMIOfferRevision,
    // checkoutUrls,
    // roiDiscounted,
    creditStatus = "",
    reservationDetail = null,
    onCardClickHandler,
    reserved,
    getDistance,
    distance,
    isCancelled,
    isExpired,
    handleSimilarCarRedirection,
    roiOriginal,
    handleOnClickSimilerCarCta,
    showmediumCarCard,
    setPostBookingSourceConnect,
    isEmiAvailable
}) => {

    const isFreeBooking = reservationDetail ? reservationDetail.reservationChargesCategory === RESERVATION_OPTION.FREE : false;

    const preventPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onTrackRefundStatusClick = async (e) => {
        preventPropagation(e);
        sendCustomGaEvents({
            ...trackRefundStatusClick,
            eventLabel: `${appointmentId} | ${bookingId}`,
            mobile_last_digit: mobileLastDigit(mobile),
            appointment_id: appointmentId,
            booking_id: bookingId,
            user_id: userId
        });
        const data = await getPaymentHistoryConnect(secureToken, orderId);

        const eligibleForRefund = data.find(log => log.amount > 0);
        if (data && !!data.length && eligibleForRefund) {
            setShowPaymentHistory(true);
        } else {
            setOpenNoRefundPopup(true);
        }
    };

    const onTrackBooking = (e) => {
        preventPropagation(e);
        sendCustomGaEvents({
            ...trackBookingClick,
            eventLabel: `${appointmentId} | ${bookingId}`,
            mobile_last_digit: mobileLastDigit(mobile),
            appointment_id: appointmentId,
            booking_id: bookingId,
            user_id: userId
        });
        if (source === SOURCE.MY_BOOKINGS) {
            saveCookie("currentBookedCar", appointmentId);
            setCfModalTypeConnect("");
        }
        setPostBookingSourceConnect("my cars");
        history.push(`${checkoutUrls.relativeURL}`);
    };

    const handleSimilarClick = (e) => {
        preventPropagation(e);
        handleSimilarCarRedirection();
        handleOnClickSimilerCarCta();
    };

    const goToDetailsPage = (e) => {
        toggelTooltip(e);
        history.push(relativeURL);
    };

    const getToolTipOverLay = () => {
        if (showPreApproved && isEMIOfferExperiment && isROIOfferApplicable) {
            return (
                <ROIOfferPopup
                    emiAmount={emi}
                    emiOriginalStartingValue={emiOriginalStartingValue}
                    roiDiscounted={roiDiscounted || roiOriginal}
                    downPayment={downPayment}
                    tenure={72}
                    onClose={toggelTooltip}
                    openCfFormPopup={openCfFormPopup}
                    checkoutUrls={checkoutUrls}
                    relativeURL={relativeURL}
                    isEMIOfferRevision={isEMIOfferRevision}
                    goToDetailsPage={goToDetailsPage}
                />
            );
        } else if (showPreApproved) {
            return (
                <Modal isOpen={true} close={toggelTooltip}>
                    <PreApprovedLoanPopup
                        onClick={toggelTooltip}
                        emiAmount={emi}
                        interestRate={roiOriginal}
                        downPayment={downPayment}
                        tenure={72}
                        goToDetailsPage={goToDetailsPage}
                    />
                </Modal>
            );
        }

        return (
            <ListingDiscountModal
                emiAmount={emi}
                emiOriginalStartingValue={emiOriginalStartingValue}
                downPayment={downPayment}
                tenure={72}
                isEMIOfferExperiment={isEMIOfferExperiment}
                onClose={toggelTooltip}
                openCfFormPopup={openCfFormPopup}
                relativeURL={relativeURL}
                formSource={isEMIOfferExperiment ? FORM_SOURCE.ROI_DISCOUNT : FORM_SOURCE.LISTING_TOOLTIP}
            />
        );
    };

    return (
        <div itemProp="itemOffered" styleName="styles.carSoldWrap">
            <div styleName={"styles.outer styles.experimentOuter"}>
                <div styleName={"styles.carNameWrap"}>
                    <h3 styleName={"styles.carNameHeading"}>{carName}</h3>
                    {showWishlist && <IconHeart
                        active={isWishlistActive}
                        onClick={onClickWishlist}
                    />
                    }
                </div>
                <ul styleName={"styles.modalType"}>
                    <li>{variant}</li>
                    <li styleName={"styles.listDot"} />
                    <li>{transmission ? transmission : null}</li>
                </ul>
                <ul styleName={"styles.carType"}>
                    <li>{kilometer}</li>
                    {ownerNumber && source !== SOURCE.SIMILAR_CARS && <li>{ownerLabel}</li>}
                    <li>{getFuelType(fuelType)}</li>
                    <li>{registrationNumber}</li>
                </ul>
                <div styleName={"styles.emiValueWrap"}>
                    {
                        isEmiAvailable ?
                            <React.Fragment>
                                <div styleName={"carEmiPrice"}>
                                    <strong>{`₹${priceFormatter(emi)}/month`}
                                        {(SOURCE.LISTING === source && creditStatus !== CF_STATUS.DISCARDED) && (
                                            <span
                                                styleName={`styles.tooltipIconWrapper`}
                                                onClick={toggelTooltip}
                                            >
                                                {
                                                    showPreApproved
                                                        ?
                                                        <img src={exclamationBlue} alt="Pre-approved" />
                                                        :
                                                        <img src={Exclamation} alt="Not pre-approved" />

                                                }
                                            </span>
                                        )}
                                    </strong>
                                    {isEMIOfferExperiment && <p styleName={"styles.roiDiscount"}>{`₹${priceFormatter(emiOriginalStartingValue)}/month`}</p>}
                                    {
                                        tooltipVisible && getToolTipOverLay()
                                    }
                                    {downPayment > NUMBER.ZERO && (
                                        <span styleName={"styles.downPayment"}>
                                            {`₹${priceFormatter(downPayment)} Down Payment`}
                                        </span>
                                    )}
                                    {downPayment === NUMBER.ZERO && !isEMIOfferExperiment && (
                                        <span styleName={"styles.downPayment"}>Zero Down Payment </span>
                                    )}
                                </div>
                            </React.Fragment> :
                            <React.Fragment>
                                <span styleName={"styles.noEMi"}>
                                    <span styleName={"styles.noEmiText"}>Not available on EMIs</span>
                                </span>
                            </React.Fragment>
                    }

                    <div styleName={"styles.carEmiDetail"}>
                        <p>{`₹${priceFormatter(price)}`}</p>
                        {isDiscountPriceExperiment && <span styleName={"styles.lineThrough"}>{makePriceLabel(originalPrice || 0)}</span>}
                        {/* <span styleName={"styles.benefits"} onClick={toggleBenefitPopup}>Benefits of {benefitValue}</span> */}
                    </div>
                </div>
                {(parkingYardAddress || distance) && !isCarSold &&
                    <div styleName={"styles.hubLocationWrap"}>
                        {parkingYardAddress && <span styleName={"styles.hubLocation"}><img src={mapPin} alt="Address" />{parkingYardAddress}</span>}
                        {distance && <span styleName={"styles.distance"}>{getDistance(distance)} away</span>}
                    </div>
                }
                {
                    (showmediumCarCard) && isCarSold && (
                        <span styleName={`styles.ctaSimlarCar`} onClick={(e) => handleSimilarClick(e)}> View Similar cars</span>
                    )
                }
            </div>
            {
                (SOURCE.LISTING === source || SOURCE.RECENT_CARS === source) &&
                <div styleName={"styles.ctaWrap"}>
                    {
                        isCarSold && (
                            <div styleName={`styles.ctaButtonWrap ${isCarSold ? "styles.viewSimilarButton" : ""}`}>
                                <Button
                                    text="View Similar cars"
                                    onClick={(e) => handleSimilarClick(e)}
                                />
                            </div>
                        )
                    }
                </div>
            }
            {source === SOURCE.MY_BOOKINGS &&
                <div styleName={"styles.ctaWrap"}>
                    {
                        isCancelled && (
                            <span styleName={"styles.cta"}><img src={Cancelled} alt="Cancelled" />Cancelled</span>
                        )
                    }
                    {
                        isExpired && !reserved && !isCarSold && (
                            <span styleName={"styles.cta"}><img src={Expired} alt="Expired" />Expired</span>
                        )
                    }
                    {
                        SOURCE.MY_BOOKINGS === source && (isCancelled || isExpired) && isFreeBooking && !reserved && !isCarSold && (
                            <div styleName={"styles.ctaButtonWrap"}>
                                <Button text={"Book again"} onClick={onTrackBooking} />
                            </div>
                        )
                    }
                    {
                        SOURCE.MY_BOOKINGS === source && (isCancelled) && !isFreeBooking && !reserved && (
                            <span styleName={"styles.trackTefund"} onClick={onTrackRefundStatusClick} >Track refund <ArrowIcon /></span>
                        )
                    }
                    {
                        !isDelivered && isBookingValid && (
                            <div styleName={"styles.ctaButtonWrap"}>
                                <Button
                                    onClick={onTrackBooking}
                                    text="Track Booking"
                                />
                            </div>
                        )
                    }
                    {
                        (isCarSold || reserved) && !isBookingValid && SOURCE.MY_BOOKINGS === source &&
                        (
                            <div styleName={`styles.ctaButtonWrap ${isCarSold ? "styles.viewSimilarButton" : ""}`}>
                                <Button
                                    text="View Similar cars"
                                    onClick={(e) => handleSimilarClick(e)}
                                />
                            </div>
                        )
                    }
                    {
                        !isCarSold && SOURCE.PENDING_BOOKINGS === source && (
                            <div styleName={"styles.ctaButtonWrap"}>
                                <Button
                                    onClick={onCardClickHandler}
                                    text="Continue Booking"
                                />
                            </div>
                        )
                    }
                    {
                        isCarSold && SOURCE.PENDING_BOOKINGS === source && (
                            <div styleName={`styles.ctaButtonWrap ${isCarSold ? "styles.viewSimilarButton" : ""}`}>
                                <Button
                                    text="View Similar cars"
                                    onClick={(e) => handleSimilarClick(e)}
                                />
                            </div>
                        )
                    }
                </div>
            }

        </div>
    );
};

ExperimentCarCard.propTypes = {
    carName: PropTypes.string,
    emi: PropTypes.number,
    price: PropTypes.number,
    kilometer: PropTypes.string,
    fuelType: PropTypes.string,
    ownerNumber: PropTypes.string,
    ownerLabel: PropTypes.string,
    downPayment: PropTypes.number,
    showWishlist: PropTypes.bool,
    onClickWishlist: PropTypes.func,
    isWishlistActive: PropTypes.bool,
    registrationNumber: PropTypes.string,
    originalPrice: PropTypes.string,
    isDiscountPriceExperiment: PropTypes.string,
    hasFilledCFForm: PropTypes.bool,
    toggelTooltip: PropTypes.func,
    tooltipVisible: PropTypes.bool,
    openCfForm: PropTypes.func,
    source: PropTypes.string,
    showPreApproved: PropTypes.bool,
    selectedOffer: PropTypes.object,
    variant: PropTypes.string,
    transmission: PropTypes.string,
    benefits: PropTypes.object,
    getPaymentHistoryConnect: PropTypes.func,
    setOpenNoRefundPopup: PropTypes.func,
    setShowPaymentHistory: PropTypes.func,
    secureToken: PropTypes.string,
    orderId: PropTypes.string,
    appointmentId: PropTypes.string,
    bookingId: PropTypes.string,
    mobile: PropTypes.number,
    userId: PropTypes.string,
    bookingState: PropTypes.string,
    isCancelledLocally: PropTypes.bool,
    isDelivered: PropTypes.bool,
    isBookingValid: PropTypes.bool,
    isCarSold: PropTypes.bool,
    setCfModalTypeConnect: PropTypes.func,
    history: PropTypes.object,
    checkoutUrls: PropTypes.object,
    isROIOfferApplicable: PropTypes.bool,
    isEMIOfferExperiment: PropTypes.bool,
    emiOriginalStartingValue: PropTypes.number,
    roiDiscounted: PropTypes.number,
    openCfFormPopup: PropTypes.func,
    relativeURL: PropTypes.string,
    isEMIOfferRevision: PropTypes.number,
    parkingYardAddress: PropTypes.string,
    creditStatus: PropTypes.string,
    userDetailsSubmitted: PropTypes.bool,
    roiOriginal: PropTypes.number,
    // history: PropTypes.object
    reservationDetail: PropTypes.object,
    onCardClickHandler: PropTypes.func,
    onClickSimilarCars: PropTypes.func,
    reserved: PropTypes.bool,
    getDistance: PropTypes.func,
    distance: PropTypes.number,
    isCancelled: PropTypes.bool,
    isExpired: PropTypes.bool,
    handleSimilarCarRedirection: PropTypes.func,
    handleOnClickSimilerCarCta: PropTypes.func,
    showmediumCarCard: PropTypes.bool,
    setPostBookingSourceConnect: PropTypes.func,
    isEmiAvailable: PropTypes.bool
};

export default withRouter(ExperimentCarCard);
