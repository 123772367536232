
import Types from "./types";
import { CarService } from "../../../service/car-service";

const setIsLoading = flag => ({
    type: Types.SET_IS_LOADING,
    isLoading: flag
});

const fetchDataSuccess = data => {
    return {
        type: Types.FETCH_LEAD_BRAND_SUCCESS,
        data
    };
};

const fetchDataFailure = error => ({
    type: Types.FETCH_LEAD_BRAND_FAILURE,
    error
});

const fetchData = () => dispatch => {
    dispatch(setIsLoading(true));
    return CarService.fetchBrands()
        .then(response => {
            dispatch(fetchDataSuccess(response));
        })
        .catch(error => {
            dispatch(fetchDataFailure(error));
        });
};

export {
    fetchData,
    fetchDataSuccess,
    fetchDataFailure
};
