/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
import { API_PATH_ALTERNATE } from "../constants/api-constants";
import { convertJsonToQueryParams } from "../utils/helpers/convert-json-to-query-params";
import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

const API_BASE_PATH = "api/v1/consumer-api";

const newCarCmsService = (api) => {
    const getPriceScreenData = (queryParams) => {
        const queryString = queryParams
            ? `${convertJsonToQueryParams(queryParams)}`
            : "";

        const url = `api/v1/consumer-api/page-template/seller-price-page?${queryString}`;

        return api.get(url);
    };

    const fetchVideoGallery = () => {
        return api.get(`${API_BASE_PATH}/videos/pricepage`);
    };

    const fetchAppDownloadSliderData = () => {
        return api.get(`${API_BASE_PATH}/carouselbanner/app-adoption-bottomsheet`);
    };

    const fetchLuxuryPageData = () => {
        return api.get(`${API_BASE_PATH}/page-template/seller-luxury-page`);
    };
    const fetchScrapCarContent = () => {
        return api.get(`${API_PATH_ALTERNATE.CONSUMER_BASE_PATH_V1}${API_PATH_ALTERNATE.PAGE_TEMPLATE}${API_PATH_ALTERNATE.SCRAP_CAR_PAGE}`);
    };

    const getOfferDetails = ({ bannerName = [] }) => {
        try {
            return api.get(`${API_PATH_ALTERNATE.CONSUMER_BASE_PATH_V1}${API_PATH_ALTERNATE.BANNER_WIDGET_V2}/${bannerName.length ? `?bannerName=${bannerName.join(",").toString()}` : ""}&dataFormat=object`, {parse: true});
        } catch (e) {
            console.error("Offer Details Error ------------");
        }
    };

    const fetchfaqs = (faqCode) => {
        return api.get(`${API_BASE_PATH}/faq-data?faqCategoryCode=${faqCode}`);
    };

    return {
        getPriceScreenData,
        fetchVideoGallery,
        fetchAppDownloadSliderData,
        fetchLuxuryPageData,
        fetchScrapCarContent,
        getOfferDetails,
        fetchfaqs
    };
};

export const NewCarCmsServiceApi = newCarCmsService(middlewareConfig(MIDDLEWARE_ENUMS.NEW_CAR_CMS_SERVICE));
