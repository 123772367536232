import axios from "axios";

export const getData = async (url) => {
    try {
        const { data } = await axios.get(url);
        return data;
    } catch (err) {
        return {};
    }
};

export const getMultipleDataInParallel = async (list) => {
    try {
        const promises = list.map(item => axios.get(item.jsonFile));
        const results = await Promise.allSettled(promises);

        const response = results.reduce((acc, result, index) => {
            acc[index] = result.status === "fulfilled" ? result.value.data : null;
            return acc;
        }, {});

        return response;
    } catch (err) {
        return {};
    }
};
