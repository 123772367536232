import React from "react";
import styles from "./styles.css";
import priceFormatter from "../../../utils/helpers/price-formatter";
import PropTypes from "prop-types";

const SimilarCarCardSeo = ({
    relatedData: data,
    currentCity,
    mediaConfigUrl,
    history,
    updateSSRStatusConnect
}) => {

    const openModel = (e) => {
        e.preventDefault();
        updateSSRStatusConnect(false);
        const url = data.slug;
        history.push(`/${url}`);
    };
    return (
        <React.Fragment>
            <a styleName={"styles.carCard"} onClick={openModel}>
                <div styleName={"styles.carImage"}>
                    <img src={`${mediaConfigUrl}${data.model_image}`} alt="car image" />
                </div>
                <div styleName={"styles.outer"}>
                    <h3 styleName={"styles.carName"}>{data.seo_make_name} {data.seo_model_name}</h3>
                    <strong styleName={"styles.price"}>₹{priceFormatter(data.ex_showroom_price)}</strong>
                    <span styleName={"styles.message"}>Ex Showroom Price In {currentCity} *</span>
                </div>
            </a>
        </React.Fragment>
    );
};

SimilarCarCardSeo.propTypes = {
    relatedData: PropTypes.string,
    currentCity: PropTypes.string,
    mediaConfigUrl: PropTypes.string,
    history: PropTypes.object,
    updateSSRStatusConnect: PropTypes.func
};

export default SimilarCarCardSeo;
