import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarBrandList from "./component";
import { fetchData } from "../lead-brands/actions";
import { setData, setStep, activateLastStep } from "../../mobile/lead-form/actions";
import { withRouter } from "react-router";

const mapStateToProps = ({
    brands: { all, popular, isSSR, luxury},
    user: { statsigId, gaId },
    leadData,
    experiments: {
        sellMarketing: {
            data: sellMarketingExperiment
        }
    }
}) => ({
    all,
    popular,
    isSSR,
    sellMarketingExperiment,
    luxury,
    leadData,
    statsigId,
    gaId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchDataConnect: fetchData,
    setDataConnect: setData,
    setStepConnect: setStep,
    activateLastStepConnect: activateLastStep
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarBrandList));
