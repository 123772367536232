import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { OGImage, OG_IMAGE_TYPES } from "../og-image";

const PARAM_SLUG = "slug1";

const getPageSchema = (seo) => JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": seo.default.title,
    "description": seo.default.description,
    "publisher": {
        "@type": "Organization",
        "name": "CARS24"
    }
});

const MetaTags = ({
    match: { params, url },
    fetchDataConnect,
    home,
    seo,
    isSSR,
    isIndexEnabled = true,
    attacheWebPageSchema = false
}) => {
    useEffect(() => {
        if (!isSSR && params[PARAM_SLUG]) {
            const payload = {
                cmsuri: url,
                expand: "seo_content"
            };
            fetchDataConnect(payload);
        }
    }, [fetchDataConnect, isSSR, params, url]);

    const renderOGTags = (data) => {
        return Object.keys(data).map((item, index) => {
            return <meta key={`meta-og-${index}`} property={`og:${item}`} content={data[item]} />;
        });
    };

    const renderMetaTags = (data) => {
        const tags = [];
        tags.push(
            <title key="title">
                {data.title}
            </title>);
        tags.push(<meta key="meta" name="description" content={data.description} />);
        return tags;
    };

    const renderTags = () => {
        let ogTags = {};
        let defaultTags = {};
        let canonical;
        if (Object.keys(params).length) {
            ogTags = { ...seo.og };
            defaultTags = { ...seo.default };
            canonical = seo.canonical;
        } else {
            ogTags = { ...home.og };
            defaultTags = { ...home.default };
            canonical = home.canonical;
        }
        return {
            og: renderOGTags(ogTags),
            default: renderMetaTags(defaultTags),
            canonical: <link href={(canonical || "").toLowerCase()} rel="canonical" />
        };
    };

    return (
        <React.Fragment>
            {
                attacheWebPageSchema && seo && seo.default && seo.default.title &&
                <Helmet script={[{
                    type: "application/ld+json",
                    innerHTML: getPageSchema(seo)
                }]} />
            }
            <Helmet>
                {renderTags().default}
                {renderTags().canonical}
                {renderTags().og}
                {!isIndexEnabled ? <meta name="ROBOTS" content="NOINDEX,FOLLOW" /> : <meta name="ROBOTS" content="INDEX,FOLLOW" />}
            </Helmet>
            <OGImage type={OG_IMAGE_TYPES.sellcar} />
        </React.Fragment>
    );
};

MetaTags.propTypes = {
    match: PropTypes.object.isRequired,
    fetchDataConnect: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
    seo: PropTypes.object.isRequired,
    isSSR: PropTypes.bool.isRequired,
    isIndexEnabled: PropTypes.bool,
    attacheWebPageSchema: PropTypes.bool
};

export default MetaTags;
