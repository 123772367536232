/* eslint-disable camelcase */
/* eslint-disable no-magic-numbers */
import qs from "querystring";

export default (isC2C = false) => {
    let prefix = "c2b_";
    if (isC2C) prefix = "";
    const sampleObject = {
        [`${prefix}itm_source`]: "",
        [`${prefix}itm_medium`]: "",
        [`${prefix}utm_source`]: "",
        [`${prefix}utm_medium`]: "",
        [`${prefix}utm_keyword`]: "",
        [`${prefix}utm_matchtype`]: "",
        [`${prefix}utm_device`]: "",
        [`${prefix}adgroup_id`]: "",
        [`${prefix}campaign_id`]: "",
        [`${prefix}utm_campaign`]: ""
    };

    const parsedUrl = qs.parse(window.location.search.substr(1));

    const { search } = window.location;
    if (search.includes("utm") ||
        search.includes("itm") ||
        search.includes("adgroup_id") ||
        search.includes("campaign_id")) {
        Object.keys(parsedUrl).forEach(item => {
            if (item.includes("utm") ||
                item.includes("itm") ||
                item.includes("adgroup_id") ||
                item.includes("campaign_id")) {
                sampleObject[`${prefix}${item}`] = parsedUrl[item];
            }
        });
        const dateTime = new Date();
        dateTime.setTime(dateTime.getTime() - (1000 * 60 * 60 * 24));
        const expires = `expires=${dateTime.toGMTString()}`;
        //delete cookies in browser to update
        Object.keys(sampleObject).forEach(item => {
            document.cookie = `${item}=${sampleObject[item]};path=/${expires}`;
        });
        Object.keys(sampleObject).forEach(item => {
            document.cookie = `${item}=${sampleObject[item]};path=/`;
        });
    }
};
