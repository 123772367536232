import React from "react";
import DownArrow from "./images/down.svg";
import PropTypes from "prop-types";
import WhiteDownArrow from "./images/white-down-arrow.svg";
import { THEMES } from "../../../constants/theme-constants";

const SuffixIcon = ({theme}) => {
    return <img src={theme === THEMES.LIGHT ? WhiteDownArrow : DownArrow} alt={"down-arrow"} height={16} width={16}/>;
};

SuffixIcon.propTypes = {
    theme: PropTypes.string
};

export default SuffixIcon;
