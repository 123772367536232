export const APP_NUDGE_LINKS = {
    HOMEPAGE: {
        STICKY_BANNER: "https://cars24.app.link/89v0iNqyxIb",
        INLINE_BANNER: "https://cars24.app.link/wVVtxBzyxIb",
        BOTTOMSHEET: "https://cars24.app.link/acRSYhDyxIb",
        CAROUSEL: "https://cars24.app.link/1wJtHBLyxIb"
    },
    LISTING: {
        STICKY_BANNER: "https://cars24.app.link/T1OOgRQyxIb",
        INLINE_BANNER: "https://cars24.app.link/XC1gXKWyxIb",
        BOTTOMSHEET: "https://cars24.app.link/2pd5cJ1yxIb",
        CAROUSEL: "https://cars24.app.link/wFurRmczxIb"
    },
    CDP: {
        STICKY_BANNER: "https://cars24.app.link/lKjzVyizxIb",
        INLINE_BANNER: "https://cars24.app.link/5rrDH6lzxIb",
        BOTTOMSHEET: "https://cars24.app.link/MWdTybpzxIb",
        CAROUSEL: "https://cars24.app.link/KKbguTszxIb"
    },
    OTHER_PAGES: {
        STICKY_BANNER: "https://cars24.app.link/JL6XwZwzxIb",
        INLINE_BANNER: "https://cars24.app.link/7eIM8nEzxIb",
        BOTTOMSHEET: "https://cars24.app.link/sLAECaHzxIb",
        CAROUSEL: "https://cars24.app.link/Dv53LyKzxIb"
    }
};
