/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect, useState, useCallback, startTransition } from "react";
import PropTypes from "prop-types";
import HomeBannerV2 from "../home-banner-v2";
import Skeleton from "./skeleton";
import { BANNER_MAPPING } from "../../../constants/seller-constant";
import { sendCustomGaEvents } from "../../../tracking";
import { widgetApiTimeout } from "../../../constants/tracking-constants/user-drop";
import { USER_JOURNEY_STEPS } from "../../../constants/home-widget-constants";
import { AB_TEST_VARIATIONS, NUMBER } from "../../../constants/app-constants";
import useExperiments from "../../../hooks/use-statsig-experiment";
import { EXPERIMENT_NAME } from "../../../utils/statsig-ab-utils/constant";
import { userToleadLanding } from "./tracking";
import parseU2lData from "../../../utils/parsers/parse-u2l-data";
import parseCookie from "../../../utils/helpers/parse-cookie";
import UserJourneyDrop from "../user-journey-drop";
import SellMarketingRevampHomeBanner from "../sell-marketing-revamp-home-banner";
import { Divider } from "@cars24/turbo-web/lib/divider";
import { ArrowIcon } from "@cars24/turbo-web/lib/icons/arrow-icon";
import CarBrandList from "../car-brand-list";
import { Button } from "@cars24/turbo-web/lib/button";
import styles from "./styles.css";

const HomeBannerWrapper = ({
    isLoggedIn,
    isMarketing,
    handleClickCount,
    safetyBannerTop,
    onSelection,
    handleOnClick,
    secureToken,
    heading,
    mainHeading,
    subHeading,
    fetchHomepageBannersConnect,
    isError,
    isSSR,
    getRepeatInspectionWidgetConnect,
    isUserDrop,
    userDropUpdateConnect = () => { },
    setSsrStatusConnect,
    allCardInfo,
    userDropExperiment,
    userId,
    homePageType,
    setUserCarRegistrationNOConnect,
    setUserDropDataConnect,
    gaId,
    isUserToLead,
    persist = {},
    userToLeadDropExperiment,
    year,
    leadToken,
    fetchLeadDetailsConnect,
    isDlsCompliant,
    isLuxuryPage,
    isU2lAppointmentCase = false,
    isSellMarketingRevamp,
    statsigId,
    getLeadFormDataConnect = () => { },
    isFetchedOnSSR = false,
    isU2lgaVisible,
    leadOrApptExp,
    isNewUser = false,
    history,
    userDropList
}) => {
    // const userToLeadDropExperiment = AB_TEST_VARIATIONS.VARIANT;
    const { rehydrated = false } = persist || {};
    useExperiments({ experimentKeys: [EXPERIMENT_NAME.user_to_lead_drop] });
    const [isFetching, setIsFetching] = useState(((isUserDrop && isSSR) || isNewUser) ? false : true);
    // const [userJourneyComponent, setUserJourneyComponent] = useState();

    const handleHomepageBanners = useCallback(() => {
        if (!isSellMarketingRevamp) {
            const cmsPayload = {
                expand: "banner",
                category: BANNER_MAPPING.MWEB
            };
            fetchHomepageBannersConnect(cmsPayload).then(() => {
                setIsFetching(false);
            }).catch(() => {
                setIsFetching(false);
            });
        } else {
            setIsFetching(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSellMarketingRevamp]);

    // eslint-disable-next-line max-params
    const gaEventHandler = useCallback((experimentType, eventLabel, isRevamped, leadOrApptExpType = leadOrApptExp) => {
        startTransition(() => {
            sendCustomGaEvents({
                event: "Repeat_user_homepage",
                eventCategory: `homepage_revamp_${experimentType ? experimentType.toLowerCase() : experimentType}`,
                eventLabel,
                eventAction: `revamp_${isRevamped}`,
                dimension148: userId,
                ...(leadOrApptExpType && { dimension149: leadOrApptExpType })
            });
        });

    }, [leadOrApptExp, userId]);

    const handleUserToLeadGa = useCallback((details = {}) => {
        const { eventLabel = "" } = details || {};
        startTransition(() => {
            if (userToLeadDropExperiment && isU2lgaVisible) {
                sendCustomGaEvents({
                    ...userToleadLanding,
                    eventAction: (userToLeadDropExperiment || "").toLowerCase(),
                    ...((allCardInfo || eventLabel) && { eventLabel: allCardInfo || eventLabel }),
                    customdimension148: gaId
                });
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allCardInfo, userToLeadDropExperiment]);

    // const importUserJourneyComponent = () => {
    //     if (userJourneyComponent === null) {
    //         import(/* webpackChunkName: "repeat-user" */ "../user-journey-drop").then((comp) => {
    //             setUserJourneyComponent(
    //                 <comp.default />
    //             );
    //             setIsFetching(false);
    //         });
    //     } else {
    //         setIsFetching(false);
    //     }
    // };

    useEffect(() => {
        if (allCardInfo) {
            gaEventHandler(userDropExperiment, allCardInfo, isUserDrop);
            setUserCarRegistrationNOConnect("");
        }
        if (isError) {
            sendCustomGaEvents(widgetApiTimeout);
        }
        if (isU2lAppointmentCase) {
            handleUserToLeadGa({ eventLabel: "edgeCase" });
        }
        setSsrStatusConnect(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line complexity
    useEffect(() => {
        if (isU2lgaVisible) {
            if (userToLeadDropExperiment === AB_TEST_VARIATIONS.VARIANT && !isLoggedIn && rehydrated) {
                const token = leadToken || parseCookie("sell_online_lead_token") || "";
                if (token) {
                    fetchLeadDetailsConnect(token, {}, secureToken).then((response) => {
                        if ([USER_JOURNEY_STEPS.BOOKED,
                            USER_JOURNEY_STEPS.BOOKED_NARROW,
                            USER_JOURNEY_STEPS.BOOKED_SO,
                            USER_JOURNEY_STEPS.LEAD,
                            USER_JOURNEY_STEPS.LEAD_NARROW,
                            USER_JOURNEY_STEPS.LEAD_QUESTION,
                            USER_JOURNEY_STEPS.STALE_LEAD_QUESTION
                        ].includes(response.stage)) {
                            setUserDropDataConnect(parseU2lData(response));
                        } else {
                            userDropUpdateConnect(isU2lgaVisible);
                            setIsFetching(false);
                            handleUserToLeadGa({ eventLabel: "edgeCase" });
                        }
                    });
                } else if (year && year.id) {
                    setUserDropDataConnect({
                        userDropList: [{ screenType: USER_JOURNEY_STEPS.USER_TO_LEAD }],
                        allCardInfo: "pre-lead",
                        isUserDrop: true,
                        isError: false,
                        noDataFound: true,
                        isUserToLead: true
                    });
                    // ga event gets triggered when data is set using 'setUserDropDataConnect'. below fn call might not be needed as its triggering the event twice
                    // isUserDrop, allCardInfo, isUserToLead - these vars are being updated so the useEffect just above <PageLoader is always triggered
                    // handleUserToLeadGa();
                } else {
                    setIsFetching(false);
                    handleUserToLeadGa();
                }
            } else if (!isLoggedIn && rehydrated) {
                userDropUpdateConnect(isU2lgaVisible);
                setIsFetching(false);
                // this is the case for CONTROL where blue widget gets displayed for which the code is in upcoming-appointments/component. have added the code there for it covering the same cases as above ^
                handleUserToLeadGa();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year, userToLeadDropExperiment, isLoggedIn, rehydrated, isU2lgaVisible]);

    useEffect(() => {
        if (!isError && !isSSR && secureToken && (!isUserDrop || isUserToLead)
        ) {
            setIsFetching(true);
            getRepeatInspectionWidgetConnect(secureToken).then((response) => {
                const {
                    experimentType: experimentTypeValue = "",
                    allCardInfo: allCardInfoValue = "",
                    isUserDrop: isUserDropValue = "",
                    leadOrApptExp: leadOrApptExpType = ""
                } = response || {};
                if (allCardInfoValue) {
                    gaEventHandler(experimentTypeValue, allCardInfoValue, isUserDropValue, leadOrApptExpType);
                }
                if (isUserDropValue) {
                    setIsFetching(false);
                } else {
                    handleHomepageBanners();
                }
                setUserCarRegistrationNOConnect("");
            }).catch(() => {
                sendCustomGaEvents({
                    ...widgetApiTimeout,
                    eventAction: "timeout_client"
                });
                handleHomepageBanners();
            });
        } else if (isError && !isUserDrop) {
            setIsFetching(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSSR, secureToken, isUserDrop, isError]);

    useEffect(() => {
        if (isUserDrop) {
            // importUserJourneyComponent();
            setIsFetching(false);
            if (allCardInfo && isUserToLead) handleUserToLeadGa();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserDrop]);

    useEffect(() => {
        if (!isFetchedOnSSR) getLeadFormDataConnect(statsigId, gaId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleViewAllClick = async () => {
        handleOnClick();
        startTransition(() => {
            sendCustomGaEvents({
                event: "more_brands_clicked",
                eventCategory: "homepage",
                eventAction: "more_brands"
            });
        });
    };

    if (isFetching) return <Skeleton />;
    if (!isUserDrop) {
        if (!isLoggedIn && year && year.id) {
            return <UserJourneyDrop isUserDropBeforeLogin />;
        } else if (isLoggedIn && year && year.id) {return (<UserJourneyDrop isUserDropAfterLogin />);}
    }
    if (isUserDrop && userDropList && userDropList.length > 0) return (<UserJourneyDrop />);
    if (isDlsCompliant) {
        return (
            <React.Fragment>
                <SellMarketingRevampHomeBanner safetyBannerTop={safetyBannerTop}history={history} />
                <div styleName={"styles.brandWrapper"}>
                    <div styleName={"styles.brandList"}>
                        <Divider
                            text="OR SELECT BRAND"
                            variant="tertiary"
                        />
                        <CarBrandList
                            btnCallback={handleOnClick}
                            useParentCallback={(selectedBrand) => onSelection(selectedBrand)}
                            visiblePopularBrands={NUMBER.EIGHT}
                            isRevamped={true}
                            isSellMarketingRevamp
                            isDlsCompliant
                            isLuxuryPage={isLuxuryPage}
                        />
                        <Button
                            label="View All"
                            onClick={handleViewAllClick}
                            variant="tertiary"
                            suffixIcon={<ArrowIcon />}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <HomeBannerV2
            isMarketing={isMarketing}
            handleClickCount={handleClickCount}
            safetyBannerTop={safetyBannerTop}
            onSelection={onSelection}
            handleOnClick={handleOnClick}
            heading={heading}
            mainHeading={mainHeading}
            subHeading={subHeading}
            homePageType={homePageType}
        />
    );
};

HomeBannerWrapper.propTypes = {
    isLoggedIn: PropTypes.bool,
    handleOnClick: PropTypes.func,
    onSelection: PropTypes.func,
    safetyBannerTop: PropTypes.object,
    handleClickCount: PropTypes.func,
    isMarketing: PropTypes.bool,
    secureToken: PropTypes.string,
    heading: PropTypes.string,
    mainHeading: PropTypes.string,
    subHeading: PropTypes.string,
    fetchHomepageBannersConnect: PropTypes.func,
    isSSR: PropTypes.bool,
    getRepeatInspectionWidgetConnect: PropTypes.func,
    isUserDrop: PropTypes.bool,
    isError: PropTypes.bool,
    userDropUpdateConnect: PropTypes.func,
    setSsrStatusConnect: PropTypes.func,
    allCardInfo: PropTypes.string,
    userDropExperiment: PropTypes.string,
    userId: PropTypes.string,
    homePageType: PropTypes.string,
    setUserCarRegistrationNOConnect: PropTypes.func,
    year: PropTypes.number,
    setUserDropDataConnect: PropTypes.func,
    userToLeadDropExperiment: PropTypes.string,
    gaId: PropTypes.string,
    isUserToLead: PropTypes.bool,
    persist: PropTypes.object,
    leadToken: PropTypes.string,
    fetchLeadDetailsConnect: PropTypes.func,
    isDlsCompliant: PropTypes.bool,
    isLuxuryPage: PropTypes.bool,
    isU2lAppointmentCase: PropTypes.bool,
    isSellMarketingRevamp: PropTypes.bool,
    statsigId: PropTypes.string,
    getLeadFormDataConnect: PropTypes.func,
    isFetchedOnSSR: PropTypes.bool,
    isU2lgaVisible: PropTypes.bool,
    leadOrApptExp: PropTypes.string,
    isNewUser: PropTypes.bool,
    history: PropTypes.object,
    userDropList: PropTypes.array
};

export default HomeBannerWrapper;
