import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import TestimonialCarousel from "./component";
import { fetchTestimonials } from "./actions";

const mapStateToProps = ({
    ratedTestimonials: { isSSR, list, template, make, model, location, city }
}) => ({
    isSSR,
    list,
    template,
    make,
    model,
    location,
    city
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTestimonialsConnect: fetchTestimonials
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestimonialCarousel));
