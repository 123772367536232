import React, { useEffect, useState } from "react";
import styles from "./styles/styles.css";
import PropTypes from "prop-types";
// import Share from "./images/share.svg";
import priceFormatter from "../../../utils/helpers/price-formatter";
import loadable from "@loadable/component";
const Swipeable = loadable(() => import("../../shared/swipeable"), {ssr: true});
import addTypeToArray from "../../../utils/helpers/add-type-to-array";
import initialImageIndex from "../../../utils/helpers/initial-image-index";
import moveArrayItem from "../../../utils/helpers/move-array-item";
import { cdnImageUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import { getMaxPrice, getMinPrice } from "../../../utils/helpers/seo-car-config";

const SeoCarOverviewInfo = ({
    price,
    carFullName,
    cityName,
    citySlectionCallback,
    media,
    baseUrl,
    colorsNumber
}) => {
    const [maxPrice, setMaxPrice] = useState("");
    const [minPrice, setMinPrice] = useState("");
    const [currentIndex, setcurrentIndex] = useState(1);

    const options = {
        enableMouseEvents: true,
        enableDots: false,
        enableArrows: false
    };

    useEffect(() => {
        if (price.length > 0) {
            setMaxPrice(getMaxPrice(price, "exShowroomPrice"));
            setMinPrice(getMinPrice(price, "exShowroomPrice"));
        }
    }, [price]);

    const mediaImages = media ? [
        ...(addTypeToArray(media.exterior, "exteriorImages")) || [],
        ...(addTypeToArray(media.interior, "interiorImages")) || []
    ] : [];
    const defaultImageIndex = initialImageIndex(mediaImages);
    const images = moveArrayItem(mediaImages, defaultImageIndex, 0);
    const imageKey = "image";
    const size = "373x200";
    const url = (base, path) => cdnImageUrl(
        path,
        imageTypes.desktopCarCard,
        {
            thumborProps: { baseUrl: base.slice(0, -1), size }
        });

    const getIndex = (i) => {
        setcurrentIndex(i + 1);
    };

    return (
        <React.Fragment>
            <div styleName={"styles.carDetailsImg"}>
                <Swipeable options={options} onChangeIndexCallback={getIndex}>
                    {
                        images.map((image, index) => (
                            <img key={index} src={`${url(baseUrl, image[imageKey])}`} alt={`${carFullName} - ${image.title}`} />
                        ))
                    }
                </Swipeable>
                <div styleName={"styles.options"}>
                    <span>{currentIndex}/{images.length}</span>
                    <span>{colorsNumber} {colorsNumber === 1 ? "Color" : "Colors"}</span>
                </div>
            </div>
            <div styleName={"styles.carTitlesWrapper"}>
                <div styleName={"styles.carTitles"}>
                    <h1>{carFullName}</h1>
                    {/* <a href="#">Change car</a> */}
                </div>
                {/* <a href="#">
                    <img src={Share} alt="share" />
                </a> */}
            </div>
            <div styleName={"styles.priceSection"}>
                <span>₹ {priceFormatter(minPrice)} - ₹ {priceFormatter(maxPrice)} </span>
            </div>
            <div styleName={"styles.showroomSection"}>
                <span>* Ex Showroom Price </span>
                <span onClick={citySlectionCallback}>Show prices in <a href="">{cityName}</a></span>
            </div>
        </React.Fragment>
    );
};

SeoCarOverviewInfo.propTypes = {
    carDetail: PropTypes.string,
    price: PropTypes.string,
    carFullName: PropTypes.string,
    cityName: PropTypes.string,
    citySlectionCallback: PropTypes.func,
    media: PropTypes.string,
    baseUrl: PropTypes.string,
    colorsNumber: PropTypes.string
};

export default SeoCarOverviewInfo;
