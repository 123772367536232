/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import reservedBg from "./images/reserved-bg.svg";
import { CAR_STATUS } from "../inventory-car-card/constant";
import Sold from "./images/sold-tag.svg";

const CarDispositionStatus = ({ text, isSmallerCarCard }) => {
    const getStyles = () => {
        let stylesClass = "";
        if (text === CAR_STATUS.GETTING_SERVICED) {
            stylesClass = "styles.gettingService";
        }
        return stylesClass;
    };

    return (
        <React.Fragment>
            {
                (text === CAR_STATUS.GETTING_SERVICED || text === CAR_STATUS.RESERVED || text === CAR_STATUS.TOKENED)
                    ?
                    <React.Fragment>
                        {!isSmallerCarCard &&
                            <div styleName={`styles.reservedCarWrap ${getStyles()}`}>
                                <img src={reservedBg} alt={text} />
                                <span>{text}</span>
                            </div>
                        }
                        {isSmallerCarCard && <span styleName={"styles.soldUnderService styles.underService"}>{text}</span>}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {!isSmallerCarCard &&
                            <span styleName={"styles.reservedCarWrap styles.soldTag"}>
                                <img src={Sold} />
                            </span>}
                        {isSmallerCarCard && <span styleName={"styles.soldUnderService styles.sold"}>{text}</span>}
                    </React.Fragment>

            }
        </React.Fragment>
    );
};
CarDispositionStatus.propTypes = {
    text: PropTypes.string,
    isSmallerCarCard: PropTypes.bool,
    isSmallerCarCardExp: PropTypes.bool
};
export default CarDispositionStatus;
