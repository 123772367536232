import Types from "./types";
import { CarService } from "../../../service/car-service";
import { getBannerImage } from "../../../utils/helpers/get-banner-image";
import { parseSellMarketingRevampData } from "../../../utils/helpers/parse-sell-marketing-cms-data";

const fetchDataSuccess = (data) => ({
    type: Types.FETCH_META_TAGS_SEO_SUCCESS,
    data
});

const storeTwitterTestimonials = (data) => ({
    type: Types.STORE_TWITTER_TESTIMONIALS,
    data
});

const storeSocialMediaContent = (data) => ({
    type: Types.STORE_SOCIAL_MEDIA_CONTENT,
    data
});

const setBannerImage = (homePageBanner) => ({
    type: Types.SET_BANNER_IMAGE,
    homePageBanner
});

const resetMeta = () => ({
    type: Types.RESET_META
});

const fetchDataFailure = () => ({ type: Types.FETCH_META_TAGS_SEO_FAILURE });

const fetchData = (params, isRevamped = false) => dispatch => {
    CarService.getCmsPageData(params).then(response => {
        if (isRevamped) {
            const cmsContentDetails = parseSellMarketingRevampData({ cmsData: response });
            if (cmsContentDetails.homePageBanner) {
                dispatch(setBannerImage(cmsContentDetails.homePageBanner));
            }
            dispatch(fetchDataSuccess({ ...response.seo, ...cmsContentDetails }));
        } else {
            dispatch(fetchDataSuccess(response.seo));
        }
        if (response.tweets) {
            dispatch(storeTwitterTestimonials(response.tweets));
        }
        if (response.cars24SocialMedia) {
            dispatch(storeSocialMediaContent(response.cars24SocialMedia));
        }
    }).catch(() => {
        dispatch(fetchDataFailure());
    });
};

const updateBGWeb = (bgImage) => ({
    type: Types.UPDATE_BG_WEB,
    bgImage
});

const updateBGMobile = (bgImage) => ({
    type: Types.UPDATE_BG_MOBILE,
    bgImage
});

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const fetchHomepageBanners = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        CarService.getCmsPageData(params).then(response => {
            if (params && (params.expand || "").includes("banner")) {
                dispatch(setBannerImage(getBannerImage((response.homePageBanner || {}), params.category)));
            }
            resolve();
        }).catch(() => {
            dispatch(setBannerImage(getBannerImage({}, params.category)));
            dispatch(fetchDataFailure());
            reject();
        });
    });
};

export {
    fetchData,
    updateBGMobile,
    updateBGWeb,
    updateSSRStatus,
    fetchDataSuccess,
    fetchHomepageBanners,
    setBannerImage,
    resetMeta
};
