import Types from "./types";
import { CatalogGatewayService } from "../../../service/catalog-gateway";

const setComparingActive = (isComparingActive) => ({
    type: Types.SET_COMPARING_ACTIVE,
    isComparingActive
});

const setCompareCarsLimitError = (isComparingLimitError) => ({
    type: Types.SET_COMPARE_CAR_LIMIT_ERROR,
    isComparingLimitError
});

const addCarToCompare = ({ carDetails }) => ({
    type: Types.ADD_CAR_TO_COMPARE,
    carDetails
});

const removeCarCompare = (appId) => ({
    type: Types.REMOVE_CAR_COMPARE,
    appId
});

const fetchComparingCarDetailDataSuccess = (data) => ({
    type: Types.FETCH_COMPARING_CAR_DETAIL_DATA_SUCCESS,
    data
});

const fetchComparingCarDetailDataFailure = (data) => ({
    type: Types.FETCH_COMPARING_CAR_DETAIL_DATA_FAILURE,
    data
});

const fetchDetails = () => ({
    type: Types.FETCH_COMPARING_CAR_DETAIL_DATA
});

const fetchComparingCarDetail = (data) => dispatch => {
    dispatch(fetchDetails());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarComparisonData(data)
            .then(response => {
                dispatch(fetchComparingCarDetailDataSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchComparingCarDetailDataFailure(error));
                reject(error);
            });
    });
};

const setSimilarCarsPopup = (isSimilarCarsPopup) => ({
    type: Types.SET_SIMILAR_CARS_POPUP,
    isSimilarCarsPopup
});

const setCarImperfectionPopup = (imperfectionPopupData) => ({
    type: Types.SET_CAR_IMPERFECTION_POPUP,
    imperfectionPopupData
});

const setReloadCarCompare = (reloadCarCompare) => ({
    type: Types.SET_RELOAD_COMPARE_CAR,
    reloadCarCompare
});

export {
    setComparingActive,
    addCarToCompare,
    removeCarCompare,
    setCompareCarsLimitError,
    setSimilarCarsPopup,
    setReloadCarCompare,
    fetchComparingCarDetail,
    setCarImperfectionPopup
};
