import React, { useState } from "react";
import styles from  "./styles.css";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {InView} from "react-intersection-observer";
import CarRegistrationLandingBannerExperiment from "../car-registration-landing-banner-experiment";
import LandingBannerExperimentv2 from "../landing-banner-experiment-v2";

const HomeBannerRevamp = ({
    hasHomeInspection,
    isFromSellOnlineFlow = false,
    safetyBannerTopFlag = false,
    heading = "",
    isRevamped = false,
    isNewHomePage = true,
    safetyBannerTopRef,
    allowFixedInput = true,
    setisBtnVisible = () => {},
    ctaText = "GET CAR PRICE",
    mainHeading,
    subHeading,
    handleClickCount = () => {},
    homePageType
}) => {

    const [isRegNoFieldVisible, setIsRegNoFieldVisible] = useState(true);
    const  onConditionScroll = (inView) => {
        setIsRegNoFieldVisible(inView);
        setisBtnVisible(inView);
    };

    return (
        <div styleName={`styles.sellBannerWrap styles.sellBannerHomeInspection`}>
            <LandingBannerExperimentv2
                heading={heading}
                mainHeading={mainHeading}
                subHeading={subHeading}
                homePageType={homePageType}
            />
            <InView
                as="div"
                threshold={0.5}
                styleName={"styles.brandListHome"}
                onChange={(inView) => onConditionScroll(inView)}
            >
                <React.Fragment>
                    <CarRegistrationLandingBannerExperiment
                        isNewHomePage={isNewHomePage}
                        isFromSellOnlineFlow={isFromSellOnlineFlow}
                        hasHomeInspection={hasHomeInspection}
                        isRevamped={isRevamped}
                        changeClass={!isRegNoFieldVisible && allowFixedInput}
                        safetyBannerTopRef={safetyBannerTopRef}
                        safetyBannerTopFlag={safetyBannerTopFlag}
                        text={ctaText}
                        handleClickCount={handleClickCount}
                    />
                </React.Fragment>
            </InView>
        </div>

    );
};

HomeBannerRevamp.propTypes = {
    hasHomeInspection: PropTypes.bool,
    isFromSellOnlineFlow: PropTypes.bool,
    safetyBannerTopFlag: PropTypes.bool,
    heading: PropTypes.string,
    isRevamped: PropTypes.bool,
    isNewHomePage: PropTypes.bool,
    allowFixedInput: PropTypes.bool,
    safetyBannerTopRef: PropTypes.any,
    setisBtnVisible: PropTypes.func,
    ctaText: PropTypes.string,
    mainHeading: PropTypes.string,
    subHeading: PropTypes.string,
    handleClickCount: PropTypes.func,
    homePageType: PropTypes.string
};

export default withRouter(HomeBannerRevamp);
