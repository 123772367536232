import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { getData } from "../../../utils/get-data";
import jsonValidator from "../../../utils/helpers/json-validator";
import isObjectEmpty from "../../../utils/helpers/isObjectEmpty";
const Lottie = loadable(() => import("react-lottie"), { ssr: false });

const options = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const LottiePlayer = ({ url = "", height = "140px", width = "204px" }) => {
    const [json, setJson] = useState(null);

    useEffect(() => {
        getData(url).then(res => {
            if (jsonValidator(res)) {
                setJson(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        !isObjectEmpty(json) && <Lottie style={{ height, width  }} options={{ ...options, animationData: json }} />
    );
};

LottiePlayer.propTypes = {
    url: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string
};
export default LottiePlayer;
