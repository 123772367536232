import { BASE_URL } from "../../components/mobile/tof-car-card/constants";

const { DEVICE_TYPE } = require("../../constants/app-constants");
const { getAppLink } = require("./get-app-links");

export const getApplinkLogo = (os, link = false) => {
    if (link) {
        return getAppLink(os);
    }
    return os && os.toLowerCase() === DEVICE_TYPE.IOS.toLowerCase() ? `${BASE_URL}india/buy/msite/app_nudge/Apple_logo_black.png` : `${BASE_URL}india/buy/msite/app_nudge/Playstore_logo_color.png`;

};

export const getApplinkLogoWhite = (os) => os && os.toLowerCase() === DEVICE_TYPE.IOS.toLowerCase() ? `${BASE_URL}india/buy/msite/app_nudge/Apple_logo_white.png` : `${BASE_URL}india/buy/msite/app_nudge/Playstore_logo_white.png`;
