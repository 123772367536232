import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withAuth from "../with-auth";
import {
    addToWishlist,
    removeFromWishList
} from "../../mobile/wishlisted-by-you/actions";
import { setActiveLoginPopup, setOnLoginCallbackProps, setLoginHeading } from "../../mobile/wishlist-login-popup/actions";
import { showToast } from "../../mobile/toast-message/actions";
import { callbackTypes } from "../../mobile/wishlist-login-popup/selectors";
import { getTrackingParameters } from "../../../tracking/c2c-tracking-params";
import { sendCustomGaEvents } from "../../../tracking";
import { B2C_WISHLIST_GA_CATEGORY } from "./constant";
import { addToWishListEvent, wishlistCtaClick } from "./tracking-constants";
import { ALERT_VARIANTS } from "../alert-card-revamp/constant";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { cdnImageUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import cleverTap from "../../../tracking/clevertap";
import { getFullCarName } from "../../../utils/helpers/car-properties";

export default function withAddToWishlist(Component, extraArgs = {openNewLogin: false}) {
    const WishlistComponent = (props) => {
        const {
            flattenedData,
            isLoggedIn,
            secureToken,
            userPincode,
            addToWishlistConnect,
            setActiveLoginPopupConnect,
            setOnLoginCallbackPropsConnect,
            showToastConnect,
            setLoginHeadingConnect,
            removeFromWishListConnect
        } = props;
        const WISHLIST_GA_CATEGORY =  B2C_WISHLIST_GA_CATEGORY;

        const {openNewLogin} = extraArgs || {};

        const isWishlistedCar = useCallback((carId) => (flattenedData || []).indexOf(carId) > -1 && isLoggedIn, [flattenedData, isLoggedIn]);

        const onClickAddToWishList = async (carId, {
            loggedOutCallback = () => { },
            addCarCallback = () => { },
            removeCarCallback = () => { },
            toastStyle,
            toggleMode = true,
            source,
            wishlistLoginHeading,
            loginBottomSheetEventsource
        } = {}, carCardData = {}) => {
            const { listingImage } = carCardData || {};
            const {bgRemovedUri: imageUri, uri} = listingImage || {};
            const imageUrl = imageUri || uri ?  cdnImageUrl(imageUri || uri, imageTypes.toast) : "";
            if (isLoggedIn) {
                if (isWishlistedCar(carId) && toggleMode) {
                    removeFromWishListConnect({
                        appointmentId: carId,
                        active: false
                    }, secureToken).then((response) => {
                        showToastConnect({
                            variant: ALERT_VARIANTS.ERROR,
                            text: "Removed from wishlist",
                            isWishlistMobile: true,
                            isExperimentAlert: true,
                            source,
                            carData: {
                                imageUrl,
                                ...carCardData
                            }
                        });
                        removeCarCallback(carId, response);
                    }).catch((response) => {
                        removeCarCallback(carId, response);
                    });
                } else {
                    const trackingParams = getTrackingParameters();
                    addToWishlistConnect({
                        appointmentId: carId,
                        active: true
                    }, { ...trackingParams, itm_medium: source }, secureToken)
                        .then((response) => {
                            sendCustomGaEvents({
                                ...addToWishListEvent,
                                eventCategory: WISHLIST_GA_CATEGORY[source],
                                eventLabel: carId
                            });
                            showToastConnect({
                                variant: ALERT_VARIANTS.SUCCESS,
                                text: "Added to wishlist",
                                isExperimentAlert: true,
                                isWishlistMobile: true,
                                ...(toastStyle && { toastStyle }),
                                source,
                                carData: {
                                    imageUrl,
                                    ...carCardData
                                }
                            });
                            addCarCallback(carId, response);
                            cleverTap.trackCarDetailsEvent("shortlisted", {
                                carDetailsContent: carCardData,
                                source: "m-site",
                                pincode: userPincode,
                                productName: getFullCarName(carCardData)
                            });
                        }).catch((response) => {
                            addCarCallback(carId, response);
                        });
                }
            } else {
                setActiveLoginPopupConnect("msite", !!openNewLogin, loginBottomSheetEventsource);
                setLoginHeadingConnect("Log in to view your selections", wishlistLoginHeading);
                setOnLoginCallbackPropsConnect(callbackTypes.ADD_TO_FAVOURITE, {
                    appId: carId,
                    source,
                    carData: {
                        imageUrl,
                        ...carCardData
                    }
                });
                loggedOutCallback(carId);
            }
            await yieldToMain();
            sendCustomGaEvents({
                ...wishlistCtaClick,
                eventCategory: WISHLIST_GA_CATEGORY[source],
                eventLabel: carId
            });
        };

        return (
            <Component
                {...props}
                isWishlistedCar={isWishlistedCar}
                onClickAddToWishList={onClickAddToWishList}
            />
        );
    };

    WishlistComponent.propTypes = {
        flattenedData: PropTypes.array,
        isLoggedIn: PropTypes.bool,
        addToWishlistConnect: PropTypes.func,
        removeFromWishListConnect: PropTypes.func,
        setActiveLoginPopupConnect: PropTypes.func,
        setOnLoginCallbackPropsConnect: PropTypes.func,
        showToastConnect: PropTypes.func,
        cityList: PropTypes.array,
        userPincode: PropTypes.string,
        ciActive: PropTypes.bool,
        secureToken: PropTypes.string,
        setLoginHeadingConnect: PropTypes.func,
        removedFromWishlistCarId: PropTypes.string,
        clearRemovedWishlistCarIdConnect: PropTypes.func
    };

    const mapStateToProps = ({
        wishlist: { flattenedData },
        config: {
            cityList
        },
        location: {
            pincode: userPincode
        },
        user: {
            secureToken
        },
        buyCarPincodeConfig: {
            data: pincodeData
        }
    }) => {
        return {
            flattenedData,
            cityList,
            userPincode,
            ciActive: (pincodeData || {}).ciActive,
            secureToken
        };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        addToWishlistConnect: addToWishlist,
        removeFromWishListConnect: removeFromWishList,
        setActiveLoginPopupConnect: setActiveLoginPopup,
        setOnLoginCallbackPropsConnect: setOnLoginCallbackProps,
        showToastConnect: showToast,
        setLoginHeadingConnect: setLoginHeading
    }, dispatch);
    return connect(mapStateToProps, mapDispatchToProps)(
        withAuth(WishlistComponent)
    );
}
