import Types from "./types";
import { CarService } from "../../../service/car-service";
import { SuperAppCmsService } from "../../../service/super-app-cms-service";

const setIsLoading = flag => ({
    type: Types.SET_IS_LOADING,
    isLoading: flag
});

const fetchDataSuccess = data => ({
    type: Types.FETCH_LEAD_VARIANTS_SUCCESS,
    data
});

const fetchDataFailure = error => ({
    type: Types.FETCH_LEAD_VARIANTS_FAILURE,
    error
});

const fetchData = ({ modelId, year }) => dispatch => {
    dispatch(setIsLoading(true));
    return new Promise((resolve, reject) => {
        CarService.fetchFuelVariant({ modelId, year })
            .then(response => {
                dispatch(fetchDataSuccess(response.data.detail));
                resolve(response.data.detail);
            })
            .catch(error => {
                dispatch(fetchDataFailure(error));
                reject(error);
            });
    });

};

const getEvData = (payload) => () => {
    return new Promise((resolve, reject) => {
        SuperAppCmsService.getCmsData(payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};
const resetVariantsData = () => ({ type: Types.RESET_LEAD_VARIANTS });

export {
    fetchData,
    resetVariantsData,
    getEvData
};
