import { useEffect } from "react";
import { useSelector } from "react-redux";
import useCleverTap from "./use-clevertap";
import cleverTap from "../tracking/clevertap";
import { getDeviceType } from "../utils/helpers/device-type";

const { type: deviceType = "Mobile" } = getDeviceType() || {};
const isMobile = deviceType === "Mobile";

const useC2bCleverTap = (buName = "") => {
    const { user } = useSelector(store => store);
    const { isLoggedIn, mobile } = user || {};
    const isCleverTapLoaded = useCleverTap();

    useEffect(() => {
        if (isCleverTapLoaded) {
            cleverTap.trackEvent("top_businesses_clicked", {
                source: isMobile ? "m-site" : "desktop",
                C2B_GridItem_Clicked: buName,
                url: typeof window === "object" ? window.location.href : "",
                ...(isLoggedIn && { mobile })
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCleverTapLoaded, isLoggedIn]);

    return isCleverTapLoaded;
};

export default useC2bCleverTap;
