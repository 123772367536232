import Types from "./types";
import { LeadServiceJava } from "../../../service/lead-service-java-api";

const fetchDataSuccess = data => ({
    type: Types.FETCH_WIDGET_DATA_SUCCESS,
    data
});

const fetchDataFailure = error => ({
    type: Types.FETCH_WIDGET_DATA_FAILURE,
    error
});

const fetchData = (token, secureToken, isV2Applicable = false) => dispatch => {
    const params = {
        token,
        pvtAuth: secureToken,
        isV2Applicable
    };
    return new Promise((resolve, reject) => {
        LeadServiceJava.fetchBannerWidget(params).then(response => {
            dispatch(fetchDataSuccess(response.data.detail));
            resolve(response.data.detail);
        }).catch(error => {
            dispatch(fetchDataFailure(error));
            reject(error);
        });
    });
};

const setIsRepeatUserApplicable = isRepeatUserApplicable => ({
    type: Types.SET_IS_REPEAT_USER_APPLICABLE,
    isRepeatUserApplicable
});

const setRepeatUserDetails = repeatUserDetails => ({
    type: Types.SET_REPEAT_USER_DETAILS,
    repeatUserDetails
});

export {
    fetchData,
    fetchDataSuccess,
    fetchDataFailure,
    setIsRepeatUserApplicable,
    setRepeatUserDetails
};
