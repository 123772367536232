import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export default function withAuth(Component, hideIfLoggedOut = false) {
    const AuthenticatedComponent = (props) => {
        const { isLoggedIn } = props;
        return (hideIfLoggedOut && !isLoggedIn) ? null : <Component {...props} />;
    };

    AuthenticatedComponent.propTypes = {
        isLoggedIn: PropTypes.bool
    };

    const mapStateToProps = ({
        user: { isLoggedIn, mobile, isFetched }
    }) => {
        return {
            isLoggedIn,
            mobile,
            isUserDetailsFetched: isFetched
        };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
    return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);
}
