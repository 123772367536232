export const getNotServicibilityStatus = ({ zone, slots, locations }) => {
    let status = false;
    /**
     * check if this case is home inspection only
     */
    if (zone && !locations.length) {
        const locationID = zone.location_id;
        /**
         * already converting date_slot object into an array at the interceptor level
         * hence just need to check on length
         */
        if (slots[locationID] && !slots[locationID].date_slot.length) {
            status = true;
        }
    } else if (!zone && !locations.length) {
        status = true;
    }
    return status;
};
