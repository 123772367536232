/* eslint-disable max-statements, complexity */
import { FINANCE_SCREEN, ORDER_STATUS } from "../../../constants/checkout-constants";
import { DELIVERY_MODE } from "../inventory-car-card/constant";
import { ACTIVE_SCREEN } from "./constant";
import { CF_OFFER_TYPE, NUMBER } from "../../../constants/app-constants";

export const formatAddressSlotsResponse = (data, params = {}) => {
    return {
        slots: data.slots,
        ...(
            !params.pincode && data.address && {
                selectedAddress: data.address,
                ...(
                    data.address && {
                        deliveryPincode: data.address.pincode
                    }
                )
            }
        ),
        isHomeDeliveryUnavailable: !data.serviceable,
        addressSlotsFetching: false,
        isFasterOrderInitiated: true,
        addressSlotsError: null,
        isSelfPickupUnavailable: !(data.pickup && data.pickup.length),
        previousBookingFinanceOpted: data.previousBookingFinanceOpted,
        previousBookingDeliveryMode: data.previousBookingDeliveryMode,
        previousBookingId: data.previousBookingId
    };
};

export const renderFasterScreen = ({
    fasterIsAddressUnServiceable,
    fasterIsSelfPickupUnavailable,
    selfPickUpChoosen,
    updateActiveScreenConnect
}) => {
    if (fasterIsAddressUnServiceable && fasterIsSelfPickupUnavailable) {
        updateActiveScreenConnect(ACTIVE_SCREEN.SELECT_ADDRESS);
    } else if (selfPickUpChoosen || (fasterIsAddressUnServiceable && !fasterIsSelfPickupUnavailable)) {
        updateActiveScreenConnect(ACTIVE_SCREEN.SELF_PICKUP);
    } else {
        updateActiveScreenConnect(ACTIVE_SCREEN.HOME_DELIVERY_SLOTS);
    }
};

export const renderFinalScreen = ({
    orderStatus,
    fasterResponse,
    updateActiveScreenConnect,
    queryParams,
    updateBankingScreenStatusConnect,
    orderResponse,
    userId,
    appointmentId,
    setFinancingScreenConnect,
    getAllOffersConnect,
    renderCallback,
    showFreePaidScreen,
    offerEngineABTest = "B",
    generateCfOfferConnect = () => { },
    checkCfOfferConnect = () => { },
    setCfFailureScreenTypeConnect = () => {}
}) => {
    const selfPickUpChoosen = fasterResponse.previousBookingDeliveryMode === DELIVERY_MODE.PICK_UP;

    const fetchAllOffers = async () => {
        const {
            vehiclePrice,
            userVariant,
            orderId
        } = orderResponse || {};
        const body = {
            source: "mSite",
            userId,
            orderId,
            vehiclePrice,
            appointmentId,
            ...(offerEngineABTest === "A", { type: CF_OFFER_TYPE.CHECKOUT })
        };
        await new Promise(resolve => window.setTimeout(resolve, NUMBER.FIVE_THOUSAND));

        return new Promise(async (resolve) => {

            let response = {};
            let offerRejectionDetails = null;
            let cfOffer = null;
            if (offerEngineABTest === "A") {
                response = await generateCfOfferConnect(body);
                const { offer } = response || {};
                const { rejectionDetails } = offer || {};
                offerRejectionDetails = rejectionDetails;
                cfOffer = offer;

                response = await checkCfOfferConnect(userId);

            } else {
                response = await getAllOffersConnect(body);
                offerRejectionDetails = response.rejectionDetails;
                cfOffer = response.offer;
            }

            const { bankingSubmitted, bankingSkippable, userDetailsSubmitted: userDetailsSubmit } = response || {};
            if (!cfOffer) {
                setCfFailureScreenTypeConnect(FINANCE_SCREEN.POST_BOOKING_EMPTY_OFFER);
                setFinancingScreenConnect({ activeScreen: FINANCE_SCREEN.SELECT_FINANCE_OFFER });
                resolve(true);
            } else if (((userVariant === "C" && bankingSkippable) || bankingSubmitted) && !offerRejectionDetails) {
                setFinancingScreenConnect({ activeScreen: FINANCE_SCREEN.SELECT_FINANCE_OFFER });
                resolve(true);
            } else if (offerRejectionDetails && bankingSubmitted && userDetailsSubmit) {
                setFinancingScreenConnect({ activeScreen: FINANCE_SCREEN.FINANCE_OFFER_PENDING });
                resolve(true);
            } else {
                resolve(false);
            }
        });
    };

    const {
        isHomeDeliveryUnavailable: fasterIsAddressUnServiceable,  //used to show slots screen or address screen
        isSelfPickupUnavailable: fasterIsSelfPickupUnavailable
    } = fasterResponse;
    if (orderStatus === ORDER_STATUS.BOOKED) {
        updateActiveScreenConnect(ACTIVE_SCREEN.RESERVATION_CONFIRMED);
    } else if (queryParams.txnId) {
        if (queryParams.status === "true") {
            fetchAllOffers(orderResponse).then((financeScreenSet) => {
                if (financeScreenSet) {
                    updateActiveScreenConnect(ACTIVE_SCREEN.FINANCE_FLOW);
                } else {
                    updateActiveScreenConnect(ACTIVE_SCREEN.SELECT_ADDRESS);
                }
            });
        } else {
            updateBankingScreenStatusConnect({
                status: true,
                source: ACTIVE_SCREEN.PAYMENT_OPTIONS
            });
            updateActiveScreenConnect(ACTIVE_SCREEN.FINANCE_FLOW);
        }
    } else if (showFreePaidScreen) {
        updateActiveScreenConnect(ACTIVE_SCREEN.FREE_PAID_BOOKING);
    } else {
        renderFasterScreen({
            fasterIsAddressUnServiceable,
            fasterIsSelfPickupUnavailable,
            selfPickUpChoosen,
            updateActiveScreenConnect
        });
    }

    renderCallback(ACTIVE_SCREEN.HOME_DELIVERY_SLOTS);
};
