import React, { useState } from "react";
import styles from "./styles/styles.css";
import PropTypes from "prop-types";
import ArrowIcon from "../../shared/arrow";
import H2 from "../../shared/h2/component";

const SeoCarOverviewServiceCost = ({
    carFullName,
    serviceCost,
    onPageRedirection,
    hrefData
}) => {
    const { Petrol = [], Diesel = [], content = "" } = serviceCost;
    const [activeTab, setActiveTab] = useState("petrol");
    const [activeData, setActiveData] = useState(Petrol);

    const changeTabs = (active) => {
        if (active === "petrol") {
            setActiveTab(active);
            setActiveData(Petrol);
        } else {
            setActiveTab(active);
            setActiveData(Diesel);
        }
    };
    return (
        <React.Fragment>
            {activeData.length > 0 && <React.Fragment>
                <div styleName={"styles.title"}>
                    <H2 text={`${carFullName} SERVICE & MAINTENANCE Cost`} />
                </div>
                <p styleName={"styles.txt"}>{content}</p>
                {Diesel.length > 0 ? <ul styleName={"styles.tabNav"}>
                    <li styleName={activeTab === "petrol" ? "styles.active" : ""}
                        onClick={() => changeTabs("petrol")}>
                        Petrol
                    </li>
                    {Diesel.length > 0 && <li styleName={activeTab === "diesel" ? "styles.active" : ""}
                        onClick={() => changeTabs("diesel")}>
                        Diesel
                    </li>}
                </ul> : <div className="container"><H2 text={"Petrol"} /></div>}
                <div styleName={"styles.maintenanceCostServices"}>
                    <table cellSpacing="0" cellPadding="0" width="100%">
                        <thead>
                            <tr>
                                <th>Service No.</th>
                                <th>Kms/Months</th>
                                <th>Type/Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            {activeData.length > 0 && activeData.map((serviceCosts, index) => (
                                <tr key={index}>
                                    <td>{serviceCosts.service_number}</td>
                                    <td>{serviceCosts.kms}/{serviceCosts.months}</td>
                                    <td>Free</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div styleName={"styles.readMore"} onClick={onPageRedirection}><a href={hrefData("service_cost")}>VIEW MORE CHARGES</a> <ArrowIcon /></div>
            </React.Fragment>}
        </React.Fragment >
    );
};

SeoCarOverviewServiceCost.propTypes = {
    carFullName: PropTypes.string,
    serviceCost: PropTypes.string,
    onPageRedirection: PropTypes.func,
    hrefData: PropTypes.func
};

export default SeoCarOverviewServiceCost;
