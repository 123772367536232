import React from "react";

const HomeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clipPath="url(#clip0_5155_122288)">
                <path d="M17.082 3.42529H15.9342C15.4905 3.42529 15.1309 3.8763 15.1309 4.43264V11.37C15.1309 11.9263 15.4905 12.3773 15.9342 12.3773H17.082C17.5257 12.3773 17.8853 11.9263 17.8853 11.37V4.43264C17.8853 3.8763 17.5257 3.42529 17.082 3.42529Z" fill="white" stroke="#717272" strokeWidth="1.3" />
                <path d="M4.11523 10.3406L11.124 5.49097L17.8876 10.452V16.5729C17.8876 17.679 16.8641 18.5747 15.6 18.5747H6.40276C5.13873 18.5747 4.11523 17.679 4.11523 16.5729V10.3406Z" fill="white" stroke="#717272" strokeWidth="1.3" />
                <path d="M20.3342 10.6298L11.7498 3.72447C11.5437 3.52352 11.2721 3.42305 11.0004 3.42533C10.7288 3.42533 10.4572 3.52352 10.2488 3.72447L1.66671 10.6298C1.25693 11.0295 1.25693 11.678 1.66671 12.0776C2.0765 12.4772 2.74152 12.4772 3.1513 12.0776L11.0004 5.88925L18.8496 12.0776C19.2594 12.4772 19.9244 12.4772 20.3342 12.0776C20.7416 11.678 20.7439 11.0295 20.3342 10.6298Z" fill="white" stroke="#717272" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M20.3342 10.6298L11.7498 3.72447C11.5437 3.52352 11.2721 3.42305 11.0004 3.42533C10.7288 3.42533 10.4572 3.52352 10.2488 3.72447L1.66671 10.6298C1.25693 11.0295 1.25693 11.678 1.66671 12.0776C2.0765 12.4772 2.74152 12.4772 3.1513 12.0776L11.0004 5.88925L18.8496 12.0776C19.2594 12.4772 19.9244 12.4772 20.3342 12.0776C20.7416 11.678 20.7439 11.0295 20.3342 10.6298Z" fill="white" stroke="#717272" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M20.3342 10.6298L11.7498 3.72447C11.5437 3.52352 11.2721 3.42305 11.0004 3.42533C10.7288 3.42533 10.4572 3.52352 10.2488 3.72447L1.66671 10.6298C1.25693 11.0295 1.25693 11.678 1.66671 12.0776C2.0765 12.4772 2.74152 12.4772 3.15131 12.0776L11.0004 5.88925L18.8496 12.0776C19.2594 12.4772 19.9244 12.4772 20.3342 12.0776C20.7416 11.678 20.7439 11.0295 20.3342 10.6298Z" stroke="#717272" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M8.93359 14.6111C8.93359 13.7576 9.62552 13.0657 10.479 13.0657H11.5198C12.3734 13.0657 13.0653 13.7576 13.0653 14.6111V18.5746H8.93359V14.6111Z" fill="white" stroke="#717272" strokeWidth="1.3" />
            </g>
            <defs>
                <clipPath id="clip0_5155_122288">
                    <rect width="21" height="21" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default HomeIcon;
