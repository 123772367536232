import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { locationPageType, toggleLocationPicker } from "../location-picker-popup-v2/actions";
import HeaderLocationBar from "./component";

const mapStateToProps = ({
    location: { cityInfo },
    user: {
        userId,
        isLoggedIn
    },
    cities: {
        selectedCity
    }
}) => ({
    cityInfo,
    userId,
    isLoggedIn,
    selectedCity
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    toggleLocationPickerConnect: toggleLocationPicker,
    locationPageTypeConnect: locationPageType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLocationBar);
