import { STEP_NAMES } from "../../../constants/app-constants";
import { NUMBER } from "../../../constants/app-constants";
import { KM_RANGE } from "../../../constants/seller-constant";
import { TABS } from "../../../utils/funnel/steps.mobile";
import { LEAD_FORM_SECTION_MAP, TABS_WITH_SECTIONS, TAB_SECTIONS } from "../../mobile/sell-onlie-car-evaluation/selector";

export const TAB_STATES = {
    FILLED: "FILLED",
    FILLED_DISABLED: "FILLED_DISABLED",
    DISABLED: "DISABLED",
    CURRENT: "CURRENT"
};

export const TAB_STYLES = {
    [TAB_STATES.FILLED]: "styles.filled",
    [TAB_STATES.FILLED_DISABLED]: "styles.disabled",
    [TAB_STATES.CURRENT]: "styles.current",
    [TAB_STATES.DISABLED]: ""
};

export const SECTION_LABELS = {
    [STEP_NAMES.FuelType]: "Fuel Type",
    [STEP_NAMES.Transmission]: "Transmission",
    [STEP_NAMES.Variant]: "Variant",
    [STEP_NAMES.RTO]: "RTO Code",
    [STEP_NAMES.State]: "State"
};

const getLabelByValue = (leadData = {}) => {
    const {displayText} = leadData || {};
    const range = KM_RANGE.filter((kms) => kms.value === parseInt(displayText));
    return range[0] && range[0].label;
};

const getDetails = (leadData, stateName, displayName) => {
    const text = stateName === STEP_NAMES.KilometerDriven ?
        getLabelByValue(leadData[stateName]) :
        leadData[stateName] && leadData[stateName].displayText;
    if (TABS_WITH_SECTIONS.includes(stateName)) {
        let unfilledDetails = null;
        TAB_SECTIONS[stateName].map((item) => {
            if (!unfilledDetails && leadData[LEAD_FORM_SECTION_MAP[item]] && !leadData[LEAD_FORM_SECTION_MAP[item]].displayText) {
                unfilledDetails = {
                    redirection: `/sell-online-evaluation/${stateName}/${item}/`,
                    displayText: SECTION_LABELS[LEAD_FORM_SECTION_MAP[item]],
                    isFilled: false
                };
            }
        });
        return unfilledDetails || {
            redirection: `/sell-online-evaluation/${stateName}/`,
            displayText: text || displayName,
            isFilled: true
        };
    } else {
        return {
            redirection: `/sell-online-evaluation/${stateName === STEP_NAMES.KilometerDriven ? STEP_NAMES.Kms : stateName}/`,
            displayText: text || displayName,
            isFilled: !!text
        };
    }
};

export const getList = (leadData = {}) => {
    let currentFound = false;
    const eventLabel = [];
    const list = TABS.slice(NUMBER.ZERO, NUMBER.SIX).map(({stateName, displayName}) => {
        let type = TAB_STATES.DISABLED;
        const {redirection, displayText, isFilled} = getDetails(leadData, stateName, displayName);
        if (isFilled) {
            eventLabel.push(displayText);
            if (currentFound) type = TAB_STATES.FILLED_DISABLED;
            else type = TAB_STATES.FILLED;
        } else if (!isFilled && !currentFound) {
            currentFound = true;
            type = TAB_STATES.CURRENT;
        }
        return {
            type,
            redirection: [TAB_STATES.DISABLED, TAB_STATES.FILLED_DISABLED].includes(type) ? "" : redirection,
            displayText
        };
    });
    return {list, eventLabel: eventLabel.toString()};
};
