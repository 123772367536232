export const parseSellMarketingRevampData = ({ cmsData, bannerImage = "", enableSeoData = false }) => {
    const {
        cmsImageGallery = {},
        cmsVideoGallery = {},
        seo: {
            genericContent,
            mainHeading,
            meta
        },
        cms_faq_list: faqList,
        homePageBanner
    } = cmsData || {};
    const { og } = meta || {};
    const { title, description } = og || {};

    return {
        cmsContentDetails: {
            ...cmsImageGallery,
            ...cmsVideoGallery
        },
        mainHeading,
        genericContent,
        faqList,
        homePageBanner: bannerImage || homePageBanner?.image,
        ...(enableSeoData && title && description && {
            og: {
                title,
                description
            }
        })
    };
};
