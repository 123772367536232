export const WISHLIST_SOURCE = {
    LISTING_PAGE: "Listing_Page",
    DETAILS_PAGE: "Detail_Page",
    HOME_PAGE: "Home_Page",
    SIMILAR_CARS: "Similar_Cars",
    THANK_YOU_PAGE: "Thank_You_Page",
    CAR_FINDER: "CAR_FINDER",
    CHECKOUT: "CHECKOUT",
    CAR_COMPARE: "CAR_COMPARE",
    FB_LANDING: "FB_LANDING",
    TOAST: "TOAST"
};

export const B2C_WISHLIST_GA_CATEGORY = {
    [WISHLIST_SOURCE.LISTING_PAGE]: "Buy_used_cars_B2C_Category_Page",
    [WISHLIST_SOURCE.DETAILS_PAGE]: "Buy_used_cars_B2C_Car_Details_Page",
    [WISHLIST_SOURCE.HOME_PAGE]: "Buy_used_cars_B2C_Car_Home_Page",
    [WISHLIST_SOURCE.SIMILAR_CARS]: "Buy_used_cars_B2C_Car_Similar_Cars",
    [WISHLIST_SOURCE.THANK_YOU_PAGE]: "Buy_used_cars_B2C_Car_Thank_You_Page",
    [WISHLIST_SOURCE.FB_LANDING]: "Buy_used_cars_B2C_Marketing_Page"
};

export const C2C_WISHLIST_GA_CATEGORY = {
    [WISHLIST_SOURCE.LISTING_PAGE]: "Buy_used_cars_C2C_Car_Listing_Page",
    [WISHLIST_SOURCE.DETAILS_PAGE]: "Buy_used_cars_C2C_Car_Details_Page",
    [WISHLIST_SOURCE.HOME_PAGE]: "Buy_used_cars_C2C_Car_Home_Page",
    [WISHLIST_SOURCE.SIMILAR_CARS]: "Buy_used_cars_C2C_Car_Similar_Cars",
    [WISHLIST_SOURCE.THANK_YOU_PAGE]: "Buy_used_cars_C2C_Car_Thank_You_Page",
    [WISHLIST_SOURCE.FB_LANDING]: "Buy_used_cars_C2C_Marketing_Page"
};
