/* eslint-disable max-statements */
import { NUMBER, STEP_NAMES } from "../../../constants/app-constants";
import { TRANSMISSION_TYPES } from "../../../constants/seller-constant";

export  const responseOrder = {
    states: {},
    RTO: {},
    brand: {},
    year: {},
    model: {},
    ds_details: {}

};

export const REGISTRATION_STEP_NAMES = {
    BRAND: "brand",
    YEAR: "year",
    MODEL: "model",
    STATES: "states",
    DS_DETAIL: "ds_details",
    VARIANT: "variant",
    RTO: "RTO",
    TRANSMISSION: "transmission_type",
    FUEL_TYPE: "fuelType"
};

export const getDataByKey = (response, key) => {
    let obj = {};

    if (key === REGISTRATION_STEP_NAMES.BRAND) {
        obj = {
            id: response.brand.make_id,
            displayText: response.brand.make_display
        };
    }

    if (key === REGISTRATION_STEP_NAMES.YEAR) {
        obj = {
            id: parseInt(response.year.year),
            displayText: response.year.year,
            year_id: response.year.year_id
        };
    }

    if (key === REGISTRATION_STEP_NAMES.MODEL) {
        obj = {
            id: response.model.model_id,
            displayText: response.model.model_display
        };
    }

    if (key === REGISTRATION_STEP_NAMES.VARIANT) {
        obj = {
            id: response.variant.variant_id,
            displayText: response.variant.variant_display_name,
            variantType: response.variant.fuel_type
        };
    }

    if (key === REGISTRATION_STEP_NAMES.STATES) {
        obj = {
            id: response.states.state_id,
            displayText: response.states.state_code
        };
    }

    if (key === REGISTRATION_STEP_NAMES.RTO) {
        obj = {
            id: response.RTO.rto_detail_id,
            displayText: response.RTO.rto_code
        };
    }

    if (key === REGISTRATION_STEP_NAMES.FUEL_TYPE) {
        obj = {
            displayText: response.fuelType
        };
    }

    if (key === REGISTRATION_STEP_NAMES.TRANSMISSION) {
        obj = {
            displayText: TRANSMISSION_TYPES[response.variant.transmission_type]
        };
    }

    return obj;

};

export const STEPS_TO_IGNORE = ["states", "RTO"];

export const formatInputValue = ({inputValue = ""}) => {
    return inputValue.replace(/\s/g, "")
        .split("")
        .map((char, index) => (index === NUMBER.ONE || index === NUMBER.THREE) ? `${char} ` : char)
        .join("")
        .trim();
};

export const getFormattedData = (data) => {
    const {
        rto_code: rtoCode = null,
        rto_id: rtoDetailId = null,
        state_code: stateCode = null,
        rto_state_id: stateId = null
    } = data || {};

    return {
        [REGISTRATION_STEP_NAMES.RTO]: {
            rto_code: rtoCode,
            rto_detail_id: rtoDetailId
        },
        [REGISTRATION_STEP_NAMES.STATES]: {
            state_code: stateCode,
            state_id: stateId
        },
        [REGISTRATION_STEP_NAMES.BRAND]: null,
        [REGISTRATION_STEP_NAMES.MODEL]: null,
        [REGISTRATION_STEP_NAMES.YEAR]: null
    };
};

const getValues = (response, key, defaultValue) => {
    return response[key] ? getDataByKey(response, key) : defaultValue;
};

export const getParsedLeadFormData = (response) => {
    const defaultValue = { displayText: "", id: null };
    let variant = { id: "", displayText: "", variantType: "" };
    let transmission = defaultValue;
    const dsDetails = response[REGISTRATION_STEP_NAMES.DS_DETAIL];
    const defaultVariant = dsDetails && dsDetails[0];
    if (dsDetails && dsDetails.length === NUMBER.ONE && defaultVariant.variant) {
        variant = getDataByKey(defaultVariant, REGISTRATION_STEP_NAMES.VARIANT);
        transmission = getDataByKey(defaultVariant, REGISTRATION_STEP_NAMES.TRANSMISSION);
    }

    return {
        [STEP_NAMES.Brand]: getValues(response, REGISTRATION_STEP_NAMES.BRAND, defaultValue),
        [STEP_NAMES.Year]: getValues(response, REGISTRATION_STEP_NAMES.YEAR, {...defaultValue, year_id: null}),
        [STEP_NAMES.Model]: getValues(response, REGISTRATION_STEP_NAMES.MODEL, defaultValue),
        [STEP_NAMES.Variant]: variant,
        [STEP_NAMES.State]: getValues(response, REGISTRATION_STEP_NAMES.STATES, defaultValue),
        [STEP_NAMES.KilometerDriven]: defaultValue,
        [STEP_NAMES.FuelType]: getValues(response, REGISTRATION_STEP_NAMES.FUEL_TYPE, defaultValue),
        [STEP_NAMES.Transmission]: transmission,
        [STEP_NAMES.RTO]: getValues(response, REGISTRATION_STEP_NAMES.RTO, defaultValue)
    };
};
