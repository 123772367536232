/* eslint-disable complexity */
import React, { useState, useEffect } from "react";
import styles from "./styles.css";
import useToggle from "../../../hooks/use-toggle";
import PropTypes from "prop-types";
import IconSuccess from "../icon-success";
const MAX_LENGTH = 30;

const InputText = ({
    value = "",
    id = "",
    text = "",
    onChange,
    isAutoFocused = false,
    onBlurCallback = () => { },
    maxLength = MAX_LENGTH,
    validator = () => { },
    errorMessage = "",
    inputRef,
    disabled = false,
    placeholder = null,
    onFocusCallback = () => { },
    showErrorOnFocus = false,
    type = "text",
    showValidCheckmark = false,
    onKeyPress = () => {},
    readOnly = false,
    serverErrorMessage = "",
    isError,
    disableOnChangeError = false,
    isFromRegHomePage = false,
    isPrefixAsFixed = false,
    onCut = () => { },
    onCopy = () => { },
    onPaste = () => { },
    img = "",
    isImgPresent = false,
    name = "",
    sanitizedValue = "",
    customErrorText = "Please enter valid registration number",
    inputmode,
    pattern
}) => {
    const [isTouched, setIsTouched] = useState(false);
    const [isFocused, setIsFocused] = useToggle(isAutoFocused);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState(serverErrorMessage || errorMessage);

    useEffect(() => {
        if (isError) {
            setError(serverErrorMessage);
        } else {
            setError("");
        }
    }, [serverErrorMessage, isError]);

    const onChangeHandler = (e) => {
        const inputValue = e.target.value;
        setIsTouched(true);
        if (!disableOnChangeError) {
            if (isFromRegHomePage) {
                if (!validator(inputValue) && inputValue) {
                    setHasError(false);
                    setError("");
                }
            } else if (validator(inputValue)) {
                setHasError(false);
                setError("");
            } else {
                setHasError(true);
                setError(errorMessage);
            }
        }
        onChange(e);
    };

    const onFocus = (e) => {
        const inputValue = e.target.value;
        setIsFocused();
        setIsTouched(true);
        if (!validator(inputValue) && showErrorOnFocus) {
            setHasError(true);
            setError(errorMessage);
        } else {
            setHasError(false);
            setError("");
        }
        onFocusCallback();
    };

    const onBlur = (e) => {
        setIsFocused();
        onBlurCallback(e.target.value);
    };

    // useEffect(() => {
    //     if (showValidCheckmark) {
    //         setHasError(!validator(value));
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <div styleName={`styles.inputShift ${isPrefixAsFixed ? "styles.prefixwidthshodow" : ""} ${isImgPresent ? "styles.prefixImg" : ""}`} >
            { isImgPresent && <div dangerouslySetInnerHTML={{ __html: img}}/> }
            <input
                ref={inputRef}
                type={type}
                className="form-control"
                styleName={isAutoFocused ?
                    "styles.focused" : ""}
                value={sanitizedValue || value}
                maxLength={maxLength}
                onChange={onChangeHandler}
                onFocus={(e) => onFocus(e)}
                onBlur={(e) => onBlur(e)}
                autoComplete={type === "password" ? "new-password" : "off"}
                placeholder={placeholder}
                disabled={disabled}
                onKeyPress={onKeyPress}
                readOnly={readOnly}
                onCut={onCut}
                onCopy={onCopy}
                onPaste={onPaste}
                {... id && {id}}
                {...name && {name}}
                {...inputmode && {inputmode}}
                {...pattern && {pattern}}
            />
            { text && <label styleName={`${(isFocused || !!value) ? "active" : ""}`}>
                {text}
            </label> }
            {
                ((isTouched &&
                !isFocused &&
                hasError) || (showErrorOnFocus && hasError) || (isTouched && hasError) || isError)  &&
                <small styleName={"styles.error"}>{error}</small>
            }
            {(isFromRegHomePage && isError && !hasError) ? <small styleName={"styles.error styles.registrationNumber"}>
                {customErrorText}
            </small> : ""}

            { (showValidCheckmark && !hasError) && <div styleName={"styles.success"}>
                <IconSuccess />
            </div>
            }
        </div>
    );
};

InputText.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.string,
    onChange: PropTypes.func,
    onBlurCallback: PropTypes.func,
    maxLength: PropTypes.number,
    validator: PropTypes.func,
    errorMessage: PropTypes.string,
    inputRef: PropTypes.object,
    onFocusCallback: PropTypes.func,
    placeholder: PropTypes.string,
    isAutoFocused: PropTypes.bool,
    disabled: PropTypes.bool,
    showErrorOnFocus: PropTypes.bool,
    type: PropTypes.number || PropTypes.string,
    showValidCheckmark: PropTypes.bool,
    onKeyPress: PropTypes.func,
    readOnly: PropTypes.bool,
    serverErrorMessage: PropTypes.string,
    isError: PropTypes.bool,
    disableOnChangeError: PropTypes.bool,
    isFromRegHomePage: PropTypes.bool,
    isPrefixAsFixed: PropTypes.bool,
    onCut: PropTypes.func,
    onCopy: PropTypes.func,
    onPaste: PropTypes.func,
    img: PropTypes.string,
    isImgPresent: PropTypes.bool,
    name: PropTypes.string,
    sanitizedValue: PropTypes.string,
    customErrorText: PropTypes.string,
    inputmode: PropTypes.string,
    pattern: PropTypes.string
};
export default InputText;
