import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SeoPageContent from "./component";

const mapStateToProps = (
    {meta: { genericContent }}) => ({
    genericContent
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SeoPageContent);
