import { NUMBER } from "../../../constants/app-constants";
import { TABS } from "../../../utils/funnel/steps.mobile";
import { TABS_WITH_SECTIONS, TAB_SECTIONS, getActiveTab, getPreviousFilledSection } from "../sell-onlie-car-evaluation/selector";

export const SLIDER_SETTINGS = {
    slidesToShow: 1.1,
    slidesToScroll: 1,
    infinite: false,
    arrow: false,
    dots: true,
    autoplay: false,
    useTransform: true
};

export const SWIPE_DIRECTIONS = {
    RIGHT: "right",
    LEFT: "left"
};

export const getU2LRedirectionAndScreen = (leadData) => {
    const activeTab = getActiveTab(TABS, leadData);
    let redirection = "/sell-online-evaluation/";
    if (TABS_WITH_SECTIONS.includes(activeTab)) {
        const tabArray = TAB_SECTIONS[activeTab];
        const requestedTab = tabArray[tabArray.length - NUMBER.ONE];
        const previousFilledSection  = getPreviousFilledSection(requestedTab, leadData, activeTab);
        redirection = `/sell-online-evaluation/${activeTab}/${previousFilledSection}`;
    } else if (leadData && leadData.askUserIntent) {
        redirection = "/seller-intent/";
    } else if (leadData && leadData.userIntent) {
        redirection = "/user-mobile/";
    } else {
        redirection = `/sell-online-evaluation/${activeTab}`;
    }
    return { redirection, activeTab };
};
