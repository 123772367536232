export const hamburgerOpen = {
    event: "Burger Menu Open",
    eventCategory: "homepage",
    eventAction: "burger_menu",
    eventLabel: "NA"
};

export const buyCarLogoClick = {
    event: "logo_click_C2C_AllPages_1",
    eventCategory: "Buy_used_cars_C2C_All_Page",
    eventAction: "logo_click",
    eventLabel: "logo"
};

export const buyCarTopLogoClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "logo_click",
    eventLabel: "logo"
};

export const buyerPageHamburgerOnClick = {
    event: "top_nav_events",
    eventCategory: "Buy_used_cars_B2C_Homepage",
    eventAction: "menu_tab_clicked",
    eventLabel: "burger_menu"
};

export const buyerPageHamburgerClick = {
    event: "t0_burger_menu",
    eventAction: "top_nav",
    eventLabel: "burger_menu"
};
