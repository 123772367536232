import ordinalInWord from "./ordinal-in-word";
import priceFormatter from "./price-formatter";
import { shortMonthName } from "./get-month-by-number";
import epochToFormattedDate from "./epoch-to-formatted-date";
import makePriceLabel from "./make-price-label";

const getCarName = (details) => {
    const { year, carName } = details || {};
    return `${year} ${carName}`;
};

const getFullCarName = (details) => {
    const { year, carName, variant } = details || {};
    return `${year} ${carName} ${variant}`;
};

const getCarParkingAddress = (hubs, locationId = "") => {
    const { locality: parkingHubAddress } = (hubs || {})[locationId] || {};
    return parkingHubAddress;
};

const getCarKilometersDrivenLabel = (details) => details.odometerReading && `${priceFormatter(details.odometerReading, true, "ae")} km`;
const getCarOwnersLabel = (details) => details.ownerNumber && `${ordinalInWord(details.ownerNumber)}`;
const getCarPriceLabel = (details, key = "listingPrice", currency = "₹") => makePriceLabel(details[key], currency);
const carProprties = (details) => {
    const {
        fuelType,
        registrationNumber,
        year,
        month,
        insuranceExpiry
    } = details || {};
    const isValidYear = (year && month);
    const kilometersDriven = getCarKilometersDrivenLabel(details);
    const owners = getCarOwnersLabel(details);
    // eslint-disable-next-line no-magic-numbers
    const buildYear = isValidYear && `${shortMonthName(parseInt(month))}'${(year).toString().substr(2)}`;

    return [
        { title: "Odometer", value: kilometersDriven, type: "kilometer" },
        { title: "Purchased", value: buildYear, type: "buildyear" },
        { title: "Registration", value: registrationNumber, type: "rto" },
        { title: "Fuel Type", value: fuelType, type: "fuel" },
        { title: "Ownership", value: owners, type: "owner" },
        {
            title: "Insurance",
            value: insuranceExpiry && epochToFormattedDate(insuranceExpiry),
            type: "insurance"
        }
    ];
};

export default carProprties;
export {
    getCarName,
    getCarKilometersDrivenLabel,
    getCarOwnersLabel,
    getCarPriceLabel,
    getCarParkingAddress,
    getFullCarName
};
