import { getKmsRange, getTransmissionType } from "../../components/mobile/lead-form/selector";
import parsePriceRanges from "./parse-price-ranges";

/* eslint-disable camelcase */
export const parseLeadFormData = ({ data = {} }) => {
    const { detail = {} } = data || {};
    const { data: leadData = {} } = detail || {};
    const filteredData = {};
    for (const [key, value] of Object.entries(leadData)) {
        if (value && key) {
            filteredData[key] = value;
        }
    }
    return filteredData;
};

export const convertLeadDetailsToLocalLeadData = (data) => {
    const {
        token,
        make_id, make_display,
        model_id, model_display,
        year, year_id,
        variant_id, variant_display_name,
        state_id, state_code,
        kms, kms_range,
        rto_id,
        cta_location,
        rto_code = "NA",
        stepper_value,
        lead_type,
        whatsapp_consent,
        language,
        fuel_type = "",
        transmission_type = ""
    } = data || {};

    return {
        brand: { id: make_id, displayText: make_display },
        model: { id: model_id, displayText: model_display },
        year: { id: year, displayText: year.toString(), year_id },
        variant: { id: variant_id, displayText: variant_display_name },
        registrationState: { id: state_id, displayText: state_code },
        priceRange: parsePriceRanges(data),
        kilometersDriven: { id: kms, displayText: kms_range || getKmsRange(kms) },
        token,
        currentStepIndex: 7,
        prevTabIndex: 6,
        currentStep: "kms",
        ...(rto_id && { rto: { displayText: rto_code, id: rto_id } }),
        ...(cta_location && { ctaLocation: cta_location }),
        isLeadFormChanged: false,
        source: lead_type,
        // eslint-disable-next-line no-magic-numbers
        stepValue: isNaN(stepper_value) ? 5000 : stepper_value,
        whatsappConsent: whatsapp_consent,
        language,
        ...(transmission_type && { transmission: { displayText: getTransmissionType(transmission_type) }}),
        ...(fuel_type && { fuelType: { displayText: fuel_type }})
    };
};
