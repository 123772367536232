import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_CURRENT_LOCATION_NAME
    SET_CURRENT_LOCATION_ID
    SET_SELECTION_TYPE

    FETCH_LEAD_DETAILS

    SET_SHOULD_REPLACE_LOADER

    SET_IS_MMVY
    SET_LEAD_DATA
    SET_CHANNEL_PARTNER_TOKEN
`,
    {
        prefix: "appointment/"
    }
);
