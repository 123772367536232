import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import SellUsedCarSeo from "./component";
import { resetLeadFormData, setData, setStatsigText, setStep } from "../lead-form/actions";
import { updateLeadSource } from "../settings/actions";
import { setFirstLoad } from "../../configuration/actions";
import { setIsSellOnline } from "../../../components/mobile/lead-form/actions";
import { fetchData } from "../../../components/shared/meta-tags/actions";

const mapStateToProps = ({
    meta: { status, templateType, identifiers, isSSR, faqList, genericContent, cmsContentDetails, homePageBanner},
    user: { isFirstLoad, statsigId, gaId  },
    brands: { all: allBrands },
    models: { all: allModels },
    config: { cityList },
    experiments: {
        statsigDynamicConfig: {
            data: statsigDynamicConfigData
        },
        websiteAdvertisementBanner: {
            data: websiteAdvertisementBannerData
        }
    },
    sellOnlineCities: {
        completeSellOnlineCities
    },
    leadData,
    ratedTestimonials: {
        list
    },
    sellUsedCar: {
        nearestCities,
        hubsAndParkingYards
    },
    footer: {
        data: {
            top_cities: topCitiesList
        }
    },
    userDropWidget: { data: { isUserDrop } }
}) => ({
    list,
    status,
    isFirstLoad,
    templateType,
    identifiers,
    allBrands,
    allModels,
    cityList,
    leadData,
    nearestCities,
    completeSellOnlineCities,
    hubsAndParkingYards,
    isSSR,
    topCitiesList,
    faqList,
    genericContent,
    cmsContentDetails,
    isUserDrop,
    statsigId,
    gaId,
    statsigDynamicConfigData,
    websiteAdvertisementBannerData,
    homePageBanner
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setFirstLoadConnect: setFirstLoad,
    resetLeadFormDataConnect: resetLeadFormData,
    updateLeadSourceConnect: updateLeadSource,
    setDataConnect: setData,
    setStepConnect: setStep,
    setIsSellOnlineConnect: setIsSellOnline,
    fetchDataConnect: fetchData,
    setStatsigTextConnect: setStatsigText
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SellUsedCarSeo));
