const { DEVICE_TYPE } = require("../../constants/app-constants");

export const getAppLink = (os = "DEFAULT") => {
    const appLinks = {
        [DEVICE_TYPE.ANDROID]: "https://play.google.com/store/apps/details?id=com.cars24.seller&hl=en_IN",
        [DEVICE_TYPE.IOS]: "https://apps.apple.com/in/app/cars24-sell-car-at-best-price/id1364492085",
        DEFAULT: "https://cars24.app.link/9evo0wLgsHb"
    };
    return appLinks[os] || appLinks[DEVICE_TYPE.DEFAULT];
};
