import RupeesIcon from "./images/rupeesIcon.svg";
import Payments from "./images/payments.svg";
import Directions from "./images/directions.svg";

export const EXCHANGE_POINTS = [
    {
        title: "Sell your car to CARS24",
        img: RupeesIcon
    },
    {
        title: "Get up to ₹20,000 discount coupon",
        img: Payments
    },
    {
        title: "Use it when you buy a car from us",
        img: Directions
    }
]
;
