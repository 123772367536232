import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_TESTIMONIALS
    FETCH_TESTIMONIALS_SUCCESS
    FETCH_TESTIMONIALS_FAILURE
    SET_IS_SSR
`,
    {
        prefix: "testimonialcarousel/"
    }
);
