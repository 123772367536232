/* eslint-disable no-unused-vars */
import { AB_TEST_VARIATIONS, LEAD_FORM, NUMBER, STEP_NAMES } from "../../../constants/app-constants";
import { LeadServiceJava } from "../../../service/lead-service-java-api";
import { FUNNEL_CONFIG } from "../../../utils/funnel/steps.mobile";
import parseUserDropData from "../../../utils/parsers/parse-user-drop-data";
import { setExperiment } from "../../shared/ab-experiment/actions";
import { setData } from "../lead-form/actions";
import Types from "./types";

const setUserDropData = data => ({
    type: Types.SET_USER_DROP_DATA,
    data
});

const setSsrStatus = isSSR => ({
    type: Types.SET_SSR_STATUS,
    isSSR
});

const resetUserDropData = () => ({
    type: Types.RESET_USER_DROP
});

const userDropUpdate = isU2lgaVisible => ({
    type: Types.USER_DROP_UPDATE,
    isU2lgaVisible
});

const setU2lGaFlag = isU2lgaVisible => ({
    type: Types.SET_U2L_GA_FLAG,
    isU2lgaVisible
});

const getRepeatInspectionWidget = (token) => dispatch => {
    return new Promise((resolve, reject) => {
        const params = {
            pvtAuth: token,
            version: "v5",
            customError: true,
            deviceCategory: "m-web"
        };
        LeadServiceJava.fetchBannerWidget(params)
            .then(response => {
                const parsedData = parseUserDropData({
                    widgetDetails: response.data.detail,
                    responseStatus: response.status,
                    isClient: true
                });
                const {experimentType, ...userDropWidgetData} = parsedData;
                dispatch(setUserDropData(userDropWidgetData));
                dispatch(setExperiment("userDrop", experimentType));
                resolve(parsedData);
            })
            .catch(error => {
                const parsedData = parseUserDropData({
                    widgetDetails: {},
                    responseStatus: error.code,
                    isClient: true
                });
                const {experimentType, ...userDropWidgetData} = parsedData;
                dispatch(setUserDropData(userDropWidgetData));
                dispatch(setExperiment("userDrop", experimentType));
                reject(error);
            });
    });
};

export {
    setUserDropData,
    setSsrStatus,
    getRepeatInspectionWidget,
    resetUserDropData,
    setU2lGaFlag,
    userDropUpdate
};
