export const invalidRegNumber = {
    event: "reg_number_entered",
    eventCategory: "homepage",
    eventAction: "wrong_reg_number_entered"
};

export const regNumberEntered = {
    event: "reg_number_entered",
    eventCategory: "homepage",
    eventAction: "reg_number_entered"
};

export const regConfirmDetails = {
    event: "confirm_details",
    eventCategory: "reg_number_flow",
    eventAction: "confirm_details"
};

export  const carNoFocus = {
    event: "car_no_focus",
    eventCategory: "homepage",
    eventAction: "reg_no_type"
};

export const rtoStateSkipped = {
    event: "rto_skipped",
    eventCategory: "homepage",
    eventAction: "skipped",
    eventLabel: "rto_state"
};

export const rtoCodeSkipped = {
    event: "rto_skipped",
    eventCategory: "homepage",
    eventAction: "skipped",
    eventLabel: "rto_code"
};

export const repeatInspection = {
    event: "repeat_inspection",
    eventCategory: "repeat_user_reg"
};

export const repeatUser = {
    event: "repeat_user",
    eventCategory: "Homepage"
};

export const processHighlightRegUsed = {
    event: "process_highlight_reg_used",
    eventCategory: "Homepage",
    eventAction: "process_highlight",
    eventLabel: "reg_used"
};
