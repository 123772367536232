import React from "react";
import close from "./images/cross.svg";
import PropTypes from "prop-types";

const CrossIcon = ({ onClick }) => {
    return (
        <img src={close} alt="cancel" onClick={onClick} />
    );
};

CrossIcon.propTypes = {
    onClick: PropTypes.func
};

export default CrossIcon;
