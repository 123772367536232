export const trackBookingClick = {
    eventCategory: "Buy_B2C_my_booking_screen",
    eventAction: "track_booking_click",
    event: "track_booking_click"
};

export const carsSelectedForComparison = {
    eventAction: "cars_added_to_compare",
    eventCategory: "Buy_used_cars_B2C_Car_Listing_Page",
    event: "cars_added_to_compare"
};

export const cardClickMyBookingScreen = {
    eventCategory: "Buy_B2C_my_booking_screen",
    eventAction: "card_click",
    event: "card_click"
};

export const trackRefundStatusClick = {
    eventCategory: "B2C_my_booking_screen",
    eventAction: "buyer_track_refund_status_click",
    event: "buyer_track_refund_status_click"
};

