export const C2B_LEAD_TYPES = {
    SELL_MARKETING_ORIGINAL: "mkt_page",
    SELL_MARKETING_SELL_ONLINE: "mkt_page-sell-online",
    SEO_ORIGINAL: "seo-page",
    SEO_SELL_ONLINE: "seo-page-sell-online",
    SELL_USED_CARS_ORIGINAL: "original",
    SELL_USED_CARS_SELL_ONLINE: "original-sell-online",
    DEALER_REFERRAL: "dealer_referral",
    SCRAP_CAR: "scrap-car-page",
    CAR_TRUTH_VAS: "car-truth-vas",
    SEO_LUXURY: "seo_luxury"
};

export const ALL_MKT_LEAD_TYPES = ["mkt_page", "mkt_page-sell-online"];
export const MKT_SELL_ONLINE_LEAD_TYPE = ["mkt_page_sell-online", "mkt_v1_sell-online", "mkt_page-sell-online", "seo-page-sell-online"];
export const SELL_ONLINE_LEAD_TYPE = {
    SEO_PAGE_SELL_ONLINE: "seo-page-sell-online"
};

export const LEAD_CREATED_TYPE = {
    NORMAL_LEAD_CREATED: "Msite_normal",
    LEAD_CREATED_ADDRILLA_MAPPING_FOUND: "Msite_mapping_found",
    LEAD_CREATED_ADDRILLA_MAPPING_NOT_FOUND: "Msite_mapping_not_found",
    MANUAL_LEAD_CREATED: "Msite_entered_details_manually"
};
