// eslint-disable-next-line max-len
import {  NUMBER} from "../../constants/app-constants";
const Cr = 10000000;

export const getNormalPrice = (price, allowZero = false, country = "IN") => {
    const countryLocale = {
        IN: "en-IN",
        AE: "en-AE",
        AU: "en-AU"
    };
    return Number((price || (allowZero ? "0" : ""))).toLocaleString(countryLocale[country] || "en-US");
};

// eslint-disable-next-line max-params
export const getPrice = (price, text = false, fixedUpTo = NUMBER.TWO, unitType = "lakh") => {
    let val = Math.abs(price);
    if (val >= Cr) {
        val = `${(val / Cr).toFixed(fixedUpTo)  } ${text ? "cr" : ""}`;
    } else if (val >= NUMBER.ONE_LAKH) {
        val = `${(val / NUMBER.ONE_LAKH).toFixed(fixedUpTo)} ${text ? unitType : ""}`;
    } else if (val <= NUMBER.ONE_LAKH) {
        val = getNormalPrice(price);
    }
    return val;
};
