import HomeIcon from "./icons/home-icon";
import HomeIconFill from "./icons/home-icon-fill";
import BuyIcon from "./icons/buy-icon";
import BuyIconFill from "./icons/buy-icon-fill";
import SellIcon from "./icons/sell-icon";
import SellIconFill from "./icons/sell-icon-fill";
import LoanIcon from "./icons/loan-icon";
import LoanIconFill from "./icons/loan-icon-fill";

export const NAV_ICON_LABELS = {
    HOME: "HOME",
    BUY_CAR: "BUR_CAR",
    SELL_CAR: "SELL_CAR",
    LOAN: "LOAN",
    MY_CARS: "MY_CARS"
};

export const NAV_ICON_LIST = [
    {
        label: NAV_ICON_LABELS.HOME,
        tab: "Home",
        ActiveIcon: HomeIconFill,
        DefaultIcon: HomeIcon,
        pageName: ["buy-used-cars"]
    },
    {
        label: NAV_ICON_LABELS.BUY_CAR,
        tab: "Buy Car",
        ActiveIcon: BuyIconFill,
        DefaultIcon: BuyIcon,
        pageName: ["car-listing"]
    },
    {
        label: NAV_ICON_LABELS.SELL_CAR,
        tab: "Sell Car",
        ActiveIcon: SellIconFill,
        DefaultIcon: SellIcon,
        pageName: ["home", "sell-used-car", "used-car-evaluation"]
    },
    {
        label: NAV_ICON_LABELS.LOAN,
        tab: "Loan",
        ActiveIcon: LoanIconFill,
        DefaultIcon: LoanIcon,
        pageName: ["used-car-loan"]
    }
    // {
    //     label: NAV_ICON_LABELS.MY_CARS,
    //     tab: "My Cars",
    //     ActiveIcon: myCarsFill,
    //     DefaultIcon: myCars,
    //     pageName: "myBookings"
    // }
];
