export const cityHomePageBanner = {
    event: "city_homepage_banner",
    eventCategory: "city_homepage",
    eventAction: "exchange_banner_clicked"
};

export const getCarPriceSticky = {
    event: "get_car_price_sticky",
    eventCategory: "homepage",
    eventAction: "get_car_price_sticky"
};

export const  whatsappHelpClicked = {
    event: "whatsapp_help_clicked",
    eventCategory: "homepage",
    eventAction: "Wapp_help_clicked",
    eventLabel: "City_page"
};

export const scrollStoresAndParkingYards = {
    event: "scroll_stores_and_parking_yards",
    eventCategory: "homepage",
    eventAction: "branch_widget_scrolled",
    eventLabel: "city_page"
};

export const popularCityTextClick = {
    event: "popular_city_text_click",
    eventCategory: "homepage",
    eventAction: "popular_city_clicked"
};

export const regNumberSkip = {
    event: "reg_number_skip",
    eventCategory: "homepage",
    eventAction: "reg_no_skip"
};
export const sellNowStickyCTA = {
    event: "sell_now_sticky_cta",
    eventCategory: "homepage",
    eventAction: "sticky sell now clicked",
    eventLabel: "sell online flow"
};

export const sellNowCTA = {
    event: "sell_now_cta",
    eventCategory: "homepage",
    eventAction: "sell now clicked",
    eventLabel: "sell online flow"
};
