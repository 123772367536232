import Types from "./types";
import { CarService } from "../../../service/car-service";
import { fetchDataSuccess } from "../../shared/meta-tags/actions";
import { VehicleService } from "../../../service/vehicle-service";
import parseVehicleServiceTestimonials from "../../../utils/parsers/parse-vehicle-service-testimonials";

const fetchTestimonialsSuccess = (data) => ({
    type: Types.FETCH_TESTIMONIALS_SUCCESS,
    list: data.reviewData,
    make: data.make,
    model: data.model,
    location: data.location,
    city: data.city
});

const fetchTestimonialsFailure = () => ({ type: Types.FETCH_TESTIMONIALS_FAILURE });

const fetchTestimonials = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {

        CarService.getCmsPageData(payload)
            .then(response => {
                dispatch(fetchTestimonialsSuccess(response.ratedTestimonials));
                if (response.seo) {
                    dispatch(fetchDataSuccess(response.seo));
                }
                resolve(response);
            }).catch(error => {
                dispatch(fetchTestimonialsFailure());
                reject(error);
            });
    });
};

const fetchTestimonialsByCategory = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        VehicleService.fetchTestimonialsByCategory(payload)
            .then(response => {
                const parsedData = {reviewData: parseVehicleServiceTestimonials(response.data.detail)};
                dispatch(fetchTestimonialsSuccess(parsedData));
                resolve(response);
            }).catch(error => {
                dispatch(fetchTestimonialsFailure());
                reject(error);
            });
    });
};

const setIsSSR = (isSSR) => ({
    type: Types.SET_IS_SSR,
    isSSR
});

export {
    fetchTestimonials,
    setIsSSR,
    fetchTestimonialsByCategory
};
