import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import SellMarketingRevampHomeBanner from "./component";

const mapStateToProps = ({
    meta: { mainHeading, homePageBanner }
}) => {
    return {
        mainHeading,
        homePageBanner
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SellMarketingRevampHomeBanner));

