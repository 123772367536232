import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "./styles.css";
import withLayout from "../../../components/mobile/layout/component";
import PropTypes from "prop-types";
import NotFound from "../../../components/shared/page-not-found";
import { HTTP_CODES, FALLBACK_C2B_CITY_ID } from "../../../constants/app-constants";
import { updateLeadSource } from "../../../components/mobile/settings/actions";
import ErrorHandler from "../../../components/shared/with-error-handler";
import SeoCarOverview from "../../../components/mobile/seo-car-overview";
import Skeleton from "./skeleton";
import { fetchSEODetails } from "../../../components/mobile/seo-car-overview/actions";
import { getSeoCarName } from "../../../utils/helpers/seo-car-config";
import { withRouter } from "react-router";
import SellUsedCarSeo from "../../../components/mobile/sell-used-car-seo";
import withReducer from "../../../components/shared/with-reducer";
import injectableReducers from "./injectable";

const Seo = ({
    status,
    updateLeadSourceConnect,
    fetchSEODetailsConnect,
    isCarDetailLoading,
    location,
    isSSR,
    currentSEOSelectedCity,
    isNewSeoPage
}) => {

    const seoCarName = getSeoCarName(location.pathname);
    const { value: cityId = "" } = currentSEOSelectedCity || {};

    useEffect(() => {
        updateLeadSourceConnect("seo-page");
    }, [updateLeadSourceConnect]);

    useEffect(() => {
        if (!isSSR) {
            fetchSEODetailsConnect({ slug: seoCarName, city: cityId || FALLBACK_C2B_CITY_ID });
            window.scrollTo(0, 0);
        }
        // if (isSSR) updateSSRStatusConnect(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSSR, seoCarName, cityId]);

    if (status === HTTP_CODES.NOT_FOUND || status === HTTP_CODES.PAGE_NOT_FOUND) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            {isNewSeoPage ? <ErrorHandler>
                {isCarDetailLoading ? <Skeleton /> : <SeoCarOverview />}
            </ErrorHandler> : (
                <SellUsedCarSeo topCityKey={"appointment_city"}/>
            )
            }
        </React.Fragment>
    );
};

Seo.propTypes = {
    match: PropTypes.object.isRequired,
    status: PropTypes.bool,
    updateLeadSourceConnect: PropTypes.func,
    fetchSEODetailsConnect: PropTypes.func,
    isCarDetailLoading: PropTypes.bool,
    location: PropTypes.object,
    isSSR: PropTypes.bool,
    currentSEOSelectedCity: PropTypes.string,
    history: PropTypes.object,
    isNewSeoPage: PropTypes.bool
};

const mapStateToProps = ({
    meta: { status, isNewSeoPage },
    seoCarOverview: { isLoading: isCarDetailLoading, isSSR, currentSEOSelectedCity }
}) => ({
    status,
    isCarDetailLoading,
    isSSR,
    currentSEOSelectedCity,
    isNewSeoPage
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateLeadSourceConnect: updateLeadSource,
    fetchSEODetailsConnect: fetchSEODetails

}, dispatch);

export default withReducer(injectableReducers)(() => ({}), dispatch => ({ dispatch }))(withRouter(
    withLayout(
        connect(mapStateToProps, mapDispatchToProps)(Seo),
        true,
        true,
        { headerProps: { alternateHeader: true, showCallIcon: true }, showBottomNavbar: true, bottomSticky: true }
    )));
