import React from "react";

const LoanIconFill = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clipPath="url(#clip0_5155_122452)">
                <path d="M10.9527 12.3716C14.8001 12.3589 17.9237 13.3896 17.9263 14.6739C17.9314 15.9581 14.814 17.0076 10.9666 17.0178C7.3332 17.0305 4.34541 16.1113 4.02426 14.9288C4.00167 14.8589 3.99207 14.786 3.99296 14.7155C3.98787 13.4339 7.10517 12.3817 10.9527 12.3716Z" fill="white" />
                <path d="M17.9254 14.6741L17.956 17.3255C17.9595 17.7874 17.6957 18.2115 17.2803 18.4122C11.7406 21.0765 6.98394 19.767 5.17101 19.0698C4.69405 18.8857 4.35982 18.4526 4.31091 17.9448L4.02344 14.929C4.34459 16.1115 7.33238 17.0307 10.9657 17.0179C14.8132 17.0078 17.9306 15.9582 17.9254 14.6741Z" fill="#EF6E0B" />
                <path d="M17.9286 14.6738C17.9337 15.958 14.8164 17.0076 10.969 17.0203C7.11886 17.0305 3.99523 15.9998 3.99268 14.7155C3.99013 13.4313 7.10489 12.3818 10.9524 12.3716C14.7998 12.3589 17.9234 13.3897 17.9286 14.6738Z" stroke="#0F0F10" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M4.02539 14.9292L4.31541 17.9424C4.36432 18.4502 4.69861 18.8858 5.1729 19.0675C6.98583 19.7647 11.729 21.4373 17.2687 18.773C17.6841 18.5723 17.9478 18.1482 17.9444 17.6863L17.9299 14.6716" stroke="#0F0F10" strokeWidth="1.3" strokeLinejoin="round" />
                <circle cx="10.9602" cy="8.7854" r="6.00161" fill="white" stroke="#0F0F10" strokeWidth="1.3" />
                <path d="M13.5615 6.21252C13.5615 6.43588 13.3783 6.63061 13.1434 6.63061H12.0037C12.124 6.81961 12.2214 7.02579 12.2729 7.25488H13.1434C13.3668 7.25488 13.5615 7.44388 13.5615 7.67297C13.5615 7.90206 13.3783 8.09106 13.1434 8.09106H12.2729C12.1011 8.86423 11.4367 9.45414 10.6349 9.54005C10.5776 9.5515 10.5089 9.5515 10.4345 9.5515H9.98201L11.305 10.8802L12.187 11.7679C12.3531 11.934 12.3531 12.1975 12.187 12.3636C12.1068 12.438 11.9923 12.4839 11.8892 12.4839C11.7861 12.4839 11.6715 12.438 11.5914 12.3636L8.66474 9.43123C8.5502 9.30523 8.51583 9.12769 8.5731 8.96732C8.64183 8.81269 8.79074 8.7096 8.96828 8.7096H10.4287C10.8525 8.7096 11.2191 8.44615 11.3852 8.0796H8.77356C8.55019 8.0796 8.35547 7.8906 8.35547 7.66151C8.35547 7.43242 8.53874 7.24343 8.77356 7.24343V7.25488H11.3966C11.2305 6.88833 10.864 6.63061 10.4345 6.63061H8.77356C8.55019 6.63061 8.35547 6.43588 8.35547 6.21252C8.35547 5.98916 8.53874 5.79443 8.77356 5.79443H13.1434C13.3668 5.79443 13.5615 5.98343 13.5615 6.21252Z" fill="#0F0F10" />
            </g>
            <defs>
                <clipPath id="clip0_5155_122452">
                    <rect width="21" height="21" fill="white" transform="translate(0.5 0.812988)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LoanIconFill;
