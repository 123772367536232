import React, {Fragment} from "react";
import styles from "./skeleton.css";

const Skeleton = () => {
    return (
        <Fragment>
            <div styleName={"styles.seoNav"}>
                <ul>
                    <li className={"shimmer"}>&nbsp;</li>
                    <li className={"shimmer"}>&nbsp;</li>
                    <li className={"shimmer"}>&nbsp;</li>
                    <li className={"shimmer"}>&nbsp;</li>
                    <li className={"shimmer"}>&nbsp;</li>
                </ul>
            </div>
            <div className="container">
                <div styleName={"styles.outer"}>
                    <div className="row">
                        <div className="col-12">
                            <div className="shimmer" styleName={"styles.shimmerHeading"}/>
                            <div className="shimmer" styleName={"styles.shimmerBanner"}/>
                        </div>
                        <div className="col-12">
                            <div className="shimmer" styleName={"styles.shimmerHeading"}/>
                            <div className="shimmer" styleName={"styles.shimmerBanner"}/>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Skeleton;
