export const editClicked = {
    event: "edit_homepage",
    eventCategory: "homepage_revamp_event",
    eventAction: "edit_clicked"
};

export const editManualClicked = {
    event: "enter_car_detail_manually",
    eventCategory: "Homepage",
    eventAction: "manual_flow"
};

export const u2lCtaClicked = {
    event: "U2L_homepage_exp",
    eventCategory: "homepage_exp_click",
    eventAction: "user_clicked_continue"
};
