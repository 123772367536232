import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const SemiModal = ({
    children,
    onOverlayClick
}) => {
    return (
        <React.Fragment>
            <div onClick={onOverlayClick} styleName="styles.overlay" />
            <div styleName="styles.semiModal">
                {children}
            </div>
        </React.Fragment>
    );
};
SemiModal.propTypes = {
    children: PropTypes.object,
    onOverlayClick: PropTypes.func
};
export default SemiModal;
