import { NUMBER } from "../../constants/app-constants";

export default (file = "") => {
    const splitFileName = file && file.split(".") || [];
    if (splitFileName.length <= NUMBER.ONE) {
        return "";
    } else {
        return splitFileName.pop();
    }
};
