import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { BottomSheet } from "@cars24/turbo-web/lib/bottom-sheet";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import InfoIcon from "./images/info-icon.svg";
import { Button } from "@cars24/turbo-web/lib/button";
import { Text } from "@cars24/turbo-web/lib/text";

const RcTrasferBottomSheet = ({content, isBottomsheet, setIsBottomsheet}) => {
    const { tokens } = useTokens();
    const {
        makeName,
        modelName,
        redirectionUrl
    } = content || {};
    const redirect = () => {
        window.location.href = redirectionUrl;
    };
    return (
        <BottomSheet overlayColor={tokens.background_surface_overlay_primary} height="fit-content" isOpen={isBottomsheet} onClose={setIsBottomsheet} onOverlayClick={setIsBottomsheet}>
            <img src={InfoIcon} alt="info" styleName={"styles.infoIcon"} />
            <div styleName={"styles.rcInfoText"}>
                <Text
                    align="center"
                    as="p"
                    color="#0F0F10"
                    fontWeight="semibold"
                    size="300"
                    transform="none"
                >
                     Planning to sell your <br/>{makeName} {modelName} already?
                </Text>
                <Text
                    align="center"
                    as="p"
                    color="#717272"
                    fontWeight="regular"
                    size="250"
                    transform="none"
                >
                     RC transfer for this car is still under process. You can sell this car once RC transfer is complete.
                </Text>
            </div>
            <div styleName={"styles.getAssistance"}>
                <Button
                    label="Get assistance"
                    onClick={() => redirectionUrl && redirect()}
                    variant="primary"
                />
            </div>
        </BottomSheet>
    );
};
RcTrasferBottomSheet.propTypes = {
    content: PropTypes.object,
    isBottomsheet: PropTypes.bool,
    setIsBottomsheet: PropTypes.func,
    isGSCarListed: PropTypes.bool
};
export default RcTrasferBottomSheet;
