/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect, useState } from "react";
import styles from  "./styles.css";
import PropTypes from "prop-types";
import EvCars from "./images/ev-cars.svg";
import RightTick from "./images/right-tick.svg";
import BuyUsedCar from "./images/buy-used-car.svg";
import RightArrow from "./images/right-arrow.svg";
import Loan from "./images/loan.svg";
import PayChallan from "./images/pay-challan.svg";
import { Text } from "@cars24/turbo-web/lib/text";
import { BottomSheet } from "@cars24/turbo-web/lib/bottom-sheet";
import { Button } from "@cars24/turbo-web/lib/button";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import Modal from "../../shared/modal";
import { Label } from "@cars24/turbo-web/lib/label";
import { sendCustomGaEvents } from "../../../tracking";
import { NUMBER} from "../../../constants/app-constants";
import { hamburgerLogin } from "../login-panel-cta/tracking";
import LottiePlayer from "../../shared/lottie-player";

const dataMapper = [
    {image: BuyUsedCar, text: "Buy used car", url: "/buy-used-cars"},
    {image: PayChallan, text: "Pay eChallan", url: "/traffic-challan"},
    {image: Loan, text: "Loan against car", url: "/used-car-loan"}
];
const STOP_EV = "b2c-stop-ev-appointment-banner";

const EvVehicles = ({isBottomsheet, onClose, history, sendClevertapEventsConnect, event, secureToken, token,
    isLoggedIn,
    otp,
    setIsLoading,
    phoneNumber,
    isLoading,
    errorMessage,
    onMobileNumberChange,
    getOtpHandler,
    onOtpVerify,
    onOtpChange,
    resendOTPHandler,
    setOtp,
    setIsAutoFilledOTP,
    showOtpField,
    saveUserConsentConnect,
    resetTruecallerDetailsConnect,
    setIsTrueCallerConnect,
    setShowOtpField,
    isBackRequired = true,
    isHome = false,
    getEvDataConnect
}) => {
    const { tokens } = useTokens();
    const [modal, setIsmodal] = useState(false);
    const [showLoginScreen, setShowLoginScreen] = useState(false);
    const [LoginAuthenticatorWithTruecallerComp, setLoginAuthenticatorWithTruecallerComp] = useState(null);
    const [otpVerified, setOtpVerified] = useState(false);
    const [pageSection, setPageSection] = useState({});
    const [allowWhatsapp, setAllowWhatsapp] = useState(true);
    const [isModalOverride, setIsModalOverride] = useState(false);

    const {title = "We will be ready for Electric cars soon!", description = "We’re building our ecosystem to support EVs. It’s a new, exciting space for us.", ctaText = "Notify me", image = {}, meta = {}} = pageSection || {};
    const { secondaryCTAText = "Go back to home", showLottie = false} = meta || {};

    const onWhatsappChange = (e) => {
        const { target: { checked } } = e;
        setAllowWhatsapp(checked);
    };

    useEffect(() => {
        getEvDataConnect(STOP_EV).then((response) => {
            if (response.data.data && response.data.data.pageSections.length > 0) {
                setPageSection(response.data.data.pageSections[0].pageSection);
            }
        }).catch(() => {
            onClose();
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (otpVerified) {
            setIsmodal(true);
        }
    }, [otpVerified]);
    const saveConsent = () => {
        const params = {
            chat: allowWhatsapp
        };
        saveUserConsentConnect(phoneNumber, params);
    };

    const onBackHandler = () => {
        setShowOtpField(false);
        setShowLoginScreen(false);
    };

    const onBack = () => {
        setIsmodal(true);
        setShowOtpField(false);
        setShowLoginScreen(false);

    };

    const onTruecallerSuccess = () => {
        setIsTrueCallerConnect(true);
        onBack();
    };

    const otpVerificationHandler = async () => {
        if (otp && otp.length === NUMBER.FOUR) {
            onOtpVerify().then(async () => {
                saveConsent();
                setOtpVerified(true);
                onBack();
            });
        }
    };

    const importLoginAuthenticatorWithTrueCaller = () => {
        if (LoginAuthenticatorWithTruecallerComp === null) {
            import(/* webpackChunkName: "login-authenticator-with-truecaller" */ "../login-authenticator-with-truecaller").then((comp) => {
                setLoginAuthenticatorWithTruecallerComp(() => comp.default);
                setIsLoading(false);
                setShowLoginScreen(true);
            });
        } else {
            setIsLoading(false);
            setShowLoginScreen(true);
        }
    };

    const handleLoginSignup = async () => {
        importLoginAuthenticatorWithTrueCaller();
        sendCustomGaEvents(hamburgerLogin);
    };
    const onModalClose = () => {
        onClose();
        setIsmodal(false);
        setIsModalOverride(false);
    };

    if (showLoginScreen) {
        return (
            <React.Fragment>
                <LoginAuthenticatorWithTruecallerComp
                    showWhatsappConsent={true}
                    allowWhatsapp={allowWhatsapp}
                    onWhatsappChange={onWhatsappChange}
                    otp={otp}
                    onClose={onBackHandler}
                    onBack={() => { setShowOtpField(false); }}
                    mobile={phoneNumber}
                    onChange={onMobileNumberChange}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    onOtpChange={onOtpChange}
                    onOtpVerify={otpVerificationHandler}
                    showOtpField={showOtpField}
                    onResendClick={resendOTPHandler}
                    setOtp={setOtp}
                    setIsAutoFilledOTP={setIsAutoFilledOTP}
                    getOtpHandler={() => { getOtpHandler(phoneNumber); }}
                    setShowOtpField={setShowOtpField}
                    onTruecallerSuccess={onTruecallerSuccess}
                    resetTruecallerDetailsConnect={resetTruecallerDetailsConnect}
                />
            </React.Fragment>
        );
    }
    return (
        <div>
            { !modal && <BottomSheet
                onOverlayClick={onClose}
                overlayColor={tokens.background_surface_overlay_primary} height="fit-content" isOpen={isBottomsheet && !modal} onClose={onClose} id={"evBottomSheet"}>
                <div>
                    <div styleName={"styles.carWrapper"}>
                        {
                            image && image.url &&  showLottie ? <LottiePlayer url={image.url} width="100%" height="178x"/> : <img src={image.url || EvCars} alt={image.alternativeText || "EV"}/>
                        }
                    </div>
                    <div styleName={"styles.evTextWrapper"}>
                        <Text
                            align="center"
                            as="h4"
                            color="#0F0F10"
                            fontWeight="bold"
                            size="500"
                            transform="none"
                        >
                            {title}
                        </Text>
                        <Text
                            align="center"
                            as="h4"
                            color="#717272"
                            fontWeight="regular"
                            size="250"
                            transform="none"
                        >
                            {description}
                        </Text>
                    </div>
                    <div styleName={"styles.buttonWrapper"}>
                        <Button
                            label={ctaText}
                            onClick={() => {
                                sendClevertapEventsConnect({
                                    token,
                                    secureToken,
                                    payload: {
                                        eventName: "C2B_EV_Notify_start",
                                        ...event,
                                        phoneNumber,
                                        source: "m-web"
                                    }
                                });
                                if (!isLoggedIn) {
                                    handleLoginSignup();
                                } else {
                                    setIsModalOverride(true);
                                    setIsmodal(true);
                                }
                            }}
                            variant="primary"
                            suffixIcon={<img src={RightArrow} alt="right arrow"/>}
                        />
                        {isBackRequired && <Button
                            label={secondaryCTAText}
                            onClick={() => history.push("/sell-used-cars/")}
                            variant="secondary"
                        />}
                    </div>
                </div>
            </BottomSheet>}

            <Modal isOpen={modal} id={isModalOverride ? "evModal" : ""}>
                <div styleName={"styles.modalWrapper"}>
                    <div styleName={"styles.notifyText"}>
                        <img src={RightTick} alt="right tick"/>
                        <div styleName={"styles.text"}>
                            <Text
                                align="center"
                                as="h4"
                                color="#0F0F10"
                                fontWeight="bold"
                                size="450"
                                transform="none"
                            >
                            We will notify you!
                            </Text>
                            <Text
                                align="center"
                                as="p"
                                color="#717272"
                                fontWeight="regular"
                                size="250"
                                transform="none"
                            >
                            As soon as we’re ready for your electric car.
                            </Text>
                        </div>
                    </div>
                    <div styleName={"styles.tabsWrapper"}>
                        <Text
                            align="center"
                            as="p"
                            color="#0F0F10"
                            fontWeight="semibold"
                            size="200"
                            transform="none"
                        >
                            Meanwhile, explore other services we offer
                        </Text>
                        <div styleName={"styles.tabs"}>
                            {dataMapper.map((item) => {
                                return (<div styleName={"styles.tabscard"} key={item.text} onClick={() => history.push(item.url)}>
                                    <img src={item.image} alt={item.text} width="42px" height="42px" />
                                    <Label level={4} color={tokens.text_default_text_primary}>{item.text}</Label>
                                </div>);
                            })}
                        </div>
                    </div>
                    <div styleName={"styles.backtoHomeCta"}>
                        <Button
                            label="Go back to home"
                            onClick={ isHome ? onModalClose  : () => history.push("/sell-used-cars/")}
                            variant="secondary"
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};
EvVehicles.propTypes = {
    isBottomsheet: PropTypes.bool,
    onClose: PropTypes.func,
    history: PropTypes.object,
    sendClevertapEventsConnect: PropTypes.func,
    event: PropTypes.string,
    token: PropTypes.string,
    secureToken: PropTypes.string,
    pageSection: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    otp: PropTypes.string,
    phoneNumber: PropTypes.string,
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string,
    onMobileNumberChange: PropTypes.func,
    getOtpHandler: PropTypes.func,
    onOtpVerify: PropTypes.func,
    onOtpChange: PropTypes.func,
    resendOTPHandler: PropTypes.func,
    setOtp: PropTypes.func,
    setIsAutoFilledOTP: PropTypes.func,
    showOtpField: PropTypes.bool,
    saveUserConsentConnect: PropTypes.func,
    resetTruecallerDetailsConnect: PropTypes.func,
    setIsTrueCallerConnect: PropTypes.func,
    setShowOtpField: PropTypes.func,
    setIsLoading: PropTypes.func,
    updateRecommendationTransferStatusConnect: PropTypes.func,
    isBackRequired: PropTypes.bool,
    isHome: PropTypes.bool,
    getEvDataConnect: PropTypes.func
};
export default EvVehicles;
