/* eslint-disable consistent-return */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect, useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { sendCustomGaEvents } from "../../../tracking";
import useAppointmentWidget from "./useAppointmentWidget";
import Card from "./card";
import { USER_JOURNEY_STEPS } from "../../../constants/home-widget-constants";
import { AB_TEST_VARIATIONS, NUMBER } from "../../../constants/app-constants";
import { UPCOMING_APPOINTMENT_TYPE } from "../../mobile/upcoming-appointments/selector";
import parseU2lData from "../../../utils/parsers/parse-u2l-data";
import parseCookie from "../../../utils/helpers/parse-cookie";
import { getList } from "../user-journey-pre-lead/selector";
import Skeleton from "./skeleton";

const StageCard = ({
    leadData,
    widgetData,
    user,
    cardData,
    stage,
    history,
    price,
    maxPrice,
    minPrice,
    setFormDataConnect,
    setStepConnect,
    activateLastStepConnect,
    details,
    isLoading,
    isUserDropBeforeLogin = false,
    isUserDropAfterLogin = false,
    fetchDataSuccessConnect,
    fetchDataConnect,
    userToLeadDropExperiment,
    fetchLeadDetailsConnect,
    fetchAppointmentDetailsConnect,
    popular,
    userDropWidget,
    otp,
    phoneNumber,
    errorMessage,
    onMobileNumberChange,
    getOtpHandler,
    onOtpVerify,
    onOtpChange,
    resendOTPHandler,
    setOtp,
    setIsAutoFilledOTP,
    showOtpField,
    saveUserConsentConnect,
    resetTruecallerDetailsConnect,
    setIsTrueCallerConnect,
    updateStatsigConfigConnect,
    userCityId,
    updateStatsigTimeStampConnect,
    setShowOtpField,
    luxuryGoodPriceExperimentFlag,
    webQuoteRevampExperimentFlag
}) => {
    const { isU2lgaVisible } = userDropWidget || {};

    const { secureToken, isLoggedIn, isFetched, gaId } = user || {};
    const [sourceType, setSourceType] = useState(null);
    const [leadResponse, setLeadResponse] = useState(null);
    const { year, token } = leadData || {};
    const isYearSelected = (year || {}).id;
    const [userYear, setuserYear] = useState(year.id);
    const cookieToken = parseCookie("sell_online_lead_token");
    const [isFetching, setIsFetching] = useState(false);
    const [appointmentAlreadyExist, setAppointmentAlreadyExist] = useState(false);
    const [apptToken, setApptToken] = useState("");
    useEffect(() => {
        const { eventLabel = "" } = getList(leadData || {});
        if ((cookieToken || token || apptToken) && !userYear) {
            setIsFetching(true);
            const leadToken = cookieToken || token || apptToken;
            fetchLeadDetailsConnect(leadToken)
                .then((response) => {
                    setuserYear(response.year_id);
                    setLeadResponse({ ...response, leadToken });
                    setSourceType(response.lead_type);
                    setIsFetching(false);
                    if (userToLeadDropExperiment === AB_TEST_VARIATIONS.CONTROL) {
                        if (
                            [
                                USER_JOURNEY_STEPS.BOOKED,
                                USER_JOURNEY_STEPS.BOOKED_NARROW,
                                USER_JOURNEY_STEPS.BOOKED_SO,
                                USER_JOURNEY_STEPS.LEAD,
                                USER_JOURNEY_STEPS.LEAD_NARROW,
                                USER_JOURNEY_STEPS.LEAD_QUESTION,
                                USER_JOURNEY_STEPS.STALE_LEAD_QUESTION
                            ].includes(response.stage)
                        ) {
                            const { allCardInfo } = parseU2lData(response);
                            sendCustomGaEvents({
                                event: "U2L_homepage_exp",
                                eventCategory: "U2L_homepage_exp",
                                eventAction: "control",
                                ...(allCardInfo && { eventLabel: allCardInfo }),
                                customdimension148: gaId
                            });
                        } else {
                            sendCustomGaEvents({
                                event: "U2L_homepage_exp",
                                eventCategory: "U2L_homepage_exp",
                                eventAction: "control",
                                eventLabel: "edgeCase",
                                customdimension148: gaId
                            });
                            if (eventLabel) {
                                sendCustomGaEvents({
                                    event: "U2L_homepage_exp",
                                    eventCategory: "homepage_exp_click",
                                    eventAction: "user_viewed",
                                    eventLabel
                                });
                            }
                        }
                    }
                })
                .catch(() => {
                    setIsFetching(false);
                });
        } else if (isYearSelected && !userYear) {
            setuserYear(year.id);
            if (
                userToLeadDropExperiment === AB_TEST_VARIATIONS.CONTROL &&
        isU2lgaVisible
            ) {
                sendCustomGaEvents({
                    event: "U2L_homepage_exp",
                    eventCategory: "U2L_homepage_exp",
                    eventAction: "control",
                    eventLabel: "pre-lead",
                    customdimension148: gaId
                });
                if (eventLabel) {
                    sendCustomGaEvents({
                        event: "U2L_homepage_exp",
                        eventCategory: "homepage_exp_click",
                        eventAction: "user_viewed",
                        eventLabel
                    });
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, apptToken, isYearSelected, isU2lgaVisible, userYear]);

    const handleApptToken = (response = {}) => {
        const { data = {} } = response || {};
        const { appointmentData = {} } = data || {};
        setApptToken(appointmentData.token || "");
    };

    useEffect(() => {
        if (
            !cookieToken &&
      widgetData &&
      widgetData.data &&
      widgetData.data.length === NUMBER.ZERO
        ) {
            if (secureToken) {
                setIsFetching(true);
                fetchDataConnect(token, secureToken)
                    .then((response) => {
                        handleApptToken(response);
                        setIsFetching(false);
                    })
                    .catch(() => {
                        setIsFetching(false);
                    });
            }
        } else {
            handleApptToken(widgetData.data);
            setAppointmentAlreadyExist(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, cookieToken, secureToken]);

    useEffect(() => {
        if (
            widgetData &&
      widgetData.data &&
      widgetData.data.data &&
      widgetData.data.data.type &&
      UPCOMING_APPOINTMENT_TYPE.includes(widgetData.data.data.type)
        ) {
            setSourceType(widgetData.data.data.appointmentData.lead_type);
            setAppointmentAlreadyExist(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetData]);

    useEffect(() => {
        if (!isLoggedIn && isFetched) fetchDataSuccessConnect([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetched, isLoggedIn]);

    const {
        goToSteps,
        LoginAuthenticatorWithTruecallerComp,
        loginCallback,
        otpVerificationHandler,
        onTruecallerSuccess,
        onWhatsappChange,
        allowWhatsapp,
        onBack,
        showLoginScreen
    } = useAppointmentWidget({
        leadData,
        leadResponse,
        appointmentAlreadyExist,
        fetchAppointmentDetailsConnect,
        popular,
        sourceType,
        onOtpVerify,
        setShowOtpField,
        setIsTrueCallerConnect,
        updateStatsigConfigConnect,
        saveUserConsentConnect,
        phoneNumber,
        updateStatsigTimeStampConnect,
        otp,
        userCityId,
        gaId
    });

    if (showLoginScreen) {
        return (
            <React.Fragment>
                <LoginAuthenticatorWithTruecallerComp
                    showWhatsappConsent={true}
                    allowWhatsapp={allowWhatsapp}
                    onWhatsappChange={onWhatsappChange}
                    otp={otp}
                    onClose={onBack}
                    onBack={() => {
                        setShowOtpField(false);
                    }}
                    mobile={phoneNumber}
                    onChange={onMobileNumberChange}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    onOtpChange={onOtpChange}
                    onOtpVerify={otpVerificationHandler}
                    showOtpField={showOtpField}
                    onResendClick={resendOTPHandler}
                    setOtp={setOtp}
                    setIsAutoFilledOTP={setIsAutoFilledOTP}
                    getOtpHandler={() => {
                        getOtpHandler(phoneNumber);
                    }}
                    setShowOtpField={setShowOtpField}
                    onTruecallerSuccess={onTruecallerSuccess}
                    resetTruecallerDetailsConnect={resetTruecallerDetailsConnect}
                />
            </React.Fragment>
        );
    }
    if (isFetching) return <Skeleton />;
    return (
        <>
            <Card
                cardData={cardData}
                stage={stage}
                history={history}
                price={price}
                maxPrice={maxPrice}
                minPrice={minPrice}
                setFormDataConnect={setFormDataConnect}
                setStepConnect={setStepConnect}
                activateLastStepConnect={activateLastStepConnect}
                details={details}
                isLoading={isLoading}
                isUserDropBeforeLogin={isUserDropBeforeLogin}
                isUserDropAfterLogin={isUserDropAfterLogin}
                goToSteps={goToSteps}
                loginCallback={loginCallback}
                luxuryGoodPriceExperimentFlag={luxuryGoodPriceExperimentFlag}
                leadData={leadData}
                webQuoteRevampExperimentFlag={webQuoteRevampExperimentFlag}
            />
        </>
    );
};

StageCard.propTypes = {
    cardData: PropTypes.object,
    details: PropTypes.object,
    stage: PropTypes.string,
    history: PropTypes.history,
    price: PropTypes.string,
    maxPrice: PropTypes.string,
    minPrice: PropTypes.string,
    setFormDataConnect: PropTypes.func,
    setStepConnect: PropTypes.func,
    activateLastStepConnect: PropTypes.func,
    isLoading: PropTypes.bool,
    isUserDropBeforeLogin: PropTypes.bool,
    isUserDropAfterLogin: PropTypes.bool,
    user: PropTypes.object,
    widgetData: PropTypes.any,
    leadData: PropTypes.object,
    fetchDataConnect: PropTypes.func,
    fetchLeadDetailsConnect: PropTypes.func,
    fetchDataSuccessConnect: PropTypes.func,
    userToLeadDropExperiment: PropTypes.string,
    userDropWidget: PropTypes.object,
    fetchAppointmentDetailsConnect: PropTypes.func,
    popular: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    logoutIcon: PropTypes.any,
    mobile: PropTypes.string,
    otp: PropTypes.string,
    phoneNumber: PropTypes.string,
    errorMessage: PropTypes.string,
    onMobileNumberChange: PropTypes.func,
    getOtpHandler: PropTypes.func,
    onOtpVerify: PropTypes.func,
    onOtpChange: PropTypes.func,
    resendOTPHandler: PropTypes.func,
    setOtp: PropTypes.func,
    setIsAutoFilledOTP: PropTypes.func,
    showOtpField: PropTypes.bool,
    saveUserConsentConnect: PropTypes.func,
    resetTruecallerDetailsConnect: PropTypes.func,
    setIsTrueCallerConnect: PropTypes.func,
    updateStatsigConfigConnect: PropTypes.func,
    userCityId: PropTypes.string,
    updateStatsigTimeStampConnect: PropTypes.func,
    setShowOtpField: PropTypes.func,
    luxuryGoodPriceExperimentFlag: PropTypes.string,
    webQuoteRevampExperimentFlag: PropTypes.string
};

export default StageCard;
