import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_APP_NUDGE_HEADER_VALUE
    SET_NUDGE_POPUP_VALUE
    SET_SELL_APP_NUDGE_VALUE
`,
    {
        prefix: "appNudgeHeader/"
    }
);
