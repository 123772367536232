/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import getDeviceOs from "../../../utils/helpers/get-device-os";
import saveCookie from "../../../utils/helpers/save-cookie";
import DownloadAppHeaderSell from "../download-app-strip-sell";
import PropTypes from "prop-types";
import { NUMBER } from "../../../constants/app-constants";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import parseCookie from "../../../utils/helpers/parse-cookie";
import { sendCustomGaEvents } from "../../../tracking";
import { variantToEventNameMapping } from "./constants";

const deviceType = typeof window !== "undefined" && getDeviceOs();

const AppNudgeHeaderSell = ({
    route,
    appDownloadBannerExperiment,
    updateSellAppNudgeFlagsConnect,
    statsigText,
    isSellAppNudgeVisible,
    gaId
}) => {
    const { name: routeName } = route || {};

    const onClose = async () => {
        await yieldToMain();
        saveCookie("hide_app_nudge_header_sell", true, NUMBER.ONE);
        updateSellAppNudgeFlagsConnect(false);

        await yieldToMain();

        sendCustomGaEvents({
            event: "app_download_banner_u2l",
            eventAction: "app_download_dismissed",
            eventLabel: gaId,
            eventCategory: routeName
        });
    };

    const onClickDownload = async () => {
        await yieldToMain();
        sendCustomGaEvents({
            event: "app_download_banner_u2l",
            eventAction: "app_download_clicked",
            eventLabel: gaId,
            eventCategory: routeName
        });
    };

    useEffect(() => {
        const appNudgeCookie = parseCookie("hide_app_nudge_header_sell");
        if (!appNudgeCookie) {
            updateSellAppNudgeFlagsConnect(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (appDownloadBannerExperiment && gaId) {
            sendCustomGaEvents({
                event: "app_download_banner_u2l",
                eventAction: variantToEventNameMapping[appDownloadBannerExperiment],
                eventLabel: gaId,
                eventCategory: routeName
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gaId]);

    return (
        isSellAppNudgeVisible && (
            <DownloadAppHeaderSell
                statsigText={statsigText}
                deviceType={deviceType || ""}
                onClose={onClose}
                onClickDownload={onClickDownload}
            />
        )
    );
};

AppNudgeHeaderSell.propTypes = {
    route: PropTypes.object,
    appDownloadBannerExperiment: PropTypes.any,
    updateSellAppNudgeFlagsConnect: PropTypes.func,
    statsigText: PropTypes.string,
    gaId: PropTypes.string,
    isSellAppNudgeVisible: PropTypes.bool
};

export default AppNudgeHeaderSell;
