import React, { useCallback } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import HomeBannerRevamp from "./../home-banner-revamp";
import ArrowIcon from "../../shared/arrow";
import { AB_TEST_VARIATIONS, NUMBER } from "../../../constants/app-constants";
import { moreBrandsClicked, moreBrandsScrolled } from "./tracking";
import { sendCustomGaEvents } from "../../../tracking";
import { InView } from "react-intersection-observer";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import CarBrandList from "../car-brand-list";

const HomeBannerV2 = ({
    isMarketing,
    handleClickCount,
    safetyBannerTop,
    onSelection,
    handleOnClick,
    ctaText = "GET CAR PRICE",
    heading,
    mainHeading,
    subHeading,
    sellMarketingExperiment,
    homePageType
}) => {

    const handleViewAllBrands = useCallback(async () => {
        handleOnClick();
        await yieldToMain();
        sendCustomGaEvents(moreBrandsClicked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGaEvent = useCallback((inView) => {
        if (!inView) {
            sendCustomGaEvents({
                ...moreBrandsScrolled,
                ...(sellMarketingExperiment === AB_TEST_VARIATIONS.CONTROL && { eventLabel: AB_TEST_VARIATIONS.CONTROL.toLowerCase() })
            });
        }
    }, [sellMarketingExperiment]);

    const getViewAllBrands = useCallback(() => {
        return (
            <div styleName={"styles.allBrands"} onClick={handleViewAllBrands}>
                view all brands
                <ArrowIcon />
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div styleName={"styles.sellOnlineexperiments"}>
            <HomeBannerRevamp
                isMarketing={isMarketing}
                allowFixedInput={isMarketing ? false : true}
                isFromSellOnlineFlow={true}
                isRevamped={true}
                handleClickCount={handleClickCount}
                safetyBannerTopRef={safetyBannerTop}
                safetyBannerTopFlag={true}
                ctaText={ctaText}
                heading={heading}
                mainHeading={mainHeading}
                subHeading={subHeading}
                homePageType={homePageType}
            />
            <div styleName={"styles.brandsWrapper"}>
                <div styleName={"styles.homeBrandsWrapper"}>
                    <div styleName={`styles.sellBannerWrap styles.sellBannerHomeInspection `}>
                        <div styleName={"styles.brandListHome"}>
                            <CarBrandList
                                btnCallback={handleOnClick}
                                useParentCallback={(selectedBrand) => onSelection(selectedBrand)}
                                visiblePopularBrands={NUMBER.EIGHT}
                                isRevamped={true}
                            />
                        </div>
                    </div>
                </div>
                {isMarketing ?
                    <InView
                        as="div"
                        threshold={NUMBER.ONE}
                        onChange={(inView) => handleGaEvent(inView)}
                    >
                        {getViewAllBrands()}
                    </InView> :
                    getViewAllBrands()
                }
            </div>
        </div>
    );
};

HomeBannerV2.propTypes = {
    handleOnClick: PropTypes.func,
    onSelection: PropTypes.func,
    safetyBannerTop: PropTypes.object,
    handleClickCount: PropTypes.func,
    isMarketing: PropTypes.bool,
    visiblePopularBrands: PropTypes.number,
    ctaText: PropTypes.string,
    heading: PropTypes.string,
    mainHeading: PropTypes.string,
    subHeading: PropTypes.string,
    sellMarketingExperiment: PropTypes.string,
    homePageType: PropTypes.string
};

export default HomeBannerV2;
