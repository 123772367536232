import Types from "./types";

const setConfirmationFlag = (token) => ({
    type: Types.SET_CONFIRMATION_FLAG,
    token
});

const skipCitySelection = (isCitySelectionSkipped) => ({
    type: Types.SKIP_CITY_SELECTION,
    isCitySelectionSkipped
});

const updateLeadSource = (leadSource) => ({
    type: Types.UPDATE_LEAD_SOURCE,
    leadSource
});

const setDealerCode = (dealerCode) => ({
    type: Types.SET_DEALER_CODE,
    dealerCode
});

export {
    setConfirmationFlag,
    skipCitySelection,
    updateLeadSource,
    setDealerCode
};
