/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { Helmet } from "react-helmet-async";
import IconRotate from "../../shared/icon-360-rotate";
import IconHeart from "../../shared/icon-heart/component";
import priceFormatter from "../../../utils/helpers/price-formatter";
import oridinalSuffix from "../../../utils/helpers/oridinal-suffix";
import { cdnImageUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import { NoCarImageFoundPath } from "../no-car-image-found";
import withAddToWishlist from "../../shared/with-add-to-wishlist";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import { SOURCE, BOOKING_STATE, CAR_STATUS } from "./constant";
import { DISPOSITION_STATUS, NUMBER } from "../../../constants/app-constants";
import LocationPointer from "./images/location-pointer.svg";
import Button from "../../shared/button";
import Checkbox from "../../shared/checkbox";
// import TransparentButton from "../../shared/button/transparent-button";
import CrossIcon from "./images/close-icon.svg";
import ExperimentCarCard from "./experiment";
import registrationNumberDash from "../../../utils/helpers/registration-number-dash";
import ArrowIcon from "../../shared/arrow";
import { getCustomDeliveryReturnDate } from "../../../utils/helpers/get-day-from-date";
import { sendCustomGaEvents } from "../../../tracking";
import mobileLastDigit from "../../../utils/helpers/mobile-last-digit";
import isDiscountPrice from "../../../utils/helpers/is-discount-price";
import withRequireLogin from "../../shared/with-require-login";
import { FORM_SOURCE } from "../interest-form-revamp/constant";
import LazyImage from "../../shared/lazy-image";
import BookingLimitReached from "../booking-limit-reached";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { withRouter } from "react-router-dom";
import cleverTap from "../../../tracking/clevertap";
import FiltersV3 from "../../../utils/filters-v3";
import isObjectEmpty from "../../../utils/helpers/isObjectEmpty";
import Percent from "./images/percent-icon.svg";
import isDiscountExp from "../../../utils/helpers/discount-price-experiment";
import CarDispositionStatus from "../car-disposition-status";
import saveCookie from "../../../utils/helpers/save-cookie";
import withCfForm from "../with-cf-form";
import { FILTER_OPERATOR } from "../../../utils/filters-v3/constant";
import { CF_STATUS } from "../../../constants/cf-constants";
import { checkIsEmiAvailable } from "../../../utils/helpers/check-is-emi-available";

const PaymentHistoryPage = loadable(() => import("../../../pages/mobile/payment-history-page"), {ssr: false});
const NoRefundAmountPopup = loadable(() => import("../no-refund-amount-popup"));
const BookingCancelPopup = loadable(() => import("../booking-cancel-popup"));
import {
    trackBookingClick,
    cardClickMyBookingScreen,
    carsSelectedForComparison
} from "./tracking-constants";

const InventoryCarCard = ({
    year,
    carId,
    carName,
    make,
    model,
    price,
    vehiclePrice,
    originalPrice,
    kilometerDriven,
    fuelType,
    transmission,
    mainImage,
    s20MainImage,
    baseUrl,
    ownerNumber,
    bodyType,
    distance,
    cardRef,
    history,
    onClickCallback = () => { },
    threeSixtyViewUrl,
    variant,
    isWishlistedCar,
    onClickAddToWishList,
    canFavourite = true,
    city,
    registrationNumber,
    source,
    // emiStartingValue,
    // cashDownPayment,
    dispositionStatus,
    onClickRemove,
    bookingState,
    // onClickCta = () => { },
    onClickSimilarCars = () => { },
    // onCancelBooking = () => { },
    isCancelledLocally,
    wishlistSource = "",
    ciVehicleLocationInfo,
    pincode,
    orderId,
    appointmentId,
    reserved,
    deliveredDetails,
    orderReturnDetails,
    bookingId,
    mobile,
    userId,
    hasFilledCFForm,
    onClickRequireLogin,
    index = 0,
    setCFormIconConnect,
    cfTooltipVisible,
    isBookingLimitExceeded,
    prePopulateCarDetailConnect,
    location,
    isPreloadImageEnable,
    secureToken,
    setCfLabelConnect,
    isComparingActive,
    selectedCarsToCompare,
    totalSelectedCarsToCompare,
    setCompareCarsLimitErrorConnect,
    addCarToCompareConnect,
    removeCarCompareConnect,
    showSimilarCarPopup,
    showSimilarCarPopupConnect,
    selectedCity,
    getTotalBookingConnect,
    setCfModalTypeConnect = () => { },
    match,
    preApproved,
    isROIOfferApplicable,
    openCfForm: openCfFormPopupWrapper = () => { },
    parkingYard,
    creditStatus,
    getPaymentHistoryConnect,
    reservationDetail,
    currentTab = null,
    appliedOptions,
    handleOnClickSimilerCarCta = () => {},
    reloadCarListConnect,
    emiDetails,
    oemServiceHistoryAvailable,
    totalAmountPaid = 0,
    setPostBookingSourceConnect
}) => {
    const {params} = match || {};
    const {lid: carAppId} = params || {};
    const queryParams = parseLocationSearchParams(location.search);
    const { storeName, cityName } = ciVehicleLocationInfo || {};
    // eslint-disable-next-line no-unused-vars
    const [showBookingLimitPopup, setShowBookingLimitPopup] = useState(false);
    const [bookedCar, setBookedCar] = useState(false);
    const carSoldList = [DISPOSITION_STATUS.SOLD, DISPOSITION_STATUS.LOST, DISPOSITION_STATUS.REJECTED];
    const isCarSold = carSoldList.indexOf(dispositionStatus) !== -1;
    const isCarDelisted = dispositionStatus === DISPOSITION_STATUS.DRAFT;
    const carFullName = `${year} ${carName} ${variant}`;
    const carDisplayName = `${year} ${carName}`;
    const isDiscountPriceExperiment = isDiscountPrice(originalPrice, price);
    const isDiscountTagVisible = isDiscountPriceExperiment && !reserved && !isCarDelisted && !isCarSold;
    const priceLabel = `₹ ${priceFormatter(SOURCE.MY_BOOKINGS === source ? vehiclePrice : price)}`;
    const kilometerLabel = `${priceFormatter(kilometerDriven)} km`;
    const ownerLabel = `${oridinalSuffix(ownerNumber)} owner`;
    const registrationNumberLabel = registrationNumberDash(registrationNumber);
    const isView360Enabled = !!threeSixtyViewUrl;
    const { relativeURL } = getDetailPageUrl({ year, carName, carId: carId || appointmentId, city });
    const checkoutUrls = getDetailPageUrl({ year, carName, carId: carId || appointmentId, city, flowType: "checkout" });
    const hasImage = mainImage && mainImage.url;
    const hasS20Image = s20MainImage && s20MainImage.url;
    const imageUrl = (hasS20Image || hasImage) ? cdnImageUrl(hasS20Image ? s20MainImage.url : mainImage.url,
        isView360Enabled ? imageTypes.gsCarCard360 : imageTypes.gsCarCard, {
            thumborProps: {
                baseUrl: baseUrl.slice(0, -1)
            }
        }) : NoCarImageFoundPath;
    const { locality: parkingAddress } = parkingYard || {};
    const parkingYardAddress = parkingAddress && parkingAddress.length > NUMBER.FOURTY_SIX ? `${parkingAddress.slice(0, NUMBER.FOURTY_SIX)}...` : parkingAddress;
    const isDelivered = SOURCE.MY_BOOKINGS === source && (BOOKING_STATE.DELIVERED === bookingState || BOOKING_STATE.RETURNED === bookingState);
    const isCancelled = (isCancelledLocally || bookingState === BOOKING_STATE.CANCEL) && SOURCE.MY_BOOKINGS === source;
    const isExpired = bookingState === BOOKING_STATE.EXPIRED && SOURCE.MY_BOOKINGS === source;
    const isBookingValid = !isCancelled && !isExpired && SOURCE.MY_BOOKINGS === source;
    const [openNoRefundPopup, setOpenNoRefundPopup] = useState(false);
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);
    const extendedCardSources = [SOURCE.LISTING, SOURCE.SIMILAR_CARS, SOURCE.RECENT_CARS, SOURCE.CAR_COMPARE, SOURCE.MY_BOOKINGS, SOURCE.PENDING_BOOKINGS, SOURCE.WISHLIST, SOURCE.FEATURED_CARS, SOURCE.CARS_BY_BODY_TYPE, SOURCE.RECENT_CARS_HOME];
    const mediumCarCardSource = [SOURCE.RECENT_CARS_HOME];
    const showExtendedCard = extendedCardSources.indexOf(source) !== -1 && currentTab !== SOURCE.MY_PURCHASE;
    const showmediumCarCard = mediumCarCardSource.indexOf(source) !== -1;
    const { emiOriginalStartingValue = 0, emiStartingValue = 0, cashDownPayment = 0, roiMinDiscounted: roiDiscounted, roiMinOriginal: roiOriginal, tenure: emiTenure } = emiDetails || {};
    const isEmiAvailable = checkIsEmiAvailable({emi: emiStartingValue, tenure: emiTenure});
    const isEMIOfferRevision = isDiscountExp({ originalPrice: emiOriginalStartingValue, price: emiStartingValue });
    const isEMIOfferExperiment = isROIOfferApplicable && isDiscountPrice(emiOriginalStartingValue, emiStartingValue) && !reserved && !isCarDelisted && !isCarSold;

    const showWishlistSources = [
        SOURCE.LISTING,
        SOURCE.SIMILAR_CARS,
        SOURCE.RECENT_CARS,
        SOURCE.CAR_COMPARE,
        SOURCE.MY_BOOKINGS,
        SOURCE.PENDING_BOOKINGS,
        SOURCE.WISHLIST,
        SOURCE.CARS_BY_BODY_TYPE,
        SOURCE.FEATURED_CARS,
        SOURCE.RECENT_CARS_HOME
    ];
    const hideOwnerSources = [SOURCE.FEATURED_CARS, SOURCE.CARS_BY_BODY_TYPE, SOURCE.RECENT_CARS_HOME];

    const handleSimilarCarRedirection = () => {
        const query = [`make${FILTER_OPERATOR.EQUAL_TO}${make.toLowerCase()};model${FILTER_OPERATOR.IN}${model.toLowerCase()}`];
        const appliedFilters = new FiltersV3({ cityID: selectedCity.id, pincode });
        const listingUrl = appliedFilters.getListingUrl({
            filters: query,
            options: {
                ...appliedOptions
            }
        }).relativeURL;
        history.push(listingUrl);
        reloadCarListConnect({ shouldReloadList: true });
        window.scrollTo(0, 0);
    };

    const prePopulateData = {
        kilometerDriven,
        ownerNumber,
        fuelType,
        registrationNumber,
        variant,
        transmission,
        carName,
        year,
        price,
        originalPrice,
        baseUrl,
        mainImage,
        s20MainImage,
        dispositionStatus,
        emiDetails: {
            emiStartingValue,
            emiOriginalStartingValue,
            cashDownPayment
        },
        make,
        model,
        oemServiceHistoryAvailable
    };

    const showWishlist = showWishlistSources.indexOf(source) !== -1;
    const hideOwner = hideOwnerSources.indexOf(source) === -1;

    const preventPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onClickWishlist = (e) => {
        preventPropagation(e);
        onClickAddToWishList(
            carId || appointmentId,
            { source: wishlistSource }
        );
    };

    const onTrackBooking = (e) => {
        preventPropagation(e);
        sendCustomGaEvents({
            ...trackBookingClick,
            eventLabel: `${appointmentId} | ${bookingId}`,
            mobile_last_digit: mobileLastDigit(mobile),
            appointment_id: appointmentId,
            booking_id: bookingId,
            user_id: userId
        });
        if (source === SOURCE.MY_BOOKINGS) {
            saveCookie("currentBookedCar", appointmentId);
            setCfModalTypeConnect("");
        }

        history.push(`${checkoutUrls.relativeURL}`);
    };

    const onTrackCancellation = (e) => {
        preventPropagation(e);
        sendCustomGaEvents({
            ...trackBookingClick,
            eventLabel: `${appointmentId} | ${bookingId}`,
            mobile_last_digit: mobileLastDigit(mobile),
            appointment_id: appointmentId,
            booking_id: bookingId,
            user_id: userId
        });
        if (source === SOURCE.MY_BOOKINGS) {
            saveCookie("currentBookedCar", appointmentId);
            setCfModalTypeConnect("");
        }
        history.push(`${checkoutUrls.relativeURL}?userOrderId=${orderId}`);
    };

    const checkIfSelectedCarExists = () => {
        return !!selectedCarsToCompare.filter(({ carId: carAppointmentId }) => carAppointmentId === carId).length;
    };

    const handleCompareCars = (e) => {
        preventPropagation(e);
        if (!checkIfSelectedCarExists()) {
            sendCustomGaEvents({
                ...carsSelectedForComparison,
                eventLabel: carId
            });
            if (totalSelectedCarsToCompare >= NUMBER.THREE) {
                setCompareCarsLimitErrorConnect(true);

                window.setTimeout(() => {
                    setCompareCarsLimitErrorConnect(false);
                }, NUMBER.FIVE_THOUSAND);
            } else {
                const payloadCarToCompare = {
                    city,
                    carId,
                    mainImage,
                    s20MainImage,
                    make,
                    model,
                    variant,
                    year,
                    baseUrl,
                    carName,
                    bodyType,
                    reserved,
                    dispositionStatus,
                    condition: [],
                    specification: [],
                    overview: []
                };
                addCarToCompareConnect({ carDetails: payloadCarToCompare });
            }
        } else {
            removeCarCompareConnect(carId);
        }
    };

    const onCardClickHandler = (e) => {
        preventPropagation(e);
        if (SOURCE.CAR_COMPARE === source) {
            handleCompareCars(e);
            onClickCallback();
            return;
        }
        if (carAppId !== carId) prePopulateCarDetailConnect(prePopulateData);
        if (!queryParams.restrictMyBookingGa) {
            sendCustomGaEvents({
                ...cardClickMyBookingScreen,
                eventLabel: `${appointmentId} | ${bookingId}`,
                mobile_last_digit: mobileLastDigit(mobile),
                appointment_id: appointmentId,
                booking_id: bookingId,
                user_id: userId
            });
        }
        if (SOURCE.MY_BOOKINGS === source && isBookingValid) {
            onTrackBooking(e);
        } else if (SOURCE.MY_BOOKINGS === source && isCancelled && totalAmountPaid > 0) {
            onTrackCancellation(e);
        } else {
            history.push(relativeURL);
        }
        window.scrollTo(0, 0);
        if (!queryParams.restrictOnClickCallback) onClickCallback();
    };

    const getDistance = (dis) => {
        if (dis < NUMBER.ONE) {
            return `${(dis * NUMBER.THOUSAND).toFixed(0)} m`;
        } else if (dis >= NUMBER.ONE && dis < NUMBER.HUNDRED) {
            return `${dis.toFixed(1)} km`;
        } else if (dis >= NUMBER.HUNDRED) {
            return `${dis.toFixed(0)} km`;
        }
        return `${dis} km`;
    };

    const onClickCross = (e) => {
        preventPropagation(e);
        onClickRemove(carId);
    };

    const toggelTooltip = (e) => {
        preventPropagation(e);
        setCFormIconConnect(index);
    };

    const openCfFormPopup = (formSource = FORM_SOURCE.LISTING_TOOLTIP, event) => {
        openCfFormPopupWrapper({ source: formSource });
        if (cfTooltipVisible && event) {
            toggelTooltip(event);
        }
    };

    const openCfForm = (e) => {
        setCfLabelConnect(FORM_SOURCE.LISTING_TOOLTIP);
        preventPropagation(e);
        onClickRequireLogin({
            loggedInCallback: () => {
                openCfFormPopup(FORM_SOURCE.LISTING_TOOLTIP);
                setCFormIconConnect(index);
            }
        });
        cleverTap.trackEvent("cf_initiated", {
            cf_initiates: true
        });
    };

    const onCloseLimitPopup = () => {
        history.push("/");
    };

    const bookingData = async () => {

        const data = await getTotalBookingConnect(secureToken);
        const { count } = data;

        if (!isObjectEmpty(count)) {
            setBookedCar(count.BOOKED || 0);
        }
    };

    useEffect(() => {

        if (source === SOURCE.MY_BOOKINGS && showSimilarCarPopup.status) {
            bookingData();
        }

    }, [showSimilarCarPopup.status, source, showSimilarCarPopup.orderId]); // eslint-disable-line react-hooks/exhaustive-deps

    const getOriginalCard = () => {
        const deliveryDate = BOOKING_STATE.DELIVERED === bookingState && deliveredDetails && (deliveredDetails.actualDeliveryDate || deliveredDetails.expectedDeliveryDate);
        const returnDate = BOOKING_STATE.RETURNED && orderReturnDetails && (orderReturnDetails.returnDate);
        const date = (deliveryDate && getCustomDeliveryReturnDate(new Date(deliveryDate))) || (returnDate && getCustomDeliveryReturnDate(new Date(returnDate)));
        return (
            <div styleName={"styles.outer"}>
                <div
                    styleName={`${isCarSold ? `styles.carSoldFadeOffWrapper` : ``} styles.carName`}>
                    <p itemProp="name">
                        {carDisplayName}
                        {/* {(source === SOURCE.MY_BOOKINGS && !isBookingValid) && <span>Valid Upto</span>} */}
                    </p>
                </div>
                <div
                    styleName={`${isCarSold ? `styles.carSoldFadeOffWrapper` : ``} styles.carDetail`}>
                    <p styleName={"styles.amount"}>
                        {priceLabel}
                    </p>
                    {
                        isExpired && (
                            <span styleName="styles.expCancelled styles.expired">
                                Expired
                            </span>
                        )
                    }
                    {canFavourite && showWishlist && <div styleName={"styles.heartWrap"}>
                        <IconHeart
                            active={isWishlistedCar(carId)}
                            onClick={onClickWishlist}
                        />
                    </div>}
                </div>

                <ul styleName={"styles.modalType"}>
                    <li>{variant}</li>
                    <li styleName={"styles.listDot"} />
                    <li>{transmission ? transmission : null}</li>
                </ul>
                <ul styleName={`${SOURCE.MY_PURCHASE ? "styles.carTypeOld" : "styles.carType"}`}>
                    <li>{kilometerLabel}</li>
                    {ownerNumber && <li>{ownerLabel}</li>}
                    <li>{fuelType}</li>
                    <li>{registrationNumber}</li>
                </ul>
                {isDelivered && <div styleName={"styles.seeDetailWrap"}>
                    <div styleName={"styles.deliveryType"}>
                        <strong styleName={"styles.heading"}>
                            {BOOKING_STATE.DELIVERED === bookingState ? "Car Delivered!" : "Return Requested"}
                        </strong>
                        <span styleName={"styles.seeDetail"}>See Details <ArrowIcon /></span>
                    </div>
                    {date && <p styleName={"styles.deliveryDate"}>
                        {BOOKING_STATE.DELIVERED === bookingState ? "Delivery date: " : "Return request date: "}
                        <span>{date}</span>
                    </p>}
                </div>}
                {
                    !isDelivered && (SOURCE.MY_BOOKINGS === source || SOURCE.WISHLIST === source) && (
                        <div styleName={`styles.buttonWrap ${isCarSold && isCancelled && source === SOURCE.MY_BOOKINGS ? `styles.carSoldFadeOffWrapper` : ``}`}>

                            {
                                SOURCE.WISHLIST === source && (
                                    <div onClick={onClickCross} styleName="styles.call">
                                        <img styleName="styles.crossIcon" src={CrossIcon} />
                                    </div>
                                )
                            }
                        </div>
                    )
                }

                {
                    !isDelivered && isBookingValid && (
                        <div styleName={`styles.ctaButtonWrap ${isCarSold ? `styles.carSoldFadeOffWrapper` : ``}`}>
                            <Button
                                onClick={onTrackBooking}
                                text="Track Test Drive"
                            />
                        </div>
                    )
                }

            </div>
        );
    };

    const renderCarComparison = () => {
        return (
            <div styleName={`styles.compareCars ${checkIfSelectedCarExists() ? "styles.added" : ""}`}>
                <div className={"d-flex align-items-center"} onClick={(event) => { handleCompareCars(event); }}>
                    <Checkbox checkedStatus={checkIfSelectedCarExists()} />
                    {checkIfSelectedCarExists() ? "Added" : "+ Add to Compare"}

                </div>
            </div>
        );
    };

    const onSeeCars = () => {

        const appliedFilters = new FiltersV3({ cityID: selectedCity.id });
        const listingUrl = appliedFilters.getListingUrl({}).relativeURL;
        history.push(listingUrl);
        reloadCarListConnect({ shouldReloadList: true });
    };

    return (
        <div
            itemProp="makesOffer"
            itemScope
            itemType="http://schema.org/Offer"
        >
            {isPreloadImageEnable &&
                <Helmet>
                    <link rel="preload" href={imageUrl} as={"image"} type="image/jpg" />
                </Helmet>}
            <div>
                <span
                    itemProp="priceSpecification"
                    itemScope
                    itemType="http://schema.org/UnitPriceSpecification"
                >
                    <meta itemProp="priceCurrency" content="INR" />
                    <meta itemProp="price" content={price && parseInt(price)} />
                </span>
            </div>

            <div itemProp="itemOffered"
                styleName={"styles.carSoldWrap"}
                itemScope
                itemType="http://schema.org/Car">
                <a
                    // href={absoluteURL}
                    styleName={`styles.carCard ${(isCarSold) && currentTab !== SOURCE.MY_PURCHASE && bookingState !== BOOKING_STATE.BOOKED ? "styles.blurBg" : ""} `}
                    ref={cardRef}
                    onClick={onCardClickHandler}
                >
                    <div className={"align-self-center"}
                        styleName={`styles.carImage`}>
                        {isView360Enabled && <div styleName={"styles.cardCarRotate"}>
                            <IconRotate />
                        </div>}
                        <LazyImage visibleByDefault={isPreloadImageEnable} itemProp="image" styleName={"styles.mainImage"} src={imageUrl} alt={`${carFullName} ${variant}`} />
                        {
                            storeName && cityName && (
                                <p styleName="styles.location"><img src={LocationPointer} alt="location pointer" />{`${storeName}, ${cityName}`}</p>
                            )
                        }
                        {(isCarSold && !isBookingValid && currentTab !== SOURCE.MY_PURCHASE && (SOURCE.LISTING === source || SOURCE.WISHLIST === source || SOURCE.MY_BOOKINGS === source || SOURCE.RECENT_CARS)) && <CarDispositionStatus text={CAR_STATUS.SOLD} />}
                        {((reserved && !isCarSold) && currentTab !== SOURCE.MY_PURCHASE && (SOURCE.LISTING === source || SOURCE.WISHLIST === source || SOURCE.MY_BOOKINGS === source && SOURCE.RECENT_CARS_HOME === source)) && <CarDispositionStatus text={CAR_STATUS.RESERVED} />}
                        {((isCarDelisted && !isCarSold) && currentTab !== SOURCE.MY_PURCHASE && (SOURCE.LISTING === source || SOURCE.WISHLIST === source || SOURCE.MY_BOOKINGS === source || SOURCE.RECENT_CARS_HOME)) && <CarDispositionStatus text={CAR_STATUS.GETTING_SERVICED} />}

                        {(isDiscountTagVisible) &&
                            <p className={"summerDiscount"} styleName={"styles.summerDiscount"}>
                                <img src={Percent} alt="Discount" width={10} height={10} />

                                <span>₹{priceFormatter(isDiscountExp({ originalPrice, price }))}</span>

                                <span className={"ml-1"}>Off</span>
                            </p>
                        }

                    </div>
                    {(isCarSold && (SOURCE.LISTING === source || SOURCE.WISHLIST === source)) && <div styleName={"styles.carSoldWrapBg"}>
                        <CarDispositionStatus text={CAR_STATUS.SOLD} />
                    </div>}
                    {showExtendedCard ? (
                        <ExperimentCarCard
                            carName={carDisplayName}
                            emi={emiStartingValue}
                            price={price}
                            originalPrice={originalPrice}
                            year={year}
                            kilometer={kilometerLabel}
                            fuelType={fuelType}
                            ownerNumber={hideOwner && ownerNumber}
                            ownerLabel={ownerLabel}
                            registrationNumber={registrationNumberLabel}
                            downPayment={cashDownPayment}
                            showWishlist={canFavourite && showWishlist}
                            onClickWishlist={onClickWishlist}
                            isWishlistActive={isWishlistedCar(carId || appointmentId)}
                            isDiscountPriceExperiment={isDiscountPriceExperiment}
                            hasFilledCFForm={hasFilledCFForm}
                            toggelTooltip={toggelTooltip}
                            tooltipVisible={cfTooltipVisible}
                            openCfForm={openCfForm}
                            source={source}
                            showPreApproved={(preApproved && (creditStatus !== CF_STATUS.DISCARDED))}
                            variant={variant}
                            transmission={transmission}
                            isCancelledLocally={isCancelledLocally}
                            bookingState={bookingState}
                            appointmentId={appointmentId}
                            setOpenNoRefundPopup={setOpenNoRefundPopup}
                            setShowPaymentHistory={setShowPaymentHistory}
                            isDelivered={isDelivered}
                            isBookingValid={isBookingValid}
                            isCarSold={isCarSold}
                            mobile={mobile}
                            setCfModalTypeConnect={setCfModalTypeConnect}
                            isROIOfferApplicable={isROIOfferApplicable}
                            isEMIOfferExperiment={isEMIOfferExperiment}
                            emiOriginalStartingValue={emiOriginalStartingValue}
                            roiDiscounted={roiDiscounted}
                            openCfFormPopup={openCfFormPopup}
                            checkoutUrls={checkoutUrls}
                            relativeURL={relativeURL}
                            isEMIOfferRevision={isEMIOfferRevision}
                            parkingYardAddress={parkingYardAddress}
                            creditStatus={creditStatus}
                            roiOriginal={roiOriginal}
                            // history={history}
                            getPaymentHistoryConnect={getPaymentHistoryConnect}
                            orderId={orderId}
                            secureToken={secureToken}
                            reservationDetail={reservationDetail}
                            onCardClickHandler={onCardClickHandler}
                            onClickSimilarCars={onClickSimilarCars}
                            reserved={reserved}
                            history={history}
                            getDistance={getDistance}
                            distance={distance}
                            isCancelled={isCancelled}
                            isExpired={isExpired}
                            handleSimilarCarRedirection={handleSimilarCarRedirection}
                            handleOnClickSimilerCarCta={handleOnClickSimilerCarCta}
                            showmediumCarCard={showmediumCarCard}
                            onTrackCancellation={onTrackCancellation}
                            setPostBookingSourceConnect={setPostBookingSourceConnect}
                            isEmiAvailable={isEmiAvailable}
                        />
                    ) : (
                        getOriginalCard()
                    )}
                </a>
                {isComparingActive && SOURCE.LISTING === source && renderCarComparison()}
            </div >
            {
                isBookingLimitExceeded && showBookingLimitPopup && <BookingLimitReached onClose={onCloseLimitPopup} />
            }

            {
                showPaymentHistory &&
                <PaymentHistoryPage bookingId={orderId} onClose={() => setShowPaymentHistory(false)} />
            }

            {
                openNoRefundPopup &&
                <NoRefundAmountPopup setOpenNoRefundPopup={setOpenNoRefundPopup} />
            }

            {
                showSimilarCarPopup.orderId === orderId && showSimilarCarPopup.status && bookedCar === NUMBER.ZERO &&
                <BookingCancelPopup showSimilarCarPopupConnect={showSimilarCarPopupConnect} onViewCar={onSeeCars} />
            }
        </div >
    );
};

InventoryCarCard.propTypes = {
    year: PropTypes.number,
    carName: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    price: PropTypes.number,
    kilometerDriven: PropTypes.number,
    fuelType: PropTypes.string,
    bodyType: PropTypes.string,
    ownerNumber: PropTypes.string,
    cardRef: PropTypes.object,
    originalPrice: PropTypes.string,
    absoluteURL: PropTypes.string,
    url: PropTypes.string,
    city: PropTypes.string,
    isPriceRevision: PropTypes.bool,
    variant: PropTypes.string,
    onClickWishlist: PropTypes.func,
    isWishlistedCar: PropTypes.func,
    canFavourite: PropTypes.bool,
    carId: PropTypes.string,
    mainImage: PropTypes.string,
    s20MainImage: PropTypes.string,
    baseUrl: PropTypes.string,
    history: PropTypes.object,
    onClickCallback: PropTypes.func,
    threeSixtyViewUrl: PropTypes.bool,
    onClickAddToWishList: PropTypes.func,
    bookingExpiryTime: PropTypes.string,
    source: PropTypes.string,
    updateCallPopupStatusConnect: PropTypes.func,
    dispositionStatus: PropTypes.string,
    onClickSimilarCars: PropTypes.func,
    onClickRemove: PropTypes.func,
    onClickCta: PropTypes.func,
    isListingCardExperimentEnabled: PropTypes.bool,
    emiStartingValue: PropTypes.number,
    cashDownPayment: PropTypes.number,
    onCancelBooking: PropTypes.func,
    bookingState: PropTypes.string,
    isCancelledLocally: PropTypes.bool,
    wishlistSource: PropTypes.string,
    ciVehicleLocationInfo: PropTypes.string,
    ciChargesApplicable: PropTypes.bool,
    showWishlistOnExperiment: PropTypes.bool,
    pincode: PropTypes.number,
    orderId: PropTypes.string,
    appointmentId: PropTypes.string,
    ciActive: PropTypes.bool,
    reserved: PropTypes.bool,
    registrationNumber: PropTypes.string,
    deliveredDetails: PropTypes.object,
    orderReturnDetails: PropTypes.object,
    onShowBookingSummary: PropTypes.func,
    bookingId: PropTypes.string,
    mobile: PropTypes.number,
    userId: PropTypes.string,
    hasFilledCFForm: PropTypes.bool,
    onClickRequireLogin: PropTypes.func,
    showCFModalConnect: PropTypes.func,
    updateCFPopUpTypeConnect: PropTypes.func,
    index: PropTypes.string,
    setCFormIconConnect: PropTypes.func,
    cfTooltipVisible: PropTypes.bool,
    couponDiscount: PropTypes.number,
    tofDiscount: PropTypes.number,
    vehiclePrice: PropTypes.number,
    isBookingLimitExceeded: PropTypes.bool,
    prePopulateCarDetailConnect: PropTypes.func,
    transmission: PropTypes.string,
    location: PropTypes.object,
    match: PropTypes.object,
    isPreloadImageEnable: PropTypes.bool,
    getPaymentSummaryConnect: PropTypes.func,
    secureToken: PropTypes.string,
    getPaymentHistoryConnect: PropTypes.func,
    setCfLabelConnect: PropTypes.func,
    isComparingActive: PropTypes.bool,
    selectedCarsToCompare: PropTypes.array,
    totalSelectedCarsToCompare: PropTypes.number,
    setCompareCarsLimitErrorConnect: PropTypes.func,
    addCarToCompareConnect: PropTypes.func,
    removeCarCompareConnect: PropTypes.func,
    showSimilarCarPopup: PropTypes.object,
    showSimilarCarPopupConnect: PropTypes.func,
    selectedCity: PropTypes.object,
    getTotalBookingConnect: PropTypes.func,
    setCfModalTypeConnect: PropTypes.func,
    distance: PropTypes.string,
    preApproved: PropTypes.bool,
    listingPagePreApprovedABTest: PropTypes.string,
    benefits: PropTypes.object,
    isROIOfferApplicable: PropTypes.bool,
    emiOriginalStartingValue: PropTypes.number,
    roiDiscounted: PropTypes.number,
    checkCfOfferConnect: PropTypes.func,
    parkingYard: PropTypes.object,
    creditStatus: PropTypes.string,
    roiOriginal: PropTypes.number,
    openCfForm: PropTypes.func,
    reservationDetail: PropTypes.object,
    currentTab: PropTypes.string,
    appliedOptions: PropTypes.object,
    lastUpdatedAppliedFilters: PropTypes.string,
    isRedirected: PropTypes.bool,
    handleOnClickSimilerCarCta: PropTypes.bool,
    reloadCarListConnect: PropTypes.func,
    emiDetails: PropTypes.object,
    oemServiceHistoryAvailable: PropTypes.bool,
    totalAmountPaid: PropTypes.number,
    setPostBookingSourceConnect: PropTypes.func
};

export default withRouter(withCfForm(withRequireLogin(withAddToWishlist(InventoryCarCard))));
