import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

const catalogCarService = (api) => {

    const getNotifyme = (appId, token, clientId) => {
        return api.get(`reserved-cars/v1/notify/${appId}`, {
            headers: {
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN",
                Authorization: `Bearer ${token}`,
                ...(clientId && { clientId })
            }
        });
    };

    const postNotifyme = ({appointmentId, notify}, clientId = "", token) => {
        return api.post(`reserved-cars/v1/notify/${appointmentId}`, {
            notify
        }, {
            headers: {
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN",
                Authorization: `Bearer ${token}`,
                clientId
            }
        });
    };

    return {
        getNotifyme,
        postNotifyme
    };
};

export const CatalogCarService = catalogCarService(middlewareConfig(MIDDLEWARE_ENUMS.CATALOG_SERVICE));
