import { LmsService } from "../../../service/lms-service";
import  Types from "./types";

const leadpost = (params) => () => {
    return new Promise((resolve, reject) => {
        LmsService.requestCallbackLead(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const updateCallBackPopupStatus = (showCallBackPopup) => ({
    type: Types.UPDATE_REQUEST_CALLBACK_POPUP_STATUS,
    showCallBackPopup
});

const updateCEPCallBackPopupStatus = (showCEPCallBackPopup) => ({
    type: Types.UPDATE_CEP_REQUEST_CALLBACK_POPUP_STATUS,
    showCEPCallBackPopup
});

const requestCallbackSubmittedTime = (submittedTime) => ({
    type: Types.REQUEST_CALL_SUBMITTED_TIME,
    submittedTime
});

const setIsTicking = (isTicking) => ({
    type: Types.IS_TICKING,
    isTicking
});

const setCallbackSource = (source) => ({
    type: Types.SET_CALLBACK_SOURCE,
    source
});

const setAskCallIntent = (askCallIntent) => ({
    type: Types.SET_ASK_CALL_INTENT,
    askCallIntent
});

const saveCallbackIntent = (isIntentSaved) => ({
    type: Types.SAEV_CALLBACK_INTENT,
    isIntentSaved
});

export {
    updateCallBackPopupStatus,
    updateCEPCallBackPopupStatus,
    requestCallbackSubmittedTime,
    setIsTicking,
    setCallbackSource,
    leadpost,
    setAskCallIntent,
    saveCallbackIntent
};
