import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    locationName: "",
    placeID: "",
    selectionType: null,
    shouldReplaceLoader: false,
    isMmvy: false,
    lead: {},
    channelPartnerToken: null,
    isRedirected: false
};

export const setCurrentLocationName = (state = INITIAL_STATE, { locationName }) => {
    return { ...state, locationName };
};

export const setCurrentLocationID = (state = INITIAL_STATE, { placeID }) => {
    return { ...state, placeID };
};

export const setSelectionType = (state = INITIAL_STATE, { selectionType }) => {
    return { ...state, selectionType };
};

export const updateLoader = (state = INITIAL_STATE, { shouldReplaceLoader }) => {
    return { ...state, shouldReplaceLoader };
};

export const setIsMmvy = (state = INITIAL_STATE, { isMmvy }) => {
    return { ...state, isMmvy };
};

export const setLeadDetail = (state = INITIAL_STATE, { leadDetail }) => {
    return { ...state, lead: leadDetail };
};

export const setChannelPartnerToken = (state = INITIAL_STATE, { channelPartnerToken }) => {
    return { ...state, channelPartnerToken, isRedirected: true };
};

export const HANDLERS = {
    [Types.SET_CURRENT_LOCATION_NAME]: setCurrentLocationName,
    [Types.SET_CURRENT_LOCATION_ID]: setCurrentLocationID,
    [Types.SET_SELECTION_TYPE]: setSelectionType,
    [Types.SET_SHOULD_REPLACE_LOADER]: updateLoader,
    [Types.SET_IS_MMVY]: setIsMmvy,
    [Types.SET_LEAD_DATA]: setLeadDetail,
    [Types.SET_CHANNEL_PARTNER_TOKEN]: setChannelPartnerToken
};

export default createReducer(INITIAL_STATE, HANDLERS);
