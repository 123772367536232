/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import { Button } from "@cars24/turbo-web/lib/button";
import { TinyText } from "@cars24/turbo-web/lib/tiny-text";
import { Label } from "@cars24/turbo-web/lib/label";
import { Badge } from "@cars24/turbo-web/lib/badge";
import { LinkButton } from "@cars24/turbo-web/lib/link-button";
import { Divider } from "@cars24/turbo-web/lib/divider";
import { BodyText } from "@cars24/turbo-web/lib/body-text";
import MapPin from "./images/map-pin.svg";
import ClockIcon from "./images/clock.svg";
import Avatar from "./images/avatar.svg";
import Download from "./images/download.svg";
import CarIcon from "./images/car.svg";
import Star from "./images/star.svg";
import {
    PICKUP_IN_PROGRESS_DOC_UPLOADED_SHARE_OTP,
    getFormattedRegNo,
    getPrice
} from "./selector";
import { sendCustomGaEvents } from "../../../tracking";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import {
    editClicked,
    u2lCtaClicked
} from "../../mobile/user-journey-drop/tracking";
import { AB_TEST_VARIATIONS, STEP_NAMES } from "../../../constants/app-constants";
import { getU2LRedirectionAndScreen } from "../../mobile/user-journey-drop/selector";
import Skeleton from "./skeleton";

const CANCELLED = "CANCELLED";

const Card = ({
    cardData,
    stage,
    history,
    setFormDataConnect,
    setStepConnect,
    activateLastStepConnect,
    details,
    isLoading,
    isUserDropBeforeLogin = false,
    isUserDropAfterLogin = false,
    goToSteps,
    loginCallback,
    luxuryGoodPriceExperimentFlag,
    leadData,
    webQuoteRevampExperimentFlag
}) => {
    const { tokens } = useTokens();
    const {
        badge = {},
        title = "",
        subTitle = "",
        subTitleColor = "",
        carName = "",
        carImage = CarIcon,
        showFormattedRegNo = false,
        showPrice = false,
        price: priceContainer = {},
        showEdit = false,
        editAction = {},
        showHiLocation = false,
        hiLocation = {},
        showDivider = false,
        helperText = "",
        showActionBtns = false,
        actionButtons = {},
        showSecondaryCard = false,
        secondaryCardData = {},
        showTimeText = false,
        time = "",
        otp = ""
    } = cardData || {};

    const { title: badgeTitle, variant: badgeVariant } = badge || {};
    const {
        text: priceText,
        subText: priceSubText,
        backgroundColor: priceBackgroundColor,
        ctaText: priceCtaText,
        ctaUrl: priceCtaUrl,
        value: priceValue
    } = priceContainer || {};

    const { ctaText: editCtaText, ctaUrl: editCtaUrl } = editAction || {};

    const { text: hiLocationText } = hiLocation || {};
    const { veryGoodMinQuotePrice,
        veryGoodMaxQuotePrice,
        goodMaxQuotePrice,
        goodMinQuotePrice,
        minQuotedPrice} = details || {};
    const { isLuxuryCar } = leadData || {};

    const webQuoteRevamp = (webQuoteRevampExperimentFlag === AB_TEST_VARIATIONS.VARIANT);
    const luxuryPrice = (luxuryGoodPriceExperimentFlag === AB_TEST_VARIATIONS.VARIANT) && (isLuxuryCar);

    const minPrice = luxuryPrice ? goodMinQuotePrice : webQuoteRevamp ? minQuotedPrice : veryGoodMinQuotePrice;
    const maxPrice = luxuryPrice ? goodMaxQuotePrice : webQuoteRevamp ? veryGoodMaxQuotePrice : veryGoodMaxQuotePrice;

    const {
        primaryButtonCtaText,
        primaryButtonCtaUrl,
        secondaryButtonCtaText,
        secondaryButtonCtaUrl,
        showDownloadSecondary,
        primaryButtonVariant
    } = actionButtons || {};

    const {
        title: secondaryTitle,
        subTitle: secondarySubTitle,
        leftIcon: secondaryLeftIcon,
        rightIcon: secondaryRightIcon,
        rightIconUrl: secondaryRightIconUrl,
        ctaText: secondaryCtaText,
        ctaUrl: secondaryCtaUrl,
        backgroundColor: secondaryBackgroundColor,
        rating,
        phone,
        showCaller = false
    } = secondaryCardData || {};

    const renderBadge = () =>
        badgeTitle && (
            <Badge label={badgeTitle} type="light" variant={badgeVariant} />
        );

    const renderTitle = () =>
        title && (
            <BodyText level={2} isBold color={tokens.text_default_text_primary}>
                {title}
            </BodyText>
        );

    const renderSubtitle = () =>
        subTitle && (
            <Label
                level={4}
                color={subTitleColor || tokens.text_default_text_secondary}
            >
                {subTitle}
            </Label>
        );
    const renderCarInfo = () =>
        stage === PICKUP_IN_PROGRESS_DOC_UPLOADED_SHARE_OTP ? (
            <Badge label={otp} type="light" variant="accent" />
        ) : (
            cardData && <div styleName={"styles.carWrapper"}>
                <Helmet>
                    <link
                        rel="preload"
                        href={carImage}
                        as={"image"}
                        fetchpriority="high"
                    />
                </Helmet>
                <img src={carImage || CarIcon} alt={carName || "car"} />
                {carName && (
                    <TinyText color={tokens.text_default_text_primary} fontWeight={showFormattedRegNo ? "semibold" : "regular"}>
                        {showFormattedRegNo ?  getFormattedRegNo(carName) :  carName}
                    </TinyText>
                )}
            </div>
        );

    const renderLocation = () =>
        showHiLocation &&
    hiLocationText && (
            <div styleName={"styles.addressDateTime"}>
                <img src={MapPin} alt="Location" width="16px" height="16px" />
                <Label level={4} color={tokens.text_default_text_secondary}>{hiLocationText}</Label>
            </div>
        );

    const renderTime = () =>
        showTimeText &&
    time && (
            <div styleName={"styles.addressDateTime"}>
                <img src={ClockIcon} alt="Time" />
                <Label level={4} color={tokens.text_default_text_secondary}>
                    {time}
                </Label>
            </div>
        );

    const renderHelperText = () =>
        helperText && <TinyText>{helperText}</TinyText>;

    const renderPrice = () =>
        showPrice && (
            <div
                styleName={"styles.priceEstimateCard"}
                style={{ backgroundColor: priceBackgroundColor }}
            >
                <div styleName={"styles.priceWrapper"}>
                    <div styleName={"styles.estimateText"}>
                        {priceText && (
                            <TinyText isBold color={tokens.text_default_text_secondary}>
                                {priceText}
                            </TinyText>
                        )}
                        {priceSubText && <TinyText>{priceSubText}</TinyText>}
                    </div>
                    <Label
                        fontWeight="semibold"
                        level={3}
                        color={tokens.text_default_text_primary}
                    >
                        {priceValue || getPrice(maxPrice, minPrice)}

                    </Label>
                </div>
                <div styleName={"styles.buttonWrapper"}>
                    <Button
                        label={priceCtaText}
                        onClick={() => history.push(priceCtaUrl)}
                        variant="primary"
                        size="small"
                    />
                </div>
            </div>
        );

    const ctaClickCallback = async (ctaUrl) => {
        const { activeTab = "" } = getU2LRedirectionAndScreen(details);
        history.push(ctaUrl);
        sendCustomGaEvents({
            ...u2lCtaClicked,
            eventLabel: activeTab
        });
    };
    const appDownloadCta = (redirectionLink) => {
        if (redirectionLink) {
            window.location.href = redirectionLink;
        }
        sendCustomGaEvents({
            event: "app_adoption_experiment",
            eventCategory: "appointment_confirmation",
            eventAction: "a2i_app_download_button_click",
            eventLabel: details.token
        });
    };

    const secondaryRightIconToRender = () => {
        if (showCaller) {
            return (
                <a href={`tel:${phone}`}>
                    <img src={secondaryRightIcon} alt="right-icon" />
                </a>
            );
        } else return <img src={secondaryRightIcon} alt="right-icon" width="24px" height="24px" onClick={() => secondaryRightIconUrl && window.location.replace(secondaryRightIconUrl)}/>;
    };

    const secondaryTitleToRender = () => {
        return secondaryTitle && rating ? (
            <div styleName={"styles.wrap"}>
                {`${secondaryTitle} ( ${rating}`}
                <img src={Star} alt="rating" width="13px" height="13px" /> {`)`}
            </div>
        ) : (
            secondaryTitle
        );
    };
    const renderSecondaryCard = () =>
        showSecondaryCard &&
    secondaryTitle && (
            <div styleName={"styles.priceEstimateCard"}>
                <div
                    styleName={"styles.callExpert"}
                    style={{ backgroundColor: secondaryBackgroundColor }}
                >
                    <div styleName={"styles.callExpertName"}>
                        {secondaryLeftIcon && (
                            <img
                                src={secondaryLeftIcon || Avatar}
                                alt="left-icon"
                                width={secondaryLeftIcon ? "16px" : "32px"}
                                height={secondaryLeftIcon ? "16px" : "32px"}
                            />
                        )}
                        <div styleName={"styles.expertName"}>
                            <Label level={4} fontWeight="semibold">
                                {secondaryTitleToRender()}
                            </Label>
                            <Label level={4} fontWeight="regular" color={tokens.text_default_text_secondary}>
                                {secondarySubTitle}
                            </Label>
                        </div>
                    </div>
                    {secondaryRightIcon && secondaryRightIconToRender()}
                </div>
                <div styleName={"styles.buttonWrapper"}>
                    <Button
                        label={secondaryCtaText}
                        onClick={() => ctaClickCallback(secondaryCtaUrl)}
                        variant="primary"
                        size="small"
                    />
                </div>
            </div>
        );

    const renderActionButtons = () =>
        showActionBtns && (
            <div styleName={"styles.primarySecondaryCta"}>
                {primaryButtonCtaText && (
                    <Button
                        label={primaryButtonCtaText}
                        onClick={() => {
                            if (stage === CANCELLED) {
                                setFormDataConnect(details);
                            }
                            ctaClickCallback(primaryButtonCtaUrl);
                        }
                        }
                        variant={primaryButtonVariant ||  "secondary" }
                        size="small"
                    />
                )}
                {secondaryButtonCtaText && (
                    <Button
                        label={secondaryButtonCtaText}
                        onClick={() =>
                            showDownloadSecondary
                                ? appDownloadCta(secondaryButtonCtaUrl)
                                : ctaClickCallback(secondaryButtonCtaUrl)
                        }
                        variant="primary"
                        size="small"
                        suffixIcon={
                            showDownloadSecondary && <img src={Download} alt="download" />
                        }
                    />
                )}
            </div>
        );

    const handleEdit = async (leadId) => {
        await yieldToMain();
        sendCustomGaEvents({
            ...editClicked,
            eventLabel: leadId
        });
        setFormDataConnect(details);
        setStepConnect({ currentStep: STEP_NAMES.Brand });
        activateLastStepConnect(false);
        history.push(`${editCtaUrl}/${STEP_NAMES.Brand}`);
    };

    const renderEditButton = () =>
        showEdit &&
    editCtaText && (
            <div styleName={"styles.linkButtonCta"}>
                <LinkButton
                    size={"small"}
                    label={editCtaText}
                    onClick={handleEdit}
                    variant="ghost"
                    preventDefaultBehaviour
                    underLine
                />
            </div>
        );
    const renderBlurText = () =>
        title && (
            <BodyText level={2} isBold color={tokens.text_default_text_primary}>
                {title}
                <span styleName={"styles.blurText"}>{9876897}</span>
            </BodyText>
        );
    const loginActionButtons = () =>
        primaryButtonCtaText && (
            <div styleName={"styles.primarySecondaryCta"}>
                <Button
                    label={primaryButtonCtaText}
                    onClick={isUserDropBeforeLogin ? loginCallback : goToSteps}
                    variant="primary"
                    size="small"
                />
            </div>
        );
    if (isLoading) return <Skeleton />;
    return (
        cardData && <div styleName={"styles.userStagesJourneyDropCard"}>
            {
                <>
                    {isUserDropBeforeLogin || isUserDropAfterLogin ? (
                        <>
                            <div styleName={"styles.userStagesJourneyDropText"}>
                                <div>
                                    {renderBadge()}
                                    <div styleName={"styles.titles"}>
                                        {renderBlurText()}
                                        {renderSubtitle()}
                                    </div>
                                </div>
                                {renderCarInfo()}
                            </div>
                            {loginActionButtons()}
                        </>
                    ) : (
                        <>
                            { cardData && <div styleName={"styles.userStagesJourneyDropText"}>
                                <div>
                                    {renderBadge()}
                                    <div styleName={"styles.titles"}>
                                        {renderTitle()}
                                        {renderSubtitle()}
                                        {renderLocation()}
                                    </div>
                                    {renderTime()}
                                </div>
                                {renderCarInfo()}
                            </div>}
                            {showDivider && (
                                <div styleName={"styles.divider"}>
                                    <Divider variant="primary" />
                                </div>
                            )}
                            {renderHelperText()}
                            {renderPrice()}
                            {renderSecondaryCard()}
                            {renderActionButtons()}
                            {renderEditButton()}
                        </>
                    )}
                </>
            }
        </div>
    );
};

Card.propTypes = {
    cardData: PropTypes.object,
    details: PropTypes.object,
    stage: PropTypes.string,
    history: PropTypes.history,
    maxPrice: PropTypes.string,
    minPrice: PropTypes.string,
    setFormDataConnect: PropTypes.func,
    setStepConnect: PropTypes.func,
    activateLastStepConnect: PropTypes.func,
    isLoading: PropTypes.bool,
    isUserDropBeforeLogin: PropTypes.bool,
    isUserDropAfterLogin: PropTypes.bool,
    goToSteps: PropTypes.func,
    loginCallback: PropTypes.func,
    luxuryGoodPriceExperimentFlag: PropTypes.string,
    leadData: PropTypes.object,
    webQuoteRevampExperimentFlag: PropTypes.string
};

export default Card;
