import Types from "./types";

const setPageLoading = (pageLoading, loaderTitle = "") => ({
    type: Types.SET_PAGE_LOADING,
    pageLoading,
    loaderTitle
});

export {
    setPageLoading
};
