import axios from "axios";
import { b2cCrmServiceUrl } from "../constants/url-constants";

const api = axios.create({
    baseURL: b2cCrmServiceUrl()
});

export default () => {
    const getCancellationFeedbackQuestions = (bookingId, token, feedbackType = "order_cancel") => {
        return api.get(`/feedback/v1/config/${bookingId}/${feedbackType}`, {
            headers: {
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN",
                Authorization: `Bearer ${token}`
            }
        });
    };

    // eslint-disable-next-line max-params
    const postCancellationFeedback = (data, bookingId, token, feedbackType = "order_cancel") => {
        return api.post(
            `/feedback/v1/post/${bookingId}/${feedbackType}`,
            data,
            {
                headers: {
                    X_VEHICLE_TYPE: "CAR",
                    X_COUNTRY: "IN",
                    Authorization: `Bearer ${token}`
                }
            }
        );
    };

    const getVisitRatingStatus = (orderId, token) => {
        return api.get(`/feedback/v1/${orderId}/test_drive_visit`, {
            headers: {
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN",
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getSubInsurenceData = (token, params) => {
        return api.get(`faq/v1/faqs`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const getRepairData = (token, orderId) => {
        return api.get(`api/v1/static/repair-category/${orderId}`, {
            headers: {
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN",
                Authorization: `Bearer ${token}`
            }
        });
    };

    const repairRating = (token, orderId) => {
        return api.get(`/feedback/v1/config/${orderId}/order_repair_delivery`, {
            headers: {
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN",
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getRatingData = (token, orderId) => {
        return api.get(`feedback/v2/${orderId}/visit`, {
            headers: {
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN",
                Authorization: `Bearer ${token}`
            }
        });
    };

    const postRatingData = (token, orderId, data) => {
        return api.post(`feedback/v2/${orderId}/TEST_DRIVE_VISIT`, data, {
            headers: {
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN",
                Authorization: `Bearer ${token}`
            }
        });
    };

    const bulkFeedback = (token, payload) => {
        return api.post(`feedback/v1/bulk-submit-feedback/order_cancel`, payload, {
            headers: {
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN",
                Authorization: `Bearer ${token}`
            }
        });
    };

    return {
        getCancellationFeedbackQuestions,
        postCancellationFeedback,
        getVisitRatingStatus,
        getSubInsurenceData,
        getRepairData,
        repairRating,
        getRatingData,
        postRatingData,
        bulkFeedback
    };
};
