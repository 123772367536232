import {NUMBER, dateFormat} from "../../constants/app-constants";
import { dateDiffrence } from "./date-diff-in-days";

const getDayCount = (dateToCheck) => {
    const receivedDate = new Date(dateToCheck);
    const today = new Date();
    return Number(dateDiffrence(today, receivedDate));
};

export const converMilliToTime = (duration) => {
    let minutes = parseInt((duration / (NUMBER.THOUSAND * NUMBER.SIXTY)) % NUMBER.SIXTY);
    let hours = parseInt((duration / (NUMBER.THOUSAND * NUMBER.SIXTY * NUMBER.SIXTY)));

    if (hours < 0) {hours = 0;}
    if (minutes < 0) {minutes = 0;}

    hours = (hours < NUMBER.TEN) ? `0${  hours}` : hours;
    minutes = (minutes < NUMBER.TEN) ? `0${  minutes}` : minutes;

    return `${hours  }:${  minutes  }`;
};

export const isPastDayAndTime = (dateToCheck) => {
    const dayCount = getDayCount(dateToCheck);
    return dayCount > NUMBER.ZERO ? false : true;
};

export const isSameDay = (dateToCheck) => {
    const dayCount = getDayCount(dateToCheck);
    return dayCount <= NUMBER.ONE ? true : false;
};

export const isTimeUnderGivenThreshold = (time, minutesThreshold) => {
    const currentTime = new Date();
    const [hours, minutes] = time.split(":");
    const specificDate = new Date();
    specificDate.setHours(hours);
    specificDate.setMinutes(minutes);
    const timeDifferenceMs = specificDate - currentTime;
    const threshold = minutesThreshold * NUMBER.SIXTY * NUMBER.THOUSAND;
    return timeDifferenceMs < threshold;
};

export const getFormattedDate = (date) => {
    const formattedDate = new Date(date).toLocaleDateString("en-IN", dateFormat);
    return formattedDate.replace(/(\d{2} \w{3}) (\d{4})/, "$1, $2");
};
