import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import ExchangeBonus from "./component";

const mapStateToProps = ({
    location: {
        cityInfo
    }
}) => ({
    cityInfo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExchangeBonus));
