import { NUMBER } from "../../constants/app-constants";

export default ({popular, visiblePopularBrands}) => {
    const list = (popular || []).slice(NUMBER.ZERO, visiblePopularBrands);
    if ((list || []).length === NUMBER.ZERO) return list;

    const firstItem = list[0].display_order % NUMBER.TWO === NUMBER.ZERO ? NUMBER.ZERO : NUMBER.ONE;

    let currentIndex = NUMBER.ZERO;
    for (let i = NUMBER.ZERO; i < list.length; i++) {
        if (list[i].display_order % NUMBER.TWO === firstItem) {
            const item = list[i];
            list.splice(i, NUMBER.ONE);
            list.splice(currentIndex, NUMBER.ZERO, item);
            currentIndex++;
        }
    }
    return list;
};
