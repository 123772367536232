import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HomeBannerWrapper from "./component";
import { fetchHomepageBanners } from "../../shared/meta-tags/actions";
import { getRepeatInspectionWidget, setSsrStatus, setUserDropData, userDropUpdate } from "../user-journey-drop/actions";
import { setUserCarRegistrationNO } from "../car-registration-landing-banner-experiment/actions";
import { fetchLeadDetails } from "./actions";
import { getLeadFormData } from "../lead-form/actions";

const mapStateToProps = ({
    user: {
        isLoggedIn,
        secureToken,
        userId,
        gaId,
        statsigId
    },
    userDropWidget: {
        data: {isUserDrop, isError, allCardInfo, isUserToLead, leadOrApptExp, userDropList},
        isSSR,
        isU2lAppointmentCase,
        isU2lgaVisible,
        isNewUser
    },
    experiments: {
        userDrop: {
            data: userDropExperiment
        },
        userToLeadDrop: {
            data: userToLeadDropExperiment
        }
    },
    leadData: { year, token: leadToken, isFetchedOnSSR },
    _persist: persist
}) => ({
    isLoggedIn,
    secureToken,
    isSSR,
    isUserDrop,
    isError,
    allCardInfo,
    userId,
    userDropExperiment,
    userToLeadDropExperiment,
    gaId,
    isUserToLead,
    persist,
    year,
    leadToken,
    isU2lAppointmentCase,
    statsigId,
    isFetchedOnSSR,
    isU2lgaVisible,
    leadOrApptExp,
    isNewUser,
    userDropList
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchHomepageBannersConnect: fetchHomepageBanners,
    getRepeatInspectionWidgetConnect: getRepeatInspectionWidget,
    userDropUpdateConnect: userDropUpdate,
    setSsrStatusConnect: setSsrStatus,
    setUserCarRegistrationNOConnect: setUserCarRegistrationNO,
    setUserDropDataConnect: setUserDropData,
    fetchLeadDetailsConnect: fetchLeadDetails,
    getLeadFormDataConnect: getLeadFormData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeBannerWrapper);
