import React, { useEffect, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { sendCustomGaEvents } from "../../../tracking";
import { heroBanner } from "./tracking";
import { NUMBER } from "../../../constants/app-constants";
import getFileExtension from "../../../utils/helpers/get-file-extension";
import { BANNER_MAPPING, HOMEPAGE_TYPES } from "../../../constants/seller-constant";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const LandingBannerExperimentv2 = ({
    heading = "",
    headingContent,
    mainHeading,
    subHeading,
    homePageBanner,
    isSSR,
    fetchHomepageBannersConnect,
    children,
    homePageType = ""
}) => {

    const { h1, h2, h3, h4 } = headingContent || {};

    useEffect(() => {
        if (!isSSR && !homePageBanner) {
            const cmsPayload = {
                expand: "banner",
                category: BANNER_MAPPING.MWEB
            };
            fetchHomepageBannersConnect(cmsPayload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSSR]);

    const onClickHandler = useCallback(async () => {
        await yieldToMain();
        sendCustomGaEvents(heroBanner);
    }, []);

    const getHeading = ({headingText, subHeadingText}) => {
        return `${headingText} ${subHeadingText}`;
    };

    const renderHeading = () => {
        if (mainHeading) return <h1 >{mainHeading}</h1>;
        return (<h1 styleName={"styles.mainHeadingWrapper"}>
            {getHeading({headingText: h1, subHeadingText: h2})}
        </h1>);
    };

    const getBannerHeadingText = () => {
        if (homePageType === HOMEPAGE_TYPES.CITY_PAGE && mainHeading) {
            return (
                <>
                    <h1 styleName={"styles.mainHeadingCityPage"}>{mainHeading}</h1>
                </>
            );
        } else if (heading.length > NUMBER.ZERO) {
            return (
                <h1 styleName={"styles.mainHeadingWrapper"}>
                    {getHeading({
                        headingText: "Sell used car in",
                        subHeadingText: heading
                    })}
                </h1>
            );
        } else {
            return renderHeading();
        }
    };

    const renderSubHeading = () => {
        if (subHeading) return subHeading;
        return `${h3} ${h4}`;
    };

    return (
        <React.Fragment>
            <div styleName={"styles.bannerWrapper"} onClick={onClickHandler}>
                <Helmet>
                    <link
                        rel="preload"
                        href={homePageBanner}
                        imageSrcSet={homePageBanner}
                        as={"image"}
                        type={`image/${getFileExtension(homePageBanner)}`}
                        fetchpriority="high"
                    />
                </Helmet>
                <div styleName={"styles.soBannerWrapper"}>
                    <img width="360" height="220" fetchpriority="high" styleName={"styles.banners"} src={homePageBanner} alt="Hero Banner" />
                    <div styleName={"styles.bannerContent"}>
                        <div styleName="styles.content">
                            {getBannerHeadingText()}
                            <p styleName={"styles.puchline"}>{renderSubHeading()}</p>
                            { children && children}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

LandingBannerExperimentv2.propTypes = {
    heading: PropTypes.string,
    headingContent: PropTypes.object,
    mainHeading: PropTypes.string,
    subHeading: PropTypes.string,
    homePageBanner: PropTypes.string,
    isSSR: PropTypes.bool,
    fetchHomepageBannersConnect: PropTypes.func,
    children: PropTypes.any,
    homePageType: PropTypes.string
};

export default LandingBannerExperimentv2;
