/* eslint-disable complexity, max-statements */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { CF_RTD_STATUS, CF_STEP_ID, LOADER_STEPS } from "../../../constants/cf-constants";
import { POPUP_TYPES } from "../cf-popup/constant";
import { checkCfOffer, setDeeplinkOffer, setFinancePersonalDetails } from "../cf-popup/actions";
import { setLoanApplicationStatus, setPreApproved, setBajajOfferStatus } from "../../configuration/actions";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { checkLoaderWithCta, checkRtdLoader } from "../../../utils/helpers/check-cf-experiment";
import { NUMBER } from "../../../constants/app-constants";
const CfEmiLoader = loadable(() => import("../cf-emi-loader"));
const EligibleCarLoanPopup = loadable(() => import("../eligible-car-loan-popup"));
import { CF_STATUS } from "../../../constants/cf-constants";
import { oglScreenEntryPoints } from "./constants";
import { FORM_SOURCE } from "../interest-form-revamp/constant";

export default function withCfForm(Component) {
    const WrapperComponent = (props) => {

        const [showLoader, setShowLoader] = useState(false);
        const [showSplash, setShowSplash] = useState(false);

        const {
            checkCfOfferConnect,
            userId,
            match,
            history,
            setDeeplinkOfferConnect,
            mobile,
            equifax,
            equifaxLoader,
            name,
            cfRtdStatus,
            setLoanApplicationStatusConnect = () => { },
            setPreApprovedConnect = () => { },
            setBajajOfferStatusConnect = () => { },
            setFinancePersonalDetailsConnect = () => { },
            financePersonalDetail
        } = props || {};
        const queryParams = parseLocationSearchParams(history.location.search);
        const equiFaxLoaderWithCta = checkLoaderWithCta(equifaxLoader);
        const sourceObjRef = useRef({});
        const loaderTimer = useRef();

        const { dealer, dealerCode = "" } = queryParams || {};
        const isRtdLoaderEnabled = checkRtdLoader({ equifaxLoader, equifax, dealerCode });

        const cfNavigate = (path = "personal-details", cfSource, backTo) => {
            // eslint-disable-next-line no-undef
            const utmParams = new URLSearchParams(history.location.search);

            history.push(`/finance/${path}?backTo=${backTo}&cfSource=${cfSource}&${utmParams.toString()}`);
        };

        const navigate = (type, source, backTo) => {
            cfNavigate(CF_STEP_ID[type], source, backTo);
        };

        const onClickClose = () => {
            window.clearTimeout(loaderTimer.current);
            setShowLoader(false);
        };

        const openCfForm = ({ source, backTo = match.url, goToOfferScreen = false, fromCallback = false }) => {
            sourceObjRef.current = {
                source,
                backTo,
                goToOfferScreen
            };

            const skipRTDFlow = source === FORM_SOURCE.TEST_DRIVE_BOOKING;

            checkCfOfferConnect(userId, dealer === "true" ? mobile : undefined).then((response) => {
                const {
                    userDetailsSubmitted,
                    preApproved,
                    pincodeServiceable,
                    status,
                    bajajOfferStatus,
                    bankingSubmitted
                } = response || {};

                if (isRtdLoaderEnabled && !skipRTDFlow) {
                    setLoanApplicationStatusConnect(userDetailsSubmitted, bankingSubmitted);
                    setPreApprovedConnect(preApproved);
                    setBajajOfferStatusConnect(bajajOfferStatus);
                }

                if (!pincodeServiceable && oglScreenEntryPoints.includes(source)) {
                    setFinancePersonalDetailsConnect({
                        ...financePersonalDetail,
                        pinCode: ""
                    });
                }

                if (!pincodeServiceable && !oglScreenEntryPoints.includes(source)) {
                    navigate(POPUP_TYPES.PINCODE_UNSERVICEABLE, source, backTo);
                } else if (userDetailsSubmitted && preApproved && isRtdLoaderEnabled && fromCallback && !skipRTDFlow) {
                    setShowLoader(false);
                    setShowSplash(true);
                    window.clearTimeout(loaderTimer.current);
                    window.setTimeout(() => {
                        setShowSplash(false);
                        navigate(POPUP_TYPES.LOAN_OFFER, source, backTo);
                    }, NUMBER.TWO_THOUSAND);
                } else if (isRtdLoaderEnabled && !skipRTDFlow && userDetailsSubmitted && bajajOfferStatus === CF_RTD_STATUS.IN_PROGRESS) {
                    setShowLoader(true);
                    loaderTimer.current = window.setTimeout(() => {
                        onClickClose();
                    }, NUMBER.THIRTY_THOUSAND);
                } else if (goToOfferScreen) {
                    setDeeplinkOfferConnect(true);
                    navigate(POPUP_TYPES.LOAN_OFFER, source, "/");
                } else if ((userDetailsSubmitted && !preApproved) || (status === CF_STATUS.DISCARDED)) {
                    navigate(POPUP_TYPES.LOAN_ERROR, source, backTo);
                } else if (userDetailsSubmitted && preApproved) {
                    navigate(POPUP_TYPES.LOAN_OFFER, source, backTo);
                } else {
                    navigate(POPUP_TYPES.CF_FORM, source, backTo);
                }
            }).catch(() => {
                cfNavigate(CF_STEP_ID.CF_FORM, source, backTo);
            });

        };

        const offerCallback = () => {
            window.clearTimeout(loaderTimer.current);
            openCfForm({ ...sourceObjRef.current, fromCallback: true, open: false });
        };

        useEffect(() => {
            if (showLoader && cfRtdStatus && cfRtdStatus !== CF_RTD_STATUS.IN_PROGRESS) {
                window.clearTimeout(loaderTimer.current);
            }
        }, [cfRtdStatus, showLoader]);

        return (
            <React.Fragment>
                <Component openCfForm={openCfForm} {...props} />
                {
                    showLoader &&
                    <CfEmiLoader
                        currentStep={LOADER_STEPS.WAIT_FOR_OFFER}
                        closeLoader={onClickClose}
                        loaderCallback={offerCallback}
                        withDismissableCta={equiFaxLoaderWithCta}
                        rtdStatus={cfRtdStatus}
                        onClose={onClickClose}
                    />
                }
                {
                    showSplash && <EligibleCarLoanPopup name={name} />
                }
            </React.Fragment>
        );
    };

    WrapperComponent.propTypes = {
        checkCfOfferConnect: PropTypes.func,
        userId: PropTypes.string,
        match: PropTypes.object,
        history: PropTypes.object,
        setDeeplinkOfferConnect: PropTypes.func,
        mobile: PropTypes.string,
        equifax: PropTypes.string,
        equifaxLoader: PropTypes.string,
        name: PropTypes.string,
        setLoanApplicationStatusConnect: PropTypes.func,
        setPreApprovedConnect: PropTypes.func,
        setBajajOfferStatusConnect: PropTypes.func,
        setFinancePersonalDetailsConnect: PropTypes.func,
        financePersonalDetail: PropTypes.object
    };

    const mapStateToProps = ({
        user: {
            userId,
            mobile,
            equifax,
            equifaxLoader
        },
        consumerFinance: {
            name,
            financePersonalDetail
        }
    }) => ({
        userId,
        mobile,
        equifax,
        equifaxLoader,
        name,
        financePersonalDetail
    });

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        checkCfOfferConnect: checkCfOffer,
        setDeeplinkOfferConnect: setDeeplinkOffer,
        setLoanApplicationStatusConnect: setLoanApplicationStatus,
        setPreApprovedConnect: setPreApproved,
        setBajajOfferStatusConnect: setBajajOfferStatus,
        setFinancePersonalDetailsConnect: setFinancePersonalDetails
    }, dispatch);
    return connect(mapStateToProps, mapDispatchToProps)(
        withRouter(WrapperComponent)
    );
}
