import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HomeBannerV2 from "./component";

const mapStateToProps = ({
    experiments: {
        sellMarketing: {
            data: sellMarketingExperiment
        }
    }
}) => ({
    sellMarketingExperiment
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeBannerV2);
