import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SeoCarOverview from "./component";
import { updateCurrentSEOCity, fetchSEODetails, updateSSRStatus, fetchCarList } from "./actions";
import { withRouter } from "react-router";

const mapStateToProps = ({
    seoCarOverview: carDetail,
    carList: {
        filters: carListFilters
    },
    user: {
        gaId
    }
}) => ({
    carDetail,
    carListFilters,
    gaId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateCurrentSEOCityConnect: updateCurrentSEOCity,
    fetchSEODetailsConnect: fetchSEODetails,
    updateSSRStatusConnect: updateSSRStatus,
    fetchCarListConnect: fetchCarList
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeoCarOverview));
