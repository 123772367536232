import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_CURRENT_STEP
    SET_SELECTED_FINANCING_DATA
    SET_FINANCE_DATA
    SET_LOAN_TENURE_VALUE
    SET_CREDIT_SCORE_VALUE
    SET_DOWNPAYMENT_VALUE
    SET_MONTHLY_EMI_VALUE
    SET_ORDER_CREATED_DATE_TIME
    SET_DELIVERY_MODE
    SET_FINANCING_TAB
    SET_MIN_MAX_DOWNPAYMENT_VALUE
    SET_MIN_MAX_EMI_VALUE
    SET_FINANCE_SELECTED
    SET_FINANCE_PERSONAL_DETAILS
    SET_ORDER_ID
    SET_ORDER
    SET_INITIAL_STEP
    SET_FINANCE_UPDATED_DATA
    SET_DELIVERY_DATA

    GET_DELIVERY_INFO_SUCCESS
    GET_DELIVERY_INFO_FAILURE

    SET_FINANCE_SCREEN
    UPDATE_DELIVERY_MODE_SUCCESS
    UPDATE_DELIVERY_MODE_FAILURE

    CREATE_ORDER_SUCCESS
    CREATE_ORDER_FAILURE

    SET_CONFIRM_BOOKING_DATA

    UPDATE_VISITED_STEPS

    SET_FINANCE_CHARGE_LIST
    SET_CHARGE_LIST

    GET_ORDER_DETAIL_INIT
    GET_ORDER_DETAIL_SUCCESS
    GET_ORDER_DETAIL_FAILURE

    CHECK_ORDER_DETAIL_INIT
    CHECK_ORDER_DETAIL_SUCCESS
    CHECK_ORDER_DETAIL_FAILURE

    FETCH_LATEST_ORDER
    UPDATE_ACTIVE_FINANCE_FORM
    UPDATE_FINANCE_FORM_STATE
    SET_FINANCE_ADDRESS_DETAILS
    SET_FINANCE_INCOME_DETAILS

    GET_DELIVERY_SLOTS_SUCCESS
    GET_DELIVERY_SLOTS_FAILURE

    GET_ALL_OFFER_INITIATED
    GET_ALL_OFFER_SUCCESS
    GET_ALL_OFFER_FAILURE
    CHANGE_OFFER_DOWNPAYMENT
    SHOW_CAR_MODAL_MODAL
    FINANCE_USER_SELECTED_OFFER_DATA

    RESET_CHECKOUT

    UPDATE_FAST_CHECKOUT_TOGGLE

    SET_INSPECTION_STATE

    GET_LOAN_STAGES
    MOCK_NEXT_STAGE
    GET_LOAN_TNC
    GET_LOAN_TNC_CAR_DETAILS
    MARK_TERMS_FINALIZED
    DISABLE_TNC_FEEDBACK
    FINALIZE_LOAN_GRATIFICATION_VIEWED
    SET_CHECKOUT_INITIATED_STATUS
    GET_BOOKING_COUNT

    GET_ORDER_SUMMARY_INIT
    GET_ORDER_SUMMARY_SUCCESS
    GET_ORDER_SUMMARY_FAILURE
    SET_CHECKOUT_LOADER

    CONFIRM_FREE_BOOKING_INIT
    CONFIRM_FREE_BOOKING_SUCCESS
    CONFIRM_FREE_BOOKING_FAILURE

    SET_VEHICLE_PRICE
    SET_DOWNPAYMENT_CHANGED

    GET_FINANCE_INITIATED
    GET_FINANCE_SUCCESS
    GET_FINANCE_FAILURE
    SET_CHECKOUT_INFO
    UPDATED_SELECTED_PICKUP_LOCATION
    UPDATE_SELECTED_DATE_SLOT
    UPDATE_FINANCE_SOURCE
    UPDATE_DELIVERY_CHECKLIST_DATA
    UPDATE_DELIVERY_CHECKLIST_OTP

    SET_FINVU_PHONE_NUMBER
    GET_BOOKING_AMOUNT_SUCCESS

    SET_LOCATION_ADDRESS
    RESET_DELIVERY_SLOTS
    SET_CALCULATOR_EMI_OFFER
    UPDATE_ERROR_REASON
    SET_USER_SELECTED_POST_CF_OFFER

    SIMILAR_CAR_POPUP

    UPDATE_HD_MAP_POPUP
    SET_CF_BANK_LIST
    SET_POST_CF_FINANCE_DATA
    SET_CF_FAILURE_SCREEN_TYPE

    SET_REFRESH_STABLE_STATE

    TOGGLE_ADDED_BENEFIT_POPUP

    GET_OFFER_RANGES_SUCCESS
    SET_CURRENT_LOCATION
    SET_CHECKOUT_DATA

    SET_BOOKED_ORDERS_LIST
    SET_LOAN_STATUS_LABEL

    SET_ORDER_SUMMARY_DATA
    SET_RATING_DATA
    SET_VAS_LIST
    SET_VAS_LIST_V2

    SET_CHECKOUT_SOURCE
    SET_POST_BOOKING_SOURCE
    CLEAR_USER_SELECTED_OFFER
    SET_ADD_MORE_CAR

    FETCH_BUYBACK_INIT
    FETCH_BUYBACK_SUCCESS
    FETCH_BUYBACK_FAILURE
    TOGGLE_BUYBACK

    SET_TEST_DRIVE_DELIVERY_DATA
    SET_TEST_DRIVE_LOCATION_DATA
    SET_TEST_DRIVE_DETAILS_FETCHED
    SET_TEST_DRIVE_ADDRESS_FORM_DATA
    SET_TEST_DRIVE_ADDRESS_LIST
    SET_TEST_DRIVE_SUCCESS_LOADER
    SET_SELECTED_DELIVERY_ADDRESS
    CLEAR_SELECTED_DELIVERY_ADDRESS
    CLEAR_ADDRESS_FORM_DATA
    SET_TEST_DRIVE_CONFIG
    RESET_TEST_DRIVE_DATA
    SET_USER_HOME_LOCATION
    CLEAR_USER_HOME_LOCATION

`,
    {
        prefix: "checkout/"
    }
);
