import isNonZeroNumber from "./is-non-zero-number";

const IGNORE_PRICES = ["c2c_prices"];

export default (priceRange) => {
    const ranges = Object.keys(priceRange);
    for (let i = 0; i < ranges.length; i++) {
        if (!IGNORE_PRICES.includes(ranges[i])) {
            if (!isNonZeroNumber(priceRange[ranges[i]].min) ||
            !isNonZeroNumber(priceRange[ranges[i]].max)) {
                return true;
            }
        }
    }
    return false;
};
