export const ACTIVE_SCREEN = {
    HOME_DELIVERY_SLOTS: "HOME_DELIVERY_SLOTS",
    SELECT_ADDRESS: "SELECT_ADDRESS",
    SELF_PICKUP: "SELF_PICKUP",
    ADD_ADDRESS: "ADD_ADDRESS",
    BOOKING_SUMMARY: "BOOKING_SUMMARY",
    FINANCE_OFFER: "FINANCE_OFFER",
    RESERVATION_CONFIRMED: "RESERVATION_CONFIRMED",
    COMPLETE_PAYMENT: "COMPLETE_PAYMENT",
    TRANSACTION_HISTORY: "TRANSACTION_HISTORY",
    FINANCE_FLOW: "FINANCE_FLOW",
    PAYMENT_OPTIONS: "PAYMENT_OPTIONS",
    SKIPPABLE_BANKING: "SKIPPABLE_BANKING",
    FREE_PAID_BOOKING: "FREE_PAID_BOOKING",
    VISIT_HUB: "VISIT_HUB"
};
