
import { reducerConstants } from "../../../constants/reducer-constant";
import { INITIAL_STATE as seoReducerInitialState, HANDLERS as seoReducerHandler } from "../../../components/mobile/seo-car-overview/reducers";
import { INITIAL_STATE as modelsReducerInitialState, HANDLERS as modelsReducerHandler } from "../../../components/mobile/lead-models/reducers";
import { INITIAL_STATE as ratedTestimonialReducerInitialState, HANDLERS as ratedTestimonialReducerHandler } from "../../../components/mobile/testimonial-carousel/reducers";
import { INITIAL_STATE as sellUsedCarSeoReducerInitialState, HANDLERS as sellUsedCarSeoReducerHandler } from "../../../components/mobile/sell-used-car-seo/reducers";
import { INITIAL_STATE as registrationNoReducerInitialState, HANDLERS as registrationNoReducerHandler } from "../../../components/mobile/car-registration-landing-banner-experiment/reducer";
import { INITIAL_STATE as recentlyInspectedReducerInitialState, HANDLERS as recentlyInspectedReducerHandler } from "../../../components/mobile/recently-inspected/reducers";
import { INITIAL_STATE as leadDataReducerInitialState, HANDLERS as leadDataReducerHandler } from "../../../components/mobile/lead-form/reducers";
import { INITIAL_STATE as userDropWidgetReducerInitialState, HANDLERS as userDropWidgetReducerHandler } from "../../../components/mobile/user-journey-drop/reducers";
import { INITIAL_STATE as appointmentLocationReducerInitialState, HANDLERS as appointmentLocationReducerHandler } from "../../../components/mobile/appointment/reducers";
import { INITIAL_STATE as sellOnlineOfferPriceReducerInitialState, HANDLERS as sellOnlineOfferPriceReducerHandler } from "../../../components/mobile/sell-online-offer-price/reducers";

export default {
    [reducerConstants.SEO_CAR_OVERVIEW]: { state: seoReducerInitialState, handler: seoReducerHandler },
    [reducerConstants.MODELS]: { state: modelsReducerInitialState, handler: modelsReducerHandler },
    [reducerConstants.RATED_TESTIMONIALS]: { state: ratedTestimonialReducerInitialState, handler: ratedTestimonialReducerHandler },
    [reducerConstants.SELL_USED_CAR_SEO]: { state: sellUsedCarSeoReducerInitialState, handler: sellUsedCarSeoReducerHandler },
    [reducerConstants.REG_NO]: { state: registrationNoReducerInitialState, handler: registrationNoReducerHandler },
    [reducerConstants.RECENTLY_INSPECTED]: { state: recentlyInspectedReducerInitialState, handler: recentlyInspectedReducerHandler },
    [reducerConstants.LEAD_DATA]: { state: leadDataReducerInitialState, handler: leadDataReducerHandler },
    [reducerConstants.USER_DROP_WIDGET]: { state: userDropWidgetReducerInitialState, handler: userDropWidgetReducerHandler },
    [reducerConstants.APPOINTMENT_LOCATION]: { state: appointmentLocationReducerInitialState, handler: appointmentLocationReducerHandler },
    [reducerConstants.SELL_ONLINE_PRICE]: { state: sellOnlineOfferPriceReducerInitialState, handler: sellOnlineOfferPriceReducerHandler }

};

