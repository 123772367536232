import { Heading } from "@cars24/turbo-web/lib/heading";
import { BodyText } from "@cars24/turbo-web/lib/body-text";
import React, { startTransition, useCallback } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Banner from "./images/banner-bg-img.jpg";
import LightningIcon from "./images/lightning-icon.svg";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";

import CarRegistrationLandingBannerExperiment from "../car-registration-landing-banner-experiment";
import ResponsiveImage from "../../shared/responsive-image/index";
import { sendCustomGaEvents } from "../../../tracking";

const SellMarketingRevampHomeBanner = ({ safetyBannerTop, mainHeading, homePageBanner, history }) => {
    const { tokens } = useTokens();
    const altText = history.location.pathname.replace("/", "");

    const onClickHandler = useCallback(async () => {
        startTransition(() => {
            sendCustomGaEvents({
                event: "hero_banner",
                eventCategory: "homepage",
                eventAction: "banner_clicked"
            });
        });
    }, []);

    return (
        <React.Fragment>
            <div styleName={"styles.sell-marketing-banner-wrapper"}>
                <ResponsiveImage
                    src={homePageBanner || Banner}
                    alt={altText}
                    width={480}
                    height={467}
                    styleName={"styles.banner"}
                    onClick={onClickHandler}
                />
                <div styleName={"styles.bannerText"}>
                    <Heading level={1} color={tokens.text_default_text_primary}>{mainHeading || "Sell your car in minutes"}</Heading>
                </div>
                <div styleName={"styles.regNumbeWrapper"}>
                    <BodyText level={3} color={tokens.text_default_text_inverse}>Enter registration Number <img src={LightningIcon} alt="icon" /></BodyText>
                    <CarRegistrationLandingBannerExperiment
                        isSellMarketingRevamp
                        safetyBannerTopRef={safetyBannerTop}
                        safetyBannerTopFlag
                        isDlsCompliant
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

SellMarketingRevampHomeBanner.propTypes = {
    safetyBannerTop: PropTypes.object,
    mainHeading: PropTypes.string,
    homePageBanner: PropTypes.string,
    history: PropTypes.object
};

export default SellMarketingRevampHomeBanner;
