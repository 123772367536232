import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import InventoryCarCard from "./component";
import { setCFormIcon, reloadCarList } from "../car-list/actions";
import { showCFModal, updateCFPopUpType, setCfLabel, setCfModalType, checkCfOffer, toggleCfTenurePopup } from "../cf-popup/actions";
import { prePopulateCarDetail } from "../gs-car-details/actions";
import { getPaymentSummary, getPaymentHistory, showSimilarCarPopup as similarCarPopup, setPostBookingSource } from "../checkout-flow/actions";
import { setCompareCarsLimitError, addCarToCompare, removeCarCompare } from "../compare-widget/actions";
import { getTotalBooking } from "../../configuration/actions";

const mapStateToProps = ({
    carList: {
        filters: carListFilters,
        appliedOptions,
        isRedirected
    },
    buyCarPincodeConfig: {
        data: pincodeData
    },
    location: {
        pincode
    },
    user: {
        mobile,
        userId,
        isBookingLimitExceeded,
        secureToken,
        preApproved,
        buyerAbTest: {
            isROIOfferApplicable
        }
    },
    checkout: {
        order: {
            orderId: bookingId
        },
        showSimilarCarPopup
    },
    carDetails: { content: carDetailContent },
    compareCars: {
        isComparingActive,
        selectedCars: selectedCarsToCompare,
        totalCars: totalSelectedCarsToCompare
    },
    cities: { selectedCity },
    carList: { totalCarCount },
    myBookings: { bookings: cars },
    consumerFinance: {
        cfStatus
    }
}) => ({
    pincode,
    ciActive: (pincodeData || {}).ciActive,
    bookingId,
    mobile,
    carDetailContent,
    userId,
    isBookingLimitExceeded,
    secureToken,
    isComparingActive,
    selectedCarsToCompare,
    totalSelectedCarsToCompare,
    showSimilarCarPopup,
    selectedCity,
    totalCarCount,
    cars,
    preApproved,
    isROIOfferApplicable,
    creditStatus: cfStatus,
    carListFilters,
    appliedOptions,
    isRedirected
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showCFModalConnect: showCFModal,
    updateCFPopUpTypeConnect: updateCFPopUpType,
    setCFormIconConnect: setCFormIcon,
    prePopulateCarDetailConnect: prePopulateCarDetail,
    getPaymentSummaryConnect: getPaymentSummary,
    getPaymentHistoryConnect: getPaymentHistory,
    setCfLabelConnect: setCfLabel,
    setCompareCarsLimitErrorConnect: setCompareCarsLimitError,
    addCarToCompareConnect: addCarToCompare,
    removeCarCompareConnect: removeCarCompare,
    showSimilarCarPopupConnect: similarCarPopup,
    getTotalBookingConnect: getTotalBooking,
    setCfModalTypeConnect: setCfModalType,
    checkCfOfferConnect: checkCfOffer,
    reloadCarListConnect: reloadCarList,
    setPostBookingSourceConnect: setPostBookingSource,
    toggleCfTenurePopupConnect: toggleCfTenurePopup
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InventoryCarCard));
