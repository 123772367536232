export const EXPERIMENT_NAME = {
    similar_car_on_listing: "similar_car_on_listing",
    sell_marketing_download_app: "sell_marketing_download_app",
    skip_home_test_drive: "skip_home_test_drive",
    test_drive_flow_on_fb_page: "test_drive_flow_on_fb_page",
    recommended_car_on_listing: "recommended_car_on_listing",
    sell_marketing_video_highlights: "sell_marketing_video_highlights",
    rto_profiling: "rto_profiling",
    user_to_lead_drop: "user_to_lead_drop",
    car_model: "car_model",
    single_web_quote: "single_web_quote",
    app_nudges_on_msite: "get_app_nudges_on_msite",
    dls_listing_page: "dls_listing_page",
    in_session_personalisation: "in_session_personalisation",
    location_category_page: "location_category_page",
    app_download_banner: "app_download_banner",
    cash_intake: "cash_intake",
    seller_finance: "seller_finance",
    sell_marketing_revamp: "sell_marketing_revamp",
    rto_revamp: "rto_revamp",
    ola_maps: "ola_maps",
    repeat_different_phone_number: "repeat_different_phone_number",
    price_page_app_download: "price_page_app_download",
    luxury_good_price_experiment: "luxury_good_price_experiment",
    web_quote_revamp_experiment: "web_quote_revamp_experiment",
    sell_marketing_prospect_lead: "sell_marketing_prospect_lead",
    exit_intent_experiment: "exit_intent_experiment",
    challan_post_order_summary_parivahan_screen_experiment: "challan_post_order_summary_parivahan_screen_experiment",
    sell_marketing_call_intent: "sell_marketing_call_intent"
};

export const GATE_NAME = {
    skip_home_test_drive_gate: "skip_home_test_drive_gate",
    tax_visibility_tof: "tax_visibility_tof",
    app_nudge_floating_experiment: "app_nudge_floating_experiment",
    hide_inspection_report_msite: "hide_inspection_report_msite",
    tof_free_htd_experiment: "tof_free_htd_experiment",
    website_advertisement_banner: "website_advertisement_banner",
    location_permission_bottom_nudge: "location_permission_bottom_nudge",
    real_time_recommendations_gate: "real_time_recommendations_gate",
    login_flow_bottom_sheet: "login_flow_bottom_sheet",
    autonaut_msite: "autonaut_msite",
    seller_post_appt_feedback_app: "seller_post_appt_feedback_app",
    ucl_web_user_journey: "ucl_web_user_journey"
};

export const DYNAMIC_CONFIG = {
    category_page: "category_page",
    category_page_desktop: "category_page_desktop",
    website_ads: "website_ads"
};
