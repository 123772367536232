import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BottomNavbar from "./component";
import { withRouter } from "react-router-dom";
import { toggleLocationPicker, locationPageType, setIsCTAClickedWithoutPincode } from "../location-picker-popup-v2/actions";

const mapStateToProps = ({
    user: {
        isLoggedIn,
        name,
        isFetched
    },
    cities: {
        selectedCity
    },
    config: { cityList: cities },
    location: { pincode, cityInfo },
    locationPicker: { isCTAClickedWithoutPincode }
}) => ({
    isLoggedIn,
    selectedCity,
    cities,
    userName: name,
    isFetched,
    pincode,
    isCTAClickedWithoutPincode,
    cityInfo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    toggleLocationPickerConnect: toggleLocationPicker,
    locationPageTypeConnect: locationPageType,
    setIsCTAClickedWithoutPincodeConnect: setIsCTAClickedWithoutPincode

}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BottomNavbar));
