import { STEP_NAMES } from "../../../constants/app-constants";
import { findStep } from "../../../utils/funnel/stepper";

export const SELL_ONLINE_LEAD_TYPE = "original-sell-online";
export const UPCOMING_APPOINTMENT_TYPE = ["UPCOMING_APPOINTMENT", "PASSED_APPOINTMENT"];

export const setLeadDetail = (leadResponse) => {
    let payload = {};
    payload = {
        brand: {
            displayText: leadResponse.make_name,
            id: leadResponse.make_id
        },
        model: {
            displayText: leadResponse.model_name,
            id: leadResponse.model_id
        },
        year: {
            displayText: leadResponse.year,
            id: leadResponse.year_id
        },
        fuelType: {
            displayText: leadResponse.fuel_type
        },
        transmission: {
            displayText: leadResponse.transmission_type
        },
        variant: {
            displayText: leadResponse.variant_name,
            id: leadResponse.variant_id
        },
        state: {
            displayText: leadResponse.state_code,
            id: leadResponse.state_id
        },
        rto: {
            displayText: leadResponse.rto_code,
            id: leadResponse.rto_id
        }

    };
    return payload;
};

export const getCurrentStep = ({ leadData, leadStep, stepper}) => {
    let currentStepData = leadData[leadStep] || {};
    if (leadStep === STEP_NAMES.Kms) {
        currentStepData = leadData[STEP_NAMES.KilometerDriven] || {};
    }
    if (currentStepData && currentStepData.displayText) {
        const currentNode = findStep({ list: stepper, stepName: leadStep });
        return currentNode.next.value.name;
    }
    return leadStep;
};
