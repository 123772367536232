/* eslint-disable complexity */
/* eslint-disable max-depth */
/* eslint-disable max-statements */
import React from "react";
import styles from "./styles.css";
import Rating from "../react-rating-wrapper";
import IconStar from "../icon-star";
import initialExtractor from "../../../utils/parsers/initial-extractor";
import PropTypes from "prop-types";
import LazyImage from "../lazy-image";
import {getDateWithMonthFormat} from "../../../utils/helpers/get-day-from-date";
const RatingCard = ({
    customer_name: name,
    with_image: withImage,
    make,
    model,
    purchaseType = "Sold",
    review_image: reviewImage,
    review_rating: rating,
    review_description: description,
    city,
    testimonialRevamp,
    created_at: createdAt,
    title,
    isLazyLoaded = false,
    showDate = true
}) => {
    const getAvatarImage = () => {
        let image = (
            <LazyImage
                styleName={"styles.userImg"}
                src={reviewImage}
                alt="Seller" />
        );
        if (!withImage) {
            image = (
                <p
                    styleName={"styles.intials"}>
                    {initialExtractor(name)}
                </p>
            );
        }
        return image;
    };

    return (
        <div>
            <div className={"card"} styleName={`styles.cardWrap  ${testimonialRevamp ? "testimonialWrapper" : ""}`}>
                <div className="media" styleName={"styles.userList"}>
                    {!testimonialRevamp && getAvatarImage()}
                    <div className="media-body" styleName={"styles.userName"}>
                        <div styleName={"styles.usernameMakeModal"}>
                            {!testimonialRevamp && <React.Fragment>
                                <h5>{name}</h5>
                                <p styleName={"styles.soldText"}>{purchaseType} {make} {model}</p>
                            </React.Fragment>}
                        </div>
                        <div styleName={"styles.starRatingList"}>
                            {
                                rating &&
                                <Rating
                                    readonly
                                    initialRating={parseInt(rating)}
                                    emptySymbol={<IconStar type={"empty"} isLazyLoaded={isLazyLoaded}/>}
                                    fullSymbol={<IconStar type={"fill"} isLazyLoaded={isLazyLoaded}/>}
                                />
                            }
                            {testimonialRevamp && showDate && createdAt && <span styleName={"styles.date"}>{getDateWithMonthFormat(new Date(createdAt))}</span>}
                            {!testimonialRevamp &&
                                <p styleName={"styles.areaTag"}>{city}</p>
                            }
                        </div>
                        {testimonialRevamp && title && <h3 styleName={"styles.title"}>“{title}”</h3>}
                    </div>
                </div>
                <div styleName={"styles.testimonialCard"} className="card-body">
                    <p>
                        {description}
                    </p>
                </div>
                {testimonialRevamp && <div styleName={"styles.testimonialUser"}>
                    <h5>{name}</h5>
                    <p styleName={"styles.makeModel"}>{purchaseType} {make} {model}</p>
                </div>}
            </div>
        </div>
    );
};

RatingCard.propTypes = {
    customer_name: PropTypes.string,
    withImage: PropTypes.bool,
    review_rating: PropTypes.string,
    purchaseType: PropTypes.string,
    review_description: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    location_slug: PropTypes.string,
    with_image: PropTypes.bool,
    review_image: PropTypes.string,
    city: PropTypes.string,
    testimonialRevamp: PropTypes.bool,
    created_at: PropTypes.any,
    title: PropTypes.string,
    isLazyLoaded: PropTypes.bool,
    showDate: PropTypes.bool
};

export default RatingCard;
