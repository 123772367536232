import { getDeviceType } from "./device-type";

const ORIGIN_SOURCE = "c2b-website";

export const getPlatformHeaders = (platform) => {
    return {
        platform,
        origin_source: ORIGIN_SOURCE,
        device_category: getDeviceType().source
    };
};
