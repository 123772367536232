export const SOURCE = {
    MY_BOOKINGS: "MY_BOOKINGS",
    PENDING_BOOKINGS: "PENDING_BOOKINGS",
    LISTING: "LISTING",
    WISHLIST: "WISHLIST",
    WISHLIST_SIMILAR_CARS: "WISHLIST_SIMILAR_CARS",
    SIMILAR_CARS: "SIMILAR_CARS",
    RECENT_CARS: "RECENT_CARS",
    FEATURED_CARS: "FEATURED_CARS",
    CAR_COMPARE: "CAR_COMPARE",
    MY_BOOKING: "MY_BOOKING",
    PENDING_BOOKING: "PENDING_BOOKING",
    MY_PURCHASE: "MY_PURCHASE",
    CARS_BY_BODY_TYPE: "CARS_BY_BODY_TYPE",
    RECENT_CARS_HOME: "RECENT_CARS_HOME",
    RECENTLY_ADDED: "RECENTLY_ADDED",
    BEST_MATCHES: "BEST_MATCHES"
};

export const BOOKING_STATE = {
    CANCEL: "CANCEL",
    EXPIRED: "EXPIRED",
    DELIVERED: "DELIVERED",
    RETURNED: "RETURNED",
    BOOKED: "BOOKED"
};

export const RETURN_STATE = {
    REQUESTED: "REQUESTED"
};

export const DELIVERY_MODE = {
    HOME_DELIVERY: "HOME_DELIVERY",
    PICK_UP: "PICK_UP"
};

export const CAR_STATUS = {
    RESERVED: "Reserved",
    GETTING_SERVICED: "Under Service",
    SOLD: "Sold",
    TOKENED: "tokened"
};
