import React, { memo } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { NAV_ICON_LIST } from "./constants";
import BottomNavIcon from "../bottom-nav-icon";

const BottomNavIconList = memo(({
    onTabClickHandler = () => {},
    isScrollingDown
}) => {

    return  (
        <div styleName={`styles.navbar styles.container ${!isScrollingDown ? "styles.visible" : "styles.hidden"}`}>
            {
                NAV_ICON_LIST.map((navItem) => {
                    const { tab, ActiveIcon, DefaultIcon, pageName, label } = navItem || {};
                    return (
                        <BottomNavIcon
                            key={tab}
                            pageName={pageName}
                            label={label}
                            onTabClickHandler={onTabClickHandler}
                            tab={tab}
                            ActiveIcon={ActiveIcon}
                            DefaultIcon={DefaultIcon}
                        />
                    );
                })
            }
        </div>
    );
}, (prevProps, nextProps) => {
    return prevProps.selectedCityID === nextProps.selectedCityID && prevProps.pincode === nextProps.pincode && prevProps.isScrollingDown === nextProps.isScrollingDown;
});

BottomNavIconList.propTypes = {
    onTabClickHandler: PropTypes.func,
    isScrollingDown: PropTypes.bool
};

export default BottomNavIconList;
