import React from "react";
import PropTypes from "prop-types";
import RcTrasferBottomSheet from "../rc-trasfer-bottom-sheet";
import GsTagNotSoldBottomSheet from "../gs-tag-not-sold-bottom-sheet";
import WelcomeBackLoader from "../welcome-back-loader";
import { GS_TAGS } from "../../../constants/seller-constant";

const GsRepeatComponentWrapper = ({ gsData, gsRedirectHandler, isBottomsheet, setIsBottomsheet }) => {
    const { gsCarStatus = "", gsStateContent = {} } = gsData || {};
    const commonProps = {
        content: gsStateContent,
        isBottomsheet,
        setIsBottomsheet: () => setIsBottomsheet(false)
    };

    const componentMap = {
        [GS_TAGS.SOLD]: <RcTrasferBottomSheet {...commonProps} isGSsoldRCNotTransferred />,
        [GS_TAGS.PUBLISHED]: <GsTagNotSoldBottomSheet {...commonProps} isGSCarListed />,
        [GS_TAGS.UNPUBLISHED]: <GsTagNotSoldBottomSheet {...commonProps} />,
        [GS_TAGS.RETURNED]: <GsTagNotSoldBottomSheet {...commonProps} />,
        [GS_TAGS.NON_GS_CAR]: <WelcomeBackLoader {...commonProps} redirect={gsRedirectHandler} />,
        [GS_TAGS.RC_TRANSFERRED]: <WelcomeBackLoader {...commonProps} redirect={gsRedirectHandler} />
    };

    return (
        <>
            {isBottomsheet && componentMap[gsCarStatus]}
        </>
    );
};

GsRepeatComponentWrapper.propTypes = {
    gsRedirectHandler: PropTypes.func,
    gsData: PropTypes.object,
    isBottomsheet: PropTypes.bool.isRequired,
    setIsBottomsheet: PropTypes.func.isRequired
};

export default GsRepeatComponentWrapper;
