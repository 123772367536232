
import Types from "./types";
import { CarService } from "../../../service/car-service";
import { VehicleService } from "../../../service/vehicle-service";
import { AB_TEST_VARIATIONS, HTTP_CODES } from "../../../constants/app-constants";
import { getFormattedData } from "./selector";
import { LeadServiceJava } from "../../../service/lead-service-java-api";
import parseVehicleNumberDetails from "../../../utils/parsers/parse-vehicle-number-details";

const setUserCarRegistrationNO = number => ({
    type: Types.SET_USER_CAR_REGISTRATION_NO,
    number
});

const setDataFaliure = (flag) => ({
    type: Types.SET_ERROR,
    flag
});

const fetchDataSuccess = data => ({
    type: Types.FETCH_REGISTRATION_NO_DETAIL,
    data
});

const setIsChangingPreFetchedDetail = flag => ({
    type: Types.IS_CHANGING_PRE_FETCHED_DETAIL,
    flag
});

const resetLeadFormData = () => ({
    type: Types.RESET_REGISTRATION_DATA
});

const setAllDetailFetchedCorrectly = flag => ({
    type: Types.ALL_DETAIL_FETCHED_CORRECTLY,
    flag
});

const setDuplicateInspectionInfo = duplicateInspectionInfo => ({
    type: Types.SET_DUPLICATE_INSPECTION_INFO,
    duplicateInspectionInfo
});

const setRtoServiceHistoryPopupCount = rtoServiceHistoryPopupCount => ({
    type: Types.SET_RTO_SERVICE_HISTORY_POPUP_COUNT,
    rtoServiceHistoryPopupCount
});

const setIsRepeatInspection = isRepeatInspection => ({
    type: Types.SET_IS_REPEAT_INSPECTION,
    isRepeatInspection
});

const setIsRegInputBoxActive = isRegInputBoxActive => ({
    type: Types.SET_IS_INPUT_BOX_ACTIVE,
    isRegInputBoxActive
});

const getRtoDetailsFromRegNo = ({
    registrationNo,
    resolve,
    reject,
    dispatch
}) => {
    VehicleService.getRtoDetailByRegNo(registrationNo)
        .then(response => {
            const data = getFormattedData(response.data.detail);
            dispatch(fetchDataSuccess(data));
            resolve(data);
        })
        .catch(error => {
            dispatch(setDataFaliure(true));
            reject(error);
        });
};

const getVehicleDetail = (registrationNo, isFallBackApplicable = true, extraParams) => dispatch => {
    dispatch(resetLeadFormData());
    dispatch(setUserCarRegistrationNO(registrationNo));
    return new Promise((resolve, reject) => {
        CarService.vehicleNoRegistration(registrationNo, extraParams)
            .then(response => {
                dispatch(fetchDataSuccess(response.data.detail));
                resolve(response.data.detail);
            })
            .catch((error) => {
                if (isFallBackApplicable && error.status === `${HTTP_CODES.NOT_FOUND}`) {
                    getRtoDetailsFromRegNo({
                        registrationNo,
                        resolve,
                        reject,
                        dispatch
                    });
                } else {
                    dispatch(setDataFaliure(true));
                    reject(error);
                }
            });
    });
};

const checkInspectedAppointmentExistence = (registrationNo, leadSource, secureToken) => dispatch => {
    dispatch(resetLeadFormData());
    dispatch(setUserCarRegistrationNO(registrationNo));
    return new Promise((resolve, reject) => {
        LeadServiceJava.checkInspectedAppointmentExistence(registrationNo, leadSource, false, secureToken)
            .then((response) => {
                const data = parseVehicleNumberDetails(response.data.detail);
                dispatch(setDuplicateInspectionInfo(data));
                dispatch(fetchDataSuccess(response.data.detail));
                resolve({
                    ...data,
                    isRepeatInspection: response.data.detail.userJourneyAbExperiment !== AB_TEST_VARIATIONS.CONTROL
                });
            }).catch((error) => {
                if (error.status === HTTP_CODES.NOT_FOUND) {
                    resolve({
                        isRepeatInspection: false
                    });
                } else {
                    reject(error);
                }
            });
    });
};

export {
    getVehicleDetail,
    setDataFaliure,
    setUserCarRegistrationNO,
    setAllDetailFetchedCorrectly,
    setIsChangingPreFetchedDetail,
    checkInspectedAppointmentExistence,
    setRtoServiceHistoryPopupCount,
    setIsRepeatInspection,
    setIsRegInputBoxActive,
    resetLeadFormData
};
