/* eslint-disable max-params */
import Types from "./types";
import { UsedCarService } from "../../../service/used-car-service";
import { OmsAggregatorService } from "../../../service/oms-aggregator-service";
import { CatalogGatewayService } from "../../../service/catalog-gateway";
import { CatalogCarService } from "../../../service/catalog-car-service";
import { B2cGatewayService } from "../../../service/b2c-gateway-service";

const fetchDetails = () => ({
    type: Types.FETCH_CAR_DETAILS
});
// eslint-disable-next-line max-params

const initFetchInspectionReport = () => ({
    type: Types.INIT_FETCH_INSPECTION_REPORT
});

const setInspectionReport = (data) => ({
    type: Types.SET_FETCH_INSPECTION_REPORT,
    data
});

const fetchInspectionReportFailure = (error) => ({
    type: Types.FETCH_INSPECTION_REPORT_FAILURE,
    error
});

const fetchInspectionReport = (appId) => dispatch => {
    dispatch(initFetchInspectionReport());
    return new Promise((resolve, reject) => {
        UsedCarService.fetchCarInspectionReport(appId)
            .then(response => {
                dispatch(setInspectionReport(response.data.data));
                resolve(response.data.data);
            }).catch(error => {
                dispatch(fetchInspectionReportFailure(error));
                reject(error);
            });
    });
};

const fetchSimilarOfferCars = ({ size, page, append }) => ({
    type: Types.FETCH_SIMILAR_TO_OFFER_CARS,
    similarCarsPage: page,
    similarCarsSize: size,
    appendSimilarCars: append
});

const fetchSimilarToOfferCarsSuccess = (data) => ({
    type: Types.FETCH_SIMILAR_TO_OFFER_CARS_SUCCESS,
    data
});

const fetchSimilarToOfferCarsFailure = (error) => ({ type: Types.FETCH_SIMILAR_TO_OFFER_CARS_FAILURE, error });

const fetchSimilarToOfferCars = (carId, params) => dispatch => {
    dispatch(fetchSimilarOfferCars(params));
    return new Promise((resolve, reject) => {
        const apiParams = { ...params };
        delete apiParams.append;
        UsedCarService.fetchSimilarCarsById(carId, apiParams)
            .then(response => {
                const data = response.data.data || {};
                const { similarCars } = data;
                dispatch(fetchSimilarToOfferCarsSuccess(similarCars || []));
                resolve(response.data.data);
            }).catch(error => {
                dispatch(fetchSimilarToOfferCarsFailure(error));
                reject(error);
            });
    });
};

const hasOfferMade = (mobile, appointmentId) => () => {
    return new Promise((resolve, reject) => {
        UsedCarService.hasOfferMade(mobile, appointmentId)
            .then(resp => {
                resolve(resp.data.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const fetchOfferScoreSuccess = (data) => ({
    type: Types.FETCH_OFFER_SCORE_SUCCESS,
    data
});

const fetchOfferScoreFailure = (error) => ({
    type: Types.FETCH_OFFER_SCORE_FAILURE,
    error
});

const fetchOfferScore = (mobile, appointmentId) => (dispatch) => {
    UsedCarService.fetchOfferScore(mobile, appointmentId)
        .then(response => {
            dispatch(fetchOfferScoreSuccess(response.data.data));
        }).catch(error => {
            dispatch(fetchOfferScoreFailure(error));
        });
};

const updateCarDetailsSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const updateSelectedImage = (selectedImage) => ({
    type: Types.UPDATE_SELECTED_IMAGE,
    selectedImage
});

const updateImpressionSource = (impressionSource) => ({
    type: Types.UPDATE_IMPRESSION_SOURCE,
    impressionSource
});

const updateActiveSimilarToOfferCarIndex = (activeSimilarToOfferCarIndex) => ({
    type: Types.UPDATE_ACTIVE_SIMILAR_TO_OFFER_CAR_INDEX,
    activeSimilarToOfferCarIndex
});

const removeSimilarToOfferCar = (appId) => ({
    type: Types.REMOVE_SIMILAR_TO_OFFER_CAR,
    appId
});

const updatePageLoaderStatus = (isPageLoading) => ({
    type: Types.UPDATE_PAGE_LOADER_STATUS,
    isPageLoading
});

const setPaymentOption = (paymentOption) => ({
    type: Types.SET_PAYMENT_OPTION,
    paymentOption
});

const fetchPdfLinkInit = () => ({
    type: Types.FETCH_PDF_LINK
});

const fetchPdfLinkSuccess = (data) => ({
    type: Types.FETCH_PDF_LINK_SUCCESS,
    data
});

const fetchPdfLinkFailure = (error) => ({ type: Types.FETCH_PDF_LINK_FAILURE, error });

const fetchPdfLink = (carId) => dispatch => {
    dispatch(fetchPdfLinkInit());
    return new Promise((resolve, reject) => {
        UsedCarService.fetchPdfLink(carId)
            .then(response => {
                dispatch(fetchPdfLinkSuccess(response.data.data));
                resolve(response.data.data);
            }).catch(error => {
                dispatch(fetchPdfLinkFailure(error));
                reject(error);
            });
    });
};

const updateSpincarModalStatus = (isSpinCarModalOpen) => ({
    type: Types.UPDATE_SPINCAR_MODAL_STATUS,
    isSpinCarModalOpen
});

const fetchCarLocationsSuccess = (data, pincode) => ({
    type: Types.FETCH_CAR_LOCATIONS_SUCCESS,
    data,
    pincode
});

const fetchCarLocationsFailure = (error) => ({
    type: Types.FETCH_CAR_LOCATIONS_FAILURE,
    error
});

const fetchCarLocations = ({ appointmentId, pincode, token, clientId }) => (dispatch) => {
    OmsAggregatorService.getPickupLocations({ appointmentId, pincode, token, clientId, source: "mSite" })
        .then(response => {
            dispatch(fetchCarLocationsSuccess(response.data.data, pincode));
            dispatch(fetchCarLocationsFailure(null));
        }).catch(error => {
            dispatch(fetchCarLocationsFailure(error));
        });
};

const setGsGalleryData = (data) => ({
    type: Types.SET_GS_GALLERY_DATA,
    data
});

const setReportType = (reportType) => ({
    type: Types.SET_REPORT_TYPE,
    reportType
});

const setFinancingPopup = (financingPopup) => ({
    type: Types.SET_FINANCING_POPUP,
    financingPopup
});

// eslint-disable-next-line no-unused-vars

const setActiveCarConditionTab = (key) => ({
    type: Types.SET_ACTIVE_CAR_CONDITION_TAB,
    key
});

const setHelloArGalleryData = (data) => ({
    type: Types.SET_HELLO_AR_GALLERY,
    data
});

const setS20GalleryData = (data) => ({
    type: Types.SET_S20_GALLERY,
    data
});

const setImperfectionGalleryData = (data) => ({
    type: Types.SET_IMPERFECTION_GALLERY,
    data
});

const resetCarDetails = () => ({
    type: Types.RESET_CAR_DETAILS
});

const setGetNotifyme = (data) => ({
    type: Types.GET_NOIFY_ME,
    data
});

const getNotifyme = (apptId, clientId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (token) {
            CatalogCarService.getNotifyme(apptId, token, clientId)
                .then(response => {
                    dispatch(setGetNotifyme(response.data));
                    resolve(response.data.data);
                }).catch(error => {
                    dispatch(setGetNotifyme(null));
                    reject(error);
                });
        } else {
            dispatch(setGetNotifyme(null));
        }
    });
};

const postNotifyme = (params, clientId, token) => () => {
    return new Promise((resolve, reject) => {
        CatalogCarService.postNotifyme(params, clientId, token)
            .then(response => {
                resolve(response.data.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const fetchPincodeValidation = (appointmentId, pincode, params) => () => {
    return new Promise((resolve, reject) => {
        UsedCarService.getCarLocations(appointmentId, pincode, params)
            .then(response => {
                resolve(response.data.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const prePopulateCarDetail = (data) => ({
    type: Types.PRE_POPULATE_CAR_DETAIL,
    data
});

const setSendServiceHistory = (sendServiceHistoryReport) => ({
    type: Types.SEND_SERVICE_HISTORY,
    sendServiceHistoryReport
});

const setSendNotifyFlow = (sendNotifyFlow) => ({
    type: Types.SEND_NOTIFY_FLOW,
    sendNotifyFlow
});

const sendOemServiceHstoryNotify = (apptId, token) => () => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.postOemServiceHstoryNotify(apptId, token)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const fetchCarDetailsSuccess = (data) => ({
    type: Types.FETCH_CAR_DETAILS_SUCCESS,
    data
});

const fetchCarDetailsFailure = (error) => ({ type: Types.FETCH_CAR_DETAILS_FAILURE, error });

// eslint-disable-next-line max-params
const fetchCarDetails = (appointmentId, payload, token) => (dispatch) => {
    dispatch(fetchDetails());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarDetails(appointmentId, payload, token)
            .then(response => {
                dispatch(fetchCarDetailsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarDetailsFailure(error));
                reject(error);
            });
    });
};

const updateCarDetails = (carId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarDetails(carId)
            .then(response => {
                dispatch(fetchCarDetailsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarDetailsFailure(error));
                reject(error);
            });
    });
};

const setGalleryData = (data) => ({
    type: Types.SET_GALLERY_DATA,
    data
});

const fetchMoreCarsSuccess = (moreCarsData) => ({
    type: Types.FETCH_MORE_CARS,
    moreCarsData
});

const fetchMoreCarsForYou = (appointmentId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.fetchMoreCarsForYou(appointmentId)
            .then(response => {
                dispatch(fetchMoreCarsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const updateMoreCarsSSRStatus = (isSSR) => ({
    type: Types.UPDATE_MORE_CARS_SSR_STATUS,
    isSSR
});

const updateVisitedAppointmentIds = (data) => ({
    type: Types.UPDATE_VISITED_APPOINTMENT_IDS,
    data
});

// action for new car API Version

const fetchDetailsV2 = () => ({
    type: Types.FETCH_CAR_DETAILS_V2
});
const fetchCarDetailsSuccessV2 = (data, creditStatus) => ({
    type: Types.FETCH_CAR_DETAILS_SUCCESS_V2,
    data,
    creditStatus
});

const fetchCarDetailsFailureV2 = (error) => ({ type: Types.FETCH_CAR_DETAILS_FAILURE_V2, error });

// eslint-disable-next-line max-params
const fetchCarDetailsV2 = (appointmentId, payload, creditStatus, secureToken) => (dispatch) => {
    dispatch(fetchDetailsV2());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarDetailsVersion2(appointmentId, payload, secureToken)
            .then(response => {
                dispatch(fetchCarDetailsSuccessV2(response.data, creditStatus));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarDetailsFailureV2(error));
                reject(error);
            });
    });
};

const updateCategoryImages = (showSimilarCarCategory) => ({
    type: Types.UPDATE_CATEGORY_IMAGES,
    showSimilarCarCategory
});

const updateFeatureSpecsModalData = (featureSpecsModal) => ({
    type: Types.UPDATE_FEATURE_SPECS_MODAL_DATA,
    featureSpecsModal
});

const updateInspectionReportData = (inspectionReportData) => ({
    type: Types.UPDATE_INSPECTION_REPORT_DATA,
    inspectionReportData
});
const updateLinkedCarData = (data) => ({
    type: Types.UPDATE_LINKED_CAR_DATA,
    data
});

const serviceHistoryReportInit = () => ({
    type: Types.FETCH_SERVICE_HISTORY_REPORT_INIT
});

const serviceHistoryReportSuccess = (serviceHistory) => ({
    type: Types.FETCH_SERVICE_HISTORY_REPORT_SUCCESS,
    serviceHistory
});

const serviceHistoryReportFailure = () => ({
    type: Types.FETCH_SERVICE_HISTORY_REPORT_INIT_FAILURE
});

const fetchServiceHistoryReport = (appointmentId, token, params) => dispatch => {
    dispatch(serviceHistoryReportInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getOemServiceHistory(appointmentId, token, params)
            .then(response => {
                dispatch(serviceHistoryReportSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(serviceHistoryReportFailure());
                reject(error);
            });
    });
};

const setViewSimilarButtonClicked = (data) => ({
    type: Types.VIEW_SIMILAR_BUTTON_CLICKED,
    data
});

const setPageLoadState = (data) => ({
    type: Types.SET_PAGE_LOAD_STATE,
    data
});

const detailPageVisited = () => ({
    type: Types .DETAIL_PAGE_VISITED
});
const initVasPayment = (token, body, params, userId) => () => {
    return new Promise((resolve, reject) => {
        B2cGatewayService.initVasPayment(token, body, params, userId)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export {
    fetchCarDetails,
    hasOfferMade,
    updateCarDetailsSSRStatus,
    updateSelectedImage,
    updateImpressionSource,
    fetchSimilarToOfferCars,
    updateActiveSimilarToOfferCarIndex,
    removeSimilarToOfferCar,
    fetchOfferScore,
    updatePageLoaderStatus,
    setPaymentOption,
    fetchPdfLink,
    updateSpincarModalStatus,
    fetchCarLocations,
    fetchInspectionReport,
    setGsGalleryData,
    setReportType,
    setFinancingPopup,
    setActiveCarConditionTab,
    resetCarDetails,
    getNotifyme,
    postNotifyme,
    setHelloArGalleryData,
    setS20GalleryData,
    setImperfectionGalleryData,
    updateCarDetails,
    fetchPincodeValidation,
    prePopulateCarDetail,
    setSendServiceHistory,
    setSendNotifyFlow,
    sendOemServiceHstoryNotify,
    setGalleryData,
    updateMoreCarsSSRStatus,
    fetchMoreCarsForYou,
    updateVisitedAppointmentIds,
    fetchCarDetailsV2,
    updateCategoryImages,
    updateFeatureSpecsModalData,
    updateInspectionReportData,
    fetchServiceHistoryReport,
    updateLinkedCarData,
    setViewSimilarButtonClicked,
    setPageLoadState,
    detailPageVisited,
    initVasPayment
};
