import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import { getNotServicibilityStatus } from "./utils";

export const INITIAL_STATE = {
    isSubmittingData: false,
    appointmentId: "",
    fullname: "",
    error: false,
    sellerReview: {},
    offeredPrice: 0,
    isSubmitted: false,
    showFeedbackScreen: false,
    userChoice: "",
    phone: "",
    showAsssuredPriceBanner: false,
    appointmentDetails: {},
    appointmentDetailsLoading: false,
    appointmentDetailsError: false,
    updateAppointmentBookingError: false,
    updateAppointmentBookingLoading: false,
    dateSlotLoading: false,
    dateSlotError: false,
    dateSlot: {},
    activeScreen: "SODetail",
    isPincodeGsEligble: false,
    isMMYVSgsEligible: false,
    isGsEligible: false,
    userResponse: {},
    isSsr: false,
    currentQuestionIndex: 0,
    registrationNo: null,
    leadCreatedType: "",
    marketPrice: null,
    c24mp: null,
    dealerCode: "",
    leadData: null,
    vehicleBoughtCount: 0,
    soLeadData: null,
    currentVersionQuestion: {},
    currentVersionDetails: {},
    zone: null,
    locations: [],
    slots: null,
    isLoading: true,
    isNotServicible: null,
    isFetchingSlots: null,
    userReport: null,
    isEditable: true,
    isUserLocationAccessible: false,
    hypothecation: null,
    skipQuestions: [],
    selectedParts: {
        hasScratches: [],
        hasDents: [],
        hasDamages: []
    },
    activeConsultationScreen: "",
    consultationFlowType: "",
    soLocation: {
        isOpen: false,
        allowedCoords: null,
        activeScreen: "slot"
    },
    isChangingLocation: false,
    videoGallery: [],
    flowType: ""
};

export const setCarPriceDetail = (state = INITIAL_STATE, details) => {
    const {
        fullname,
        isSubmitted,
        userChoice,
        showFeedbackScreen,
        appointmentId,
        sellerReview = {},
        offeredPrice,
        phone,
        showAsssuredPriceBanner,
        appointmentDetails = {},
        appointmentDetailsLoading,
        registrationNo,
        marketPrice,
        c24mp,
        soLeadData,
        applicableForNewOfferScreen
    } = details;
    return {
        ...state,
        appointmentId,
        fullname,
        isSubmitted,
        showFeedbackScreen,
        userChoice,
        sellerReview: sellerReview || {},
        offeredPrice,
        phone,
        showAsssuredPriceBanner,
        appointmentDetails,
        appointmentDetailsLoading,
        marketPrice,
        registrationNo,
        c24mp,
        soLeadData,
        applicableForNewOfferScreen
    };
};

export const getDealerCarOfferPrice = (state = INITIAL_STATE, { offeredPrice, leadData }) => {
    return { ...state, offeredPrice, leadData };
};

export const fetchLeadDetailFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error };
};

export const setIsSubmitted = (state = INITIAL_STATE, { isSubmitted }) => {
    return { ...state, isSubmitted };
};

export const setActiveScreen = (state = INITIAL_STATE, { screen }) => {
    return { ...state, activeScreen: screen };
};

export const setIsSubmittingQuestions = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isSubmittingData: flag };
};

export const setIsPincodeGsEligble = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isPincodeGsEligble: flag };
};

export const fetchAppointmentDetailsInit = (state = INITIAL_STATE, { appointmentDetailsLoading = true }) => {
    return { ...state, appointmentDetails: {}, appointmentDetailsLoading };
};

export const fetchAppointmentDetailSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, appointmentDetails: data, appointmentDetailsLoading: false, updateAppointmentBookingLoading: false };
};

export const fetchAppointmentDetailFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, appointmentDetailsError: error, appointmentDetailsLoading: false };
};

export const fetchDateSlotInit = (state = INITIAL_STATE, { dateSlotLoading = true }) => {
    return { ...state, dateSlot: {}, dateSlotLoading, dateSlotError: false };
};

export const fetchDateSlotSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, dateSlot: data, dateSlotLoading: false, dateSlotError: false };
};

export const fetchDateSlotFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, dateSlot: {}, dateSlotLoading: false, dateSlotError: error };
};

export const updateAppointmentBookingFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, updateAppointmentBookingError: error, updateAppointmentBookingLoading: false };
};

export const updateAppointmentBookingInit = (state = INITIAL_STATE, { updateAppointmentBookingLoading = true }) => {
    return { ...state, updateAppointmentBookingLoading };
};

export const postOfferPriceInputSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return { ...state, ...data };
};

export const setIsMMYVSElgiible = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isMMYVSgsEligible: flag === "yes" ? true : false };
};

export const setIsGsEligible = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isGsEligible: flag };
};

export const setOfferPrice = (state = INITIAL_STATE, { price }) => {
    return { ...state, offeredPrice: price };
};

export const setCurrentQuestionIndex = (state = INITIAL_STATE, { index }) => {
    return { ...state, currentQuestionIndex: index };
};

export const setDataFromSSR = (state = INITIAL_STATE, { index, userResponse, price, isEditable, selectedParts = {} }) => {
    return { ...state, currentQuestionIndex: index, userResponse, offeredPrice: price, isEditable, selectedParts };
};

export const setAnsweredQuestions = (state = INITIAL_STATE, { key, value }) => {
    //needs better handling
    if (key && value) {
        return {
            ...state,
            userResponse: {
                ...state.userResponse,
                [key]: value
            }
        };
    }

    return {
        ...state,
        userResponse: {
            ...state.userResponse
        }
    };

};

export const setUserResponse = (state = INITIAL_STATE, { userResponse }) => {
    return { ...state, userResponse };
};

export const resetQuestions = (state = INITIAL_STATE) => {
    return { ...state, currentQuestionIndex: 0, userResponse: {}, activeScreen: "SODetail" };
};

export const setRegistrationNo = (state = INITIAL_STATE, { number }) => {
    return { ...state, registrationNo: number };
};

export const setLeadCreatedType = (state = INITIAL_STATE, { leadType }) => {
    return { ...state, leadCreatedType: leadType };
};

export const setDealerCode = (state = INITIAL_STATE, { code }) => {
    return { ...state, dealerCode: code };
};

export const setIsChangingLocation = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isChangingLocation: flag };
};

export const setVehicleBoughtCount = (state = INITIAL_STATE, { count }) => {
    return { ...state, vehicleBoughtCount: count };
};

export const setCurrentVersionQuestion = (state = INITIAL_STATE, { data }) => {
    return { ...state, currentVersionQuestion: data };
};
export const setVersionQuestionDetails = (state = INITIAL_STATE, { data }) => {
    return { ...state, currentVersionDetails: data };
};
export const fetchSlotsByLocationSuccess = (state = INITIAL_STATE, {
    zone,
    locations,
    slots,
    homeInspectionPremiumSlotAvailable,
    homeInspectionExpressSlotAvailable
}) => {
    return {
        ...state,
        zone,
        locations,
        slots,
        isFetchingSlots: false,
        isLoading: false,
        homeInspectionPremiumSlotAvailable,
        homeInspectionExpressSlotAvailable,
        isNotServicible: getNotServicibilityStatus({
            zone, locations, slots
        })
    };
};

export const fetchSlotsByLocationFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        zone: null,
        slots: null,
        locations: [],
        isFetchingSlots: false,
        isLoading: false,
        isNotServicible: false
    };
};

export const setUserReport = (state = INITIAL_STATE, { report }) => {
    return { ...state, userReport: report };
};

export const setIsApplicableForPriceReport = (state = INITIAL_STATE, { applicableForNewOfferScreen }) => {
    return { ...state, applicableForNewOfferScreen };
};

export const setSellerReview = (state = INITIAL_STATE, { review }) => {
    return { ...state, sellerReview: review };
};

export const setUserLocationAccessibility = (state = INITIAL_STATE, { isUserLocationAccessible }) => {
    return { ...state, isUserLocationAccessible };
};

export const setHypothecation = (state = INITIAL_STATE, { hypothecation }) => {
    return { ...state, hypothecation };
};

export const setSkipQuestions = (state = INITIAL_STATE, { questionKey }) => {
    return { ...state, skipQuestions: questionKey };
};

export const setSelectedCarParts = (state = INITIAL_STATE, { key, parts }) => {
    const storedParts = state.selectedParts[key] ? [...state.selectedParts[key]] : [];
    const index = storedParts.findIndex(part => part === parts);

    if (index !== -1) {
        storedParts.splice(index, 1);
    } else {
        storedParts.push(parts);
    }
    return {
        ...state, selectedParts: {
            ...state.selectedParts,
            [key]: storedParts
        }
    };
};

export const resetSelectedParts = (state = INITIAL_STATE, { key }) => {
    return {
        ...state, selectedParts: {
            ...state.selectedParts,
            [key]: []
        }
    };
};

export const setSoLeadData = (state = INITIAL_STATE, {soLeadData}) => {
    return { ...state, soLeadData };
};

export const setCarExpertData = (state = INITIAL_STATE, { carExpertDetails }) => {
    return {
        ...state,
        soLeadData: {
            ...state.soLeadData,
            carExpert: {
                ...(state.soLeadData && { ...state.soLeadData.carExpert }),
                ...carExpertDetails
            }
        }
    };
};

export const setConsultationData = (state = INITIAL_STATE, {data}) => {
    const { activeConsultationScreen = "", consultationFlowType = "" } = data;
    return {
        ...state,
        activeConsultationScreen,
        consultationFlowType
    };
};

export const setSoLocation = (state = INITIAL_STATE, {data}) => {
    const { isOpen, allowedCoords, activeScreen = "" } = data;
    return {
        ...state,
        soLocation: {
            ...state.soLocation,
            ...(allowedCoords && { allowedCoords }),
            ...(activeScreen && { activeScreen }),
            isOpen
        }
    };
};

export const setAppointmentDetailsLoading = (state = INITIAL_STATE, { appointmentDetailsLoading }) => {
    return { ...state, appointmentDetailsLoading };
};

export const setVideoGallery = (state = INITIAL_STATE, { videoGallery }) => {
    return { ...state, videoGallery };
};

export const setFlowType = (state = INITIAL_STATE, { flowType }) => {
    return { ...state, flowType };
};

export const HANDLERS = {
    [Types.FETCH_LEAD_DETAIL_FAILURE]: fetchLeadDetailFailure,
    [Types.SET_IS_SUBMITTED]: setIsSubmitted,
    [Types.SET_CAR_PRICE_DETAIL]: setCarPriceDetail,
    [Types.SET_ACTIVE_SCREEN]: setActiveScreen,
    [Types.IS_SUBMITTING_DATA]: setIsSubmittingQuestions,
    [Types.IS_PINCODE_GS_ELIGBLE]: setIsPincodeGsEligble,
    [Types.FETCH_APPOINTMENT_DETAIL_SUCCESS]: fetchAppointmentDetailSuccess,
    [Types.FETCH_APPOINTMENT_DETAIL_FAILURE]: fetchAppointmentDetailFailure,
    [Types.FETCH_APPOINTMENT_DETAIL_INIT]: fetchAppointmentDetailsInit,
    [Types.UPDATE_APPOINTMENT_BOOKING_FAILURE]: updateAppointmentBookingFailure,
    [Types.FETCH_DATE_SLOTS_SUCCESS]: fetchDateSlotSuccess,
    [Types.FETCH_DATE_SLOTS_INIT]: fetchDateSlotInit,
    [Types.FETCH_DATE_SLOTS_FAILURE]: fetchDateSlotFailure,
    [Types.UPDATE_APPOINTMENT_BOOKING_INIT]: updateAppointmentBookingInit,
    [Types.POST_OFFER_PRICE_INPUT_SUCCESS]: postOfferPriceInputSuccess,
    [Types.SET_MMYVS_ELIGIBLITY]: setIsMMYVSElgiible,
    [Types.STORE_ANSWERED_QUESTION]: setAnsweredQuestions,
    [Types.SET_IS_GS_ELIGIBLE]: setIsGsEligible,
    [Types.SET_OFFER_PRICE]: setOfferPrice,
    [Types.SET_CURRENT_QUESTION_INDEX]: setCurrentQuestionIndex,
    [Types.SET_DATA_FROM_SSR]: setDataFromSSR,
    [Types.RESET_QUESTIONS]: resetQuestions,
    [Types.SET_LEAD_CREATED_TYPE]: setLeadCreatedType,
    [Types.SET_REGISTRATION_NUMBER]: setRegistrationNo,
    [Types.SET_DEALER_CODE]: setDealerCode,
    [Types.GET_DEALER_CAR_PRICE]: getDealerCarOfferPrice,
    [Types.GET_VEHICLE_BOUGHT_COUNT]: setVehicleBoughtCount,
    [Types.GET_CURRENT_VERSION_QUESTION]: setCurrentVersionQuestion,
    [Types.GET_CURRENT_VERSION_DETAILS]: setVersionQuestionDetails,
    [Types.FETCH_SLOTS_OFFER_BY_LOCATIONS_SUCCESS]: fetchSlotsByLocationSuccess,
    [Types.FETCH_SLOTS_OFFER_BY_LOCATIONS_FAILURE]: fetchSlotsByLocationFailure,
    [Types.SET_USER_REPORT]: setUserReport,
    [Types.SET_IS_APPLICABLE_FOR_PRICE_REPORT]: setIsApplicableForPriceReport,
    [Types.SET_SELLER_REVIEW]: setSellerReview,
    [Types.SET_USER_LOCATION_ACCESSIBILITY]: setUserLocationAccessibility,
    [Types.SET_USER_RESPONSE]: setUserResponse,
    [Types.SET_HYPOTHECATION]: setHypothecation,
    [Types.SET_SKIP_QUESTIONS]: setSkipQuestions,
    [Types.SET_SELECTED_CAR_PARTS]: setSelectedCarParts,
    [Types.RESET_SELECTED_CAR_PARTS]: resetSelectedParts,
    [Types.SET_SO_LEAD_DATA]: setSoLeadData,
    [Types.SET_CAR_EXPERT_DATA]: setCarExpertData,
    [Types.SET_CONSULTATION_DATA]: setConsultationData,
    [Types.SET_SO_LOCATION]: setSoLocation,
    [Types.SET_APPOINTMENT_DETAILS_LOADING]: setAppointmentDetailsLoading,
    [Types.SET_IS_CHANGING_LOCATION]: setIsChangingLocation,
    [Types.SET_VIDEO_GALLERY]: setVideoGallery,
    [Types.SET_FLOW_TYPE]: setFlowType
};

export default createReducer(INITIAL_STATE, HANDLERS);
