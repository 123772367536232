/* eslint-disable no-magic-numbers */
import React, {useState} from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import H2 from "../h2/component";
import { appUrl } from "../../../constants/url-constants";
import RightArrow from "./images/righ-arrow.svg";
import { LinkButton } from "@cars24/turbo-web/lib/link-button";
import ArrowIcon from "../../shared/arrow";
import { Heading } from "@cars24/turbo-web/lib/heading";

const SellUsedCarCityList = ({
    cityList = [],
    callBack = () => {},
    heading = "Popular cities to sell your car",
    showArrow = false,
    initiallyVisibleCount = 8,
    showMoreControl = false,
    isDlsCompliant = false
}) => {
    const getName = (city) => {
        return `Sell car in ${city.city_name || ""}`;
    };
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(prevState => !prevState);
    };
    if (!cityList.length) return null;
    const visibleCities = isExpanded ? cityList : cityList.slice(0, initiallyVisibleCount);

    return (
        <div className="container">
            {
                isDlsCompliant ?
                    <div styleName={"styles.headingText"}>
                        <Heading level={2}>{heading}</Heading>
                    </div>
                    :
                    <H2 text={heading} />
            }

            <div styleName={"styles.cityListWrapper"}>
                {
                    visibleCities.map((city, index) => (
                        <a key={index}
                            onClick={() => callBack(city.city_name || "")}
                            href={`${appUrl()}/sell-used-car-${(city.city_slug || "").toLowerCase()}/`}
                            styleName={ showArrow ?  "styles.cityListWithArrow"   : "styles.cityList"}
                        >
                            {getName(city)}
                            {showArrow &&  <img src={RightArrow} alt={"arrow"} />}
                        </a>
                    ))
                }
            </div>
            {cityList.length > initiallyVisibleCount && showMoreControl && (
                <div styleName={"styles.linkbutton"}>
                    <LinkButton
                        label={isExpanded ? "Show Less" : "Show More"}
                        onClick={handleToggle}
                        suffixIcon={<ArrowIcon rotateBy={isExpanded ? -90 : 90} />}
                        variant="brand"
                        preventDefaultBehaviour
                    />
                </div>
            )}
        </div>
    );
};

SellUsedCarCityList.propTypes = {
    cityList: PropTypes.array,
    callBack: PropTypes.func,
    heading: PropTypes.string,
    showArrow: PropTypes.bool,
    initiallyVisibleCount: PropTypes.number,
    showMoreControl: PropTypes.bool,
    isDlsCompliant: PropTypes.bool
};

export default SellUsedCarCityList;
