/* eslint-disable complexity */
/* eslint-disable max-len */
/* eslint-disable max-statements */
/* eslint-disable no-undef */
import React, { useEffect } from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Logo from "../../shared/logo";
const LoginPanel = loadable(() => import("../login-panel"), { ssr: true });
import useToggle from "../../../hooks/use-toggle";
import { CITY_KEY, USED_CAR_ALLOWED_CITIES, DEFAULT_C2C_CITY, FALLBACK_C2B_CITY_ID, routeToEventCategoryMapping, AB_TEST_VARIATIONS } from "../../../constants/app-constants";
import { getItem, clearItem } from "../../../utils/helpers/storage-crud";
import disableScroll from "../../../utils/helpers/disable-scroll";
import { sendCustomGaEvents } from "../../../tracking";
import MenuIcon from "./images/menu.svg";
import CallIcon from "./images/phone.svg";
import HeaderLocationBar from "../header-location-bar";
import { IconButton } from "@cars24/turbo-web/lib/icon-button";

import {
    hamburgerOpen,
    buyCarLogoClick,
    buyCarTopLogoClick,
    buyerPageHamburgerOnClick,
    buyerPageHamburgerClick
} from "./tracking";
import { cfBanking } from "../../../constants/tracking-constants/cf-banking-ga";
import addTrackingCookie from "../../../utils/helpers/add-tracking-cookie";
import { findByKey } from "../../../utils/helpers/find-by-key";
import updateCurrentCity from "../../../utils/helpers/set-current-city";
import mobileLastDigit from "../../../utils/helpers/mobile-last-digit";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { useHistory } from "react-router";
import { Button } from "@cars24/turbo-web/lib/button";
import parseCookie from "../../../utils/helpers/parse-cookie";

const Header = ({
    route = {},
    selectedCity = {},
    selectCityConnect,
    userName,
    isLoggedIn,
    isFetched,
    unAuthorized,
    checkAuth,
    cities,
    updateC2CPromotionFlagConnect,
    // updateCurrentPageTypeConnect,
    resetCitiesConnect,
    userDetailsSubmitted,
    bankingSubmitted,
    pincode,
    mobile,
    appNudge,
    // isAppNudgeExperimentOn,
    currentPageType,
    showLocationPickerBar = false,
    showAppNudge,
    transparentHeader = false,
    alternativeHeader = false,
    isScrollingDown,
    sellMarketingCallIntentExperiment,
    setAskCallIntentConnect,
    isIntentSaved,
    showHamburgerMenu = true
}) => {
    const history = useHistory();
    const {
        name,
        url,
        id: selectedCityID,
        isVirtualCity,
        carServiceCity
    } = selectedCity;

    const isC2CPage = (route && route.isUsedCarRoute);
    const { name: routeName } = route;
    const isHomePageRoute = (routeName === "buy-used-cars" || routeName === "buy-used-cars-home");

    const pageURL = typeof window !== "undefined" ? window.location.href : "";

    const isSellCarRoute = (route && route.isSellCarRoute);

    const { appNudgeHeader, isSellAppNudgeVisible } = appNudge || {};
    const { visible: isAppNudgeVisible } = appNudgeHeader || {};
    const isSellmarketingRequestCallback = !!(
        route && (route.name || "").includes("sell-marketing") &&
        sellMarketingCallIntentExperiment === AB_TEST_VARIATIONS.VARIANT &&
        !parseCookie("sell-mkt-consent") &&
        !isIntentSaved
    );

    const onClickLogo = async () => {
        if (isC2CPage) {
            await yieldToMain();
            sendCustomGaEvents(buyCarLogoClick);
        }
        if (isHomePageRoute) {
            await yieldToMain();
            sendCustomGaEvents({
                ...buyCarTopLogoClick,
                dimension16: pageURL,
                dimension88: pincode ? pincode : "N/A",
                dimension91: "N/A",
                dimension116: mobile ? mobileLastDigit(mobile) : "N/A"
            });
        }
    };

    // useEffect(() => {
    //     updateCurrentPageTypeConnect(route && route.name);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    //reset cities reducer if there is any discrepancy in stored image
    useEffect(() => {
        const selection = getItem(CITY_KEY);
        //reset selection if image doesnt belong to mobile
        if (!history.location.pathname.includes("centres")) {
            if (selection && !((selection.image &&
                selection.image.includes("_m")) || (selection.image && selection.image.includes("m_")))) {
                clearItem(CITY_KEY);
                resetCitiesConnect();
            }
        }
    }, [history, resetCitiesConnect]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history]);

    useEffect(() => {
        updateC2CPromotionFlagConnect(USED_CAR_ALLOWED_CITIES.includes(selectedCityID));
    }, [selectedCityID, updateC2CPromotionFlagConnect]);

    /*
      used for external url changes
    */
    // useEffect(() => {
    //     const _wr = function (type) {
    //         const orig = window.history[type];
    //         return function () {
    //             // eslint-disable-next-line no-invalid-this
    //             const rv = orig.apply(this, arguments);
    //             const e = new Event(type);
    //             e.arguments = arguments;
    //             window.dispatchEvent(e);
    //             return rv;
    //         };
    //     };
    //     window.history.pushState = _wr("pushState");
    //     const urlListener = window.addEventListener("pushState", e => {
    //         if (e.arguments[0] && e.arguments[0].customURL) {
    //             history.replace(e.arguments[NUMBER.TWO]);
    //         }
    //     });
    //     return () => {
    //         window.removeEventListener("pushState", urlListener);
    //     };
    // }, [history]);

    const [isOpen, setIsOpen] = useToggle();

    useEffect(() => {
        //adds utm parameters to cookie if they exist in url
        addTrackingCookie(route && route.isUsedCarRoute);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * if user comes from c2c to c2b via csr and delhi ncr is selected
     * reset city selection state
     */
    useEffect(() => {
        if (selectedCityID && isVirtualCity && route && !route.isUsedCarRoute) {
            resetCitiesConnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCityID]);

    /**
     * update city with full details in website if city is selected from server
     */

    // eslint-disable-next-line complexity
    useEffect(() => {
        if (!selectedCityID && cities.length) {
            const currentSelectedCity = getItem(CITY_KEY);
            if (currentSelectedCity) {
                const selection = findByKey(cities, "city_id", parseInt(currentSelectedCity.id || ""));
                if (route && !route.isUsedCarRoute && selection.is_virtual_city) {
                    /**
                     * if user lands on a c2b page and selection is of a virtual city
                     * then set new delhi as city
                    */
                    updateCurrentCity(
                        cities,
                        parseInt(FALLBACK_C2B_CITY_ID),
                        selectCityConnect,
                        true
                    );
                } else if (route && route.isUsedCarRoute && !selection.is_buy_used_car) {
                    /**
                      * if user lands on a c2c page and selection is of a non c2c city,
                      * then select delhi ncr as default city
                     */
                    updateCurrentCity(
                        cities,
                        parseInt(DEFAULT_C2C_CITY.id),
                        selectCityConnect,
                        true
                    );
                } else {
                    /**
                     * assume current selected city is a valid city for any case
                     * proceed to reducer population
                    */
                    updateCurrentCity(
                        cities,
                        parseInt(currentSelectedCity.id),
                        selectCityConnect,
                        true
                    );
                }
            }
        } else if (selectedCityID && isVirtualCity && route && !route.isUsedCarRoute) {
            updateCurrentCity(
                cities,
                parseInt(FALLBACK_C2B_CITY_ID),
                selectCityConnect,
                true
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cities.length, selectedCityID, selectCityConnect]);

    useEffect(() => {
        if (checkAuth && isFetched && unAuthorized) {
            history.replace("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetched]);

    useEffect(() => {
        disableScroll(isOpen, "hidden");
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            sendCustomGaEvents(hamburgerOpen);
        }
    }, [isOpen]);

    const onNewHamburgerClick = async () => {
        await yieldToMain();
        setIsOpen();
        if (isC2CPage) {
            sendCustomGaEvents({
                ...buyerPageHamburgerOnClick,
                eventCategory: routeToEventCategoryMapping[currentPageType] || buyerPageHamburgerOnClick.eventCategory,
                dimension16: pageURL,
                dimension88: pincode ? pincode : "N/A"
            });
        } else {
            sendCustomGaEvents({
                ...buyerPageHamburgerClick,
                eventCategory: route.name === "sell-car" ? "t0_sell_car_page" : "Buy_used_cars_B2C_Homepage"
            });
            sendCustomGaEvents({
                ...cfBanking,
                eventAction: "Menu_click",
                Form_Filled: !!userDetailsSubmitted,
                Banking_shared: !!bankingSubmitted
            });
        }
    };

    return  (
        <React.Fragment>
            <header
                styleName={`${(isAppNudgeVisible && showAppNudge && isC2CPage) || (isSellAppNudgeVisible && isSellCarRoute) ? "styles.headerWithNudge" : "styles.header"} ${alternativeHeader ? "styles.alternativeHeader" : ""}`}
            >
                <div className="fixed-top" styleName={`styles.superAppHeader ${(isAppNudgeVisible && showAppNudge && isC2CPage) || (isSellAppNudgeVisible && isSellCarRoute) ? "styles.fixedHeader" : ""} ${transparentHeader ? "" : "styles.solidHeader"} styles.container ${!isScrollingDown ? "styles.visible" : "styles.hidden"}`}>
                    {showLocationPickerBar && <HeaderLocationBar />}
                    <div styleName={`styles.leftMenu ${showLocationPickerBar ? "" : "styles.menuWithoutLocation"}`}>
                        <a onClick={onClickLogo} href={"/"}>
                            <Logo width={68} height={32} />
                        </a>
                        {
                            isSellmarketingRequestCallback &&
                            <div styleName={"styles.callWrapper"}>
                                <Button
                                    prefixIcon={<img src={CallIcon} alt="call" />}
                                    variant="secondary"
                                    size="small"
                                    label="Get assistance"
                                    onClick={() => setAskCallIntentConnect(true)}
                                />
                            </div>
                        }
                        {showHamburgerMenu &&
                          <IconButton size="medium" onClick={onNewHamburgerClick}>
                              <img src={MenuIcon} alt="menu" height={24} width={24}/>
                          </IconButton>
                        }
                    </div>
                </div>

                <LoginPanel
                    isOpen={isOpen}
                    close={setIsOpen}
                    // toggleModal={setIsModalOpen}
                    buyUsedUrl={url}
                    route={route && route}
                    selectedCityName={name}
                    userName={userName}
                    isLoggedIn={isLoggedIn}
                    isFetched={isFetched}
                    selectedCityID={selectedCityID}
                    cities={cities}
                    carServiceCity={carServiceCity}
                />
            </header>
        </React.Fragment>
    );
};

Header.propTypes = {
    selectedCity: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.string,
        url: PropTypes.string,
        slug: PropTypes.string,
        isVirtualCity: PropTypes.bool,
        isB2CCity: PropTypes.bool,
        carServiceCity: PropTypes.object || PropTypes.string
    }),
    selectCityConnect: PropTypes.func,
    userName: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    isFetched: PropTypes.bool,
    unAuthorized: PropTypes.bool,
    checkAuth: PropTypes.bool,
    history: PropTypes.object.isRequired,
    templateType: PropTypes.string.isRequired,
    isCitySelectionSkipped: PropTypes.bool,
    cities: PropTypes.array,
    resetCitiesConnect: PropTypes.func,
    updateC2CPromotionFlagConnect: PropTypes.func,
    alternateSellCarFlow: PropTypes.bool,
    route: PropTypes.object,
    isCitySelectionSSR: PropTypes.bool,
    fetchFiltersConnect: PropTypes.func,
    updateSSRStatusFiltersConnect: PropTypes.func,
    updateCurrentPageTypeConnect: PropTypes.func,
    updateCallBackPopupStatusConnect: PropTypes.func,
    ciActive: PropTypes.bool,
    pincodeCityInfo: PropTypes.object,
    pincode: PropTypes.number,
    userDetailsSubmitted: PropTypes.bool,
    bankingSubmitted: PropTypes.bool,
    mobile: PropTypes.string,
    appNudge: PropTypes.object,
    // isAppNudgeExperimentOn: PropTypes.bool,
    currentPageType: PropTypes.string,
    showLocationPickerBar: PropTypes.bool,
    showAppNudge: PropTypes.bool,
    transparentHeader: PropTypes.bool,
    alternativeHeader: PropTypes.bool,
    isScrollingDown: PropTypes.bool,
    sellMarketingCallIntentExperiment: PropTypes.string,
    setAskCallIntentConnect: PropTypes.func,
    isIntentSaved: PropTypes.bool,
    showHamburgerMenu: PropTypes.bool
};

export default Header;
