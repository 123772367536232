/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { sendCustomGaEvents } from "../../../tracking";
import { buyCarApplinkClick, footerClick } from "./tracking";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { seoLinkClicked, downloadBannerClick } from "../../../constants/tracking-constants/super-home-events";
import { Footer as DlsFooter } from "@cars24/turbo-web/lib/footer";
import { DOWNLOAD_BANNER_TYPES } from "../../../constants/super-app-constants";
import getDeviceOs from "../../../utils/helpers/get-device-os";
import styles from "./styles.css";

const Footer = (props) => {
    const {
        footer,
        showBottomNavbar,
        bottomSticky,
        route
    } = props;

    const superHomePage = route && route.name === "buy-used-cars";

    const sendFooterEvent = async (_e, title) => {
        await yieldToMain();
        if (superHomePage) {
            sendCustomGaEvents({
                ...seoLinkClicked,
                eventLabel: title
            });

        } else {
            sendCustomGaEvents({
                ...footerClick,
                eventCategory: route.name,
                eventLabel: title
            });

        }

    };

    const appLinksClickCallback = async (text) => {

        await yieldToMain();
        if (superHomePage) {
            sendCustomGaEvents({
                ...downloadBannerClick,
                eventLabel: `${DOWNLOAD_BANNER_TYPES.FOOTER} | ${getDeviceOs()}`
            });
        } else if (route.isUsedCarRoute) {
            sendCustomGaEvents({
                ...buyCarApplinkClick,
                eventLabel: (text || "").toLowerCase().replace(/ /g, "")
            });
        } else {
            sendCustomGaEvents({
                ...footerClick,
                eventCategory: route.name,
                eventLabel: text
            });
        }
    };

    if (!footer?.pageSections) return null;

    return (
        <div styleName={`${showBottomNavbar ? "" : "styles.noBottomNav"} ${bottomSticky ? "styles.bottomNavWithStickyCta" : ""}`}>
            <DlsFooter
                data={footer?.pageSections[0]}
                isRevamped
                lazyLoadImage
                eventActions={{
                    footerEvent: sendFooterEvent,
                    appLinksClickEvent: appLinksClickCallback
                }}
                gradientColor= {"linear-gradient(270deg, rgba(251, 255, 246, 0.20) 0.45%, rgba(255, 255, 255, 0.00) 194.45%), linear-gradient(1deg, rgba(171, 254, 214, 0.20) 15.19%, rgba(228, 254, 186, 0.20) 76.1%, rgba(255, 255, 255, 0.20) 105.61%)"}
            />
        </div>
    );
};

Footer.propTypes = {
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    route: PropTypes.object,
    showBottomNavbar: PropTypes.bool,
    bottomSticky: PropTypes.bool
};

export default Footer;
