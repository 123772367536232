import { fetchGeoDetails, updateCityLocationDetails } from "../../location/actions";
import Types from "./types";
import { updateCurrentCityWithPicker } from "../../../utils/helpers/set-current-city";
import { selectCity } from "../location-picker/actions";
import { sanitizeObject } from "../../../utils/helpers/sanitizers";
import { UsedCarService } from "../../../service/used-car-service";
import { findByKey } from "../../../utils/helpers/find-by-key";
import isObjectEmpty from "../../../utils/helpers/isObjectEmpty";
import { VehicleService } from "../../../service/vehicle-service";

const toggleLocationPicker = (status) => ({
    type: Types.TOGGLE_LOCATION_PICKER,
    status
});

const setLocationSelectionType = (locationType) => ({
    type: Types.LOCATION_SELECTETION_TYPE,
    locationType
});

const changePincode = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(fetchGeoDetails(data)).then((response) => {
            const { city_info: cityInfo, pincode } = response;
            resolve(response);
            updateCurrentCityWithPicker(
                sanitizeObject(cityInfo),
                () => dispatch(selectCity),
                true,
                pincode
            );
        }).catch((err) => {
            reject(err);
        });
    });
};

const locationPageType = (pageType) => ({
    type: Types.LOCATION_PAGE_TYPE,
    pageType
});

const setIsCTAClickedWithoutPincode = (isCTAClickedWithoutPincode) => ({
    type: Types.IS_CTA_CLICKED_WITHOUT_PINCODE,
    isCTAClickedWithoutPincode
});

const fetchgetBannerCarCountSuccess = (data) => ({
    type: Types.FETCH_CI_AVAILABLE_SUCCESS,
    ...data
});

const getCiAvailable = (pinId, allInventory = false) => (dispatch) => {
    return new Promise((resolve, reject) => {
        UsedCarService.fetchBannerCarCounts(pinId, allInventory)
            .then(res => {
                resolve(res.data.data);
                dispatch(fetchgetBannerCarCountSuccess(res.data.data));
            }).catch(error => {
                reject(error);
                dispatch(fetchgetBannerCarCountSuccess(0));
            });
    });
};

const setComingSoon = (isComingSoon) => ({
    type: Types.COMING_SOON_POPUP,
    isComingSoon
});

const setIsManualPincode = (isManualPincode) => ({
    type: Types.SET_MANUAL_PINCODE,
    isManualPincode
});

const changeLocationAfterIndiaPage = ({ isLocationChanged, cityName }) => ({
    type: Types.CHANGE_LOCATION_AFTER_INDIA_PAGE,
    isLocationChanged,
    cityName
});

const updateCustomLocationCardDetails = ({ heading, imageUrl, geoError }) => ({
    type: Types.UPDATE_CUSTOM_LOCATION_CAR_CARD,
    heading, imageUrl, geoError
});

const fetchCityByCityId = ({ cityId }) => () => {
    return new Promise((resolve, reject) => {
        VehicleService.fetchCityByCityId(cityId).then((response) => {
            const cityData = response?.data?.detail;
            resolve(cityData);
        }).catch((err) => {
            reject(err);
        });
    });
};

const updateUserLocation = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { cityId } = data || {};
        const cityList = getState().config.cityList || [];
        const selectedUserCity = findByKey(cityList, "city_id", (cityId || "").toString());

        const handleCityUpdate = (cityData) => {
            resolve(cityData);
            dispatch(updateCityLocationDetails(cityData));
            updateCurrentCityWithPicker(
                sanitizeObject(cityData),
                () => dispatch(selectCity),
                true,
                cityData.pincode
            );
        };

        if (!isObjectEmpty(selectedUserCity)) {
            handleCityUpdate(selectedUserCity);
        } else {
            VehicleService.fetchCityByCityId(cityId).then((response) => {
                const cityData = response?.data?.detail;
                handleCityUpdate(cityData);
            }).catch((err) => {
                reject(err);
            });
            // fetchCityByCityId({cityId}).then((response) => {
            //     const cityData = response?.data?.detail;
            //     handleCityUpdate(cityData);
            // }).catch((err) => {
            //     reject(err);
            // });
        }
    });
};

const setCitySearchFocus = (data) => ({
    type: Types.SET_CITY_SEARCH_FOCUS,
    data
});

export {
    toggleLocationPicker,
    changePincode,
    setLocationSelectionType,
    locationPageType,
    setIsCTAClickedWithoutPincode,
    getCiAvailable,
    setComingSoon,
    setIsManualPincode,
    changeLocationAfterIndiaPage,
    updateCustomLocationCardDetails,
    updateUserLocation,
    setCitySearchFocus,
    fetchCityByCityId
};
