export const cfBanking = {
    event: "CF_Banking",
    eventCategory: "CF_Banking"
};

export const cfSelfServe =  {
    event: "cf_self_serve",
    eventCategory: "buy_used_cars_b2c_cf_page"
};

export const pitchPage = {
    event: "pitch_page",
    eventCategory: "Buy_used_cars_B2C_CF_Page",
    eventLabel: "Homepage"
};
