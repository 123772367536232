import Types from "./types";
import { CarService } from "../../../service/car-service";

const setIsLoading = flag => ({
    type: Types.SET_IS_LOADING,
    isLoading: flag
});

const fetchDataSuccess = data => ({
    type: Types.FETCH_LEAD_YEAR_SUCCESS,
    data
});

const fetchDataFailure = error => ({
    type: Types.FETCH_LEAD_YEAR_FAILURE,
    error
});

const fetchData = (modelId) => dispatch => {
    dispatch(setIsLoading(true));
    return new Promise((resolve, reject) => {
        CarService.fetchYearList(modelId)
            .then(response => {
                dispatch(fetchDataSuccess(response.data.detail));
                resolve(response.data.detail);
            })
            .catch(error => {
                dispatch(fetchDataFailure(error));
                reject(error);
            });
    });
};

const fetchYearsBasedOnMakeId = (makeId) => dispatch => {
    dispatch(setIsLoading(true));
    return new Promise((resolve, reject) => {
        CarService.fetchYearListBasedOnMake(makeId)
            .then(response => {
                dispatch(fetchDataSuccess(response.data.detail));
                resolve(response.data.detail);
            })
            .catch(error => {
                dispatch(fetchDataFailure(error));
                reject(error);
            });
    });
};

export {
    fetchData,
    fetchYearsBasedOnMakeId
};
