import React from "react";
// Sad! loadable.lib didn't work! Tried every possible combination of loadable.lib and react-loadable.
// import loadable from "@loadable/component";
// const ReactRating = loadable.lib(() => import("react-rating"), { ssr: true });
import ReactRating from "react-rating";

const ReactRatingWrapper = ({
    ...props
}) => {
    return (
        <ReactRating {...props} />
    );
};

export default ReactRatingWrapper;
