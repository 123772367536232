export const PRICE_DISCOVERY = {
    NOT_STARTED: "NOT_STARTED",
    START: "START",
    OVER: "OVER",
    NEGOTIATE_PRICE_REQUESTED: "NEGOTIATE_PRICE_REQUESTED",
    PRICE_UPDATED: "PRICE_UPDATED",
    ACCEPTED: "ACCEPTED"
};

export const APPOINTMENT_STATE = {
    INSPECTION_DONE: "INSPECTION_DONE",
    DEAL_LOST: "DEAL_LOST",
    STOCK_IN: "STOCK_IN"
};

export const PURCHASE_REQUEST_STATUS = {
    NOT_CREATED: "NOT_CREATED",
    DEAL_CANCELLED: "DEAL_CANCELLED",
    CREATED: "CREATED",
    DECLINED: "DECLINED"
};

export const PICKUP_STATUS = {
    PICKUP_NOT_SCHEDULED: "PICKUP_NOT_SCHEDULED",
    PICKUP_SCHEDULED: "PICKUP_SCHEDULED",
    PICKUP_COMPLETED: "PICKUP_COMPLETED",
    PICKUP_CANCELLED: "PICKUP_CANCELLED",
    PICKUP_IN_PROGRESS: "PICKUP_IN_PROGRESS",
    DRIVER_ASSIGNED: "DRIVER_ASSIGNED"
};

export const PICKUP_UNDER_PROGRESS = [
    PICKUP_STATUS.PICKUP_SCHEDULED,
    PICKUP_STATUS.DRIVER_ASSIGNED,
    PICKUP_STATUS.PICKUP_IN_PROGRESS
];

export const POST_PICKUP_SCHEDULED_STATES = [
    ...PICKUP_UNDER_PROGRESS,
    PICKUP_STATUS.PICKUP_CANCELLED,
    PICKUP_STATUS.PICKUP_NOT_SCHEDULED
];
