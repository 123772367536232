import Types from "./types";
import { LeadServiceJava } from "../../../service/lead-service-java-api";

const setLocationName = (locationName) => ({
    type: Types.SET_CURRENT_LOCATION_NAME,
    locationName
});

const setPlaceID = (placeID) => ({
    type: Types.SET_CURRENT_LOCATION_ID,
    placeID
});

const setSelectionType = (selectionType) => ({
    type: Types.SET_SELECTION_TYPE,
    selectionType
});

const setLeadDetails = (leadDetail) => ({
    type: Types.SET_LEAD_DATA,
    leadDetail
});

const fetchLeadDetails = (token, params, secureToken) => (dispatch) => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.fetchLeadDetailsProtected(token, params, true, secureToken).then(response => {
            dispatch(setLeadDetails(response.data.detail));
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const fetchLeadDetailsProtected = (token, params, secureToken) => (dispatch) => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.fetchLeadDetailsProtected(token, params, true, secureToken).then(response => {
            dispatch(setLeadDetails(response.data.detail));
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const fetchAppointmentDetails = (token) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.getAppointmentDetailsByToken(token).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
};

const updateLoader = (shouldReplaceLoader) => ({
    type: Types.SET_SHOULD_REPLACE_LOADER,
    shouldReplaceLoader
});

const setIsMmvy = (isMmvy) => ({
    type: Types.SET_IS_MMVY,
    isMmvy
});

const setChannelPartnerToken = (channelPartnerToken) => ({
    type: Types.SET_CHANNEL_PARTNER_TOKEN,
    channelPartnerToken
});

export {
    setLocationName,
    setPlaceID,
    setSelectionType,
    fetchLeadDetails,
    fetchAppointmentDetails,
    updateLoader,
    setIsMmvy,
    setChannelPartnerToken,
    fetchLeadDetailsProtected
};
