import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
// import map from "./images/map.svg";
import { LOCATION_PAGE_TYPE } from "../location-picker-popup-v2/constant";
// import downArrow from "./images/down-arrow.svg";
import { sendCustomGaEvents } from "../../../tracking";
import { LocationPickerOnClick } from "./tracking";
import { getGaEventDimensionObject } from "../../../utils/helpers/get-ga-event-object";
import capitalize from "../../../utils/helpers/capitalize";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import useToggle from "../../../hooks/use-toggle";
import { HeaderLocation } from "@cars24/turbo-web/lib/header-location";
import PrefixIcon from "./prefix-icon";
import SuffixIcon from "./suffix-icon";
import { THEMES } from "../../../constants/theme-constants";

const DELHI_NCR_CITY_NAME = "Delhi NCR";

const HeaderLocationBar = ({
    theme,
    // cityInfo,
    toggleLocationPickerConnect,
    locationPageTypeConnect,
    userId,
    // cityName,
    isLoggedIn,
    selectedCity
}) => {
    const { id: cityId, name: cityName } = selectedCity || {};
    const dimensionObject = getGaEventDimensionObject({
        isLoggedIn,
        cityId,
        cityName,
        userId
    });

    const [, setIsLoading] = useToggle();

    const toggle = async () => {
        setIsLoading();
        await yieldToMain();
        toggleLocationPickerConnect();
        await yieldToMain();
        locationPageTypeConnect(LOCATION_PAGE_TYPE.homePage);
        await yieldToMain();
        sendCustomGaEvents({
            ...LocationPickerOnClick,
            eventLabel: cityName,
            eventCategory: "Buy_used_cars_B2C_Homepage",
            ...dimensionObject
        });
        setIsLoading();
    };

    const city = cityName || "India";
    const color  = theme === THEMES.LIGHT ? "#ffffff" : "#0f0f10";

    return (
        <div styleName="styles.locationBar">
            <HeaderLocation
                label={city === DELHI_NCR_CITY_NAME ? city : capitalize(city)}
                clickHandler={toggle}
                prefixIcon={<PrefixIcon theme={theme} />}
                suffixIcon={<SuffixIcon theme={theme} />}
                color={color}
            />
        </div>
    );
};

HeaderLocationBar.propTypes = {
    pincode: PropTypes.any,
    cityInfo: PropTypes.object,
    toggleLocationPickerConnect: PropTypes.func,
    locationPageTypeConnect: PropTypes.func,
    userId: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    cityName: PropTypes.string,
    selectedCity: PropTypes.object,
    theme: PropTypes.string
};

export default HeaderLocationBar;
