export const appNudgeHeaderVisible = {
    event: "top_sticky_banner_impression",
    eventAction: "top_sticky_banner | impression",
    eventLabel: "app_download_nudge"
};
export const appNudgeHeaderClick = {
    event: "top_sticky_banner_cta",
    eventAction: "top_sticky_banner_cta | click",
    eventLabel: "app_download_nudge"
};
export const appNudgeHeaderDismiss = {
    event: "top_sticky_banner_dismiss",
    eventAction: "top_sticky_banner_dismiss | click",
    eventLabel: "app_download_nudge"
};
