import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "./component";
import { selectCity, updateC2CPromotionFlag, resetCities } from "../location-picker/actions";
import { fetchFilters, updateSSRStatus as updateSSRStatusFilters } from "../../filters/actions";
import { updateCurrentPageType } from "../../configuration/actions";
import { setAskCallIntent, updateCallBackPopupStatus } from "../request-callback-modal/action";
import { updateSSRStatus } from "../footer/actions";

const mapStateToProps = ({
    cities: { selectedCity, isCitySelectionSSR },
    meta: { templateType },
    user: { name, isLoggedIn, isFetched, unAuthorized, checkAuth, mobile, currentPageType },
    config: { cityList: cities },
    settings: { isCitySelectionSkipped },
    experiments: {
        alternateSellCarFlow,
        getAppNudgesOnMsite: {
            data
        },
        sellMarketingCallIntent: {
            data: sellMarketingCallIntentExperiment
        }
    },
    buyCarPincodeConfig: {
        data: pincodeData
    },
    location: { cityInfo: pincodeCityInfo, pincode },
    appNudge,
    callBackDetails: {
        isIntentSaved
    }
}) => ({
    selectedCity,
    userName: name,
    isLoggedIn,
    isFetched,
    unAuthorized,
    templateType,
    checkAuth,
    isCitySelectionSkipped,
    cities,
    isCitySelectionSSR,
    alternateSellCarFlow,
    ciActive: (pincodeData || {}).ciActive,
    pincodeCityInfo,
    pincode,
    mobile,
    appNudge,
    isAppNudgeExperimentOn: data,
    currentPageType,
    sellMarketingCallIntentExperiment,
    isIntentSaved
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    selectCityConnect: selectCity,
    updateC2CPromotionFlagConnect: updateC2CPromotionFlag,
    resetCitiesConnect: resetCities,
    fetchFiltersConnect: fetchFilters,
    updateSSRStatusFiltersConnect: updateSSRStatusFilters,
    updateCurrentPageTypeConnect: updateCurrentPageType,
    updateCallBackPopupStatusConnect: updateCallBackPopupStatus,
    updateSSRStatusConnect: updateSSRStatus,
    setAskCallIntentConnect: setAskCallIntent
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
