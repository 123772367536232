import { NUMBER } from "../../constants/app-constants";
import { getWeekDay, getParticularDay, getMonth, getTwelveHourFormat } from "./get-day-from-date";
import oridinalSuffix from "./oridinal-suffix";

export const parseDateString = (date, abbr = false, isAppendFullStop = true) => {
    const _date = new Date(date);
    const textBlocks = [];
    textBlocks.push(
        getWeekDay(_date, abbr),
        ",",
        getParticularDay(_date),
        `${getMonth(_date, abbr)}${isAppendFullStop ? "." : ""}`,
    );
    return {
        year: _date.getFullYear(),
        text: textBlocks.join(" ")
    };
};

export const parseDateStringWithTime = (date, showTime = false) => {
    if (!date) return "";
    const _date = new Date(date);
    const time = getTwelveHourFormat(_date).string;
    const dateString = `${getMonth(_date, true)} ${getParticularDay(_date)}, ${_date.getFullYear()}`;
    if (!showTime) return dateString;
    return `
        ${dateString} ${time}
    `;

    //sample o/p - Nov 20, 2022 10:54 PM
};

export const convertDateStringToObject = (date) => {
    if (!date) return "";
    const _date = new Date(date);
    return {
        month: getMonth(_date, true),
        day: getParticularDay(_date),
        year: _date.getFullYear()
    };
};

export const getDateTimeObject = (date, format = false) => {
    const _date = new Date(date);
    const { hours, minutes, string: formattedTime, fullDayHours } = getTwelveHourFormat(_date);
    const month = format ? getMonth(_date, true) : `${_date.getMonth() + NUMBER.ONE}`.toString().padStart(NUMBER.TWO, "0");
    const day = getParticularDay(_date).toString();
    const year = _date.getFullYear();

    return {
        month,
        day: day.padStart(NUMBER.TWO, "0"),
        year,
        hours,
        minutes,
        formattedTime,
        weekDay: getWeekDay(_date, format),
        string: `${year}-${month}-${day} ${fullDayHours.toString().padStart(NUMBER.TWO, "0")}:${minutes}:00`,
        formattedDay: oridinalSuffix(day),
        formattedTimeStamp: `${year}-${month}-${day.padStart(NUMBER.TWO, "0")} ${fullDayHours.toString().padStart(NUMBER.TWO, "0")}:${minutes}:00`
    };
};

const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + (minutes * NUMBER.SIXTY * NUMBER.THOUSAND));
};

export const addMinutesToDate = ({date = new Date(), minutes}) => {
    const _date = new Date(date);

    return {
        currentTime: getDateTimeObject(_date),
        futureTime: getDateTimeObject(addMinutes(_date, minutes))
    };
};
