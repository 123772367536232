import React, { useEffect } from "react";
import CarouselBottomArrow from "../../shared/carousel-bottom-arrow";
import H2 from "../../shared/h2";
import TransparentButton from "../../shared/button/transparent-button";
import { DEFAULT_MOBILE_SLIDER_SETTINGS, NUMBER } from "../../../constants/app-constants";
import RatingCard from "../../shared/rating-card/component";
import styles from "./styles.css";
import PropTypes from "prop-types";
import slugUrl from "../../../utils/parsers/slug-url";
import RatingComponent from "../rating-component";
import { leftRightWipe } from "./tracking";
import { sendCustomGaEvents } from "../../../tracking";
import { BodyText } from "@cars24/turbo-web/lib/body-text";
import { TinyText } from "@cars24/turbo-web/lib/tiny-text";
import Rating from "../../shared/react-rating-wrapper";
import LazyImage from "../../shared/lazy-image";
import FullActiveStar from "./images/star-icon.svg";
import EmptyStar from "./images/empty-star-icon.svg";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import initialExtractor from "../../../utils/parsers/initial-extractor";

const TestimonialCarousel = ({
    heading = "Over 4 Lakh+ Happy Sellers and Counting",
    headingComponent = H2,
    history,
    isSSR,
    match,
    template,
    list,
    make,
    model,
    city,
    location,
    currentPage,
    fetchTestimonialsConnect,
    cityName = "",
    sellOnlineTestimonial = false,
    testimonialRevamp = false,
    customersHeaderReview = false,
    minLength = NUMBER.ZERO,
    isApiCall = true,
    apiPayload = {},
    isLazyLoaded = false,
    showDate = true,
    isDlsCompliant,
    purchaseType = "Sold"
}) => {
    const { tokens } = useTokens();
    const sliderSetting = {
        ...DEFAULT_MOBILE_SLIDER_SETTINGS,
        afterChange: () => sendCustomGaEvents(leftRightWipe)
    };

    useEffect(() => {
        if (!isSSR && isApiCall) {
            const payload = {
                ...(apiPayload || {}),
                expand: ["customer_review_list", ...(apiPayload && apiPayload.expand || [])].toString()
            };
            fetchTestimonialsConnect(payload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, isSSR]);

    const renderCards = () => (
        list.map((item, index) => (
            <RatingCard
                showDate={showDate}
                path={history.location.pathname}
                template={template}
                templateMake={make}
                templateModel={model}
                templateCity={city}
                templateLocation={location}
                currentPage={currentPage}
                key={index}
                cityName={cityName}
                testimonialRevamp={testimonialRevamp}
                isLazyLoaded={isLazyLoaded}
                {...item} />

        ))
    );
    const getAvatarImage = (reviewImage, withImage, name) => {
        let image = (
            <div styleName={"styles.avtarImg"}>
                <LazyImage
                    src={reviewImage}
                    alt="Seller" />
            </div>
        );
        if (!withImage) {
            image = (
                <p styleName={"styles.intials"}>
                    {initialExtractor(name)}
                </p>
            );
        }
        return image;
    };

    const renderDlsCompliantCards = () => (
        list.map(({ title, review_image: reviewImage, review_rating: rating, review_description: description, customer_name: name, make: testimonialMake, model: testimonialModel, with_image: withImage }, index) => {

            return (<div key={`review${index}`} styleName={"styles.testimonialsCard"}>
                {getAvatarImage(reviewImage, withImage, name)}
                <div styleName={"styles.testimonialsText"}>
                    {
                        rating &&
                        <div styleName={"styles.ratingWrapper"}>
                            <Rating
                                readonly
                                initialRating={parseInt(rating)}
                                emptySymbol={<img src={EmptyStar} alt="rating" />}
                                fullSymbol={<img src={FullActiveStar} alt="rating" />}
                            />
                        </div>
                    }
                    <BodyText level={NUMBER.TWO} isBold>{title}</BodyText>
                    <div styleName={"styles.description"}>
                        <BodyText level={NUMBER.THREE} color={tokens.text_default_text_secondary}>{description}</BodyText>
                    </div>
                    <div styleName={"styles.testimonialsUser"}>
                        <BodyText level={NUMBER.THREE} isBold>{name}</BodyText>
                        <TinyText color={tokens.text_default_text_secondary}>{`${purchaseType} ${testimonialMake} ${testimonialModel}`}</TinyText>
                    </div>
                </div>
            </div>);
        })
    );

    const onClickHandler = () => {
        let url = "/testimonials/";
        if (currentPage === "seo") {
            url = `/${slugUrl({ match })}/testimonials/`;
        }
        history.push({
            pathname: url,
            focusElement: "rated"
        });
    };

    const Heading = headingComponent;
    return (
        list.length > minLength &&
            isDlsCompliant ?
            <div styleName={"styles.dlsCarouselWrap"}>
                <CarouselBottomArrow
                    settings={{ ...sliderSetting, slidesToShow: 1.2 }}>
                    {renderDlsCompliantCards()}
                </CarouselBottomArrow>
            </div>
            :
            <div styleName={sellOnlineTestimonial ? "" : "styles.testimonialWrap"}>
                {customersHeaderReview && <RatingComponent />}
                {!sellOnlineTestimonial && <Heading text={heading} />}
                <div styleName={"styles.carouselWrap"} >
                    <CarouselBottomArrow
                        settings={{ ...sliderSetting, slidesToShow: 1.2 }}>
                        {renderCards()}
                    </CarouselBottomArrow>
                </div>
                {(currentPage === "home" || template === "view-all") && !testimonialRevamp &&
                    <div styleName={"styles.buttonWrap"}>
                        <TransparentButton onClick={onClickHandler} text="VIEW ALL" />
                    </div>}
            </div>
    );
};

TestimonialCarousel.propTypes = {
    isSSR: PropTypes.bool,
    match: PropTypes.object,
    list: PropTypes.array,
    currentPage: PropTypes.bool,
    fetchTestimonialsConnect: PropTypes.func,
    history: PropTypes.object,
    heading: PropTypes.string,
    headingComponent: PropTypes.object || PropTypes.array,
    template: PropTypes.object,
    make: PropTypes.object,
    model: PropTypes.string,
    city: PropTypes.string || PropTypes.object,
    location: PropTypes.object,
    cityName: PropTypes.any,
    sellOnlineTestimonial: PropTypes.bool,
    testimonialRevamp: PropTypes.bool,
    customersHeaderReview: PropTypes.bool,
    minLength: PropTypes.number,
    isApiCall: PropTypes.bool,
    apiPayload: PropTypes.object,
    isLazyLoaded: PropTypes.bool,
    showDate: PropTypes.bool,
    isDlsCompliant: PropTypes.bool,
    purchaseType: PropTypes.string
};

export default TestimonialCarousel;
