import React from "react";
import styles from "./styles.css";
import skeleton from "./skeleton.css";

const Skeleton = () => {

    return (
        <div styleName={"skeleton.carCard"}>
            <div className="shimmer" styleName={"skeleton.shimmerImg"} />
            <h4 className="shimmer" styleName={"skeleton.shimmer"} />
            <h4 className="shimmer" styleName={"skeleton.shimmer"} />
            <h4 className="shimmer" styleName={"skeleton.shimmer"} />
        </div>
    );
};

export default Skeleton;
