import { CURRENCY } from "../../constants/app-constants";
import priceFormatter from "./price-formatter";

const COUNTRY_CODES = {
    IN: "IN"
};

const makePriceLabel = (price, currency = CURRENCY.IN) => `${currency}${priceFormatter(price, true,
    currency === CURRENCY.IN ? COUNTRY_CODES.IN : currency)}`;

export default makePriceLabel;
