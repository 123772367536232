import React from "react";
import IconStar from "../../shared/icon-star";
import styles from "./styles.css";

const RatingComponent = () => {
    return (
        <div styleName={"styles.ratingComponent"}>
            <div styleName={"styles.rating"}>
                <h2>4.5</h2>
                <p>1.5 Lakh+ reviews</p>
            </div>
            <div styleName={"styles.starSection"}>
                <div styleName={"styles.star"}>
                    <IconStar type={"fill"} />
                    <IconStar type={"fill"} />
                    <IconStar type={"fill"} />
                    <IconStar type={"fill"} />
                    <IconStar type={"half"} />
                </div>
                <p>Average rating from sellers</p>
            </div>
        </div>
    );
};
export default RatingComponent;
