import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_CAR_DETAILS
    FETCH_CAR_DETAILS_SUCCESS
    FETCH_CAR_DETAILS_FAILURE

    ADD_SIMILR_CAR_DETAILS_TO_SIMILAR_CAR_COMARISON_DETAILS

    FETCH_SIMILAR_CAR_COMPARISON_DETAIL
    FETCH_SIMILAR_CAR_COMPARISON_DETAIL_FAILURE
    FETCH_SIMILAR_CAR_COMPARISON_DETAIL_SUCCESS

    UPDATE_SSR_STATUS

    UPDATE_SELECTED_IMAGE

    UPDATE_IMPRESSION_SOURCE

    FETCH_SIMILAR_TO_OFFER_CARS
    FETCH_SIMILAR_TO_OFFER_CARS_SUCCESS
    FETCH_SIMILAR_TO_OFFER_CARS_FAILURE

    UPDATE_ACTIVE_SIMILAR_TO_OFFER_CAR_INDEX
    REMOVE_SIMILAR_TO_OFFER_CAR

    UPDATE_BOOK_NOW_PAYWALL_VISIBILITY
    FETCH_OFFER_SCORE_SUCCESS
    FETCH_OFFER_SCORE_FAILURE

    UPDATE_MAKE_OFFER_PAYWALL_VISIBILITY

    UPDATE_PAGE_LOADER_STATUS
    SET_PAYMENT_OPTION

    FETCH_PDF_LINK
    FETCH_PDF_LINK_SUCCESS
    FETCH_PDF_LINK_FAILURE

    UPDATE_SPINCAR_MODAL_STATUS

    FETCH_CAR_LOCATIONS_SUCCESS
    FETCH_CAR_LOCATIONS_FAILURE

    INIT_FETCH_INSPECTION_REPORT
    SET_FETCH_INSPECTION_REPORT
    FETCH_INSPECTION_REPORT
    FETCH_INSPECTION_REPORT_FAILURE
    SET_GS_GALLERY_DATA

    SET_REPORT_TYPE
    SET_FINANCING_POPUP
    SET_ACTIVE_CAR_CONDITION_TAB
    SET_HELLO_AR_GALLERY
    SET_S20_GALLERY
    SET_IMPERFECTION_GALLERY

    GET_NOIFY_ME
    PRE_POPULATE_CAR_DETAIL

    SEND_SERVICE_HISTORY
    SEND_NOTIFY_FLOW

    RESET_CAR_DETAILS

    SET_GALLERY_DATA

    FETCH_MORE_CARS
    UPDATE_MORE_CARS_SSR_STATUS

    UPDATE_VISITED_APPOINTMENT_IDS

    FETCH_CAR_DETAILS_V2
    FETCH_CAR_DETAILS_SUCCESS_V2
    FETCH_CAR_DETAILS_FAILURE_V2

    UPDATE_CATEGORY_IMAGES

    UPDATE_FEATURE_SPECS_MODAL_DATA

    UPDATE_INSPECTION_REPORT_DATA

    FETCH_SERVICE_HISTORY_REPORT_INIT
    FETCH_SERVICE_HISTORY_REPORT_SUCCESS
    FETCH_SERVICE_HISTORY_REPORT_INIT_FAILURE
    UPDATE_LINKED_CAR_DATA

    VIEW_SIMILAR_BUTTON_CLICKED
    
    SET_PAGE_LOAD_STATE
    DETAIL_PAGE_VISITED
`,
    {
        prefix: "gscardetails/"
    }
);
