export const indiaLogo = `
<svg xmlns="http://www.w3.org/2000/svg" width="26" height="58" viewBox="0 0 26 60" fill="none">
    <g clip-path="url(#clip0_16172_90068)">
    <path d="M0 8C0 3.58172 3.58172 0 8 0H26V60H8C3.58172 60 0 56.4183 0 52V8Z" fill="#F5F5F5"/>
    <circle cx="13.125" cy="22.75" r="0.875" fill="#0059A3"/>
    <circle cx="13.125" cy="22.75" r="4.6875" stroke="#0059A3" stroke-width="0.625"/>
    <circle cx="12.5539" cy="18.4118" r="0.25" transform="rotate(-7.5 12.5539 18.4118)" fill="#0059A3"/>
    <circle cx="13.6965" cy="27.0869" r="0.25" transform="rotate(-7.5 13.6965 27.0869)" fill="#0059A3"/>
    <circle cx="17.1675" cy="24.4236" r="0.25" transform="rotate(112.5 17.1675 24.4236)" fill="#0059A3"/>
    <circle cx="9.08352" cy="21.0751" r="0.25" transform="rotate(112.5 9.08352 21.0751)" fill="#0059A3"/>
    <circle cx="16.5964" cy="20.086" r="0.25" transform="rotate(52.5 16.5964 20.086)" fill="#0059A3"/>
    <circle cx="9.65307" cy="25.4127" r="0.25" transform="rotate(52.5 9.65307 25.4127)" fill="#0059A3"/>
    <circle cx="15.7883" cy="19.2784" r="0.25" transform="rotate(37.5 15.7883 19.2784)" fill="#0059A3"/>
    <circle cx="10.4622" cy="26.2202" r="0.25" transform="rotate(37.5 10.4622 26.2202)" fill="#0059A3"/>
    <circle cx="14.7984" cy="26.7913" r="0.25" transform="rotate(157.5 14.7984 26.7913)" fill="#0059A3"/>
    <circle cx="11.4507" cy="18.7074" r="0.25" transform="rotate(157.5 11.4507 18.7074)" fill="#0059A3"/>
    <circle cx="17.4617" cy="23.3203" r="0.25" transform="rotate(97.5 17.4617 23.3203)" fill="#0059A3"/>
    <circle cx="8.78787" cy="22.1782" r="0.25" transform="rotate(97.5 8.78787 22.1782)" fill="#0059A3"/>
    <circle cx="14.7994" cy="18.7074" r="0.25" transform="rotate(22.5 14.7994 18.7074)" fill="#0059A3"/>
    <circle cx="11.4498" cy="26.7914" r="0.25" transform="rotate(22.5 11.4498 26.7914)" fill="#0059A3"/>
    <circle cx="15.7881" cy="26.2202" r="0.25" transform="rotate(142.5 15.7881 26.2202)" fill="#0059A3"/>
    <circle cx="10.462" cy="19.2784" r="0.25" transform="rotate(142.5 10.462 19.2784)" fill="#0059A3"/>
    <circle cx="17.4625" cy="22.1783" r="0.25" transform="rotate(82.5 17.4625 22.1783)" fill="#0059A3"/>
    <circle cx="8.78672" cy="23.3204" r="0.25" transform="rotate(82.5 8.78672 23.3204)" fill="#0059A3"/>
    <circle cx="13.6957" cy="18.4117" r="0.25" transform="rotate(7.5 13.6957 18.4117)" fill="#0059A3"/>
    <circle cx="12.5531" cy="27.0869" r="0.25" transform="rotate(7.5 12.5531 27.0869)" fill="#0059A3"/>
    <circle cx="16.5948" cy="25.4126" r="0.25" transform="rotate(127.5 16.5948 25.4126)" fill="#0059A3"/>
    <circle cx="9.65338" cy="20.0859" r="0.25" transform="rotate(127.5 9.65338 20.0859)" fill="#0059A3"/>
    <circle cx="17.1674" cy="21.0751" r="0.25" transform="rotate(67.5 17.1674 21.0751)" fill="#0059A3"/>
    <circle cx="9.08345" cy="24.4236" r="0.25" transform="rotate(67.5 9.08345 24.4236)" fill="#0059A3"/>
    <g clip-path="url(#clip1_16172_90068)">
    <path d="M12.9555 27.106L12.8055 24.481L12.9555 23.231L13.1055 24.481L12.9555 27.106Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip2_16172_90068)">
    <path d="M13.0055 18.356L13.1555 20.981L13.0055 22.231L12.8555 20.981L13.0055 18.356Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip3_16172_90068)">
    <path d="M9.20353 20.522L11.5518 21.7045L12.5594 22.4595L11.4018 21.9644L9.20353 20.522Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip4_16172_90068)">
    <path d="M16.7574 24.9402L14.4091 23.7576L13.4016 23.0027L14.5591 23.4978L16.7574 24.9402Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip5_16172_90068)">
    <path d="M9.17853 24.897L11.3768 23.4545L12.5344 22.9595L11.5268 23.7144L9.17853 24.897Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip6_16172_90068)">
    <path d="M16.7824 20.5652L14.5841 22.0076L13.4266 22.5027L14.4341 21.7478L16.7824 20.5652Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip7_16172_90068)">
    <path d="M9.86874 25.8069L11.6188 23.8447L12.6088 23.0669L11.831 24.0569L9.86874 25.8069Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip8_16172_90068)">
    <path d="M16.0922 19.6551L14.3421 21.6173L13.3522 22.3951L14.13 21.4052L16.0922 19.6551Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip9_16172_90068)">
    <path d="M11.8724 18.4986L12.6967 20.9953L12.8754 22.2416L12.4069 21.073L11.8724 18.4986Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip10_16172_90068)">
    <path d="M14.0885 26.9634L13.2642 24.4667L13.0856 23.2205L13.554 24.389L14.0885 26.9634Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip11_16172_90068)">
    <path d="M8.7607 21.5745L11.3351 22.109L12.5037 22.5774L11.2574 22.3988L8.7607 21.5745Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip12_16172_90068)">
    <path d="M17.2002 23.8874L14.6259 23.3529L13.4573 22.8845L14.7035 23.0631L17.2002 23.8874Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip13_16172_90068)">
    <path d="M10.7705 26.5075L11.9531 24.1592L12.708 23.1517L12.2129 24.3092L10.7705 26.5075Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip14_16172_90068)">
    <path d="M15.1905 18.9547L14.0079 21.3031L13.253 22.3106L13.748 21.1531L15.1905 18.9547Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip15_16172_90068)">
    <path d="M10.8135 18.9296L12.2559 21.1279L12.751 22.2855L11.9961 21.2779L10.8135 18.9296Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip16_16172_90068)">
    <path d="M15.1455 26.5323L13.7031 24.334L13.208 23.1764L13.9629 24.184L15.1455 26.5323Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip17_16172_90068)">
    <path d="M8.60547 22.706L11.2305 22.556L12.4805 22.706L11.2305 22.856L8.60547 22.706Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip18_16172_90068)">
    <path d="M17.3555 22.756L14.7305 22.906L13.4805 22.756L14.7305 22.606L17.3555 22.756Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip19_16172_90068)">
    <path d="M11.8248 26.9505L12.3594 24.3761L12.8278 23.2075L12.6491 24.4537L11.8248 26.9505Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip20_16172_90068)">
    <path d="M14.1361 18.5116L13.6016 21.0859L13.1332 22.2545L13.3118 21.0083L14.1361 18.5116Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip21_16172_90068)">
    <path d="M9.90509 19.6197L11.8673 21.3698L12.6451 22.3598L11.6552 21.5819L9.90509 19.6197Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip22_16172_90068)">
    <path d="M16.0558 25.8423L14.0936 24.0922L13.3158 23.1023L14.3058 23.8801L16.0558 25.8423Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip23_16172_90068)">
    <path d="M8.74751 23.8391L11.2442 23.0148L12.4905 22.8362L11.3219 23.3046L8.74751 23.8391Z" fill="#0059A3"/>
    </g>
    <g clip-path="url(#clip24_16172_90068)">
    <path d="M17.2134 21.6228L14.7167 22.4471L13.4705 22.6257L14.639 22.1573L17.2134 21.6228Z" fill="#0059A3"/>
    </g>
    <path d="M7.23766 34.02V41H5.83766V34.02H7.23766ZM14.7909 41H13.3909L10.2209 36.21V41H8.82086V34.01H10.2209L13.3909 38.81V34.01H14.7909V41ZM18.8144 34.02C19.5477 34.02 20.191 34.1633 20.7444 34.45C21.3044 34.7367 21.7344 35.1467 22.0344 35.68C22.341 36.2067 22.4944 36.82 22.4944 37.52C22.4944 38.22 22.341 38.8333 22.0344 39.36C21.7344 39.88 21.3044 40.2833 20.7444 40.57C20.191 40.8567 19.5477 41 18.8144 41H16.3744V34.02H18.8144ZM18.7644 39.81C19.4977 39.81 20.0644 39.61 20.4644 39.21C20.8644 38.81 21.0644 38.2467 21.0644 37.52C21.0644 36.7933 20.8644 36.2267 20.4644 35.82C20.0644 35.4067 19.4977 35.2 18.7644 35.2H17.7744V39.81H18.7644Z" fill="#0059A3"/>
    </g>
    <defs>
    <clipPath id="clip0_16172_90068">
    <path d="M0 8C0 3.58172 3.58172 0 8 0H26V60H8C3.58172 60 0 56.4183 0 52V8Z" fill="white"/>
    </clipPath>
    <clipPath id="clip1_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-1 0 0 1 13.7305 23.1685)"/>
    </clipPath>
    <clipPath id="clip2_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(1 0 0 -1 12.2305 22.2935)"/>
    </clipPath>
    <clipPath id="clip3_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(0.5 -0.866025 -0.866025 -0.5 12.2266 23.1619)"/>
    </clipPath>
    <clipPath id="clip4_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-0.5 0.866025 0.866025 0.5 13.7344 22.3003)"/>
    </clipPath>
    <clipPath id="clip5_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-0.5 -0.866025 -0.866025 0.5 12.9766 23.5994)"/>
    </clipPath>
    <clipPath id="clip6_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(0.5 0.866025 0.866025 -0.5 12.9844 21.8628)"/>
    </clipPath>
    <clipPath id="clip7_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 13.2012 23.5707)"/>
    </clipPath>
    <clipPath id="clip8_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(0.707107 0.707107 0.707107 -0.707107 12.7598 21.8914)"/>
    </clipPath>
    <clipPath id="clip9_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 12.1426 22.5026)"/>
    </clipPath>
    <clipPath id="clip10_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-0.965926 0.258819 0.258819 0.965926 13.8184 22.9595)"/>
    </clipPath>
    <clipPath id="clip11_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(0.258819 -0.965926 -0.965926 -0.258819 12.3633 23.3422)"/>
    </clipPath>
    <clipPath id="clip12_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-0.258819 0.965926 0.965926 0.258819 13.5977 22.1198)"/>
    </clipPath>
    <clipPath id="clip13_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-0.866025 -0.5 -0.5 0.866025 13.4121 23.485)"/>
    </clipPath>
    <clipPath id="clip14_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(0.866025 0.5 0.5 -0.866025 12.5488 21.9772)"/>
    </clipPath>
    <clipPath id="clip15_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(0.866025 -0.5 -0.5 -0.866025 12.1113 22.7271)"/>
    </clipPath>
    <clipPath id="clip16_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-0.866025 0.5 0.5 0.866025 13.8477 22.7349)"/>
    </clipPath>
    <clipPath id="clip17_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(3.69127e-08 -1 -1 -3.69127e-08 12.543 23.481)"/>
    </clipPath>
    <clipPath id="clip18_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-3.69127e-08 1 1 3.69127e-08 13.418 21.981)"/>
    </clipPath>
    <clipPath id="clip19_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-0.965926 -0.258819 -0.258819 0.965926 13.5918 23.3478)"/>
    </clipPath>
    <clipPath id="clip20_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(0.965926 0.258819 0.258819 -0.965926 12.3691 22.1143)"/>
    </clipPath>
    <clipPath id="clip21_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 12.1406 22.952)"/>
    </clipPath>
    <clipPath id="clip22_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.8203 22.5101)"/>
    </clipPath>
    <clipPath id="clip23_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(-0.258819 -0.965926 -0.965926 0.258819 12.752 23.5686)"/>
    </clipPath>
    <clipPath id="clip24_16172_90068">
    <rect width="1.5" height="4" fill="white" transform="matrix(0.258819 0.965926 0.965926 -0.258819 13.209 21.8933)"/>
    </clipPath>
    </defs>
</svg>`
;
