import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withAuth from "../with-auth";
import { setActiveLoginPopup, setOnLoginCallbackProps, setLoginHeading } from "../../mobile/wishlist-login-popup/actions";
import { showToast } from "../../mobile/toast-message/actions";
import { callbackTypes } from "../../mobile/wishlist-login-popup/selectors";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

export default function withRequireLogin(Component, extraArgs) {
    const RequireLogin = (props) => {
        const {
            isLoggedIn,
            setActiveLoginPopupConnect,
            setOnLoginCallbackPropsConnect,
            setLoginHeadingConnect
        } = props;

        const { openNewLogin } = extraArgs || {};

        const onClickRequireLogin = async ({
            loggedInCallback = () => { },
            loggedOutCallback,
            loginHeading = "Log in to continue",
            loginCallbackRoute = callbackTypes.CF_POPUP,
            newLoginHeading = "",
            loginBottomSheetEventsource = "",
            redirectAfterLogin = true,
            customHandler = () => { }
        } = {}) => {
            if (isLoggedIn) {
                loggedInCallback();
            } else {
                await yieldToMain();
                setActiveLoginPopupConnect("msite", !!openNewLogin, loginBottomSheetEventsource);

                await yieldToMain();
                setLoginHeadingConnect(loginHeading, newLoginHeading);

                await yieldToMain();
                if (loggedOutCallback) {
                    loggedOutCallback();
                } else if (redirectAfterLogin) {
                    setOnLoginCallbackPropsConnect(loginCallbackRoute);
                } else {
                    setOnLoginCallbackPropsConnect(callbackTypes.CUSTOM, null, customHandler);
                }
            }
        };
        const cachedRequireLoginFunc = useCallback(onClickRequireLogin, [isLoggedIn]);
        return (
            <Component
                {...props}
                onClickRequireLogin={cachedRequireLoginFunc}
            />
        );
    };

    RequireLogin.propTypes = {
        isLoggedIn: PropTypes.bool,
        setOnLoginCallbackPropsConnect: PropTypes.func,
        setActiveLoginPopupConnect: PropTypes.func,
        setLoginHeadingConnect: PropTypes.func
    };

    const mapStateToProps = () => {
        return {};
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        setActiveLoginPopupConnect: setActiveLoginPopup,
        setOnLoginCallbackPropsConnect: setOnLoginCallbackProps,
        showToastConnect: showToast,
        setLoginHeadingConnect: setLoginHeading
    }, dispatch);
    return connect(mapStateToProps, mapDispatchToProps)(
        withAuth(RequireLogin)
    );
}
