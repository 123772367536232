import { OFFER, OFFER_EXPIRED } from "../../../constants/seller-constant";
import { NewCarCmsServiceApi } from "../../../service/new-car-cms-service";
import {offerParser} from "../../../utils/helpers/offer-parser";
import Types from "./types";

const fetchDataSuccess = data => ({
    type: Types.FETCH_DATA_SUCCESS,
    data
});

const fetchDataFailure = error => ({
    type: Types.FETCH_DATA_FAILURE,
    error
});

export const fetchOffersDetails = () => dispatch => {
    return new Promise((resolve, reject) => {
        NewCarCmsServiceApi.getOfferDetails({
            bannerName: [OFFER, OFFER_EXPIRED]
        }).then(response => {
            dispatch(fetchDataSuccess(offerParser(response.data.data)));
            resolve(response);
        }).catch(error => {
            dispatch(fetchDataFailure(error));
            reject(error);
        });
    });
};
