import React from "react";

const BuyIconFill = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clipPath="url(#clip0_5155_122387)">
                <path d="M17.392 10.4443L12.9544 5.15175C12.6322 4.7682 12.158 4.54712 11.6573 4.54712H5.42184C4.48693 4.54712 3.73047 5.30358 3.73047 6.23849V10.4469" fill="white" />
                <path d="M17.392 10.4443L12.9544 5.15175C12.6322 4.7682 12.158 4.54712 11.6573 4.54712H5.42184C4.48693 4.54712 3.73047 5.30358 3.73047 6.23849V10.4469" stroke="#0F0F10" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M18.9095 10.4441H3.0905C2.28436 10.4441 1.63086 11.0976 1.63086 11.9037V15.2812C1.63086 16.0873 2.28436 16.7408 3.0905 16.7408H18.9095C19.7156 16.7408 20.3691 16.0873 20.3691 15.2812V11.9037C20.3691 11.0976 19.7156 10.4441 18.9095 10.4441Z" fill="#EF6E0B" stroke="#0F0F10" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M6.89675 19.3565C8.64437 19.3565 10.0611 17.9398 10.0611 16.1922C10.0611 14.4446 8.64437 13.0278 6.89675 13.0278C5.14914 13.0278 3.73242 14.4446 3.73242 16.1922C3.73242 17.9398 5.14914 19.3565 6.89675 19.3565Z" fill="white" stroke="#0F0F10" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M15.2835 19.3565C17.0311 19.3565 18.4478 17.9398 18.4478 16.1922C18.4478 14.4446 17.0311 13.0278 15.2835 13.0278C13.5359 13.0278 12.1191 14.4446 12.1191 16.1922C12.1191 17.9398 13.5359 19.3565 15.2835 19.3565Z" fill="white" stroke="#0F0F10" strokeWidth="1.3" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_5155_122387">
                    <rect width="21" height="21" fill="white" transform="translate(0.5 0.907715)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default BuyIconFill;
