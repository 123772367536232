/* eslint-disable complexity */
import React, { useState } from "react";
import styles from "./styles/styles.css";
import PropTypes from "prop-types";
import H2 from "../../shared/h2/component";
import OverviewDescription from "../../shared/overview-description";

const TABS = {
    PROS: "pros",
    CONS: "cons"
};

const SeoCarProsCons = ({
    carFullName,
    pros,
    cons
}) => {
    const [activeTab, setActiveTab] = useState(TABS.PROS);

    const changeTabs = (active) => {
        if (active === TABS.PROS) {
            setActiveTab(active);
        } else {
            setActiveTab(active);
        }
    };
    return (
        <React.Fragment>
            {(pros || cons) && <React.Fragment>
                <div styleName={"styles.title"}>
                    <H2 text={`${carFullName} PRO’s & CON’s`} />
                </div>
                {(pros && cons) ? <ul styleName={"styles.tabNav"}>
                    <li styleName={activeTab === TABS.PROS ? "styles.active" : ""}
                        onClick={() => changeTabs(TABS.PROS)}>
                        Things We Like
                    </li>
                    <li styleName={activeTab === TABS.CONS ? "styles.active" : ""}
                        onClick={() => changeTabs(TABS.CONS)}>
                        Things we don’t like
                    </li>
                </ul> : <div className="container"><H2 text={pros ? "Things We Like" : "Things we don’t like"} /></div>}
                <div styleName={"styles.specificationsPrice"} className={"pt-3"}>
                    <OverviewDescription data={pros} isActive={activeTab === TABS.PROS} />
                    <OverviewDescription data={cons} isActive={activeTab === TABS.CONS} />
                </div>
            </React.Fragment>}
        </React.Fragment>
    );
};

SeoCarProsCons.propTypes = {
    carFullName: PropTypes.string,
    pros: PropTypes.string,
    cons: PropTypes.string
};

export default SeoCarProsCons;
