import React, { useEffect } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Modal from "../../shared/modal";
import loadable from "@loadable/component";
const Lottie = loadable(() => import("react-lottie"), { ssr: true });
import Scanner from "./scanner.json";
import { Text } from "@cars24/turbo-web/lib/text";
import CarImg from "./images/car.svg";
import { NUMBER } from "../../../constants/app-constants";
import { getFormattedDate } from "../../../utils/helpers/date-and-time";

const WelcomeBackLoader = ({redirect, content}) => {
    const {
        modelImageUrl,
        boughtDate
    } = content || {};
    useEffect(() => {
        const timer = window.setTimeout(() => {
            redirect();
        }, NUMBER.THREE_THOUSAND);

        return () => window.clearTimeout(timer);
    }, [redirect]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Scanner,
        animType: "svg",
        renderer: "svg",
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Modal isOpen={true}>
            <div styleName={"styles.modalWrapper"}>
                <div styleName={"styles.loaderText"}>
                    <Text
                        align="center"
                        as="h4"
                        color="#0F0F10"
                        fontWeight="bold"
                        size="300"
                        transform="none"
                    >
                    Hello there!
                    </Text>
                    <Text
                        align="center"
                        as="p"
                        color="#0F0F10"
                        fontWeight="regular"
                        size="250"
                        transform="none"
                    >
                        {boughtDate && "You bought this car from us on" }
                        <br/>{ boughtDate && getFormattedDate(boughtDate)}
                    </Text>
                </div>
                <div styleName={"styles.lottieWrapper"}>
                    <div styleName={"styles.lottie"}>
                        <Lottie width={280} height={204} options={{defaultOptions, animationData: Scanner }} />
                    </div>
                    <div styleName={"styles.carImgWrapper"}>
                        <img src={modelImageUrl || CarImg} alt={"car"}/>
                    </div>
                </div>
                <Text
                    align="center"
                    as="p"
                    color="#0059A3"
                    fontWeight="bold"
                    size="250"
                    transform="none"
                >
                    Looking to sell now? <br/>
                    Calculating its current market value...
                </Text>
            </div>
        </Modal>
    );
};

WelcomeBackLoader.propTypes = {
    redirect: PropTypes.func,
    content: PropTypes.object
};
export default WelcomeBackLoader;
