import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import LandingBannerExperimentv2 from "./component";
import { fetchHomepageBanners } from "../../../components/shared/meta-tags/actions";

const mapStateToProps = ({
    config: {
        headingContent
    },
    meta: {
        homePageBanner,
        isSSR
    }
}) => ({
    headingContent,
    homePageBanner,
    isSSR
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchHomepageBannersConnect: fetchHomepageBanners
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingBannerExperimentv2));
