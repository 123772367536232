/* eslint-disable no-magic-numbers */
import React, { useRef } from "react";
import styles from "./styles.css";
import H2 from "../../shared/h2/component";
import PropTypes from "prop-types";
import useToggle from "../../../hooks/use-toggle";
import scrollToRef from "../../../utils/helpers/scroll-to-element";
import ArrowIcon from "../../shared/arrow";
import { LinkButton } from "@cars24/turbo-web/lib/link-button";
import { Heading } from "@cars24/turbo-web/lib/heading";

const SeoPageContent = ({ genericContent = {}, showFullContent = false, isDlsCompliant = false }) => {
    const { content, heading } = genericContent;
    const [noDisplay, displayContent] = useToggle(!showFullContent);

    const myRef = useRef(null);

    const handleToggle = () => {
        if (!noDisplay) {
            scrollToRef(myRef);
        }
        displayContent();
    };

    const displayStyleName = () => {
        if (noDisplay) {
            return "styles.contentHide";
        }
        return "";
    };

    const displayButton = () => {
        if (noDisplay) {
            return (
                <React.Fragment>
                    Read More
                    <ArrowIcon rotateBy={90} />
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                Read Less
                <ArrowIcon rotateBy={-90} />
            </React.Fragment>
        );
    };

    return content && (
        <div styleName={"styles.contentWrap"} ref={myRef}>
            <div className={"container"}>
                {
                    isDlsCompliant ?
                        <div styleName={"styles.headingText"}>
                            <Heading level={2}><div dangerouslySetInnerHTML={{ __html: heading }} /></Heading>
                        </div>
                        :
                        <H2 text={<div dangerouslySetInnerHTML={{ __html: heading }} />} />
                }
                <div styleName={displayStyleName()} dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            {!showFullContent && (
                isDlsCompliant ?
                    <LinkButton
                        label={noDisplay ?  "Show More" : "Show Less"}
                        onClick={handleToggle}
                        suffixIcon={<ArrowIcon rotateBy={noDisplay ? 90 : -90} />}
                        variant="brand"
                        preventDefaultBehaviour
                        underLine={false}
                    /> : <div className={"container"}>
                        <div
                            styleName={"styles.readMore"}
                            onClick={handleToggle}
                        >
                            {displayButton()}
                        </div>
                    </div>
            )}
        </div>
    );
};

SeoPageContent.propTypes = {
    genericContent: PropTypes.object.isRequired,
    showFullContent: PropTypes.bool,
    isDlsCompliant: PropTypes.bool
};

export default SeoPageContent;
