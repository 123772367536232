import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "./component";

const mapStateToProps = ({
    footer,
    cities: { selectedCity },
    user: {
        userId,
        isLoggedIn
    }
}) => ({
    footer,
    selectedCity,
    userId,
    isLoggedIn
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
