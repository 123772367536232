import React from "react";
import Location from "./images/location.svg";
import PropTypes from "prop-types";
import { THEMES } from "../../../constants/theme-constants";
import WhiteLocation from "./images/white-location.svg";

const PrefixIcon = ({theme}) => {
    return <img src={theme === THEMES.LIGHT ? WhiteLocation : Location} alt={"location"} height={20} width={20}/>;
};

PrefixIcon.propTypes = {
    theme: PropTypes.string
};

export default PrefixIcon;
