import React from "react";
import styles from "./styles.css";
import Slider from "../../shared/slick-wrapper";
import SimilarCarCardSeo from "../similar-car-card-seo";
import PropTypes from "prop-types";

const SeoOverview = ({
    relatedModelList,
    currentCity,
    mediaConfigUrl
}) => {
    const settings = {
        slidesToShow: 1.3,
        slidesToScroll: 1,
        infinite: false,
        arrow: false,
        dots: true
    };

    return (
        <div styleName={"styles.carouselWrap"}>
            <Slider {...settings}>
                {relatedModelList.map((data, index) => (
                    <SimilarCarCardSeo relatedData={data} currentCity={currentCity} mediaConfigUrl={mediaConfigUrl} key={index} />
                ))}

            </Slider>
        </div>
    );
};

SeoOverview.propTypes = {
    relatedModelList: PropTypes.object,
    currentCity: PropTypes.string,
    mediaConfigUrl: PropTypes.string
};

export default SeoOverview;
