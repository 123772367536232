export const moreBrandsClicked = {
    event: "more_brands_clicked",
    eventCategory: "homepage",
    eventAction: "more_brands"
};

export const moreBrandsScrolled = {
    event: "more_brands_scrolled",
    eventAction: "More_brands_scroll",
    eventLabel: "variant_highlights",
    eventCategory: "Homepage"
};
