/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from "react";
import getDeviceOs from "../../../utils/helpers/get-device-os";
import saveCookie from "../../../utils/helpers/save-cookie";
import DownloadAppHeader from "../download-app-strip";
import PropTypes from "prop-types";
import { NUMBER, routeToEventCategoryMapping } from "../../../constants/app-constants";
import { sendCustomGaEvents } from "../../../tracking";
import {appNudgeHeaderVisible, appNudgeHeaderDismiss, appNudgeHeaderClick} from "./tracking";
import { downloadBannerView, downloadBannerClick } from "../../../constants/tracking-constants/super-home-events";
import { getGaEventDimensionObject } from "../../../utils/helpers/get-ga-event-object";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { DOWNLOAD_BANNER_TYPES } from "../../../constants/super-app-constants";
import parseCookie from "../../../utils/helpers/parse-cookie";
const AppNudge = ({
    isVisible,
    updateAppNudgeFlagsConnect,
    route,
    isLoggedIn,
    userId,
    cityName,
    cityId,
    redirectionLink,
    userDevice
}) => {

    const [device, setDevice] = useState("");
    const [visible, setVisible] = useState(isVisible);

    const {name: routeName} = route || {};
    const pageURL = typeof window !== "undefined" ? window.location.href : "";

    const dimensionObject =  React.useCallback(getGaEventDimensionObject({
        isLoggedIn,
        cityId,
        cityName,
        userId,
        pageURL
    }), [isLoggedIn, cityId, pageURL]);

    const onClose = async () => {
        setVisible(false);
        await yieldToMain();
        saveCookie("hide_app_nudge_header", true, NUMBER.ONE);
        updateAppNudgeFlagsConnect(false);
        sendCustomGaEvents({
            ...appNudgeHeaderDismiss,
            ...dimensionObject,
            eventCategory: routeToEventCategoryMapping[routeName]
        });
    };

    const onClickDownload = async() => {
        await yieldToMain();
        if (route && route.name === "buy-used-cars") {
            sendCustomGaEvents({
                ...downloadBannerClick,
                eventLabel: `${DOWNLOAD_BANNER_TYPES.HEADER} | ${device}`
            });

        } else {
            sendCustomGaEvents({
                ...appNudgeHeaderClick,
                ...dimensionObject,
                eventCategory: routeToEventCategoryMapping[routeName]
            });

        }

    };

    useEffect(() => {
        const os = getDeviceOs();
        if (os) {
            setDevice(os);
        }
        if (isVisible && (routeName !== "buy-used-cars")) {
            sendCustomGaEvents({
                ...appNudgeHeaderVisible,
                ...dimensionObject,
                eventCategory: routeToEventCategoryMapping[routeName]
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (device && isVisible && routeName === "buy-used-cars") {
            sendCustomGaEvents({
                ...downloadBannerView,
                eventLabel: `${DOWNLOAD_BANNER_TYPES.HEADER} | ${device}`
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device, route.name]);

    useEffect(() => {
        const appNudgeCookie = parseCookie("hide_app_nudge_header");
        if (!appNudgeCookie) {
            setVisible(true);
            updateAppNudgeFlagsConnect(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return  visible ? <DownloadAppHeader
        deviceType={device || ""}
        onClose={onClose}
        onClickDownload={onClickDownload}
        redirectionLink={redirectionLink}
        userDevice={userDevice}
    /> : null;
};

AppNudge.propTypes = {
    isVisible: PropTypes.bool,
    updateAppNudgeFlagsConnect: PropTypes.func,
    route: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    userId: PropTypes.string,
    cityName: PropTypes.string,
    cityId: PropTypes.number,
    redirectionLink: PropTypes.string,
    userDevice: PropTypes.string
};

export default React.memo(AppNudge, (prevProps, nextProps) => prevProps.isVisible === nextProps.isVisible);
