import { USER_JOURNEY_STEPS } from "../../constants/home-widget-constants";
import { parseDateString } from "../helpers/human-readable-date-parser";
import parseUserDropData from "./parse-user-drop-data";

/* eslint-disable camelcase */
export default (leadDetails = {}) => {
    const {
        year_id: variant_year_id,
        fullname: customerName,
        scrapLeadDetail = {},
        appointment = {},
        updated_at: updatedAtFormatted,
        kms: kilometreDriven,
        vehicle_reg_no: vehicleRegNo,
        stage
    } = leadDetails || {};

    const {
        netAmount,
        maxPrice,
        minPrice
    } = scrapLeadDetail || {};

    const {
        appointmentId: appointment_id,
        date,
        time,
        inspectionDate
    } = appointment || {};

    const {text} = parseDateString(updatedAtFormatted, true, false);

    const data =  {
        time,
        date,
        kilometreDriven,
        inspectionDate,
        vehicleRegNo,
        appointmentData: {
            ...leadDetails,
            variant_year_id,
            customerName,
            updatedAtFormatted: text,
            netAmount, maxPrice, minPrice,
            appointment_id,
            stage: stage ? stage : USER_JOURNEY_STEPS.LEAD
        }
    };

    return parseUserDropData({
        widgetDetails: {list: [{data}]},
        isUserToLead: true
    });
};
