import React from "react";
import PropTypes from "prop-types";
import { NUMBER } from "../../../constants/app-constants";
import { Helmet } from "react-helmet-async";

const convertImageUrl = (url, dpr) => {

    const match = url.match(/(\?dpr=(\d+))?$/);
    if (match) {
        const baseUrl = url.split("?dpr=")[0];
        const processedUrl = `${baseUrl}?dpr=${dpr} ${dpr}x`;
        return processedUrl;
    }

    return url;
};

const generateSrcSet = (url) => {
    if (!url) return "";
    return [NUMBER.ONE, NUMBER.TWO, NUMBER.THREE]
        .map((dpr) => convertImageUrl(url, dpr))
        .join(", ");
};

const ResponsiveImage = ({ src, alt, width, height, className, styleName, preload, onClick = () => {} }) => {
    const srcSet = src ? generateSrcSet(src) : "";
    return (
        <>
            {
                preload &&
                <Helmet>
                    <link
                        rel="preload"
                        href={src}
                        as={"image"}
                        fetchpriority="high"
                    />
                </Helmet>
            }
            <img
                src={src}
                srcSet={srcSet}
                alt={alt}
                width={width}
                height={height}
                className={className}
                styleName={styleName}
                onClick={onClick}
            />
        </>
    );
};

ResponsiveImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    styleName: PropTypes.string,
    preload: PropTypes.bool,
    onClick: PropTypes.func
};

ResponsiveImage.defaultProps = {
    alt: "",
    width: "auto",
    height: "auto",
    preload: true
};

export default ResponsiveImage;
