import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_PAGE_LOADING
`,
    {
        prefix: "repeatusertransition/"
    }
);
