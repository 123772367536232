import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarRegistrationLandingBannerExperiment from "./component";
import { fetchData, fetchYearsBasedOnMakeId } from "../lead-years/actions";
import { resetLeadFormData, setUserCarRgistrationNo, setRTO, setStep, setOwnerName, setVariantActiveScreen, activateLastStep, allStepFilled, updateLeadData, saveLeadFormData, sendClevertapEvents } from "../lead-form/actions";
import { setRegistrationNo, setAnsweredQuestions, setHypothecation } from "../sell-online-offer-price/actions";
import { getVehicleDetail, setUserCarRegistrationNO, setAllDetailFetchedCorrectly, setDataFaliure, checkInspectedAppointmentExistence, setIsRepeatInspection, setIsRegInputBoxActive } from "./actions";
import { setName } from "../../configuration/actions";
import { setU2lGaFlag } from "../user-journey-drop/actions";

const mapStateToProps = () => ({
    user: {
        isLoggedIn,
        statsigId,
        secureToken
    },
    registrationNo: {
        userCarRegistrationNo: registrationNo
    },
    leadData,
    widgetData,
    settings: {
        leadSource
    },
    experiments: {
        sellMarketing: {
            data: sellMarketingExperiment
        }
    },
    userDropWidget
}) => ({
    isLoggedIn,
    registrationNo,
    leadData,
    widgetData,
    leadSource,
    sellMarketingExperiment,
    userDropWidget,
    statsigId,
    secureToken
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setUserCarRegistrationNoConnect: setUserCarRegistrationNO,
    setRegistrationNoConnect: setRegistrationNo,
    getVehicleDetailConnect: getVehicleDetail,
    fetchYearListConnect: fetchData,
    fetchYearsBasedOnMakeIdConnect: fetchYearsBasedOnMakeId,
    setStepConnect: setStep,
    // setFuelTypeConnect: setFuelType,
    setOwnerNameConnect: setOwnerName,
    // setTransmissionTypeConnect: setTransmissionType,
    setVariantActiveScreenConnect: setVariantActiveScreen,
    setRTOConnect: setRTO,
    activateLastStepConnect: activateLastStep,
    allStepFilledConnect: allStepFilled,
    setUserCarRgistrationNoConnect: setUserCarRgistrationNo,
    resetLeadFormDataConnect: resetLeadFormData,
    setAllDetailFetchedCorrectlyConnect: setAllDetailFetchedCorrectly,
    setAnsweredQuestionsConnect: setAnsweredQuestions,
    setNameConnect: setName,
    setHypothecationConnect: setHypothecation,
    setDataFaliureConnect: setDataFaliure,
    checkInspectedAppointmentExistenceConnect: checkInspectedAppointmentExistence,
    setIsRepeatInspectionConnect: setIsRepeatInspection,
    setIsRegInputBoxActiveConnect: setIsRegInputBoxActive,
    updateLeadDataConnect: updateLeadData,
    saveLeadFormDataConnect: saveLeadFormData,
    setU2lGaFlagConnect: setU2lGaFlag,
    sendClevertapEventsConnect: sendClevertapEvents
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarRegistrationLandingBannerExperiment);

