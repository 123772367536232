import { DISCOUNT_END_DATE, NUMBER } from "../../constants/app-constants";
import { dateDiffrence } from "./date-diff-in-days";

export default (originalPrice, price) => {
    return !!originalPrice && originalPrice > price;
};

export const isDiscountPriceBanner = () => {
    const a = new Date();
    const b = new Date(DISCOUNT_END_DATE);
    const dateDiff = dateDiffrence(a, b);
    if (dateDiff > NUMBER.ZERO) return true;
    return false;
};

// until discounting experiment comes again
// export default () => {
//     return false;
// };
