import React from "react";
import styles from "./styles.css";
import LazyImage from "../../shared/lazy-image";
import closeIcon from "./images/close.svg";
import PropTypes from "prop-types";
import { TinyText } from "@cars24/turbo-web/lib/tiny-text";
import { BodyText } from "@cars24/turbo-web/lib/body-text";
// import { getApplinkLogo } from "../../../utils/helpers/get-applink-logo";
// import { BASE_URL } from "../tof-car-card/constants";
import { DEVICE_TYPE } from "../../../constants/app-constants";
import BASE_URL from "./images/family.png";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import { USER_PARSER_DEVICE_TYPES } from "../../../constants/super-app-constants";

const DownloadAppStrip = ({
    onClose,
    deviceType,
    onClickDownload,
    redirectionLink,
    userDevice
}) => {

    const { tokens } = useTokens();
    const HeadingText = userDevice === USER_PARSER_DEVICE_TYPES.TAB ? BodyText : TinyText;

    return (
        <React.Fragment>
            <div styleName={"styles.stripWrap styles.flexCenter"}>
                <span styleName={"styles.close"} onClick={onClose}><img src={closeIcon} alt="Close" /></span>

                <div styleName={"styles.contentWrap styles.flexCenter"}>

                    <LazyImage src={BASE_URL} width={60} height={36} alt="Download App" />

                    <div className={"media-body"}  styleName={"styles.mb"}>

                        <HeadingText
                            color={tokens.text_default_text_inverse}
                            isBold
                            level={3}
                        >
                                Get VIP access to car listings!
                        </HeadingText>
                        <div styleName={"styles.flexCenter"}>

                            <div styleName={"styles.rating"}>
                                <TinyText
                                    color={tokens.text_default_text_inverse}
                                >
                                    {deviceType === DEVICE_TYPE.ANDROID ? "4.4" : "4.5"}
                                </TinyText>
                            </div>

                            <TinyText
                                color={tokens.text_default_text_inverse}
                            >1Cr+ Downloads</TinyText>
                        </div>
                    </div>
                </div>

                <a styleName={"styles.button"} href={redirectionLink} onClick={onClickDownload}>
                    {/* <LazyImage src={getApplinkLogo(deviceType)} alt="store_icon" />*/} Get App</a>
            </div>
        </React.Fragment>
    );
};

DownloadAppStrip.propTypes = {
    onClose: PropTypes.func,
    deviceType: PropTypes.string,
    onClickDownload: PropTypes.func,
    redirectionLink: PropTypes.string,
    userDevice: PropTypes.string
};
export default DownloadAppStrip;
