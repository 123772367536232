import { AB_TEST_VARIATIONS } from "../constants/app-constants";

export const CHECKOUT_STEP_KEY = {
    PersonalDetails: "personalDetails",
    Financing: "financing",
    Delivery: "delivery",
    ConfirmBooking: "confirm",
    Kyc: "kyc",
    Confirmation: "confirmation"
};

export const FINANCE_TAB = {
    GetFinancing: "getfinancing",
    PayOnDelivery: "payondelivery"
};

export const GENDER = {
    Male: "Male",
    Female: "Female",
    Other: "Other"
};

export const DELIVERY_PICKUP_TAB = {
    SelfPickup: "PICK_UP",
    HomeDelivery: "HOME_DELIVERY"
};

const COMMON_LIST = [
    {
        heading: "Financing",
        subHeading: "EMI or Upfront",
        key: CHECKOUT_STEP_KEY.Financing
    },
    {
        heading: "Delivery or Pick-up",
        subHeading: "Schedule time and place",
        key: CHECKOUT_STEP_KEY.Delivery
    }
];

const CHECKOUT_STEP_PROGRESS_CONTROL_ITEMS = [
    ...COMMON_LIST,
    {
        heading: "Confirm Booking",
        subHeading: "Order Summary",
        key: CHECKOUT_STEP_KEY.ConfirmBooking
    },
    {
        heading: "KYC",
        subHeading: "Upload documents",
        key: CHECKOUT_STEP_KEY.Kyc
    }
];

const CHECKOUT_STEP_PROGRESS_VARIANT_ITEMS = [
    ...COMMON_LIST,
    {
        heading: "KYC",
        subHeading: "Upload documents",
        key: CHECKOUT_STEP_KEY.Kyc
    },
    {
        heading: "Confirm Booking",
        subHeading: "Order Summary",
        key: CHECKOUT_STEP_KEY.ConfirmBooking
    }
];

const COMMON_TAB = [
    { name: CHECKOUT_STEP_KEY.PersonalDetails, index: 1},
    { name: CHECKOUT_STEP_KEY.Financing, index: 2 },
    { name: CHECKOUT_STEP_KEY.Delivery, index: 3 }
];

export const CHECKOUT_STEP_CONTROL_TAB = [
    ...COMMON_TAB,
    { name: CHECKOUT_STEP_KEY.ConfirmBooking, index: 4 },
    { name: CHECKOUT_STEP_KEY.Kyc, index: 5 }
];

export const CHECKOUT_STEP_VARIANT_TAB = [
    ...COMMON_TAB,
    { name: CHECKOUT_STEP_KEY.Kyc, index: 3 },
    { name: CHECKOUT_STEP_KEY.ConfirmBooking, index: 4 }
];

export const STEP_PROGRESS_ITEMS = {
    [AB_TEST_VARIATIONS.CONTROL]: CHECKOUT_STEP_PROGRESS_CONTROL_ITEMS,
    [AB_TEST_VARIATIONS.VARIANT]: CHECKOUT_STEP_PROGRESS_VARIANT_ITEMS
};

export const MOBILE_STEP_PROGRESS_ITEMS = [
    {
        heading: "Personal Details",
        subHeading: "Personal details",
        key: CHECKOUT_STEP_KEY.PersonalDetails
    },
    {
        heading: "Financing",
        subHeading: "EMI or Upfront",
        key: CHECKOUT_STEP_KEY.Financing
    },
    {
        heading: "Test drive location",
        subHeading: "Schedule time and place",
        key: CHECKOUT_STEP_KEY.Delivery
    },
    {
        heading: "Confirm reservation",
        subHeading: "Order Summary",
        key: CHECKOUT_STEP_KEY.ConfirmBooking
    },
    {
        heading: "KYC",
        subHeading: "Upload documents",
        key: CHECKOUT_STEP_KEY.Kyc
    }
];

export const DOCUMENT_TYPE = {
    Adhar: "AADHAAR",
    Pan: "PAN"
};

export const OFFER_TYPE = {
    User: "USER",
    System: "SYSTEM",
    Customise: "CUSTOMISE",
    Customised: "CUSTOMISED"
};

export const SAVED_OFFER_TYPE = [
    OFFER_TYPE.System,
    OFFER_TYPE.Customise,
    OFFER_TYPE.Customised
];

export const EMI_DEFAULT_STEP = 1;
export const DOWNPAYMENT_DEFAULT_STEP = 1;

export const CHECKOUT_INITIAL_STEP = {
    CAR_RESERVED: "CAR_RESERVED",
    START_FINANCE: "START_FINANCE"
};

export const TIME_UNIT = 60;
export const START_TIMER_MIN_VAL = 0;
export const SEC_START_VALUE = 59;
export const INITIAL_TIMER_VALUE = 1;
export const MILLI_IN_MUNUTE = 60000;
export const RESERVED_HOURS = 96;
export const RESERVED_DAYS = 30;

export const ORDER_STATUS = {
    CREATED: "CREATED",
    EXPIRED: "EXPIRED",
    CANCELED: "CANCEL",
    BOOKED: "BOOKED",
    DELIVERED: "DELIVERED",
    RETURNED: "RETURNED"
};

export const FINANCING_TAB_TYPE = {
    GET_FINANCING: "Get Financing",
    PAY_ON_DELIVERY: "Pay on Delivery"
};

export const FINANCE_SCREEN = {
    FINANCE_MODE: "FINANCE_MODE",
    FINANCE: "GET_FINANCE",
    PAY_ON_DELIVERY: "PAY_ON_DELIVERY",
    FINANCE_BREAKUP: "FINANCE_BREAKUP",
    CF_FORM: "CF_FORM",
    SELECT_FINANCE_OFFER: "SELECT_FINANCE_OFFER",
    PRE_FINANCE_APPROVED: "PRE_FINANCE_APPROVED",
    FINANCE_OFFER_PENDING: "FINANCE_OFFER_PENDING",
    FINANCE_APPROVED: "FINANCE_APPROVED",
    FINANCE_CUSTOMISE: "FINANCE_CUSTOMISE",
    PENDING_STATEMENT: "PENDING_STATEMENT",
    PINOCDE_UNSERVICEABLE: "PINCODE_UNSERVICEABLE",
    SKIPPABLE_BANKING: "SKIPPABLE_BANKING",
    SHARE_STATEMENT: "SHARE_STATEMENT",
    POST_BOOKING_EMPTY_OFFER: "POST_BOOKING_EMPTY_OFFER",
    FINANCE_OFFER_FAILURE: "FINANCE_OFFER_FAILURE",
    CREDIT_REJECTED: "CREDIT_REJECTED"
};

export const COMPLIMENTARY_BENEFITS = [
    {
        name: "up to 12-Month warranty plans",
        amount: 0,
        displayText: "FREE",
        key: "warranty",
        description: "@599 per month"
    },
    {
        name: "7 day trial",
        amount: 0,
        displayText: "Included",
        key: "trial",
        description: "Test own your car for 7 days"
    },
    {
        name: "Insurance",
        amount: 0,
        displayText: "FREE",
        key: "insurance",
        description: "3rd party insurance with validity is included"
    },
    {
        name: "Service charges",
        amount: 0,
        displayText: "NEVER",
        key: "service_charge",
        description: "No service charges"
    }
];

export const RC_CHARGE =  {
    name: "RC Transfer by CARS24",
    displayText: "FREE",
    key: "rc_charge",
    description: "Additional Charges may apply",
    freeAmount: 5000
};

export const SHIPPING_CHARGE =  {
    name: "Shipping charges",
    displayText: "FREE",
    key: "shipping_charge",
    description: "Get your car delivered at selected location",
    freeAmount: 5000
};

export const BOOKING_SERVICE_CHARGES = [
    {
        name: "Shipping charges",
        amount: 0,
        displayText: "FREE",
        key: "shipping_charge",
        description: "Get your car delivered at selected location"
    },
    ...COMPLIMENTARY_BENEFITS
];

export const CHECKOUT_SCREEN_TYPE = {
    RESERVATION_START: "reservation_start",
    DELIVERY: "delivery",
    FINANCE: "finance",
    CONFIRM_BOOKING: "confirm_booking",
    BOOKING_CONFIRMATION_PAGE: "booking_confirmation_page",
    KYC: "kyc",
    FINANCE_PERSONAL: "finance - personal details",
    FINANCE_ADDRESS: "finance - address details",
    FINANCE_INCOME: "finance - income details",
    POST_BOOKING: "post booking",
    PAYMENT_OPTION: "payment option"
};

export const CHAT_SOURCE = {
    [CHECKOUT_SCREEN_TYPE.CONFIRM_BOOKING]: "order summary page",
    [CHECKOUT_SCREEN_TYPE.POST_BOOKING]: "post booking screen",
    [CHECKOUT_SCREEN_TYPE.PAYMENT_OPTION]: "payment option"
};

export const BANNER_TYPE = {
    COVID: "covid"
};

export const CHECKOUT_STEP_ID = {
    BASIC_DETAILS: "basic-details",
    PAYMENT_OPTIONS: "payment-options",
    TEST_DRIVE_LOCATION: "test-drive-location",
    TEST_DRIVE_OPTIONS: "test-drive-options",
    HOME_DELIVERY: "home-delivery",
    HUB_PICKUP: "hub-pickup",
    ORDER_SUMMARY: "order-summary",
    CONFIRMATION: "confirmation",
    COMPLETE_PAYMENT: "complete-payment",
    TRANSACTION_HISTORY: "transaction-history",
    PERSONAL_DETAILS: "personal-details",
    ADDRESS_DETAILS: "address-details",
    INCOME_DETAILS: "income-details",
    PINOCDE_UNSERVICEABLE: "pincode-unserviceable",
    FINANCE_APPROVED: "finance-approved",
    FINANCE_OFFERS: "finance-offers",
    ADD_ADDRESS: "add-address",
    CHANGE_ADDRESS: "change-address",
    CHANGE_HD_SLOT: "change-hd-slot",
    CHANGE_HUB_SLOT: "change-hub-slot",
    BANKING_OPTIONS: "banking-options",
    SHARE_STATEMENT: "share-statement",
    FINANCE_REJECTED: "finance-rejected",
    DELIVERED: "delivered",
    FREE_PAID_BOOKING: "free-paid-booking",
    VISIT_HUB: "visit-hub"
};

export const FINANCE_SCREEN_MAP = {
    [FINANCE_SCREEN.FINANCE_OFFER_PENDING]: CHECKOUT_STEP_ID.FINANCE_REJECTED,
    [FINANCE_SCREEN.CF_FORM]: CHECKOUT_STEP_ID.PERSONAL_DETAILS,
    [FINANCE_SCREEN.FINANCE_MODE]: CHECKOUT_STEP_ID.PAYMENT_OPTIONS,
    [FINANCE_SCREEN.CF_FORM]: CHECKOUT_STEP_ID.PERSONAL_DETAILS,
    [FINANCE_SCREEN.SELECT_FINANCE_OFFER]: CHECKOUT_STEP_ID.FINANCE_OFFERS,
    [FINANCE_SCREEN.PRE_FINANCE_APPROVED]: CHECKOUT_STEP_ID.FINANCE_APPROVED,
    [FINANCE_SCREEN.FINANCE_APPROVED]: CHECKOUT_STEP_ID.FINANCE_APPROVED,
    [FINANCE_SCREEN.FINANCE_CUSTOMISE]: CHECKOUT_STEP_ID.FINANCE_OFFERS,
    // PENDING_STATEMENT: "PENDING_STATEMENT",
    [FINANCE_SCREEN.PINOCDE_UNSERVICEABLE]: CHECKOUT_STEP_ID.PINOCDE_UNSERVICEABLE,
    [FINANCE_SCREEN.SKIPPABLE_BANKING]: CHECKOUT_STEP_ID.BANKING_OPTIONS,
    [FINANCE_SCREEN.SHARE_STATEMENT]: CHECKOUT_STEP_ID.SHARE_STATEMENT
};

export const CHECKOUT_NAVIGATION_TYPE = {
    BACK: "BACK",
    NEXT: "NEXT"
};

export const RESERVATION_OPTION = {
    FREE: "FREE",
    PAID: "PAID"
};

export const CHECKOUT_ROUTE = "/buy-used-:carMakeModel-cars-:city-:lid(\\d+)/checkout/:stepId?";

export const CHECKOUT_FLOW_TYPE = {
    CHECKOUT_WITH_LOGIN: "checkout_with_login",
    CHECKOUT_WITHOUT_LOGIN: "checkout_without_login"
};

export const KYC_SCREEN_TYPE = {
    DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
    BOOKING_CONFIRMED: "BOOKING_CONFIRMED"
};
