import React from "react";
import PropTypes from "prop-types";

const FaqSchema = ({ faqs = [] }) => {
    return (
        !!(faqs || []).length && <script type="application/ld+json" dangerouslySetInnerHTML={{
            __html: JSON.stringify(
                {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": faqs.map(({ question, answer }) => {
                        return {
                            "@type": "Question",
                            "name": question,
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": answer
                            }
                        };
                    })
                }
            )
        }} />
    );
};

FaqSchema.propTypes = {
    faqs: PropTypes.array
};

export default FaqSchema;

