import React from "react";
import styles from "./styles.css";
import closeIcon from "./images/close.svg";
import PropTypes from "prop-types";
import { getApplinkLogo } from "../../../utils/helpers/get-applink-logo";
import { BASE_URL } from "../tof-car-card/constants";
import StarImg from "./images/star.svg";
import { DEVICE_TYPE } from "../../../constants/app-constants";
import { Helmet } from "react-helmet-async";
import LazyImage from "../../shared/lazy-image";

const DownloadAppStripSell = ({
    onClose,
    deviceType,
    onClickDownload,
    statsigText
}) => {
    return (
        <React.Fragment>
            <Helmet>
                <link rel="preload" href={`${BASE_URL}india/buy/msite/app_nudge/GET_VIP_ACCESS_TOP_STICKY_BANNER.png`} as={"image"} type="image/webp" fetchpriority="high"/>
            </Helmet>
            <div styleName={"styles.stripWrap styles.stickyTop styles.flexCenter"}>
                <span styleName={"styles.close"} onClick={onClose}>
                    <img src={closeIcon} alt="Close" />
                </span>
                <div styleName={"styles.contentWrap styles.flexCenter"}>
                    <div styleName={"styles.animation"}>
                        <img
                            src={`${BASE_URL}india/buy/msite/app_nudge/GET_VIP_ACCESS_TOP_STICKY_BANNER.png`}
                            width={"41"}
                            height={"41"}
                            alt="Download App"
                        />
                    </div>
                    <div className={"media-body"}>
                        <h2 styleName={"styles.heading"}>{statsigText}</h2>
                        <div styleName={"styles.appDownloads"}>
                            <div styleName={"styles.ratting"}>
                                <img src={StarImg} alt="rating" />
                                {deviceType === DEVICE_TYPE.ANDROID ? "4.4" : "4.5"}
                            </div>
                            <span styleName={"styles.message"}>1 Cr+ Downloads</span>
                        </div>
                    </div>
                </div>
                <a
                    styleName={"styles.button"}
                    href={"https://cars24.app.link/6SBaA4dVNJb"}
                    onClick={onClickDownload}
                >
                    <LazyImage src={getApplinkLogo(deviceType)} alt="store_icon" />
                    Get App
                </a>
            </div>
        </React.Fragment>
    );
};

DownloadAppStripSell.propTypes = {
    onClose: PropTypes.func,
    deviceType: PropTypes.string,
    onClickDownload: PropTypes.func,
    statsigText: PropTypes.string
};
export default DownloadAppStripSell;
