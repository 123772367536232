/* eslint-disable no-unused-vars */
import Types from "./types";
import { LeadServiceJava } from "../../../service/lead-service-java-api";

import { SELL_ONLINE_USER_CHOICE } from "../../../constants/app-constants";
import { CarService } from "../../../service/car-service";
import { VehicleService } from "../../../service/vehicle-service";
import { carConsumerService } from "../../../service/car-consumer-gateway-api";

const isSubmittingData = (flag) => ({
    type: Types.IS_SUBMITTING_DATA,
    flag
});

const isMMYVsEligible = (flag) => ({
    type: Types.SET_MMYVS_ELIGIBLITY,
    flag
});

const isGsEligible = (flag) => ({
    type: Types.SET_IS_GS_ELIGIBLE,
    flag
});

const postOfferPriceInputSuccess = (data) => () => ({
    type: Types.POST_OFFER_PRICE_INPUT_SUCCESS,
    data
});

const setSellerReview = (review) => ({
    type: Types.SET_SELLER_REVIEW,
    review
});

const postOfferPriceInput = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.postOfferPriceInput(payload)
            .then(response => {
                dispatch(postOfferPriceInputSuccess({
                    userChoice: payload.quote_accepted,
                    isSubmitted: payload.quote_accepted === SELL_ONLINE_USER_CHOICE.SELL_NOW,
                    showFeedbackScreen: payload.quote_accepted !== SELL_ONLINE_USER_CHOICE.SELL_NOW
                }));
                resolve(response.data);
            })
            .catch(error => reject(error));
    });
};

const getSellerReview = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        carConsumerService.getSellerReviews()
            .then(response => {
                dispatch(setSellerReview(response.data.detail || {}));
                resolve(response.data);
            })
            .catch(error => reject(error));
    });
};

const setCarPriceDetail = ({offeredPrice, sellerReview}) => ({
    type: Types.SET_CAR_PRICE_DETAIL,
    offeredPrice,
    sellerReview,
    applicableForNewOfferScreen: true
});

const setIsSubmitted = (isSubmitted) => ({
    type: Types.SET_IS_SUBMITTED,
    isSubmitted
});

const fetchLeadDetailFailure = (error) => ({
    type: Types.FETCH_LEAD_DETAIL_FAILURE,
    dealPaymentInfoError: error
});

const setAuctionData = (data) => ({
    type: Types.SET_AUCTION_DATA,
    data
});

const postOfferPriceFeedback = (payload) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.postSellOnlineFeedback(payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const sellOnlineCallBackLead = (payload) => dispatch => {
    dispatch(isSubmittingData(true));
    return new Promise((resolve, reject) => {
        LeadServiceJava.sellOnlineCallBack(payload)
            .then(response => {
                resolve(response);
                dispatch(isSubmittingData(false));
            })
            .catch(error => {
                reject(error);
                dispatch(isSubmittingData(false));
            });
    });
};

const setActiveScreen = (screen) => ({
    type: Types.SET_ACTIVE_SCREEN,
    screen
});

const setAnsweredQuestions = ({ key, value }) => ({
    type: Types.STORE_ANSWERED_QUESTION,
    key,
    value
});
const setCurrentVersionQuestion = (data) => ({
    type: Types.GET_CURRENT_VERSION_QUESTION,
    data
});
const setVersionQuestionDetails = (data) => ({
    type: Types.GET_CURRENT_VERSION_DETAILS,
    data
});
const getSellOnlineQuestion = (payload) => () => {
    return new Promise((resolve, reject) => {
        VehicleService.getSellOnlineQuestion(payload)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => reject(error));
    });
};
const saveSellOnlineQuestion = (payload) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.saveOnlineQuestion(payload)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => reject(error));
    });
};

const fetchModelData = (makeId) => dispatch => {
    return new Promise((resolve, reject) => {
        CarService.fetchModels(makeId)
            .then(response => {
                resolve(response);
            })
            .catch(error => reject(error));
    });
};
const postSellOnlineQuestion = (payload) => () => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.postSellOnlineQuestion(payload)
            .then(response => {
                resolve(response.data.detail);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const setIsPincodeGsEligble = (flag) => ({
    type: Types.IS_PINCODE_GS_ELIGBLE,
    flag
});

const fetchAppointmentDetailSuccess = (data) => ({
    type: Types.FETCH_APPOINTMENT_DETAIL_SUCCESS,
    data
});

const fetchAppointmentDetailFailure = (error) => ({
    type: Types.FETCH_APPOINTMENT_DETAIL_FAILURE,
    error
});

const fetchAppointmentDetailsInit = (appointmentDetailsLoading) => ({
    type: Types.FETCH_APPOINTMENT_DETAIL_INIT,
    appointmentDetailsLoading
});

// eslint-disable-next-line max-params
const fetchAppointmentDetail = (token, secureToken, loading = true, upDateRedux = true) => dispatch => {
    dispatch(fetchAppointmentDetailsInit(loading));
    return new Promise((resolve, reject) => {
        LeadServiceJava.getAppointmentDetailsByToken(token, secureToken)
            .then(response => {
                if (upDateRedux) {
                    dispatch(fetchAppointmentDetailSuccess(response));
                }
                resolve(response);
            })
            .catch(error => {
                dispatch(fetchAppointmentDetailFailure(error));
                reject(error);
            });
    });
};

const fetchDateSlotSuccess = (data) => ({
    type: Types.FETCH_DATE_SLOTS_SUCCESS,
    data
});

const fetchDateSlotFailure = (error) => ({
    type: Types.FETCH_DATE_SLOTS_FAILURE,
    error
});

const fetchDateSlotInit = (dateSlotLoading) => ({
    type: Types.FETCH_DATE_SLOTS_INIT,
    dateSlotLoading
});

const fetchSlotsByLocationSuccess = ({ zone, slots, locations, homeInspectionPremiumSlotAvailable, homeInspectionExpressSlotAvailable }) => ({
    type: Types.FETCH_SLOTS_OFFER_BY_LOCATIONS_SUCCESS,
    zone,
    slots,
    locations,
    homeInspectionPremiumSlotAvailable,
    homeInspectionExpressSlotAvailable
});
const fetchSlotsFailure = () => ({
    type: Types.FETCH_SLOTS_OFFER_BY_LOCATIONS_FAILURE
});
const fetchSlots = (params, isRevamped = false) => (dispatch) => {
    fetchDateSlotInit();
    return new Promise((resolve, reject) => {
        carConsumerService.fetchGeoSlots(params, isRevamped)
            .then(response => {
                dispatch(fetchSlotsByLocationSuccess(response));
                const location = response.zone && response.zone.location_id ? Object.values(response.slots).filter(element => element.location_id === response.zone.location_id)[0] : Object.values(response.locations).reduce((a, b) => parseInt(a.distance) < parseInt(b.distance) ? a : b);
                dispatch(fetchDateSlotSuccess(response.slots[location.location_id]));
                resolve(response);
            })
            .catch(error => {
                dispatch(fetchDateSlotFailure(error));
                dispatch(fetchSlotsFailure());
                reject(error);
            });
    });
};

const updateAppointmentBookingFailure = (error) => ({
    type: Types.UPDATE_APPOINTMENT_BOOKING_FAILURE,
    error
});

const updateAppointmentBookingInit = (updateAppointmentBookingLoading) => ({
    type: Types.UPDATE_APPOINTMENT_BOOKING_INIT,
    updateAppointmentBookingLoading
});

const setOfferPrice = (price) => ({
    type: Types.SET_OFFER_PRICE,
    price
});

const setCurrentQuestionIndex = (index) => ({
    type: Types.SET_CURRENT_QUESTION_INDEX,
    index
});

const setDataFromSSR = (data) => ({
    type: Types.SET_DATA_FROM_SSR,
    data
});

const updateAppointmentBooking = (token, params) => (dispatch) => {
    dispatch(updateAppointmentBookingInit(true));
    return new Promise((resolve, reject) => {
        LeadServiceJava.rescheduleAppointment(token, params)
            .then(response => {
                dispatch(fetchAppointmentDetail(token, false));
                resolve(response);
            })
            .catch(error => {
                dispatch(updateAppointmentBookingFailure(error));
                reject(error);
            });
    });
};

const updateAppointment = (token, params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.updateAppointment(token, params)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const resetQuestions = () => ({
    type: Types.RESET_QUESTIONS
});

const setRegistrationNo = (number) => ({
    type: Types.SET_REGISTRATION_NUMBER,
    number
});

const setLeadCreatedType = (leadType) => ({
    type: Types.SET_LEAD_CREATED_TYPE,
    leadType
});

const setDealerCode = (code) => ({
    type: Types.SET_DEALER_CODE,
    code
});

const getDealerCarPrice = (code) => ({
    type: Types.GET_DEALER_CAR_PRICE,
    code
});

const getVehicleBoughtCountSuccess = (data) => ({
    type: Types.GET_VEHICLE_BOUGHT_COUNT,
    data
});

const getVehicleBoughtCount = (modelId) => dispatch => {
    return new Promise((resolve, reject) => {
        VehicleService.getVehicleBoughtCount(modelId)
            .then(response => {
                dispatch(getVehicleBoughtCountSuccess(response));
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const setUserReport = (report) => ({
    type: Types.SET_USER_REPORT,
    report
});

const setIsApplicableForPriceReport = (report) => ({
    type: Types.SET_IS_APPLICABLE_FOR_PRICE_REPORT,
    report
});

const setUserLocationAccessibility = (isUserLocationAccessible) => ({
    type: Types.SET_USER_LOCATION_ACCESSIBILITY,
    isUserLocationAccessible
});

const setUserResponse = (userResponse) => ({
    type: Types.SET_USER_RESPONSE,
    userResponse
});

const setHypothecation = (hypothecation) => ({
    type: Types.SET_HYPOTHECATION,
    hypothecation
});

const setSkipQuestions = (questionKey) => ({
    type: Types.SET_SKIP_QUESTIONS,
    questionKey
});

const setSelectedCarParts = (key, parts) => ({
    type: Types.SET_SELECTED_CAR_PARTS,
    parts,
    key
});

const resetSelectedParts = (key) => ({
    type: Types.RESET_SELECTED_CAR_PARTS,
    key
});

const setSoLeadData = (soLeadData) => ({
    type: Types.SET_SO_LEAD_DATA,
    soLeadData
});

const setCarExpertData = (carExpertDetails) => ({
    type: Types.SET_CAR_EXPERT_DATA,
    carExpertDetails
});

const setConsultationData = (data) => ({
    type: Types.SET_CONSULTATION_DATA,
    data
});

const setIsChangingLocation = (flag) => ({
    type: Types.SET_IS_CHANGING_LOCATION,
    flag
});

const fetchLeadDetails = (token, secureToken) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.fetchLeadDetailsProtected(token, {}, true, secureToken).then(response => {
            dispatch(setSoLeadData(response.data.detail));
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

const setSoLocation = (data) => ({
    type: Types.SET_SO_LOCATION,
    data
});

const getSellOnlinePrice = (appointmentId) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.getSellOnlinePrice(appointmentId)
            .then(response => {
                dispatch(setCarPriceDetail(response.data.detail));
                resolve(response.data.detail);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const setAppointmentDetailsLoading = (appointmentDetailsLoading) => ({
    type: Types.SET_APPOINTMENT_DETAILS_LOADING,
    appointmentDetailsLoading
});

const setVideoGallery = (videoGallery) => ({
    type: Types.SET_VIDEO_GALLERY,
    videoGallery
});

const setFlowType = (flowType) => ({
    type: Types.SET_FLOW_TYPE,
    flowType
});

export {
    fetchModelData,
    setSkipQuestions,
    resetSelectedParts,
    setCurrentVersionQuestion,
    setVersionQuestionDetails,
    getSellOnlineQuestion,
    saveSellOnlineQuestion,
    setCarPriceDetail,
    fetchLeadDetailFailure,
    postOfferPriceInput,
    setIsSubmitted,
    postOfferPriceFeedback,
    setAuctionData,
    sellOnlineCallBackLead,
    setActiveScreen,
    postSellOnlineQuestion,
    setIsPincodeGsEligble,
    fetchAppointmentDetail,
    fetchAppointmentDetailSuccess,
    fetchAppointmentDetailFailure,
    fetchAppointmentDetailsInit,
    fetchSlots,
    updateAppointmentBooking,
    updateAppointmentBookingInit,
    isMMYVsEligible,
    setAnsweredQuestions,
    isGsEligible,
    isSubmittingData,
    setOfferPrice,
    setCurrentQuestionIndex,
    setDataFromSSR,
    resetQuestions,
    setRegistrationNo,
    setLeadCreatedType,
    updateAppointment,
    setDealerCode,
    getDealerCarPrice,
    getVehicleBoughtCount,
    setUserReport,
    setIsApplicableForPriceReport,
    getSellerReview,
    setUserLocationAccessibility,
    setUserResponse,
    setHypothecation,
    setSelectedCarParts,
    fetchLeadDetails,
    setCarExpertData,
    setConsultationData,
    setSoLocation,
    getSellOnlinePrice,
    setSoLeadData,
    setIsChangingLocation,
    setAppointmentDetailsLoading,
    setVideoGallery,
    setFlowType
};
