/* eslint-disable no-unused-vars */
import { LeadServiceJava } from "../../../service/lead-service-java-api";

const fetchLeadDetails = (token, params, secureToken) => (dispatch) => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.fetchLeadDetails(token, params, true, secureToken).then(response => {
            resolve(response.data.detail);
        }).catch(error => {
            reject(error);
        });
    });
};

export {
    fetchLeadDetails
};
