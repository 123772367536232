/* eslint-disable max-statements */
/* eslint-disable complexity */
import { appUrl } from "../../constants/url-constants";
import qs from "querystring";

export default ({ carName, year, carId, city = "", pincode, flowType, params }) => {
    const url = ["buy", "used"];
    const carText = (carName || "");
    if (carText.includes("Suzuki")) carText.replace("Suzuki ", "");
    url.push(carText.split(" ").join("-"));
    url.push(year);
    url.push("cars");
    url.push((city || "").split(" ").join("-"));
    url.push(carId);
    const payload = [];
    const defaultParams = {
        ...(pincode && {pinId: pincode}),
        ...params
    };
    payload.push(qs.stringify(defaultParams));
    const payloadString = payload.join("&");
    const normalizedURL = url.join("-");
    const absoluteURL = `${appUrl()}/${normalizedURL}/${flowType ? `${flowType}/` : ``}${payloadString ? `?${payloadString}` : ``}`;
    const relativeURL = `/${normalizedURL}/${flowType ? `${flowType}/` : ``}${payloadString ? `?${payloadString}` : ``}`;
    let standardizedURL = absoluteURL;
    if (typeof window !== "undefined") {
        standardizedURL = window.location.hostname === "localhost" ? relativeURL : absoluteURL;
    }
    return {
        absoluteURL: absoluteURL.toLowerCase(),
        relativeURL: relativeURL.toLowerCase(),
        standardizedURL: standardizedURL.toLowerCase(),
        slug: normalizedURL.toLowerCase()
    };
};

export const carDetailUrlAu = ({ carName, carId, isCheckout }) => {
    const url = ["buy", "used"];
    const carText = (carName || "");
    url.push(carText.split(" ").join("-"));
    url.push("car");
    url.push(carId);
    const normalizedURL = url.filter(Boolean).join("-");
    return {
        absoluteURL: `${appUrl()}/au/${normalizedURL}/${isCheckout ? `checkout/` : ``}`,
        relativeURL: `/au/${normalizedURL}/${isCheckout ? `checkout/` : ``}`
    };
};
