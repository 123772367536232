import React from "react";
import skeleton from "./skeleton.css";

const Skeleton = () => {
    return (
        <div styleName={"skeleton.carCard"}>
            <div styleName={"skeleton.shimmerTitleCar"}>
                <div styleName={"skeleton.badgeTitleWrapper"}>
                    <div className="shimmer" styleName={"skeleton.shimmerBadge"} />
                    <div className="shimmer" styleName={"skeleton.shimmerTitle"} />
                </div>
                <div className="shimmer" styleName={"skeleton.shimmerCarImg"} />
            </div>
            <div className="shimmer" styleName={"skeleton.shimmerCta"} />
        </div>
    );
};

export default Skeleton;
