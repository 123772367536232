import React from "react";
import styles from "./styles/transparent-btn.css";
import PropTypes from "prop-types";
import IconSpinner from "../icon-spinner";

const TransparentButton = (props) => {
    const {
        text = "",
        leftImage = null,
        id = "",
        disabled = false,
        customStyle = [],
        onClick = null,
        isLoading = false,
        showTextWhileLoading = false
    } = props;
    let buttonStyles = [];
    customStyle.forEach(item => {
        buttonStyles.push(`styles.${item}`);
    });
    if (showTextWhileLoading && isLoading) buttonStyles.push("styles.disabledButton");
    buttonStyles = buttonStyles.join(" ");
    const disabledStyle = disabled ? "styles.disable-btn" : "";
    return (
        <button id={id}
            disabled={disabled || isLoading} onClick={onClick}
            type="button" className={"btn"}
            styleName={`styles.transparent-btn styles.btn-style ${disabledStyle} ${buttonStyles}`}>
            {
                isLoading ?
                    <React.Fragment>
                        {showTextWhileLoading && text}
                        <IconSpinner/>
                    </React.Fragment>
                    :
                    <span>
                        {leftImage && <img styleName={"styles.img-left"} src={leftImage} />}
                        {text}
                    </span>
            }
        </button>

    );
};

TransparentButton.propTypes = {
    leftImage: PropTypes.any,
    text: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    customStyle: PropTypes.array,
    onClick: PropTypes.func,
    isLoading: PropTypes.func,
    showTextWhileLoading: PropTypes.bool
};

export default TransparentButton;
