/* eslint-disable complexity */
import React, {useMemo} from "react";
import CarRegistrationLandingBannerExperiment from "../car-registration-landing-banner-experiment";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { sendCustomGaEvents } from "../../../tracking";
import { NUMBER } from "../../../constants/app-constants";
import Slider from "react-slick";
import { USER_JOURNEY_STEPS } from "../../../constants/home-widget-constants";
import { SLIDER_SETTINGS, SWIPE_DIRECTIONS} from "./selector";
import {  editManualClicked} from "./tracking";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import StageCard from "../../shared/stage-card";
import { Helmet } from "react-helmet-async";
import Banner from "./images/banner-bg-img.jpg";
import { useTokens } from "@cars24/turbo-web/lib/theme-provider";
import { Heading } from "@cars24/turbo-web/lib/heading";
import { getUserDropData } from "../../../utils/parsers/parse-user-drop-data";

const UserJourneyDrop = ({
    userDropList,
    history,
    cardHeading = "Want to sell another car?",
    isScrapFlow = false,
    leadData,
    resetLeadFormDataConnect,
    isUserToLead,
    isUserDropBeforeLogin = false,
    isUserDropAfterLogin = false,
    cmsContentDetails
}) => {
    const {returnBanner = []
    } = cmsContentDetails || {};
    const {
        heading = "Start a new journey, sell your car in minutes",
        image = Banner
    } = (returnBanner && returnBanner.length && returnBanner[NUMBER.ZERO]) || {};
    const { tokens } = useTokens();
    const { token } = leadData || {};
    const isUserToLeadCard = useMemo(() => {
        return isUserToLead && userDropList && userDropList.length && userDropList[NUMBER.ZERO]
        && userDropList[NUMBER.ZERO].screenType === USER_JOURNEY_STEPS.USER_TO_LEAD;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserToLead, (userDropList || []).length]);

    const routeToBrandsPage = async  () => {
        await yieldToMain();
        history.push("/sell-online-evaluation");
    };

    const handleManualDetailClick = async () => {
        await yieldToMain();
        if (token) resetLeadFormDataConnect(true);
        routeToBrandsPage();
        sendCustomGaEvents(editManualClicked);
    };

    const onCardSwipe = async (swipeDirection) => {
        const currentCardIndex = swipeDirection === SWIPE_DIRECTIONS.RIGHT ? NUMBER.ZERO : NUMBER.ONE;
        const { leadId } = userDropList[currentCardIndex];
        await yieldToMain();
        sendCustomGaEvents({
            event: "revamp_card_swipe",
            eventCategory: "card_swiped",
            eventAction: swipeDirection,
            eventLabel: leadId
        });
    };

    const carMakeModel = `${leadData.year.displayText} ${leadData.brand.displayText} ${leadData.model.displayText}`;
    return (
        <div>
            <Helmet>
                <link
                    rel="preload"
                    href={Banner}
                    imageSrcSet={Banner}
                    as={"image"}
                    // type={`image/${getFileExtension(Banner)}`}
                    fetchpriority="high"
                />
            </Helmet>

            <div styleName={"styles.sell-marketing-banner-wrapper"}>
                <img width="480" height="467" src={image} alt={"banner"} styleName={"styles.banner"} />
                <div styleName={"styles.cardWrapper"}>
                    <div styleName={"styles.bannerText"}>
                        <Heading level={1} color={tokens.text_default_text_primary}>{heading}</Heading>
                    </div>
                    <div styleName={"styles.sliderWrapper"}>
                        <div styleName={"styles.sliderTextWrapper"}>
                            {isUserDropBeforeLogin || isUserDropAfterLogin ? <StageCard
                                cardData={getUserDropData(isUserDropAfterLogin, carMakeModel)}
                                isUserDropBeforeLogin={isUserDropBeforeLogin}
                                isUserDropAfterLogin={isUserDropAfterLogin}
                            /> :
                                <Slider
                                    {...{...SLIDER_SETTINGS, ...(userDropList.length === NUMBER.ONE && {slidesToShow: NUMBER.ONE})}}
                                    onSwipe={onCardSwipe}
                                >
                                    {

                                        userDropList
                                            .filter(details => details.template !== null && Object.keys(details.template).length > 0)
                                            .map((details, index) => (
                                                <StageCard
                                                    key={`user-journey-screen-${index}`}
                                                    cardData={details.template}
                                                    stage={details.stage}
                                                    history={history}
                                                    price={details.price}
                                                    maxPrice={details.maxPrice}
                                                    minPrice={details.minPrice}
                                                    details={details}
                                                    leadData={leadData}
                                                />

                                            )
                                            )
                                    }
                                </Slider>

                            }

                        </div>
                    </div>
                </div>

            </div>
            <div styleName={"styles.carRegistration"}>
                <h2>{cardHeading}</h2>
                <CarRegistrationLandingBannerExperiment
                    isRepeatUser={true}
                />
                {(!isScrapFlow && !isUserToLeadCard) &&
                    <p styleName={"styles.orText"} onClick={handleManualDetailClick}>
                        OR Enter car details manually
                    </p>
                }
            </div>
        </div>
    );
};

UserJourneyDrop.propTypes = {
    userDropList: PropTypes.array,
    history: PropTypes.object,
    cardHeading: PropTypes.string,
    setFormDataConnect: PropTypes.func,
    isScrapFlow: PropTypes.bool,
    setStepConnect: PropTypes.func,
    activateLastStepConnect: PropTypes.func,
    setPageLoadingConnect: PropTypes.func,
    resetLeadFormDataConnect: PropTypes.func,
    leadData: PropTypes.object,
    resetPopupDataConnect: PropTypes.func,
    isUserToLead: PropTypes.bool,
    updateLeadConnect: PropTypes.func,
    fetchOffersDetailsConnect: PropTypes.func,
    isUserDropBeforeLogin: PropTypes.bool,
    isUserDropAfterLogin: PropTypes.bool,
    mainHeading: PropTypes.string,
    cmsContentDetails: PropTypes.object
};

export default UserJourneyDrop;

