import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MetaTags from "./component";
import { fetchData } from "./actions";

const mapStateToProps = (
    { meta: { home, seo, isSSR }}
) => ({
    home,
    seo,
    isSSR
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchDataConnect: fetchData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MetaTags);
