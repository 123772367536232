import { NUMBER } from "../../constants/app-constants";

const getUtcDate = date => Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

export const dateDiffrence = (a, b) => {
    const msPerDay = NUMBER.THOUSAND * NUMBER.SIXTY * NUMBER.SIXTY * NUMBER.TWENTY_FOUR;
    const utc1 = getUtcDate(a);
    const utc2 = getUtcDate(b);
    const remainingDays =  Math.floor((utc2 - utc1) / msPerDay) + 1; //+1 because to include current date
    return remainingDays.toString();
};

export const checkIfTwoDatesAreSame = (today, dateToCheck) => {
    return (new Date(today).toDateString() === new Date(dateToCheck).toDateString());
};
export const isTomorrow = (today, dateToCheck) => {
    const tomorrow = new Date(today);
    tomorrow.setDate(new Date().getDate() + 1);

    return checkIfTwoDatesAreSame(tomorrow, dateToCheck);
};

export const getDateLabel = (dateToCheck) => {

    const today = new Date();
    if (checkIfTwoDatesAreSame(today, dateToCheck)) return "Today";
    if (isTomorrow(today, dateToCheck)) return "Tomorrow";
    return "";
};
