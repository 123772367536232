import { SCRAP_CAR_TYPES } from "../../constants/seller-constant";
import { stringConstants } from "../../constants/app-constants";
import { C2B_LEAD_TYPES } from "../../constants/seller-lead-types";

export default (details = {}) => {
    const {
        is_scrap: isScrap = "",
        isWidgetScrap = "",
        lead_type: source = "",
        leadType = "",
        scrapLeadDetail = {}
    } = details || {};

    if ([leadType, source].includes(C2B_LEAD_TYPES.SCRAP_CAR)) {
        const { maxPrice = "", minPrice = "" } = scrapLeadDetail || {};
        if ((isScrap || isWidgetScrap || "").toLowerCase() === stringConstants.YES) {
            if (maxPrice && minPrice) return SCRAP_CAR_TYPES.ELIGIBLE;
            return SCRAP_CAR_TYPES.NON_SERVICIBLE;
        }
        return SCRAP_CAR_TYPES.NOT_ELIGIBLE;
    }
    return SCRAP_CAR_TYPES.NOT_ELIGIBLE;
};
