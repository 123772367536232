import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppNudgeHeaderSell from "./component";
import { updateSellNudgeValue } from "../app-nudge-header/action";

const mapStateToProps = ({
    experiments: {
        appDownloadBanner: {
            data: appDownloadBannerExperiment
        }
    },
    appNudge: {
        isSellAppNudgeVisible
    },
    leadData: {
        statsigText
    },
    user: {
        gaId,
        isSSR
    }
}) => {
    return {
        appDownloadBannerExperiment,
        isSellAppNudgeVisible,
        statsigText,
        gaId,
        isSSR
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSellAppNudgeFlagsConnect: updateSellNudgeValue
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppNudgeHeaderSell);
