import React, { useState } from "react";
import styles from "./styles/styles.css";
import PropTypes from "prop-types";
import priceFormatter from "../../../utils/helpers/price-formatter";
import ArrowIcon from "../../../components/shared/arrow";
import H2 from "../../shared/h2/component";

const SeoCarOverviewPrice = ({
    carFullName,
    model,
    price,
    onPageRedirection,
    variantsContent,
    hrefData
}) => {
    const { Petrol, Diesel } = price;
    const [activeTab, setActiveTab] = useState("petrol");
    const [activeData, setActiveData] = useState(Petrol);

    const changeTabs = (active) => {
        if (active === "petrol") {
            setActiveTab(active);
            setActiveData(Petrol);
        } else {
            setActiveTab(active);
            setActiveData(Diesel);
        }
    };
    return (
        <React.Fragment>
            {Petrol && Petrol.length > 0 && <React.Fragment>
                <div styleName={"styles.title"}>
                    <H2 text={`${carFullName} VARIANTS`} />
                </div>
                <p styleName={"styles.txt"}>{variantsContent}</p>
                {Diesel && Diesel.length > 0 ? <ul styleName={"styles.tabNav"}>
                    <li styleName={activeTab === "petrol" ? "styles.active" : ""}
                        onClick={() => changeTabs("petrol")}>
                        Petrol
                    </li>
                    {Diesel && Diesel.length > 0 && <li styleName={activeTab === "diesel" ? "styles.active" : ""}
                        onClick={() => changeTabs("diesel")}>
                        Diesel
                    </li>}
                </ul> : <div className="container"><H2 text={"Petrol"} /></div>}
                <div styleName={"styles.specificationsPrice"}>
                    <ul>
                        {activeData && activeData.length > 0 && activeData.map((varientPrice, index) => (
                            <li key={index}>
                                <p>{varientPrice.variantName}
                                    {/* <a href="#">Show price in my city</a> */}
                                </p>
                                <span>₹ {priceFormatter(varientPrice.exShowroomPrice)}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div styleName={"styles.readMore"} onClick={onPageRedirection}><a href={hrefData("variants")}>VIEW ALL {model} VARIANTS</a> <ArrowIcon /></div>
            </React.Fragment>}
        </React.Fragment>
    );
};

SeoCarOverviewPrice.propTypes = {
    carFullName: PropTypes.string,
    price: PropTypes.string,
    onPageRedirection: PropTypes.func,
    variantsContent: PropTypes.string,
    model: PropTypes.string,
    hrefData: PropTypes.func
};

export default SeoCarOverviewPrice;
