import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EvVehicles from "./component";
import withOtp from "../../shared/with-otp";
import { saveUserConsent } from "../../configuration/actions";
import { updateStatsigConfig, updateStatsigTimeStamp } from "../../shared/ab-experiment/actions";
import { resetTruecallerDetails } from "../truecaller-listener/actions";
import { setIsTrueCaller } from "../lead-form/actions";
import { getEvData } from "../lead-variants/actions";

const mapStateToProps = ({
    user: {
        isLoggedIn,
        mobile,
        gaId
    },
    leadData: {token}
}) => ({
    isLoggedIn,
    mobile,
    gaId,
    token
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveUserConsentConnect: saveUserConsent,
    updateStatsigConfigConnect: updateStatsigConfig,
    updateStatsigTimeStampConnect: updateStatsigTimeStamp,
    resetTruecallerDetailsConnect: resetTruecallerDetails,
    setIsTrueCallerConnect: setIsTrueCaller,
    getEvDataConnect: getEvData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withOtp(EvVehicles));
