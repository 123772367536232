import React from "react";
import Empty from "./images/emptyStar.svg";
import Fill from "./images/fillStar.svg";
import GradientEmpty from "./images/gradient-empty.svg";
import GradientFill from "./images/gradient-fill.svg";
import HalfStar from "./images/half-star.svg";
import PropTypes from "prop-types";
import LazyImage from "../lazy-image";

const IconStar = ({ type, isLazyLoaded = false }) => {
    let icon = Empty;
    if (type === "empty") {
        icon = Empty;
    }
    if (type === "fill") {
        icon = Fill;
    }
    if (type === "gradient-empty") {
        icon = GradientEmpty;
    }
    if (type === "gradient-fill") {
        icon = GradientFill;
    }
    if (type === "half") {
        icon = HalfStar;
    }
    if (isLazyLoaded) return <LazyImage src={icon} className="icon" alt="Rating" />;
    return (
        <img src={icon} className="icon" alt="Rating" />
    );
};
IconStar.propTypes = {
    type: PropTypes.string,
    isLazyLoaded: PropTypes.bool
};
export default IconStar;
