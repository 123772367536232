import React from "react";

const HomeIconFill = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clipPath="url(#clip0_5155_122396)">
                <path d="M17.082 3.83301H15.9342C15.4905 3.83301 15.1309 4.28401 15.1309 4.84035V11.7777C15.1309 12.334 15.4905 12.785 15.9342 12.785H17.082C17.5257 12.785 17.8853 12.334 17.8853 11.7777V4.84035C17.8853 4.28401 17.5257 3.83301 17.082 3.83301Z" fill="white" stroke="#0F0F10" strokeWidth="1.3" />
                <path d="M4.11523 10.7483L11.124 5.89868L17.8876 10.8598V16.9806C17.8876 18.0868 16.8641 18.9824 15.6 18.9824H6.40276C5.13873 18.9824 4.11523 18.0868 4.11523 16.9806V10.7483Z" fill="#EF6E0B" stroke="#0F0F10" strokeWidth="1.3" />
                <path d="M20.3342 11.0376L11.7498 4.13219C11.5437 3.93124 11.2721 3.83076 11.0004 3.83305C10.7288 3.83305 10.4572 3.93124 10.2488 4.13219L1.66671 11.0376C1.25693 11.4372 1.25693 12.0857 1.66671 12.4853C2.0765 12.8849 2.74152 12.8849 3.1513 12.4853L11.0004 6.29696L18.8496 12.4853C19.2594 12.8849 19.9244 12.8849 20.3342 12.4853C20.7416 12.0857 20.7439 11.4372 20.3342 11.0376Z" fill="white" stroke="#0F0F10" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M20.3342 11.0376L11.7498 4.13219C11.5437 3.93124 11.2721 3.83076 11.0004 3.83305C10.7288 3.83305 10.4572 3.93124 10.2488 4.13219L1.66671 11.0376C1.25693 11.4372 1.25693 12.0857 1.66671 12.4853C2.0765 12.8849 2.74152 12.8849 3.1513 12.4853L11.0004 6.29696L18.8496 12.4853C19.2594 12.8849 19.9244 12.8849 20.3342 12.4853C20.7416 12.0857 20.7439 11.4372 20.3342 11.0376Z" fill="white" stroke="#0F0F10" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M20.3342 11.0376L11.7498 4.13219C11.5437 3.93124 11.2721 3.83076 11.0004 3.83305C10.7288 3.83305 10.4572 3.93124 10.2488 4.13219L1.66671 11.0376C1.25693 11.4372 1.25693 12.0857 1.66671 12.4853C2.0765 12.8849 2.74152 12.8849 3.15131 12.4853L11.0004 6.29696L18.8496 12.4853C19.2594 12.8849 19.9244 12.8849 20.3342 12.4853C20.7416 12.0857 20.7439 11.4372 20.3342 11.0376Z" stroke="#0F0F10" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M8.93359 15.0188C8.93359 14.1653 9.62552 13.4734 10.479 13.4734H11.5198C12.3734 13.4734 13.0653 14.1653 13.0653 15.0188V18.9823H8.93359V15.0188Z" fill="white" stroke="#0F0F10" strokeWidth="1.3" />
            </g>
            <defs>
                <clipPath id="clip0_5155_122396">
                    <rect width="21" height="21" fill="white" transform="translate(0.5 0.907715)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default HomeIconFill;
