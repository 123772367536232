import { connect } from "react-redux";
import { updateAppNudgeFlags } from "./action";
import { bindActionCreators } from "redux";
import AppNudge from "./component";
import { withRouter } from "react-router";

const mapStateToProps = ({
    appNudge: {
        appNudgeHeader: {
            visible
        }
    },
    user: {
        isLoggedIn,
        userId
    },
    cities: { selectedCity },
    config: {
        deviceType
    }
}) => {
    const { id: cityId, name: cityName } = selectedCity || {};
    return {
        isVisible: visible,
        isLoggedIn,
        userId,
        cityName,
        cityId,
        userDevice: deviceType
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateAppNudgeFlagsConnect: updateAppNudgeFlags
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppNudge));
