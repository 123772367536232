import React from "react";

const SellIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clipPath="url(#clip0_6163_151072)">
                <path d="M6.71538 12.496L4.08432 8.07626C3.65074 7.3479 3.90636 6.40522 4.64851 5.99568L9.35534 3.3983C9.7396 3.18626 10.1974 3.15313 10.6082 3.30766L18.5739 6.30419C19.3111 6.58152 19.7082 7.38185 19.4832 8.13669L17.6222 14.3789C17.3896 15.1591 16.5772 15.6113 15.7916 15.3979L7.61116 13.1763C7.23563 13.0744 6.91443 12.8304 6.71538 12.496Z" fill="white" stroke="#717272" strokeWidth="1.3" />
                <circle cx="7.5748" cy="7.85905" r="1.00741" transform="rotate(-23.2048 7.5748 7.85905)" fill="white" stroke="#717272" />
                <path d="M16.8149 8.31383L15.4794 12.6156" stroke="white" strokeWidth="1.3" strokeLinecap="round" />
                <path d="M4.16385 12.9973L3.48711 7.89835C3.37559 7.05807 3.98197 6.29238 4.82545 6.20839L10.1749 5.67571C10.6116 5.63222 11.0455 5.78218 11.3622 6.08605L17.5027 11.9788C18.071 12.5242 18.1207 13.4162 17.6164 14.0213L13.4464 19.0253C12.9253 19.6507 12.0004 19.7463 11.3624 19.2406L4.71912 13.9755C4.41415 13.7338 4.21504 13.383 4.16385 12.9973Z" fill="white" stroke="#717272" strokeWidth="1.3" />
                <circle cx="7.04004" cy="9.4165" r="1.34277" fill="white" stroke="#717272" />
                <path d="M15.0943 13.1331L12.1719 16.5606" stroke="#717272" strokeWidth="1.3" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_6163_151072">
                    <rect width="21" height="21" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SellIcon;
