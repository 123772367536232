import React from "react";

const LoanIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clipPath="url(#clip0_5160_122553)">
                <path d="M10.9527 12.1533C14.8001 12.1406 17.9237 13.1714 17.9263 14.4556C17.9314 15.7398 14.814 16.7894 10.9666 16.7995C7.3332 16.8123 4.34541 15.8931 4.02426 14.7105C4.00167 14.6406 3.99207 14.5677 3.99296 14.4972C3.98787 13.2157 7.10517 12.1635 10.9527 12.1533Z" fill="white" />
                <path d="M17.9254 14.4558L17.956 17.1072C17.9595 17.5692 17.6957 17.9933 17.2803 18.1939C11.7406 20.8583 6.98394 19.5488 5.17101 18.8516C4.69405 18.6674 4.35982 18.2344 4.31091 17.7265L4.02344 14.7107C4.34459 15.8932 7.33238 16.8124 10.9657 16.7997C14.8132 16.7896 17.9306 15.74 17.9254 14.4558Z" fill="white" />
                <path d="M17.9286 14.4556C17.9337 15.7398 14.8164 16.7893 10.969 16.8021C7.11886 16.8123 3.99523 15.7815 3.99268 14.4973C3.99013 13.213 7.10489 12.1635 10.9524 12.1534C14.7998 12.1406 17.9234 13.1714 17.9286 14.4556Z" stroke="#717272" strokeWidth="1.3" strokeLinejoin="round" />
                <path d="M4.02539 14.7109L4.31541 17.7241C4.36432 18.2319 4.69861 18.6676 5.1729 18.8492C6.98583 19.5464 11.729 21.2191 17.2687 18.5547C17.6841 18.3541 17.9478 17.93 17.9444 17.468L17.9299 14.4534" stroke="#717272" strokeWidth="1.3" strokeLinejoin="round" />
                <circle cx="10.9602" cy="8.56714" r="6.00161" fill="white" stroke="#717272" strokeWidth="1.3" />
                <path d="M13.5615 5.99426C13.5615 6.21762 13.3783 6.41235 13.1434 6.41235H12.0037C12.124 6.60135 12.2214 6.80753 12.2729 7.03662H13.1434C13.3668 7.03662 13.5615 7.22562 13.5615 7.45471C13.5615 7.6838 13.3783 7.87279 13.1434 7.87279H12.2729C12.1011 8.64597 11.4367 9.23588 10.6349 9.32179C10.5776 9.33324 10.5089 9.33324 10.4345 9.33324H9.98201L11.305 10.662L12.187 11.5497C12.3531 11.7158 12.3531 11.9792 12.187 12.1453C12.1068 12.2198 11.9923 12.2656 11.8892 12.2656C11.7861 12.2656 11.6715 12.2198 11.5914 12.1453L8.66474 9.21297C8.5502 9.08697 8.51583 8.90942 8.5731 8.74906C8.64183 8.59443 8.79074 8.49134 8.96828 8.49134H10.4287C10.8525 8.49134 11.2191 8.22788 11.3852 7.86134H8.77356C8.55019 7.86134 8.35547 7.67234 8.35547 7.44325C8.35547 7.21416 8.53874 7.02516 8.77356 7.02516V7.03662H11.3966C11.2305 6.67007 10.864 6.41235 10.4345 6.41235H8.77356C8.55019 6.41235 8.35547 6.21762 8.35547 5.99426C8.35547 5.7709 8.53874 5.57617 8.77356 5.57617H13.1434C13.3668 5.57617 13.5615 5.76517 13.5615 5.99426Z" fill="#717272" />
            </g>
            <defs>
                <clipPath id="clip0_5160_122553">
                    <rect width="21" height="21" fill="white" transform="translate(0.5 0.594727)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LoanIcon;
