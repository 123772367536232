import axios from "axios";

export default () => {

    const uploadFile = (url, data) => {
        return axios.put(url, data, {
            headers: {
                "Content-Type": "image/jpeg"
            }
        });
    };

    return {
        uploadFile
    };
};
